import React from "react";
import "./Kontaktcontent.css";
import { ScrolltoTopbutton } from "../../ScrolltoTopbutton/ScrolltoTopbutton";
import { Anfunken } from "./Anfunken/Anfunken";

export function Kontaktcontent() {
  return (
    <div className="homecontent">
      <Anfunken />
      <ScrolltoTopbutton />
    </div>
  );
}
