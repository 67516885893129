import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./CTA.css";
import SVG_1440 from "../../../sources/SVG/CTA_1440.svg";
import SVG_mobile from "../../../sources/SVG/CTA_mobile.svg";

var cta_svg_width_1440,
  cta_svg_width_mobile,
  cta_svg_height,
  box,
  cta_svg_1440,
  cta_svg_mobile;

var onresize = function () {
  box = document.getElementById("svg-wrapper");
  cta_svg_1440 = document.getElementById("cta-svg-1440");
  cta_svg_mobile = document.getElementById("cta-svg-mobile");
  cta_svg_height = box.offsetHeight;
  cta_svg_width_1440 = cta_svg_height * 0.7;
  cta_svg_width_mobile = cta_svg_height * 0.659;

  cta_svg_1440.style.width = cta_svg_width_1440 + "px";
  cta_svg_1440.style.height = cta_svg_height + "px";
  cta_svg_mobile.style.width = cta_svg_width_mobile + "px";
  cta_svg_mobile.style.height = cta_svg_height + "px";
};

export function CTA() {
  useEffect(() => {
    window.addEventListener("resize", onresize);
    onresize();
    return () => {
      window.removeEventListener("resize", onresize);
    };
  });
  return (
    <div className="cta-wrapper">
      <div className="cta-content-wrapper">
        <div className="cta-content">
          <h2 className="cta-header white">
            Das Event lebt <nobr>von dir!</nobr>
          </h2>
          <Link to="/Kontakt" className="button">
            <p className="buttontext purple">Bring dich ein</p>
            <i className="icon-arrow-right purple"></i>
          </Link>
        </div>
        <div className="cta">
          <div className="svg-wrapper" id="svg-wrapper">
            <svg
              class="cta-logo-cut-out-mobile"
              viewBox="0 0 219 332"
              preserveAspectRatio="xMinYMid slice"
              id="cta-svg-mobile"
              xmlns="http://www.w3.org/2000/svg"
              shape-rendering="crispEdges"
            >
              <path d="M45.856 261.56C38.6264 237.866 23.2044 212.244 5.36644 187.106C3.65788 184.711 1.86298 182.259 0 179.753V148.109C10.904 163.421 23.8383 181.591 39.1078 203.54C45.7186 212.848 52.3461 221.871 58.7107 230.535C96.5754 282.083 125.134 320.963 85.5008 332H27.1273C48.9104 315.409 54.6099 290.296 45.856 261.56Z"></path>
              <path d="M19.0991 332C29.6101 326.15 36.7426 315.004 37.6592 297.817C39.2512 274.285 28.0534 240.888 0 198.977V332H19.0991Z"></path>
              <path d="M204.607 332H219V0H0.548178H0V0.00252427C0.182633 0.000842624 0.365362 0 0.548178 0C15.4933 0 31.3968 5.80292 43.4537 18.8572C55.5016 31.4287 67.0726 54.6359 68.5167 72.523C70.8815 98.3227 64.5439 113.573 57.3981 130.767C52.8933 141.607 48.0672 153.22 44.8978 168.736C41.5192 184.204 50.1974 208.865 67.0681 230.619C80.0833 244.639 94.5426 252.856 104.669 252.856C122.984 252.856 128.77 228.678 95.0284 167.766C82.4946 145.041 105.632 140.209 134.551 140.209C144.655 140.209 155.42 140.739 165.668 141.244C174.977 141.702 183.858 142.14 191.43 142.14C202.996 142.14 211.674 141.174 213.6 137.79C216.007 170.667 207.338 181.303 193.355 181.303C180.54 181.303 163.402 171.843 146.995 162.787C131.929 154.47 117.479 146.494 107.558 146.494C103.216 146.494 99.8422 147.943 97.4353 151.814C94.7604 156.464 101.323 168.924 109.214 183.905C122.65 209.413 139.936 242.23 122.017 256.24C118.161 259.141 113.82 260.59 109.483 260.59C100.805 260.59 91.6453 255.757 82.9715 248.989C143.456 317.099 187.375 329.623 204.607 332Z"></path>
              <path d="M0 133.483V40.18C5.11095 37.9024 10.9333 36.7443 16.933 36.7443C29.4668 36.7443 42.4819 42.0644 50.6743 53.6657C67.8483 78.0986 58.2254 108.834 49.2101 137.628C47.3431 143.591 45.5021 149.471 43.9306 155.194C38.6264 175.017 37.1823 192.904 47.3047 206.441C40.7017 197.611 31.0308 182.906 22.5364 169.989C19.8666 165.93 17.313 162.047 15.0075 158.579C9.14084 149.632 4.17184 141.276 0 133.483Z"></path>
            </svg>
            <svg
              class="cta-logo-cut-out-1440"
              viewBox="0 0 206.4 286"
              preserveAspectRatio="xMinYMid slice"
              id="cta-svg-1440"
              xmlns="http://www.w3.org/2000/svg"
              shape-rendering="crispEdges"
            >
              <path d="M0,167v79c0,16.1,9.5,30,23.3,36.3c6.8-5.5,11.2-14.2,11.9-26.6C36.6,234.8,26.1,204.8,0,167z"></path>
              <path d="M22,145.8c7.4,11.1,15.7,23.8,21.5,31.4c-8.8-11.6-7.5-27-2.9-44.1c1.4-4.9,3-10,4.6-15.1 c7.8-24.8,16.2-51.2,1.3-72.2c-7.1-10-18.4-14.6-29.2-14.6c-6.1,0-12,1.4-16.9,4C0.1,36.7,0,38.4,0,40v69.6 c4.1,8,9.2,16.8,15.5,26.4C17.5,138.9,19.7,142.3,22,145.8z"></path>
              <path d="M36.5,174.5c-13.4-19.1-24.8-34.9-34.3-48.2c-0.7-1-1.4-1.9-2.1-2.9v27.4c2.5,3.3,4.9,6.5,7.1,9.6 c15.5,21.7,28.8,43.7,35.1,64.1c7.4,24.1,2.9,45.2-14.8,59.5c3.9,1.3,8.1,2,12.5,2h33.1c39.5-8.1,14.2-42.3-19.7-88.1 C47.9,190.4,42.1,182.6,36.5,174.5z"></path>
              <path d="M40,0c-6,0-11.7,1.3-16.8,3.7c6.1,2.7,11.9,6.6,17,12c10.4,10.8,20.5,30.8,21.7,46.2c2,22.2-3.4,35.3-9.6,50.1 c-3.9,9.3-8.1,19.3-10.8,32.7c-2.9,13.3,4.6,34.5,19.2,53.2C72,210,84.5,217,93.3,217c15.9,0,20.9-20.8-8.4-73.2 c-10.9-19.6,9.2-23.7,34.3-23.7c8.8,0,18.1,0.5,27,0.9c8.1,0.4,15.8,0.8,22.3,0.8c10,0,17.5-0.8,19.2-3.7 c2.1,28.3-5.4,37.4-17.5,37.4c-11.1,0-26-8.1-40.2-15.9c-13.1-7.2-25.6-14-34.2-14c-3.8,0-6.7,1.2-8.8,4.6 c-2.3,4,3.4,14.7,10.2,27.6c11.6,21.9,26.6,50.2,11.1,62.2c-3.3,2.5-7.1,3.7-10.9,3.7c-7.5,0-15.5-4.2-23-10 c63.4,70.9,105.8,71.8,111.7,71.9c0.4,0,0.7,0,0.7,0v0.3h19.6V0H40z"></path>
            </svg>
          </div>
          <div className="cta-fill-rest"></div>
        </div>
      </div>
    </div>
  );
}
