import React, { useState } from "react";
import "./Pictures.css";
import { Pictureslider } from "./Pictureslider/Pictureslider";

export function Pictures() {
  return (
    <div className="pictures-wrapper">
      <h2 className="pictures-contentheading">Schöne Momente</h2>
      <Pictureslider />
      <div className="pictures-textwrapper">
        <p className="pictures-contenttext">
          Mehr als ein Abend, viele Erinnerungen. Wenn du bis zur nächsten Sause
          noch ein wenig in schönen Momenten schwelgen möchtest, bist du hier
          genau richtig! Alle Fotografien der bisherigen Veranstaltungen sind
          einsehbar und liegen zum Stöbern und herunterladen bereit .
        </p>
        <p className="pictures-contenttext">
          Du möchtest deine Lieblingspics nicht nur drucken, sondern auch auf
          Social Media posten? Dann würden wir uns über die Markierung
          @luftundlaune.art freuen!
        </p>
        <a
          className="button"
          href="https://www.picdrop.com/mrleonhirsch/mkPkZXjCj4"
        >
          <p className="buttontext pink">Mehr bildhafte Eindrücke</p>
          <i className="icon-link-extern pink"></i>
        </a>
      </div>
    </div>
  );
}
