import React, { useEffect } from "react";
import "./Pictureslider.css";

var counter;
var sliderinterval;

function intervalfn() {
  document.getElementById("sliderbutton" + counter).click();
  counter++;
  if (counter > 5) {
    counter = 1;
  }
}

export function Pictureslider() {
  useEffect(() => {
    sliderinterval = setInterval(intervalfn, 3000);
    counter = 2;
    document.getElementById("sliderbutton1").checked = true;
    var b1 = document.getElementById("sliderbutton1");
    var b2 = document.getElementById("sliderbutton2");
    var b3 = document.getElementById("sliderbutton3");
    var b4 = document.getElementById("sliderbutton4");
    var b5 = document.getElementById("sliderbutton5");
    var NAME = document.getElementById("slide1");
    b1.onclick = function () {
      NAME.className = "slide pic1";
      counter = 1;
    };
    b2.onclick = function () {
      NAME.className = "slide pic2";
      counter = 2;
    };
    b3.onclick = function () {
      NAME.className = "slide pic3";
      counter = 3;
    };
    b4.onclick = function () {
      NAME.className = "slide pic4";
      counter = 4;
    };
    b5.onclick = function () {
      NAME.className = "slide pic5";
      counter = 5;
    };
    return () => {
      window.clearInterval(sliderinterval);
    };
  });

  return (
    <div className="slider">
      <div className="slides">
        <div className="slide first" id="slide1" />
        <div className="slide" id="slide2" />
        <div className="slide" id="slide3" />
        <div className="slide" id="slide4" />
        <div className="slide" id="slide5" />
      </div>

      <div className="sliderbuttons">
        <input type="radio" name="sliderbutton" id="sliderbutton1" />
        <label for="sliderbutton1" />
        <input type="radio" name="sliderbutton" id="sliderbutton2" />
        <label for="sliderbutton2" />
        <input type="radio" name="sliderbutton" id="sliderbutton3" />
        <label for="sliderbutton3" />
        <input type="radio" name="sliderbutton" id="sliderbutton4" />
        <label for="sliderbutton4" />
        <input type="radio" name="sliderbutton" id="sliderbutton5" />
        <label for="sliderbutton5" />
      </div>
    </div>
  );
}
