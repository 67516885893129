import { useEffect, useState } from "react";
import "./ScrolltoTopbutton.css";

export function ScrolltoTopbutton() {
  const [isVisible, setisVisable] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setisVisable(true);
    } else {
      setisVisable(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="scrolltotopwrapper">
      <button
        className={isVisible ? "sttbutton" : "opacity0"}
        onClick={scrollToTop}
      >
        <i className="icon-arrow-up" />
      </button>
    </div>
  );
}
