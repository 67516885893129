import React from "react";
import "./Next.css";
import { Footer } from "../components/Vorschaltseite/Footer/Footer";
import { Nextcontent } from "../components/v1.0/Nextcontent/Nextcontent";
import { Navbar } from "../components/v1.0/Navbar/Navbar";

function Next() {
  return (
    <main className="website">
      <Navbar />
      <Nextcontent />
      <Footer />
    </main>
  );
}

export default Next;
