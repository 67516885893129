import React from "react";
import "./Impressum.css";
import { Footer } from "../components/Vorschaltseite/Footer/Footer";
import { Navbar } from "../components/v1.0/Navbar/Navbar";

function Impressum() {
  return (
    <div className="impressumwrapper">
      <div>
        <Navbar />
        <div className="impressumcontent">
          <h1 className="impressumheading impressumtext">Impressum</h1>
          <div className="impressumtextwrapper">
            <h5 className="impressumtext">Luft&Laune e.V.</h5>
            <p className="impressumtext">Verantwortlich:</p>
            <p className="impressumtext">
              Dennis Lossen
              <br />
              Scharnhorststr. 62
              <br />
              48151 Münster
              <br />
              Deutschland
            </p>
            <p className="impressumtext">Rechnungsadresse:</p>
            <p className="impressumtext">
              Luft&Laune e.V.
              <br />
              Scharnhorststr. 62
              <br />
              48151 Münster
              <br />
              Deutschland
              <br />
              <br />
              Booking@luftundlaune.art
            </p>
          </div>
          <a className="button" href="mailto:mail@luftundlaune.art">
            <p className="buttontext white">Kontaktiere uns via Mail</p>
            <i className="icon-arrow-right white"></i>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Impressum;
