import React, { useState } from "react";
import "./Homecontent.css";
import { ScrolltoTopbutton } from "../../ScrolltoTopbutton/ScrolltoTopbutton";
import { Pictures } from "./Pictures/Pictures";
import { Intro } from "./Intro/Intro";
import { Nextevent } from "./Nextevent/Nextevent";
import { CTA } from "../CTA/CTA";

export function Homecontent() {
  return (
    <div className="homecontent">
      <Intro />
      <Nextevent />
      <Pictures />
      <CTA />
      <ScrolltoTopbutton />
    </div>
  );
}
