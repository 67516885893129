import React from "react";
import "./Kontakt.css";
import "../fonts/fonts.css";
import { Footer } from "../components/Vorschaltseite/Footer/Footer";
import { Kontaktcontent } from "../components/v1.0/Kontaktcontent/Kontaktcontent";
import { Navbar } from "../components/v1.0/Navbar/Navbar";

function Kontakt() {
  return (
    <main className="website">
      <Navbar />
      <Kontaktcontent />
      <Footer />
    </main>
  );
}

export default Kontakt;
