import React from "react";
import "./Koncept.css";
import { Footer } from "../components/Vorschaltseite/Footer/Footer";
import { Konceptcontent } from "../components/v1.0/Konceptcontent/Konceptcontent";
import { Navbar } from "../components/v1.0/Navbar/Navbar";

function Koncept() {
  return (
    <main className="website">
      <Navbar />
      <Konceptcontent />
      <Footer />
    </main>
  );
}

export default Koncept;
