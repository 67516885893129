import React from "react";
import "./Home.css";
import "../fonts/fonts.css";
import { Footer } from "../components/Vorschaltseite/Footer/Footer";
import { Hero } from "../components/v1.0/Homecontent/Hero/Hero";
import { Homecontent } from "../components/v1.0/Homecontent/Homecontent";
import { Navbar } from "../components/v1.0/Navbar/Navbar";

function Home() {
  return (
    <main className="website">
      <Navbar />
      <Hero />
      <Homecontent />
      <Footer />
    </main>
  );
}

export default Home;
