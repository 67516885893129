import React, { useState } from "react";
import "./Awarenesscontent.css";
import { ScrolltoTopbutton } from "../../ScrolltoTopbutton/ScrolltoTopbutton";
import { Link } from "react-router-dom";

export function Awarenesscontent() {
  return (
    <div className="awareness-wrapper">
      <div className="awareness-content">
        <section className="awareness-text">
          <h1>Awareness</h1>
          <div>
            <p>
              <b className="p-highlight">
                Auf unseren Veranstaltungen sollen sich alle so sicher und wohl
                wie möglich fühlen.
              </b>
            </p>
            <p>
              Deshalb haben wir keinen Platz für Grenzüberschreitung,
              Belästigung und gewalttätiges Verhalten. Jegliche Art von
              Rassismus, Sexismus, Ableismus, Intoleranz und anderes
              diskriminierendes Verhalten wird bei uns nicht geduldet!
            </p>
            <p>
              <b className="p-highlight">
                Voneinander lernen, sich austauschen
              </b>{" "}
              und <b className="p-highlight">neue Sichtweisen kennenlernen</b>{" "}
              gehört zum ursprünglichsten Grundgedanken von Luft&Laune.
            </p>
            <p>
              Seid <b className="p-highlight">offen, verantwortungsbewusst</b>{" "}
              und informiert euch, wenn ihr etwas nicht wisst.
            </p>
            <p>
              Wir sind uns bewusst, dass es von Person zu Person verschieden
              ist, was Spaß bedeutet. Persönliche Grenzen sind individuell und
              subjektiv.{" "}
              <b className="p-highlight">
                Wir erkennen und respektieren die Grenzen anderer.
              </b>
            </p>
            <p>
              <b className="p-highlight">Dafür sind wir alle verantwortlich.</b>
            </p>
            <p>
              Wenn du Unterstützung brauchst, unangenehmes und unangebrachtes
              Verhalten erlebst oder beobachtest, sprich jemanden aus unserem{" "}
              <b className="p-highlight">Awareness-Team</b> an. Du erkennst das
              Awareness-Team bei Events von Luft&Laune an den{" "}
              <b className="p-highlight">leuchtenden Lichterketten.</b>
            </p>
          </div>
          <p>
            <div>
              <Link to="/Kontakt">
                Melde dich gern
                <i className="icon-arrow-right" />
              </Link>
            </div>{" "}
            wenn du Lust hast dich zu beteiligen, du Verbesserungspotential
            siehst oder beim nächsten Mal selbst Teil vom Team sein möchtest!
          </p>
        </section>
        <section className="awareness-table">
          <h2>Für ein freudvolles Miteinander:</h2>
          <ul>
            <li>
              <p>
                <i className="icon-bullet" />
                Wir sind respektvoll im Gespräch miteinander und respektieren
                Bedürfnisse nach Platz und Nähe.
              </p>
            </li>
            <li>
              <p>
                <i className="icon-bullet" />
                Wir begegnen anderen Lebensrealitäten, die von unseren eigenen
                abweichen, mit Offenheit und auf Augenhöhe.
              </p>
            </li>
            <li>
              <p>
                <i className="icon-bullet" />
                Wir bemühen uns, übergriffiges Verhalten zu erkennen und bei
                Bedarf die betroffene Person zu unterstützen.
              </p>
            </li>
          </ul>
        </section>
      </div>
      <ScrolltoTopbutton />
    </div>
  );
}
