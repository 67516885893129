import React, { useState } from "react";
import "./Hero.css";
import { Link } from "react-router-dom";
import Heroimage from "../../../../sources/Bilder/Heroimage.jpeg";

export function Hero() {
  return (
    <div className="heroimage">
      <div className="herowrapper">
        <h1 className="heroheading">
          Kreativ-Kollektiv für besondere Veranstaltungen
        </h1>
        <Link to="/Kontakt" className="button purplebackground">
          <p className="buttontext white">Mitwirken</p>
          <i className="icon-arrow-right white"></i>
        </Link>
      </div>
    </div>
  );
}
