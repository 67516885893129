import React, { useState } from "react";
import "./Nextcontent.css";
import { ScrolltoTopbutton } from "../../ScrolltoTopbutton/ScrolltoTopbutton";
import { Link } from "react-router-dom";
import { Nextevent } from "../Homecontent/Nextevent/Nextevent";

export function Nextcontent() {
  return (
    <div className="next-wrapper">
      <div className="nextcontent">
        <section className="next-content-heading-section">
          <h1>Nächstes Event</h1>
          <p>
            Freitag ist es wieder so weit: Luft&Laune geht in eine neue Runde!
            Tickets gibt es im Vorverkauf für 14 €* im{" "}
            <div className="next-links-wrapper">
              <a href="https://goo.gl/maps/RoYaUXzjrAH4W1dp6">
                FYAL
                <i className="icon-link-extern" />
              </a>
            </div>{" "}
            am Domplatz, dem{" "}
            <div className="next-links-wrapper">
              <a href="https://goo.gl/maps/CuqUn45dzLsYa2cRA">
                Café 8bar
                <i className="icon-link-extern" />
              </a>
            </div>{" "}
            am Leonardo-Campus, im{" "}
            <div className="next-links-wrapper">
              <a href="https://goo.gl/maps/Rxue1NmGSR2YMadn8">
                Teilchen & Beschleuniger
                <i className="icon-link-extern" />
              </a>
            </div>{" "}
            an der Wolbecker und im{" "}
            <div className="next-links-wrapper">
              <a href="https://goo.gl/maps/pRfAKfYDkdMUrfzo8">
                Wilma
                <i className="icon-link-extern" />
              </a>
            </div>{" "}
            am Hauptbahnhof. Wenn noch Tickets übrig sind, wird es eine
            Abendkasse geben!
          </p>
          <div>
            <p className="footnote">*</p>
            <p className="footnote">
              Wolltet ihr schon immer mal wissen, was ihr so mit eurem Ticket
              unterstützt? Für mehr Transparenz in der Veranstaltungsbranche
              schlüsseln wir euch{" "}
              <div className="next-links-wrapper">
                <Link className="next-about-link" to="/Konzept">
                  hier
                  <i className="icon-arrow-right" />
                </Link>
              </div>{" "}
              die Einnahmen auf.
            </p>
          </div>
        </section>
        <Nextevent />
        <section className="next-await-section">
          <h2>Dich erwarten</h2>
          <div>
            <ul>
              <li>
                <p>
                  <i className="icon-bullet" />
                  Performance Kunst / Aktzeichnen
                </p>
              </li>
              <li>
                <p>
                  <i className="icon-bullet" />
                  Electro / Techno
                </p>
              </li>
              <li>
                <p>
                  <i className="icon-bullet" />
                  Disco Perlen
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p>
                  <i className="icon-bullet" />
                  Improtheater
                </p>
              </li>
              <li>
                <p>
                  <i className="icon-bullet" />
                  Karaoke / Sex Talk
                </p>
              </li>
              <li>
                <p>
                  <i className="icon-bullet" />
                  Pizza / Drinks / Lagerfeuer
                </p>
              </li>
            </ul>
          </div>
        </section>
        <div className="timetable-wrapper">
          <section className="timetable-section">
            <h2>Programm</h2>
            <p>
              Bei uns gilt: Der frühe Vogel fängt den Wurm! Dich erwartet Punkt
              Einlass ein abwechslungsreiches Programm. Voll gepackt mit tollen
              Künstler:innen, Musik zum Abtanzen und kleinen Überraschungen,
              laden wir dich ein einen wundervollen Abend bei Luft&Laune zu
              verbringen.
            </p>
          </section>
          <div className="timetable-svg-wrapper">
            <svg
              className="timetable-svg"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 996 949.522"
              enable-background="new 0 0 996 949.522"
            >
              <g id="BG" display="none">
                <rect
                  y="0"
                  display="inline"
                  fill="#1E1B39"
                  width="996"
                  height="949.519"
                />
              </g>
              <g id="Lines">
                <line
                  opacity="0.5"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="55.249"
                  y1="79.748"
                  x2="996.212"
                  y2="79.748"
                />
                <line
                  opacity="0.5"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="55.249"
                  y1="194.343"
                  x2="996.212"
                  y2="194.343"
                />
                <line
                  opacity="0.5"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="55.249"
                  y1="308.939"
                  x2="996.212"
                  y2="308.939"
                />
                <line
                  opacity="0.25"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="32.656"
                  y1="137.046"
                  x2="996.212"
                  y2="137.046"
                />
                <line
                  opacity="0.25"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="32.656"
                  y1="251.641"
                  x2="996.212"
                  y2="251.641"
                />
                <line
                  opacity="0.5"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="55.249"
                  y1="423.534"
                  x2="996.212"
                  y2="423.534"
                />
                <line
                  opacity="0.25"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="32.656"
                  y1="366.236"
                  x2="996.212"
                  y2="366.236"
                />
                <line
                  opacity="0.5"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="55.249"
                  y1="538.129"
                  x2="996.212"
                  y2="538.129"
                />
                <line
                  opacity="0.25"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="32.656"
                  y1="480.831"
                  x2="996.212"
                  y2="480.831"
                />
                <line
                  opacity="0.5"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="55.249"
                  y1="652.724"
                  x2="996.212"
                  y2="652.724"
                />
                <line
                  opacity="0.25"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="32.656"
                  y1="595.427"
                  x2="996.212"
                  y2="595.427"
                />
                <line
                  opacity="0.5"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="55.249"
                  y1="767.319"
                  x2="996.212"
                  y2="767.319"
                />
                <line
                  opacity="0.5"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="55.249"
                  y1="881.915"
                  x2="996.212"
                  y2="881.915"
                />
                <line
                  opacity="0.25"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="32.656"
                  y1="710.022"
                  x2="996.212"
                  y2="710.022"
                />
                <line
                  opacity="0.25"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-miterlimit="10"
                  x1="32.656"
                  y1="824.617"
                  x2="996.212"
                  y2="824.617"
                />
              </g>
              <g id="Grafik">
                <g>
                  <path
                    fill="#E62E80"
                    d="M208.834,82.748c3.86,0,7,3.14,7,7v552.976c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7V89.748
			c0-3.86,3.14-7,7-7H208.834 M208.834,79.748h-132c-5.523,0-10,4.477-10,10v552.976c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10V89.748C218.834,84.225,214.357,79.748,208.834,79.748L208.834,79.748z"
                  />
                </g>
                <g>
                  <path
                    fill="#00E9B6"
                    d="M364.008,140.046c3.86,0,7,3.14,7,7v209.19c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7v-209.19
			c0-3.86,3.14-7,7-7H364.008 M364.008,137.046h-132c-5.523,0-10,4.477-10,10v209.19c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10v-209.19C374.008,141.523,369.531,137.046,364.008,137.046L364.008,137.046z"
                  />
                </g>
                <g>
                  <path
                    fill="#E62E80"
                    d="M519.156,82.748c3.86,0,7,3.14,7,7v266.488c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7V89.748
			c0-3.86,3.14-7,7-7H519.156 M519.156,79.748h-132c-5.523,0-10,4.477-10,10v266.488c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10V89.748C529.156,84.225,524.679,79.748,519.156,79.748L519.156,79.748z"
                  />
                </g>
                <g>
                  <path
                    fill="#E62E80"
                    d="M519.156,369.236c3.86,0,7,3.14,7,7v209.19c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7v-209.19
			c0-3.86,3.14-7,7-7H519.156 M519.156,366.236h-132c-5.523,0-10,4.477-10,10v209.19c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10v-209.19C529.156,370.713,524.679,366.236,519.156,366.236L519.156,366.236z"
                  />
                </g>
                <g>
                  <path
                    fill="#E62E80"
                    d="M519.156,598.427c3.86,0,7,3.14,7,7v151.893c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7V605.427
			c0-3.86,3.14-7,7-7H519.156 M519.156,595.427h-132c-5.523,0-10,4.477-10,10v151.893c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10V605.427C529.156,599.904,524.679,595.427,519.156,595.427L519.156,595.427z"
                  />
                </g>
                <g>
                  <path
                    fill="#00AED6"
                    d="M674.33,197.343c3.86,0,7,3.14,7,7v209.19c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7v-209.19
			c0-3.86,3.14-7,7-7H674.33 M674.33,194.343h-132c-5.523,0-10,4.477-10,10v209.19c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10v-209.19C684.33,198.821,679.853,194.343,674.33,194.343L674.33,194.343z"
                  />
                </g>
                <g>
                  <path
                    fill="#00AED6"
                    d="M364.008,483.831c3.86,0,7,3.14,7,7v94.595c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7v-94.595
			c0-3.86,3.14-7,7-7H364.008 M364.008,480.831h-132c-5.523,0-10,4.477-10,10v94.595c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10v-94.595C374.008,485.309,369.531,480.831,364.008,480.831L364.008,480.831z"
                  />
                </g>
                <g>
                  <path
                    fill="#00E9B6"
                    d="M829.469,426.534c3.86,0,7,3.14,7,7v209.19c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7v-209.19
			c0-3.86,3.14-7,7-7H829.469 M829.469,423.534h-132c-5.523,0-10,4.477-10,10v209.19c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10v-209.19C839.469,428.011,834.992,423.534,829.469,423.534L829.469,423.534z"
                  />
                </g>
                <path
                  fill="#E62E80"
                  d="M218.834,147.353h-152V89.748c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10V147.353z"
                />
                <path
                  fill="#00E9B6"
                  d="M374.008,234.35h-152v-87.304c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10V234.35z"
                />
                <path
                  fill="#E62E80"
                  d="M529.156,147.353h-152V89.748c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10V147.353z"
                />
                <path
                  fill="#E62E80"
                  d="M529.156,447.843h-152v-71.607c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10V447.843z"
                />
                <path
                  fill="#E62E80"
                  d="M529.156,663.031h-152v-57.604c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10V663.031z"
                />
                <path
                  fill="#00AED6"
                  d="M839.469,127.643h-152V89.748c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10V127.643z"
                />
                <g>
                  <path
                    fill="#00AED6"
                    d="M829.469,82.748c3.86,0,7,3.14,7,7v323.786c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7V89.748
			c0-3.86,3.14-7,7-7H829.469 M829.469,79.748h-132c-5.523,0-10,4.477-10,10v323.786c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10V89.748C839.469,84.225,834.992,79.748,829.469,79.748L829.469,79.748z"
                  />
                </g>
                <path
                  fill="#00AED6"
                  d="M684.33,280.318h-152v-75.975c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10V280.318z"
                />
                <path
                  fill="#00AED6"
                  d="M374.008,538.129h-152v-47.298c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10V538.129z"
                />
                <g>
                  <path
                    fill="#00AED6"
                    d="M984.643,541.129c3.86,0,7,3.14,7,7v37.298c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7v-37.298
			c0-3.86,3.14-7,7-7H984.643 M984.643,538.129h-132c-5.523,0-10,4.477-10,10v37.298c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10v-37.298C994.643,542.606,990.166,538.129,984.643,538.129L984.643,538.129z"
                  />
                </g>
                <path
                  fill="#00AED6"
                  d="M984.643,595.427h-132c-5.523,0-10-4.477-10-10v-37.298c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10
		v37.298C994.643,590.949,990.166,595.427,984.643,595.427z"
                />
                <g>
                  <path
                    fill="#00AED6"
                    d="M984.643,140.046c3.86,0,7,3.14,7,7v37.298c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7v-37.298
			c0-3.86,3.14-7,7-7H984.643 M984.643,137.046h-132c-5.523,0-10,4.477-10,10v37.298c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10v-37.298C994.643,141.523,990.166,137.046,984.643,137.046L984.643,137.046z"
                  />
                </g>
                <path
                  fill="#E62E80"
                  d="M984.643,194.343h-132c-5.523,0-10-4.477-10-10v-37.298c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10
		v37.298C994.643,189.866,990.166,194.343,984.643,194.343z"
                />
                <g>
                  <path
                    fill="#00AED6"
                    d="M208.834,311.939c3.86,0,7,3.14,7,7v67.585c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7v-67.585
			c0-3.86,3.14-7,7-7H208.834 M208.834,308.939h-132c-5.523,0-10,4.477-10,10v67.585c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10v-67.585C218.834,313.416,214.357,308.939,208.834,308.939L208.834,308.939z"
                  />
                </g>
                <path
                  fill="#00AED6"
                  d="M218.834,376.543h-152v-57.604c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10V376.543z"
                />
                <g>
                  <path
                    fill="#00E9B6"
                    d="M674.33,82.748c3.86,0,7,3.14,7,7v67.585c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7V89.748
			c0-3.86,3.14-7,7-7H674.33 M674.33,79.748h-132c-5.523,0-10,4.477-10,10v67.585c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10V89.748C684.33,84.225,679.853,79.748,674.33,79.748L674.33,79.748z"
                  />
                </g>
                <path
                  fill="#00E9B6"
                  d="M684.33,147.353h-152V89.748c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10V147.353z"
                />
                <g>
                  <path
                    fill="#E62E80"
                    d="M829.469,655.724c3.86,0,7,3.14,7,7v94.595c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7v-94.595
			c0-3.86,3.14-7,7-7H829.469 M829.469,652.724h-132c-5.523,0-10,4.477-10,10v94.595c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10v-94.595C839.469,657.201,834.992,652.724,829.469,652.724L829.469,652.724z"
                  />
                </g>
                <path
                  fill="#E62E80"
                  d="M839.469,720.329h-152v-57.604c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10V720.329z"
                />
                <g>
                  <path
                    fill="#E62E80"
                    d="M829.469,770.319c3.86,0,7,3.14,7,7v94.595c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7v-94.595
			c0-3.86,3.14-7,7-7H829.469 M829.469,767.319h-132c-5.523,0-10,4.477-10,10v94.595c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10v-94.595C839.469,771.797,834.992,767.319,829.469,767.319L829.469,767.319z"
                  />
                </g>
                <path
                  fill="#E62E80"
                  d="M839.469,834.924h-152v-57.604c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10V834.924z"
                />
                <g>
                  <path
                    fill="#E62E80"
                    d="M829.469,884.915c3.86,0,7,3.14,7,7v47.604c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7v-47.604
			c0-3.86,3.14-7,7-7H829.469 M829.469,881.915h-132c-5.523,0-10,4.477-10,10v47.604c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10v-47.604C839.469,886.392,834.992,881.915,829.469,881.915L829.469,881.915z"
                  />
                </g>
                <path
                  fill="#E62E80"
                  d="M829.469,949.519h-132c-5.523,0-10-4.477-10-10v-47.604c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10
		v47.604C839.469,945.042,834.992,949.519,829.469,949.519z"
                />
                <g>
                  <path
                    fill="#00AED6"
                    d="M208.834,224.354c3.86,0,7,3.14,7,7v37.298c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7v-37.298
			c0-3.86,3.14-7,7-7H208.834 M208.834,221.354h-132c-5.523,0-10,4.477-10,10v37.298c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10v-37.298C218.834,225.831,214.357,221.354,208.834,221.354L208.834,221.354z"
                  />
                </g>
                <path
                  fill="#E62E80"
                  d="M208.834,278.652h-132c-5.523,0-10-4.477-10-10v-37.298c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10
		v37.298C218.834,274.174,214.357,278.652,208.834,278.652z"
                />
                <g>
                  <path
                    fill="#00AED6"
                    d="M208.834,455.183c3.86,0,7,3.14,7,7v37.298c0,3.86-3.14,7-7,7h-132c-3.86,0-7-3.14-7-7v-37.298
			c0-3.86,3.14-7,7-7H208.834 M208.834,452.183h-132c-5.523,0-10,4.477-10,10v37.298c0,5.523,4.477,10,10,10h132
			c5.523,0,10-4.477,10-10v-37.298C218.834,456.66,214.357,452.183,208.834,452.183L208.834,452.183z"
                  />
                </g>
                <path
                  fill="#00AED6"
                  d="M208.834,509.48h-132c-5.523,0-10-4.477-10-10v-37.298c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10
		v37.298C218.834,505.003,214.357,509.48,208.834,509.48z"
                />
                <path
                  fill="#00E9B6"
                  d="M839.469,471.429h-152v-37.895c0-5.523,4.477-10,10-10h132c5.523,0,10,4.477,10,10V471.429z"
                />
                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  x1="24.657"
                  y1="895.981"
                  x2="24.657"
                  y2="934.883"
                />
                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  x1="24.657"
                  y1="666.63"
                  x2="24.657"
                  y2="753.414"
                />
                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  x1="24.657"
                  y1="781.225"
                  x2="24.657"
                  y2="868.009"
                />
                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  x1="24.657"
                  y1="552.034"
                  x2="24.657"
                  y2="638.819"
                />
                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  x1="24.657"
                  y1="437.439"
                  x2="24.657"
                  y2="524.224"
                />
                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  x1="24.657"
                  y1="322.844"
                  x2="24.657"
                  y2="409.628"
                />
                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  x1="24.657"
                  y1="208.249"
                  x2="24.657"
                  y2="295.033"
                />
                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  x1="24.657"
                  y1="93.654"
                  x2="24.657"
                  y2="180.438"
                />
                <polyline
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  points="66.687,51.855 24.657,51.855 
		24.657,65.843 	"
                />
                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  x1="253.213"
                  y1="51.855"
                  x2="218.981"
                  y2="51.855"
                />
                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  x1="407.424"
                  y1="51.855"
                  x2="342.804"
                  y2="51.855"
                />
                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  x1="555.71"
                  y1="51.855"
                  x2="498.887"
                  y2="51.855"
                />
                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  x1="726.923"
                  y1="51.855"
                  x2="660.952"
                  y2="51.855"
                />
                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  x1="867.534"
                  y1="51.855"
                  x2="800.016"
                  y2="51.855"
                />
                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  x1="996.204"
                  y1="51.855"
                  x2="970.534"
                  y2="51.855"
                />
              </g>
              <g id="Text">
                <g>
                  <path
                    fill="#E62E80"
                    d="M36.243,18h-8c-2.761,0-5-2.239-5-5V5c0-2.761,2.239-5,5-5h8c2.761,0,5,2.239,5,5v8
			C41.243,15.761,39.004,18,36.243,18z"
                  />
                  <g>
                    <path
                      fill="#FFFFFF"
                      d="M54.997,2.646h2.457l3.637,7.533h-0.162l3.637-7.533h2.375v11.466h-1.729V4.527h0.279l-3.924,8.226
				h-1.125l-3.906-8.181h0.172v9.54h-1.711V2.646z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M70.235,5.427h1.709v5.364c0,0.654,0.16,1.149,0.482,1.485c0.32,0.336,0.771,0.504,1.354,0.504
				c0.492,0,0.926-0.129,1.301-0.387c0.375-0.258,0.67-0.624,0.883-1.098s0.318-1.035,0.318-1.683h0.549
				c0,1.014-0.137,1.866-0.414,2.556c-0.275,0.69-0.662,1.213-1.16,1.57s-1.08,0.536-1.746,0.536c-0.637,0-1.201-0.117-1.697-0.351
				c-0.494-0.234-0.881-0.595-1.16-1.084s-0.418-1.121-0.418-1.895V5.427z M76.282,5.427h1.719v8.685h-1.719V5.427z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M84.678,14.346c-0.84,0-1.547-0.119-2.123-0.356s-1.016-0.567-1.318-0.99
				c-0.303-0.423-0.461-0.919-0.473-1.49h1.611c0.023,0.498,0.223,0.867,0.598,1.107c0.375,0.24,0.883,0.36,1.525,0.36
				c0.342,0,0.652-0.054,0.932-0.162s0.502-0.255,0.666-0.441c0.166-0.186,0.248-0.396,0.248-0.63c0-0.294-0.107-0.526-0.324-0.697
				s-0.564-0.322-1.045-0.455L83.204,9.99c-0.709-0.24-1.252-0.56-1.629-0.958c-0.379-0.399-0.568-0.877-0.568-1.436
				c0-0.714,0.318-1.288,0.955-1.723c0.635-0.435,1.479-0.653,2.529-0.653c1.086,0,1.924,0.229,2.514,0.688
				c0.592,0.459,0.9,1.034,0.924,1.724h-1.646c-0.043-0.342-0.213-0.604-0.51-0.788S85.047,6.57,84.491,6.57
				c-0.523,0-0.932,0.114-1.229,0.342c-0.297,0.229-0.447,0.486-0.447,0.774c0,0.516,0.445,0.9,1.332,1.152l1.863,0.657
				c0.709,0.229,1.254,0.522,1.639,0.882c0.385,0.36,0.576,0.816,0.576,1.368c0,0.444-0.146,0.864-0.441,1.26
				c-0.293,0.396-0.707,0.719-1.238,0.967C86.016,14.221,85.393,14.346,84.678,14.346z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M91.977,3.609c-0.342,0-0.609-0.095-0.805-0.284S90.88,2.88,90.88,2.556c0-0.312,0.098-0.559,0.293-0.742
				s0.463-0.275,0.805-0.275c0.336,0,0.602,0.092,0.797,0.275s0.293,0.431,0.293,0.742c0,0.324-0.098,0.581-0.293,0.77
				S92.313,3.609,91.977,3.609z M91.122,14.112V5.427h1.711v8.685H91.122z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M96.585,14.112V1.809h1.711v12.303H96.585z M98.051,8.973l4.113-3.519h2.35l-6.463,5.31V8.973z
				 M102.633,14.112l-4.014-5.49l1.674-0.351l4.428,5.841H102.633z"
                    />
                  </g>
                </g>
                <g>
                  <path
                    fill="#00AED6"
                    d="M280.338,18h-8c-2.761,0-5-2.239-5-5V5c0-2.761,2.239-5,5-5h8c2.761,0,5,2.239,5,5v8
			C285.338,15.761,283.099,18,280.338,18z"
                  />
                  <g>
                    <path
                      fill="#FFFFFF"
                      d="M299.047,14.112V2.646h3.843c1.278,0,2.263,0.318,2.952,0.954c0.69,0.636,1.035,1.515,1.035,2.637
				c0,1.11-0.345,1.981-1.035,2.614c-0.689,0.633-1.674,0.95-2.952,0.95h-2.114v4.311H299.047z M300.776,8.307h2.114
				c0.732,0,1.287-0.189,1.665-0.567s0.567-0.879,0.567-1.503c0-0.63-0.189-1.14-0.567-1.53c-0.378-0.39-0.933-0.585-1.665-0.585
				h-2.114V8.307z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M312.368,14.346c-0.744,0-1.416-0.192-2.017-0.576c-0.6-0.384-1.075-0.918-1.426-1.602
				c-0.352-0.684-0.527-1.476-0.527-2.376c0-0.954,0.175-1.771,0.522-2.453s0.822-1.205,1.422-1.57
				c0.6-0.366,1.275-0.549,2.025-0.549c0.672,0,1.247,0.137,1.728,0.41s0.867,0.639,1.161,1.098s0.496,0.979,0.607,1.561
				c0.111,0.583,0.131,1.182,0.059,1.8h-6.156v-0.99h4.986l-0.414,0.27c0.018-0.324,0.001-0.648-0.05-0.972
				c-0.051-0.324-0.148-0.623-0.292-0.896c-0.145-0.273-0.347-0.492-0.607-0.657c-0.262-0.165-0.593-0.248-0.995-0.248
				c-0.504,0-0.926,0.126-1.265,0.378c-0.339,0.252-0.595,0.59-0.77,1.013c-0.174,0.423-0.261,0.887-0.261,1.391v0.693
				c0,0.558,0.084,1.053,0.252,1.485c0.168,0.432,0.419,0.773,0.752,1.021c0.333,0.249,0.754,0.374,1.265,0.374
				c0.498,0,0.91-0.117,1.237-0.351c0.327-0.234,0.529-0.585,0.607-1.053h1.746c-0.09,0.564-0.297,1.056-0.621,1.476
				s-0.739,0.746-1.246,0.977C313.584,14.23,313.009,14.346,312.368,14.346z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M318.631,14.112V5.427h1.71v8.685H318.631z M319.874,10.242c0-1.11,0.115-2.032,0.347-2.768
				c0.23-0.735,0.555-1.282,0.972-1.642c0.417-0.36,0.907-0.54,1.472-0.54c0.168,0,0.325,0.012,0.473,0.036
				c0.146,0.024,0.292,0.057,0.437,0.099l-0.063,1.701c-0.162-0.042-0.337-0.08-0.526-0.113c-0.188-0.033-0.361-0.049-0.518-0.049
				c-0.492,0-0.894,0.13-1.206,0.392c-0.312,0.261-0.543,0.636-0.692,1.125c-0.15,0.489-0.226,1.075-0.226,1.759H319.874z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M324.5,6.615V5.454h4.779v1.161H324.5z M325.859,14.112V4.239c0-0.6,0.115-1.087,0.347-1.462
				c0.23-0.375,0.538-0.652,0.922-0.833s0.808-0.27,1.27-0.27c0.264,0,0.535,0.018,0.814,0.054c0.278,0.036,0.554,0.09,0.823,0.162
				l-0.324,1.332c-0.168-0.06-0.337-0.108-0.508-0.144c-0.172-0.036-0.335-0.054-0.491-0.054c-0.186,0-0.366,0.035-0.54,0.104
				s-0.316,0.193-0.427,0.374c-0.111,0.18-0.167,0.435-0.167,0.765v9.846H325.859z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M334.984,14.346c-0.786,0-1.489-0.171-2.11-0.513s-1.111-0.852-1.472-1.53
				c-0.359-0.678-0.54-1.515-0.54-2.511c0-1.014,0.181-1.859,0.54-2.534c0.36-0.675,0.851-1.184,1.472-1.525
				c0.621-0.342,1.324-0.513,2.11-0.513c0.774,0,1.471,0.171,2.088,0.513c0.618,0.342,1.105,0.851,1.463,1.525
				c0.356,0.675,0.535,1.52,0.535,2.534c0,0.996-0.179,1.833-0.535,2.511c-0.357,0.678-0.845,1.188-1.463,1.53
				C336.455,14.175,335.758,14.346,334.984,14.346z M334.93,12.852c0.498,0,0.929-0.12,1.292-0.36
				c0.362-0.24,0.642-0.589,0.837-1.048c0.194-0.459,0.292-1.009,0.292-1.651c0-0.984-0.199-1.746-0.599-2.286
				c-0.398-0.54-0.974-0.81-1.724-0.81c-0.504,0-0.938,0.122-1.3,0.365c-0.363,0.243-0.643,0.594-0.837,1.053
				c-0.195,0.459-0.293,1.019-0.293,1.679c0,0.966,0.201,1.717,0.604,2.254C333.604,12.584,334.18,12.852,334.93,12.852z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M341.716,14.112V5.427h1.71v8.685H341.716z M342.959,10.242c0-1.11,0.115-2.032,0.347-2.768
				c0.23-0.735,0.555-1.282,0.972-1.642c0.417-0.36,0.907-0.54,1.472-0.54c0.168,0,0.325,0.012,0.473,0.036
				c0.146,0.024,0.292,0.057,0.437,0.099l-0.063,1.701c-0.162-0.042-0.337-0.08-0.526-0.113c-0.188-0.033-0.361-0.049-0.518-0.049
				c-0.492,0-0.894,0.13-1.206,0.392c-0.312,0.261-0.543,0.636-0.692,1.125c-0.15,0.489-0.226,1.075-0.226,1.759H342.959z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M348.458,14.112V5.454h1.485l0.188,1.629c0.276-0.606,0.655-1.068,1.139-1.386
				c0.483-0.318,1.052-0.477,1.706-0.477c0.695,0,1.285,0.156,1.768,0.468c0.483,0.312,0.851,0.754,1.103,1.327
				c0.253,0.573,0.379,1.247,0.379,2.021v5.076h-1.701V9.036c0-0.75-0.179-1.318-0.536-1.706c-0.356-0.387-0.838-0.58-1.444-0.58
				c-0.479,0-0.898,0.13-1.255,0.391c-0.357,0.261-0.634,0.642-0.828,1.143c-0.195,0.501-0.293,1.112-0.293,1.832v3.996H348.458z
				 M360.455,14.112V9.036c0-0.75-0.179-1.318-0.535-1.706c-0.357-0.387-0.836-0.58-1.436-0.58c-0.721,0-1.276,0.282-1.67,0.846
				c-0.393,0.564-0.589,1.374-0.589,2.43h-0.747c0-0.834,0.081-1.554,0.242-2.16c0.162-0.606,0.395-1.104,0.698-1.494
				c0.303-0.39,0.666-0.679,1.089-0.868s0.896-0.284,1.417-0.284c0.696,0,1.286,0.156,1.769,0.468
				c0.483,0.312,0.851,0.754,1.103,1.327c0.252,0.573,0.378,1.247,0.378,2.021v5.076H360.455z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M367.924,14.346c-0.727,0-1.329-0.128-1.81-0.383c-0.479-0.255-0.838-0.589-1.075-1.003
				s-0.355-0.855-0.355-1.323c0-0.57,0.146-1.04,0.437-1.408c0.291-0.369,0.694-0.644,1.211-0.824c0.516-0.18,1.109-0.27,1.781-0.27
				c0.462,0,0.912,0.042,1.351,0.126c0.438,0.084,0.87,0.195,1.296,0.333v1.035c-0.349-0.126-0.733-0.227-1.156-0.302
				c-0.424-0.075-0.818-0.112-1.184-0.112c-0.546,0-1.002,0.108-1.368,0.324c-0.366,0.216-0.549,0.583-0.549,1.098
				c0,0.378,0.144,0.704,0.432,0.977s0.744,0.41,1.368,0.41c0.456,0,0.857-0.104,1.206-0.311c0.348-0.207,0.618-0.466,0.81-0.779
				c0.192-0.312,0.288-0.624,0.288-0.936h0.414c0,0.6-0.121,1.153-0.364,1.66c-0.243,0.507-0.596,0.915-1.058,1.224
				C369.136,14.191,368.578,14.346,367.924,14.346z M370.822,14.112l-0.216-1.989V8.514c0-0.708-0.146-1.222-0.44-1.543
				c-0.295-0.321-0.76-0.481-1.396-0.481c-0.36,0-0.68,0.053-0.958,0.158c-0.279,0.105-0.498,0.262-0.657,0.472
				c-0.159,0.21-0.238,0.474-0.238,0.792h-1.891c0-0.636,0.156-1.152,0.468-1.548c0.312-0.396,0.749-0.686,1.31-0.869
				c0.562-0.183,1.217-0.274,1.967-0.274c0.702,0,1.32,0.116,1.854,0.347c0.533,0.231,0.949,0.592,1.246,1.084
				s0.445,1.128,0.445,1.908v5.553H370.822z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M375.565,14.112V5.454h1.485l0.225,1.872v6.786H375.565z M381.631,14.112V9.036
				c0-0.75-0.179-1.318-0.536-1.706c-0.356-0.387-0.838-0.58-1.444-0.58c-0.479,0-0.898,0.13-1.255,0.391
				c-0.357,0.261-0.634,0.642-0.828,1.143c-0.195,0.501-0.293,1.112-0.293,1.832h-0.612c0-1.062,0.137-1.956,0.41-2.682
				c0.272-0.726,0.664-1.276,1.174-1.651c0.511-0.375,1.122-0.562,1.837-0.562c0.695,0,1.285,0.156,1.768,0.468
				c0.483,0.312,0.851,0.754,1.103,1.327c0.253,0.573,0.379,1.247,0.379,2.021v5.076H381.631z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M389.731,14.346c-0.708,0-1.368-0.189-1.98-0.567c-0.611-0.378-1.104-0.909-1.476-1.593
				c-0.372-0.684-0.559-1.488-0.559-2.412c0-0.972,0.187-1.795,0.559-2.471c0.372-0.675,0.864-1.191,1.476-1.548
				c0.612-0.357,1.272-0.536,1.98-0.536c0.521,0,0.986,0.083,1.395,0.248s0.759,0.387,1.053,0.666s0.533,0.593,0.716,0.94
				c0.183,0.348,0.311,0.708,0.383,1.08l-1.737,0.09c-0.126-0.45-0.347-0.811-0.661-1.084c-0.315-0.273-0.704-0.409-1.166-0.409
				c-0.39,0-0.757,0.109-1.103,0.328c-0.345,0.219-0.624,0.552-0.837,0.999c-0.213,0.447-0.319,1.013-0.319,1.697
				c0,0.636,0.099,1.179,0.297,1.629s0.468,0.795,0.811,1.035c0.342,0.24,0.726,0.36,1.151,0.36c0.612,0,1.069-0.148,1.373-0.445
				c0.303-0.297,0.454-0.707,0.454-1.229h1.737c0,0.606-0.142,1.152-0.423,1.638c-0.282,0.486-0.688,0.872-1.22,1.157
				S390.468,14.346,389.731,14.346z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M399.073,14.346c-0.744,0-1.416-0.192-2.017-0.576c-0.6-0.384-1.075-0.918-1.426-1.602
				c-0.352-0.684-0.527-1.476-0.527-2.376c0-0.954,0.175-1.771,0.522-2.453s0.822-1.205,1.422-1.57
				c0.6-0.366,1.275-0.549,2.025-0.549c0.672,0,1.247,0.137,1.728,0.41s0.867,0.639,1.161,1.098s0.496,0.979,0.607,1.561
				c0.111,0.583,0.131,1.182,0.059,1.8h-6.156v-0.99h4.986l-0.414,0.27c0.018-0.324,0.001-0.648-0.05-0.972
				c-0.051-0.324-0.148-0.623-0.292-0.896c-0.145-0.273-0.347-0.492-0.607-0.657c-0.262-0.165-0.593-0.248-0.995-0.248
				c-0.504,0-0.926,0.126-1.265,0.378c-0.339,0.252-0.595,0.59-0.77,1.013c-0.174,0.423-0.261,0.887-0.261,1.391v0.693
				c0,0.558,0.084,1.053,0.252,1.485c0.168,0.432,0.419,0.773,0.752,1.021c0.333,0.249,0.754,0.374,1.265,0.374
				c0.498,0,0.91-0.117,1.237-0.351c0.327-0.234,0.529-0.585,0.607-1.053h1.746c-0.09,0.564-0.297,1.056-0.621,1.476
				s-0.739,0.746-1.246,0.977C400.289,14.23,399.714,14.346,399.073,14.346z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M409.728,2.646h1.729v11.466h-1.729V2.646z M416.335,2.646h2.357l-7.425,7.623V7.902L416.335,2.646z
				 M413.374,7.443l5.634,6.669h-2.224l-4.616-5.553L413.374,7.443z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M420.807,5.427h1.71v5.364c0,0.654,0.16,1.149,0.481,1.485s0.772,0.504,1.354,0.504
				c0.492,0,0.926-0.129,1.301-0.387c0.375-0.258,0.669-0.624,0.882-1.098s0.319-1.035,0.319-1.683h0.549
				c0,1.014-0.138,1.866-0.414,2.556c-0.275,0.69-0.663,1.213-1.161,1.57s-1.079,0.536-1.745,0.536
				c-0.637,0-1.202-0.117-1.697-0.351s-0.882-0.595-1.161-1.084c-0.278-0.489-0.418-1.121-0.418-1.895V5.427z M426.855,5.427h1.719
				v8.685h-1.719V5.427z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M432.084,14.112V5.454h1.485l0.225,1.872v6.786H432.084z M438.15,14.112V9.036
				c0-0.75-0.179-1.318-0.536-1.706c-0.356-0.387-0.838-0.58-1.444-0.58c-0.479,0-0.898,0.13-1.255,0.391
				c-0.357,0.261-0.634,0.642-0.828,1.143c-0.195,0.501-0.293,1.112-0.293,1.832h-0.612c0-1.062,0.137-1.956,0.41-2.682
				c0.272-0.726,0.664-1.276,1.174-1.651c0.511-0.375,1.122-0.562,1.837-0.562c0.695,0,1.285,0.156,1.768,0.468
				c0.483,0.312,0.851,0.754,1.103,1.327c0.253,0.573,0.379,1.247,0.379,2.021v5.076H438.15z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M446.267,14.346c-0.84,0-1.548-0.119-2.124-0.356s-1.016-0.567-1.318-0.99
				c-0.303-0.423-0.461-0.919-0.473-1.49h1.611c0.023,0.498,0.224,0.867,0.599,1.107c0.375,0.24,0.883,0.36,1.525,0.36
				c0.342,0,0.652-0.054,0.932-0.162c0.278-0.108,0.501-0.255,0.666-0.441s0.247-0.396,0.247-0.63c0-0.294-0.107-0.526-0.324-0.697
				c-0.216-0.171-0.563-0.322-1.044-0.455l-1.772-0.603c-0.708-0.24-1.251-0.56-1.629-0.958s-0.567-0.877-0.567-1.436
				c0-0.714,0.318-1.288,0.954-1.723s1.479-0.653,2.529-0.653c1.086,0,1.924,0.229,2.515,0.688c0.592,0.459,0.899,1.034,0.923,1.724
				h-1.646c-0.042-0.342-0.212-0.604-0.509-0.788s-0.725-0.274-1.282-0.274c-0.522,0-0.932,0.114-1.229,0.342
				c-0.297,0.229-0.446,0.486-0.446,0.774c0,0.516,0.444,0.9,1.332,1.152l1.863,0.657c0.708,0.229,1.254,0.522,1.638,0.882
				c0.385,0.36,0.576,0.816,0.576,1.368c0,0.444-0.146,0.864-0.44,1.26s-0.707,0.719-1.238,0.967
				C447.604,14.221,446.981,14.346,446.267,14.346z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M451.415,6.615V5.454h5.589v1.161H451.415z M455.357,14.346c-0.492,0-0.937-0.094-1.332-0.279
				c-0.396-0.186-0.71-0.466-0.94-0.842c-0.231-0.375-0.347-0.847-0.347-1.417V3.096h1.701v8.658c0,0.816,0.381,1.224,1.143,1.224
				c0.234,0,0.462-0.026,0.685-0.077c0.222-0.051,0.474-0.127,0.756-0.229l0.243,1.215c-0.318,0.132-0.632,0.242-0.94,0.329
				C456.015,14.302,455.693,14.346,455.357,14.346z"
                    />
                  </g>
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M74.939,57.191V45.509h7.362v1.836h-5.238v3.078h4.788v1.818h-4.788v4.95H74.939z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M84.776,57.191v-8.766H86.9v8.766H84.776z M86.379,53.357c0-1.146,0.129-2.094,0.387-2.844
			c0.258-0.75,0.604-1.306,1.04-1.669c0.435-0.363,0.919-0.544,1.453-0.544c0.144,0,0.28,0.009,0.409,0.027
			c0.129,0.018,0.257,0.045,0.383,0.081l-0.036,2.124c-0.156-0.036-0.324-0.066-0.504-0.09s-0.342-0.036-0.486-0.036
			c-0.468,0-0.859,0.113-1.174,0.337c-0.315,0.225-0.553,0.557-0.711,0.995c-0.159,0.438-0.239,0.978-0.239,1.62H86.379z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M93.084,46.859c-0.414,0-0.737-0.109-0.968-0.329c-0.231-0.219-0.347-0.523-0.347-0.914
			c0-0.378,0.115-0.679,0.347-0.904c0.23-0.225,0.554-0.337,0.968-0.337c0.407,0,0.729,0.112,0.963,0.337
			c0.233,0.225,0.351,0.526,0.351,0.904c0,0.39-0.117,0.695-0.351,0.914C93.812,46.75,93.491,46.859,93.084,46.859z M92.021,57.191
			v-8.766h2.124v8.766H92.021z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M101.004,57.443c-0.841,0-1.562-0.115-2.165-0.347c-0.603-0.23-1.066-0.563-1.391-0.999
			c-0.323-0.435-0.489-0.958-0.495-1.571h1.963c0.018,0.432,0.188,0.747,0.513,0.945c0.324,0.198,0.765,0.297,1.323,0.297
			c0.306,0,0.577-0.045,0.814-0.135c0.236-0.09,0.421-0.213,0.553-0.369c0.132-0.156,0.198-0.33,0.198-0.522
			c0-0.246-0.089-0.439-0.266-0.58c-0.177-0.141-0.46-0.271-0.851-0.392l-1.907-0.684c-0.685-0.246-1.21-0.57-1.575-0.972
			c-0.366-0.402-0.549-0.882-0.549-1.44c0-0.738,0.334-1.333,1.003-1.787c0.669-0.453,1.564-0.679,2.687-0.679
			c1.116,0,1.979,0.245,2.588,0.733c0.608,0.489,0.922,1.108,0.94,1.858h-1.998c-0.03-0.288-0.168-0.516-0.414-0.684
			c-0.246-0.167-0.618-0.252-1.116-0.252c-0.45,0-0.803,0.092-1.058,0.275s-0.382,0.397-0.382,0.643
			c0,0.414,0.354,0.726,1.062,0.936l2.07,0.774c0.672,0.229,1.193,0.522,1.565,0.882s0.559,0.816,0.559,1.368
			c0,0.462-0.155,0.899-0.464,1.31c-0.309,0.411-0.739,0.746-1.291,1.004C102.368,57.315,101.729,57.443,101.004,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M110.552,57.443c-0.732,0-1.412-0.19-2.039-0.571c-0.627-0.381-1.134-0.918-1.521-1.611
			c-0.387-0.693-0.58-1.507-0.58-2.443c0-0.99,0.193-1.829,0.58-2.516s0.894-1.207,1.521-1.562c0.627-0.354,1.307-0.531,2.039-0.531
			c0.57,0,1.072,0.091,1.508,0.274c0.435,0.183,0.805,0.429,1.111,0.738c0.306,0.309,0.546,0.653,0.72,1.031
			s0.285,0.759,0.333,1.143l-2.142,0.054c-0.097-0.384-0.274-0.702-0.536-0.954c-0.261-0.252-0.599-0.378-1.013-0.378
			c-0.342,0-0.662,0.095-0.963,0.284c-0.3,0.189-0.544,0.481-0.733,0.877c-0.188,0.396-0.283,0.909-0.283,1.539
			c0,0.6,0.089,1.1,0.266,1.499s0.415,0.699,0.716,0.9c0.3,0.201,0.633,0.302,0.998,0.302c0.54,0,0.934-0.131,1.18-0.392
			c0.246-0.261,0.369-0.61,0.369-1.048h2.142c0,0.618-0.141,1.182-0.423,1.692c-0.282,0.51-0.696,0.917-1.242,1.219
			C112.013,57.292,111.344,57.443,110.552,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M116.771,57.191V44.843h2.124v12.348H116.771z M122.765,57.191V52.08c0-0.648-0.156-1.137-0.468-1.467
			c-0.312-0.33-0.721-0.495-1.225-0.495c-0.426,0-0.803,0.117-1.129,0.351c-0.327,0.234-0.584,0.587-0.77,1.058
			c-0.187,0.471-0.279,1.058-0.279,1.76h-0.702c0-1.134,0.141-2.078,0.423-2.831s0.681-1.315,1.197-1.688
			c0.516-0.372,1.122-0.558,1.818-0.558c0.72,0,1.321,0.161,1.804,0.481c0.483,0.321,0.847,0.772,1.09,1.354
			c0.242,0.582,0.364,1.26,0.364,2.034v5.112H122.765z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M127.985,57.191V44.843h2.124v12.348H127.985z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M133.286,48.425h2.124v5.364c0,0.57,0.141,0.998,0.423,1.282c0.282,0.285,0.669,0.428,1.161,0.428
			c0.42,0,0.803-0.109,1.147-0.329c0.345-0.219,0.621-0.543,0.828-0.972c0.207-0.429,0.311-0.961,0.311-1.597h0.576
			c0,1.068-0.139,1.953-0.414,2.655c-0.276,0.702-0.657,1.227-1.144,1.575c-0.485,0.348-1.047,0.522-1.683,0.522
			c-0.624,0-1.188-0.111-1.692-0.333c-0.504-0.222-0.902-0.579-1.196-1.071c-0.294-0.492-0.441-1.14-0.441-1.944V48.425z
			 M139.28,48.425h2.124v8.766h-2.124V48.425z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M143.96,49.865v-1.404H149v1.404H143.96z M145.255,57.191v-9.792c0-0.63,0.123-1.143,0.369-1.539
			s0.571-0.688,0.977-0.877s0.845-0.284,1.318-0.284c0.294,0,0.597,0.021,0.909,0.063c0.312,0.042,0.615,0.093,0.909,0.153
			l-0.414,1.638c-0.15-0.054-0.306-0.097-0.468-0.13c-0.162-0.033-0.318-0.049-0.469-0.049c-0.168,0-0.327,0.031-0.477,0.094
			c-0.15,0.063-0.273,0.173-0.369,0.329c-0.096,0.156-0.144,0.369-0.144,0.639v9.756H145.255z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M150.269,49.865v-1.404h5.724v1.404H150.269z M154.319,57.443c-0.516,0-0.988-0.096-1.417-0.288
			c-0.43-0.192-0.771-0.486-1.026-0.882s-0.383-0.9-0.383-1.512V46.14h2.124v8.55c0,0.72,0.342,1.08,1.026,1.08
			c0.21,0,0.42-0.024,0.63-0.072c0.21-0.048,0.45-0.12,0.72-0.216l0.307,1.494c-0.337,0.132-0.663,0.243-0.981,0.333
			S154.667,57.443,154.319,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M157.622,52.691v-1.854h5.058v1.854H157.622z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M169.258,57.443c-0.811,0-1.556-0.141-2.236-0.423c-0.682-0.282-1.233-0.711-1.656-1.287
			s-0.649-1.308-0.68-2.196h2.088c0,0.45,0.111,0.819,0.333,1.107s0.521,0.502,0.896,0.644c0.375,0.141,0.788,0.211,1.237,0.211
			c0.433,0,0.817-0.057,1.157-0.171c0.339-0.114,0.605-0.278,0.801-0.491c0.194-0.213,0.292-0.466,0.292-0.76
			c0-0.39-0.118-0.696-0.355-0.918c-0.236-0.222-0.616-0.426-1.138-0.612l-2.305-0.918c-0.899-0.354-1.59-0.765-2.069-1.233
			c-0.48-0.468-0.721-1.113-0.721-1.935c0-0.948,0.375-1.716,1.125-2.304c0.75-0.588,1.797-0.882,3.142-0.882
			c1.308,0,2.318,0.305,3.032,0.914c0.715,0.609,1.119,1.457,1.216,2.542h-2.089c-0.077-0.462-0.287-0.833-0.63-1.111
			c-0.342-0.279-0.852-0.419-1.529-0.419c-0.601,0-1.074,0.125-1.422,0.374c-0.349,0.249-0.522,0.562-0.522,0.941
			c0,0.324,0.109,0.58,0.328,0.769c0.22,0.189,0.56,0.371,1.021,0.545l2.557,0.99c0.863,0.336,1.526,0.759,1.988,1.269
			c0.463,0.51,0.693,1.149,0.693,1.917c0,0.702-0.177,1.31-0.531,1.823c-0.354,0.513-0.87,0.91-1.548,1.192
			S170.23,57.443,169.258,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M179.365,57.443c-0.75,0-1.376-0.131-1.877-0.392s-0.877-0.604-1.129-1.03
			c-0.252-0.426-0.378-0.888-0.378-1.386c0-0.552,0.146-1.015,0.437-1.39s0.703-0.657,1.237-0.846s1.164-0.284,1.89-0.284
			c0.475,0,0.923,0.036,1.346,0.108c0.423,0.072,0.845,0.168,1.265,0.288v1.242c-0.33-0.108-0.69-0.192-1.08-0.252
			s-0.756-0.09-1.098-0.09c-0.504,0-0.918,0.093-1.242,0.279s-0.486,0.501-0.486,0.945c0,0.324,0.132,0.603,0.396,0.837
			c0.264,0.234,0.684,0.351,1.26,0.351c0.438,0,0.81-0.096,1.116-0.288c0.306-0.192,0.538-0.432,0.697-0.72s0.238-0.576,0.238-0.864
			h0.504c0,0.63-0.117,1.211-0.351,1.742c-0.234,0.531-0.581,0.956-1.04,1.273S180.043,57.443,179.365,57.443z M182.227,57.191
			l-0.271-2.124v-3.51c0-0.612-0.124-1.065-0.373-1.359c-0.249-0.294-0.671-0.441-1.265-0.441c-0.342,0-0.639,0.051-0.892,0.153
			c-0.252,0.102-0.445,0.247-0.58,0.436c-0.135,0.189-0.202,0.419-0.202,0.689h-2.322c0-0.66,0.159-1.199,0.477-1.616
			c0.318-0.417,0.775-0.723,1.373-0.918c0.597-0.195,1.312-0.292,2.146-0.292c0.756,0,1.416,0.122,1.979,0.364
			c0.564,0.243,1.002,0.617,1.314,1.121c0.312,0.504,0.468,1.149,0.468,1.935v5.562H182.227z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M187.195,57.191V44.843h2.124v12.348H187.195z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M196.114,57.443c-0.799,0-1.52-0.171-2.165-0.513c-0.645-0.342-1.156-0.853-1.534-1.534
			s-0.567-1.535-0.567-2.561c0-1.044,0.189-1.908,0.567-2.592c0.378-0.684,0.89-1.194,1.534-1.53
			c0.646-0.336,1.366-0.504,2.165-0.504c0.786,0,1.498,0.168,2.137,0.504c0.64,0.336,1.148,0.846,1.526,1.53
			c0.378,0.684,0.566,1.548,0.566,2.592c0,1.026-0.188,1.879-0.566,2.561s-0.887,1.192-1.526,1.534
			C197.612,57.273,196.9,57.443,196.114,57.443z M196.042,55.589c0.42,0,0.793-0.104,1.12-0.311s0.585-0.514,0.774-0.922
			c0.188-0.408,0.283-0.915,0.283-1.521c0-0.924-0.182-1.62-0.544-2.088c-0.363-0.468-0.859-0.702-1.49-0.702
			c-0.426,0-0.804,0.104-1.134,0.311s-0.589,0.518-0.778,0.932c-0.189,0.414-0.283,0.93-0.283,1.548
			c0,0.912,0.185,1.599,0.553,2.061C194.912,55.358,195.412,55.589,196.042,55.589z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M202.863,57.191v-8.73h1.854l0.27,1.728v7.002H202.863z M208.857,57.191V52.08
			c0-0.648-0.156-1.137-0.468-1.467c-0.312-0.33-0.72-0.495-1.225-0.495c-0.426,0-0.802,0.117-1.129,0.351
			c-0.327,0.234-0.584,0.587-0.77,1.058c-0.187,0.471-0.279,1.058-0.279,1.76h-0.702c0-1.134,0.142-2.078,0.423-2.831
			c0.282-0.753,0.682-1.315,1.197-1.688c0.517-0.372,1.122-0.558,1.818-0.558c0.72,0,1.321,0.161,1.805,0.481
			c0.482,0.321,0.846,0.772,1.089,1.354s0.364,1.26,0.364,2.034v5.112H208.857z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M569.155,57.443c-0.811,0-1.556-0.141-2.237-0.423c-0.681-0.282-1.232-0.711-1.655-1.287
			c-0.424-0.576-0.649-1.308-0.68-2.196h2.088c0,0.45,0.111,0.819,0.333,1.107s0.521,0.502,0.896,0.644
			c0.375,0.141,0.787,0.211,1.237,0.211c0.432,0,0.817-0.057,1.156-0.171c0.339-0.114,0.606-0.278,0.802-0.491
			c0.194-0.213,0.292-0.466,0.292-0.76c0-0.39-0.118-0.696-0.355-0.918c-0.237-0.222-0.616-0.426-1.139-0.612l-2.304-0.918
			c-0.9-0.354-1.59-0.765-2.07-1.233c-0.479-0.468-0.72-1.113-0.72-1.935c0-0.948,0.375-1.716,1.125-2.304
			c0.75-0.588,1.797-0.882,3.141-0.882c1.309,0,2.319,0.305,3.033,0.914s1.119,1.457,1.215,2.542h-2.088
			c-0.078-0.462-0.288-0.833-0.63-1.111c-0.342-0.279-0.852-0.419-1.53-0.419c-0.6,0-1.073,0.125-1.422,0.374
			c-0.348,0.249-0.521,0.562-0.521,0.941c0,0.324,0.109,0.58,0.328,0.769c0.219,0.189,0.56,0.371,1.021,0.545l2.556,0.99
			c0.864,0.336,1.527,0.759,1.989,1.269c0.462,0.51,0.693,1.149,0.693,1.917c0,0.702-0.177,1.31-0.531,1.823
			c-0.354,0.513-0.87,0.91-1.548,1.192S570.127,57.443,569.155,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M575.464,49.865v-1.404h5.724v1.404H575.464z M579.513,57.443c-0.516,0-0.988-0.096-1.417-0.288
			c-0.43-0.192-0.771-0.486-1.026-0.882s-0.383-0.9-0.383-1.512V46.14h2.124v8.55c0,0.72,0.343,1.08,1.026,1.08
			c0.21,0,0.42-0.024,0.63-0.072c0.21-0.048,0.45-0.12,0.72-0.216l0.307,1.494c-0.336,0.132-0.663,0.243-0.981,0.333
			C580.195,57.398,579.862,57.443,579.513,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M587.1,57.443c-0.798,0-1.52-0.171-2.165-0.513c-0.645-0.342-1.156-0.853-1.534-1.534
			s-0.566-1.535-0.566-2.561c0-1.044,0.188-1.908,0.566-2.592c0.378-0.684,0.89-1.194,1.534-1.53
			c0.646-0.336,1.367-0.504,2.165-0.504c0.786,0,1.498,0.168,2.138,0.504c0.639,0.336,1.147,0.846,1.525,1.53
			c0.378,0.684,0.566,1.548,0.566,2.592c0,1.026-0.188,1.879-0.566,2.561s-0.887,1.192-1.525,1.534
			C588.598,57.273,587.886,57.443,587.1,57.443z M587.028,55.589c0.42,0,0.794-0.104,1.121-0.311
			c0.326-0.207,0.585-0.514,0.773-0.922c0.189-0.408,0.283-0.915,0.283-1.521c0-0.924-0.181-1.62-0.544-2.088
			c-0.363-0.468-0.859-0.702-1.489-0.702c-0.427,0-0.805,0.104-1.135,0.311s-0.589,0.518-0.778,0.932
			c-0.188,0.414-0.283,0.93-0.283,1.548c0,0.912,0.185,1.599,0.554,2.061S586.398,55.589,587.028,55.589z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M597.162,57.443c-0.731,0-1.411-0.19-2.038-0.571c-0.627-0.381-1.134-0.918-1.521-1.611
			c-0.387-0.693-0.58-1.507-0.58-2.443c0-0.99,0.193-1.829,0.58-2.516c0.388-0.687,0.895-1.207,1.521-1.562
			c0.627-0.354,1.307-0.531,2.038-0.531c0.57,0,1.073,0.091,1.508,0.274s0.806,0.429,1.111,0.738
			c0.307,0.309,0.546,0.653,0.721,1.031c0.174,0.378,0.284,0.759,0.333,1.143l-2.143,0.054c-0.096-0.384-0.274-0.702-0.535-0.954
			c-0.261-0.252-0.599-0.378-1.013-0.378c-0.342,0-0.663,0.095-0.963,0.284s-0.545,0.481-0.733,0.877
			c-0.189,0.396-0.284,0.909-0.284,1.539c0,0.6,0.089,1.1,0.266,1.499s0.416,0.699,0.716,0.9c0.3,0.201,0.633,0.302,0.999,0.302
			c0.54,0,0.933-0.131,1.179-0.392c0.246-0.261,0.369-0.61,0.369-1.048h2.143c0,0.618-0.142,1.182-0.424,1.692
			c-0.281,0.51-0.695,0.917-1.241,1.219C598.623,57.292,597.954,57.443,597.162,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M603.38,57.191V44.843h2.124v12.348H603.38z M605.216,51.773l3.744-3.312h2.88l-6.624,5.508V51.773z
			 M609.464,57.191l-3.905-5.562l2.016-0.486l4.481,6.048H609.464z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M612.569,48.461h2.214l1.891,6.624h-0.217l1.782-6.156h1.926l1.782,6.174h-0.216l1.89-6.642h2.124
			l-2.88,8.73h-2.088l-1.782-5.796h0.324l-1.746,5.796h-2.105L612.569,48.461z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M630.928,57.443c-0.744,0-1.425-0.192-2.043-0.576s-1.112-0.922-1.484-1.615s-0.559-1.504-0.559-2.435
			c0-0.96,0.185-1.785,0.554-2.475s0.862-1.218,1.48-1.584c0.618-0.366,1.302-0.549,2.052-0.549c0.696,0,1.298,0.14,1.805,0.418
			s0.915,0.657,1.225,1.134c0.309,0.477,0.517,1.016,0.625,1.616s0.114,1.218,0.018,1.854h-6.065V52.08h4.562l-0.414,0.27
			c0.019-0.282,0.005-0.567-0.04-0.855c-0.045-0.288-0.131-0.552-0.257-0.792c-0.126-0.24-0.305-0.434-0.535-0.581
			c-0.231-0.147-0.526-0.221-0.887-0.221c-0.432,0-0.798,0.108-1.098,0.324c-0.3,0.216-0.528,0.51-0.685,0.882
			c-0.155,0.372-0.233,0.792-0.233,1.26v0.846c0,0.492,0.073,0.927,0.221,1.305c0.146,0.378,0.365,0.675,0.656,0.891
			c0.291,0.216,0.659,0.324,1.103,0.324c0.414,0,0.763-0.096,1.044-0.288c0.282-0.192,0.462-0.48,0.54-0.864h2.143
			c-0.09,0.564-0.302,1.061-0.635,1.489c-0.333,0.429-0.764,0.765-1.291,1.008C632.201,57.322,631.6,57.443,630.928,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M637.211,57.191v-8.766h2.124v8.766H637.211z M638.813,53.357c0-1.146,0.129-2.094,0.387-2.844
			c0.258-0.75,0.604-1.306,1.039-1.669c0.436-0.363,0.92-0.544,1.454-0.544c0.144,0,0.28,0.009,0.409,0.027
			c0.129,0.018,0.257,0.045,0.383,0.081l-0.036,2.124c-0.156-0.036-0.324-0.066-0.504-0.09c-0.181-0.024-0.342-0.036-0.486-0.036
			c-0.468,0-0.859,0.113-1.175,0.337c-0.314,0.225-0.552,0.557-0.711,0.995c-0.158,0.438-0.238,0.978-0.238,1.62H638.813z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M644.275,57.191V44.843h2.124v12.348H644.275z M646.111,51.773l3.744-3.312h2.88l-6.624,5.508V51.773z
			 M650.359,57.191l-3.906-5.562l2.017-0.486l4.481,6.048H650.359z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M414.731,47.345v-1.836h9.36v1.836h-4.095l0.477-0.486v10.332h-2.124V46.859l0.478,0.486H414.731z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M424.335,57.191v-8.766h2.124v8.766H424.335z M425.936,53.357c0-1.146,0.129-2.094,0.388-2.844
			c0.258-0.75,0.604-1.306,1.039-1.669c0.436-0.363,0.92-0.544,1.453-0.544c0.145,0,0.281,0.009,0.41,0.027
			c0.129,0.018,0.256,0.045,0.383,0.081l-0.036,2.124c-0.156-0.036-0.324-0.066-0.504-0.09c-0.181-0.024-0.343-0.036-0.486-0.036
			c-0.468,0-0.859,0.113-1.175,0.337c-0.314,0.225-0.552,0.557-0.711,0.995s-0.238,0.978-0.238,1.62H425.936z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M432.641,46.859c-0.414,0-0.736-0.109-0.967-0.329c-0.231-0.219-0.347-0.523-0.347-0.914
			c0-0.378,0.115-0.679,0.347-0.904c0.23-0.225,0.553-0.337,0.967-0.337c0.408,0,0.729,0.112,0.963,0.337
			c0.234,0.225,0.352,0.526,0.352,0.904c0,0.39-0.117,0.695-0.352,0.914C433.371,46.75,433.049,46.859,432.641,46.859z
			 M431.58,57.191v-8.766h2.124v8.766H431.58z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M436.206,49.865v-1.404h5.724v1.404H436.206z M440.255,57.443c-0.517,0-0.989-0.096-1.418-0.288
			c-0.429-0.192-0.771-0.486-1.025-0.882c-0.256-0.396-0.383-0.9-0.383-1.512V46.14h2.124v8.55c0,0.72,0.342,1.08,1.026,1.08
			c0.21,0,0.42-0.024,0.63-0.072c0.21-0.048,0.449-0.12,0.72-0.216l0.306,1.494c-0.336,0.132-0.663,0.243-0.98,0.333
			C440.936,57.398,440.603,57.443,440.255,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M443.306,49.865v-1.404h5.724v1.404H443.306z M447.356,57.443c-0.517,0-0.989-0.096-1.418-0.288
			c-0.429-0.192-0.771-0.486-1.025-0.882c-0.256-0.396-0.383-0.9-0.383-1.512V46.14h2.124v8.55c0,0.72,0.342,1.08,1.026,1.08
			c0.21,0,0.42-0.024,0.63-0.072c0.21-0.048,0.449-0.12,0.72-0.216l0.306,1.494c-0.336,0.132-0.663,0.243-0.98,0.333
			C448.037,57.398,447.704,57.443,447.356,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M451.505,57.191V44.843h2.124v10.188l-0.738,2.16H451.505z M455.968,57.443
			c-0.547,0-1.045-0.153-1.498-0.459c-0.453-0.306-0.814-0.763-1.085-1.373c-0.27-0.609-0.405-1.373-0.405-2.291h0.648
			c0,0.438,0.099,0.822,0.297,1.152c0.197,0.33,0.453,0.586,0.766,0.769c0.311,0.183,0.635,0.275,0.971,0.275
			c0.414,0,0.783-0.114,1.107-0.342s0.58-0.546,0.77-0.954c0.189-0.408,0.283-0.888,0.283-1.44c0-0.534-0.094-1-0.279-1.399
			s-0.441-0.709-0.77-0.932c-0.326-0.222-0.703-0.333-1.129-0.333c-0.354,0-0.684,0.086-0.99,0.257s-0.553,0.417-0.742,0.738
			c-0.189,0.321-0.283,0.709-0.283,1.166h-0.648c0-0.882,0.128-1.626,0.383-2.232s0.617-1.063,1.088-1.373
			c0.473-0.309,1.031-0.463,1.68-0.463c0.707,0,1.352,0.188,1.93,0.562c0.58,0.375,1.041,0.906,1.387,1.593s0.518,1.493,0.518,2.417
			c0,0.936-0.18,1.754-0.535,2.453c-0.357,0.699-0.84,1.242-1.445,1.629C457.379,57.25,456.707,57.443,455.968,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M462.555,57.191v-8.766h2.124v8.766H462.555z M464.158,53.357c0-1.146,0.129-2.094,0.387-2.844
			c0.258-0.75,0.604-1.306,1.039-1.669c0.436-0.363,0.92-0.544,1.454-0.544c0.144,0,0.28,0.009,0.409,0.027
			c0.129,0.018,0.257,0.045,0.383,0.081l-0.036,2.124c-0.156-0.036-0.324-0.066-0.504-0.09c-0.181-0.024-0.342-0.036-0.486-0.036
			c-0.468,0-0.859,0.113-1.175,0.337c-0.314,0.225-0.552,0.557-0.711,0.995c-0.158,0.438-0.238,0.978-0.238,1.62H464.158z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M472.878,57.443c-0.744,0-1.425-0.192-2.043-0.576s-1.112-0.922-1.484-1.615s-0.559-1.504-0.559-2.435
			c0-0.96,0.185-1.785,0.554-2.475s0.862-1.218,1.48-1.584c0.618-0.366,1.302-0.549,2.052-0.549c0.696,0,1.298,0.14,1.805,0.418
			s0.915,0.657,1.225,1.134c0.309,0.477,0.517,1.016,0.625,1.616s0.114,1.218,0.018,1.854h-6.065V52.08h4.562l-0.414,0.27
			c0.019-0.282,0.005-0.567-0.04-0.855c-0.045-0.288-0.131-0.552-0.257-0.792c-0.126-0.24-0.305-0.434-0.535-0.581
			c-0.231-0.147-0.526-0.221-0.887-0.221c-0.432,0-0.798,0.108-1.098,0.324c-0.3,0.216-0.528,0.51-0.685,0.882
			c-0.155,0.372-0.233,0.792-0.233,1.26v0.846c0,0.492,0.073,0.927,0.221,1.305c0.146,0.378,0.365,0.675,0.656,0.891
			c0.291,0.216,0.659,0.324,1.103,0.324c0.414,0,0.763-0.096,1.044-0.288c0.282-0.192,0.462-0.48,0.54-0.864h2.143
			c-0.09,0.564-0.302,1.061-0.635,1.489c-0.333,0.429-0.764,0.765-1.291,1.008C474.15,57.322,473.549,57.443,472.878,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M478.062,49.865v-1.404h5.724v1.404H478.062z M482.112,57.443c-0.516,0-0.988-0.096-1.417-0.288
			c-0.43-0.192-0.771-0.486-1.026-0.882s-0.383-0.9-0.383-1.512V46.14h2.124v8.55c0,0.72,0.342,1.08,1.026,1.08
			c0.21,0,0.42-0.024,0.63-0.072c0.21-0.048,0.45-0.12,0.72-0.216l0.307,1.494c-0.337,0.132-0.663,0.243-0.981,0.333
			S482.46,57.443,482.112,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M485.163,49.865v-1.404h5.724v1.404H485.163z M489.213,57.443c-0.516,0-0.988-0.096-1.417-0.288
			c-0.43-0.192-0.771-0.486-1.026-0.882s-0.383-0.9-0.383-1.512V46.14h2.124v8.55c0,0.72,0.342,1.08,1.026,1.08
			c0.21,0,0.42-0.024,0.63-0.072c0.21-0.048,0.45-0.12,0.72-0.216l0.307,1.494c-0.337,0.132-0.663,0.243-0.981,0.333
			S489.56,57.443,489.213,57.443z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M260.655,57.191l3.978-11.682h2.664l3.816,11.682h-2.232l-0.81-2.52h-4.338l-0.828,2.52H260.655z
			 M264.273,53.069h3.294l-1.639-5.022L264.273,53.069z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M273.191,57.191v-8.766h2.124v8.766H273.191z M274.794,53.357c0-1.146,0.129-2.094,0.387-2.844
			c0.258-0.75,0.604-1.306,1.04-1.669c0.435-0.363,0.919-0.544,1.453-0.544c0.144,0,0.28,0.009,0.409,0.027
			c0.129,0.018,0.257,0.045,0.383,0.081l-0.036,2.124c-0.156-0.036-0.324-0.066-0.504-0.09s-0.342-0.036-0.486-0.036
			c-0.468,0-0.859,0.113-1.174,0.337c-0.315,0.225-0.553,0.557-0.711,0.995c-0.159,0.438-0.239,0.978-0.239,1.62H274.794z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M279.159,49.865v-1.404h5.724v1.404H279.159z M283.209,57.443c-0.517,0-0.989-0.096-1.418-0.288
			c-0.429-0.192-0.771-0.486-1.025-0.882c-0.256-0.396-0.383-0.9-0.383-1.512V46.14h2.124v8.55c0,0.72,0.342,1.08,1.026,1.08
			c0.21,0,0.42-0.024,0.63-0.072c0.21-0.048,0.449-0.12,0.72-0.216l0.306,1.494c-0.336,0.132-0.663,0.243-0.98,0.333
			C283.889,57.398,283.556,57.443,283.209,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M290.615,57.443c-0.84,0-1.562-0.115-2.164-0.347c-0.604-0.23-1.066-0.563-1.391-0.999
			s-0.489-0.958-0.495-1.571h1.962c0.018,0.432,0.189,0.747,0.513,0.945c0.324,0.198,0.766,0.297,1.323,0.297
			c0.306,0,0.577-0.045,0.814-0.135c0.237-0.09,0.422-0.213,0.554-0.369c0.132-0.156,0.198-0.33,0.198-0.522
			c0-0.246-0.089-0.439-0.266-0.58c-0.178-0.141-0.461-0.271-0.851-0.392l-1.908-0.684c-0.684-0.246-1.209-0.57-1.575-0.972
			c-0.366-0.402-0.549-0.882-0.549-1.44c0-0.738,0.335-1.333,1.004-1.787c0.669-0.453,1.564-0.679,2.687-0.679
			c1.115,0,1.979,0.245,2.587,0.733c0.609,0.489,0.923,1.108,0.94,1.858h-1.998c-0.029-0.288-0.168-0.516-0.414-0.684
			c-0.246-0.167-0.617-0.252-1.115-0.252c-0.45,0-0.803,0.092-1.058,0.275s-0.383,0.397-0.383,0.643
			c0,0.414,0.354,0.726,1.062,0.936l2.069,0.774c0.672,0.229,1.194,0.522,1.566,0.882s0.558,0.816,0.558,1.368
			c0,0.462-0.154,0.899-0.463,1.31c-0.31,0.411-0.74,0.746-1.292,1.004C291.98,57.315,291.34,57.443,290.615,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M296.852,61.457V48.461h1.746l0.377,2.16v10.835H296.852z M301.225,57.443
			c-0.641,0-1.188-0.138-1.637-0.414c-0.451-0.276-0.795-0.694-1.031-1.255s-0.355-1.265-0.355-2.11h0.773
			c0,0.39,0.09,0.723,0.271,0.999c0.18,0.276,0.418,0.487,0.715,0.634s0.617,0.221,0.959,0.221c0.354,0,0.703-0.098,1.047-0.292
			c0.346-0.195,0.633-0.492,0.861-0.891c0.227-0.399,0.342-0.905,0.342-1.517c0-0.558-0.098-1.04-0.289-1.444
			c-0.191-0.405-0.455-0.716-0.787-0.932s-0.713-0.324-1.139-0.324c-0.359,0-0.689,0.081-0.99,0.243
			c-0.299,0.162-0.539,0.397-0.719,0.707c-0.182,0.309-0.271,0.683-0.271,1.121h-0.773c0-0.918,0.141-1.669,0.424-2.254
			c0.281-0.585,0.66-1.019,1.133-1.301c0.475-0.282,0.994-0.423,1.557-0.423c0.691,0,1.34,0.183,1.949,0.549
			c0.609,0.366,1.104,0.893,1.48,1.58c0.379,0.687,0.566,1.514,0.566,2.479c0,0.972-0.191,1.803-0.576,2.493
			c-0.383,0.69-0.885,1.218-1.506,1.584S301.94,57.443,301.225,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M310.532,57.443c-0.75,0-1.376-0.131-1.877-0.392s-0.877-0.604-1.129-1.03
			c-0.252-0.426-0.378-0.888-0.378-1.386c0-0.552,0.146-1.015,0.437-1.39s0.703-0.657,1.237-0.846s1.164-0.284,1.89-0.284
			c0.475,0,0.923,0.036,1.346,0.108c0.423,0.072,0.845,0.168,1.265,0.288v1.242c-0.33-0.108-0.69-0.192-1.08-0.252
			s-0.756-0.09-1.098-0.09c-0.504,0-0.918,0.093-1.242,0.279s-0.486,0.501-0.486,0.945c0,0.324,0.132,0.603,0.396,0.837
			c0.264,0.234,0.684,0.351,1.26,0.351c0.438,0,0.81-0.096,1.116-0.288c0.306-0.192,0.538-0.432,0.697-0.72s0.238-0.576,0.238-0.864
			h0.504c0,0.63-0.117,1.211-0.351,1.742c-0.234,0.531-0.581,0.956-1.04,1.273S311.21,57.443,310.532,57.443z M313.394,57.191
			l-0.271-2.124v-3.51c0-0.612-0.124-1.065-0.373-1.359c-0.249-0.294-0.671-0.441-1.265-0.441c-0.342,0-0.639,0.051-0.892,0.153
			c-0.252,0.102-0.445,0.247-0.58,0.436c-0.135,0.189-0.202,0.419-0.202,0.689h-2.322c0-0.66,0.159-1.199,0.477-1.616
			c0.318-0.417,0.775-0.723,1.373-0.918c0.597-0.195,1.312-0.292,2.146-0.292c0.756,0,1.416,0.122,1.979,0.364
			c0.564,0.243,1.002,0.617,1.314,1.121c0.312,0.504,0.468,1.149,0.468,1.935v5.562H313.394z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M321.674,57.443c-0.732,0-1.412-0.19-2.039-0.571c-0.627-0.381-1.134-0.918-1.521-1.611
			c-0.388-0.693-0.581-1.507-0.581-2.443c0-0.99,0.193-1.829,0.581-2.516c0.387-0.687,0.894-1.207,1.521-1.562
			c0.627-0.354,1.307-0.531,2.039-0.531c0.569,0,1.072,0.091,1.507,0.274c0.436,0.183,0.806,0.429,1.112,0.738
			c0.306,0.309,0.546,0.653,0.72,1.031s0.285,0.759,0.333,1.143l-2.143,0.054c-0.096-0.384-0.274-0.702-0.535-0.954
			c-0.261-0.252-0.599-0.378-1.013-0.378c-0.342,0-0.663,0.095-0.963,0.284s-0.544,0.481-0.733,0.877s-0.283,0.909-0.283,1.539
			c0,0.6,0.088,1.1,0.266,1.499c0.177,0.399,0.415,0.699,0.715,0.9c0.3,0.201,0.633,0.302,0.999,0.302
			c0.54,0,0.934-0.131,1.18-0.392c0.245-0.261,0.368-0.61,0.368-1.048h2.143c0,0.618-0.142,1.182-0.423,1.692
			c-0.282,0.51-0.696,0.917-1.242,1.219C323.135,57.292,322.466,57.443,321.674,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M331.151,57.443c-0.744,0-1.425-0.192-2.043-0.576s-1.112-0.922-1.484-1.615s-0.559-1.504-0.559-2.435
			c0-0.96,0.185-1.785,0.554-2.475s0.862-1.218,1.48-1.584c0.618-0.366,1.302-0.549,2.052-0.549c0.696,0,1.298,0.14,1.805,0.418
			s0.915,0.657,1.225,1.134c0.309,0.477,0.517,1.016,0.625,1.616s0.114,1.218,0.018,1.854h-6.065V52.08h4.562l-0.414,0.27
			c0.019-0.282,0.005-0.567-0.04-0.855c-0.045-0.288-0.131-0.552-0.257-0.792c-0.126-0.24-0.305-0.434-0.535-0.581
			c-0.231-0.147-0.526-0.221-0.887-0.221c-0.432,0-0.798,0.108-1.098,0.324c-0.3,0.216-0.528,0.51-0.685,0.882
			c-0.155,0.372-0.233,0.792-0.233,1.26v0.846c0,0.492,0.073,0.927,0.221,1.305c0.146,0.378,0.365,0.675,0.656,0.891
			c0.291,0.216,0.659,0.324,1.103,0.324c0.414,0,0.763-0.096,1.044-0.288c0.282-0.192,0.462-0.48,0.54-0.864h2.143
			c-0.09,0.564-0.302,1.061-0.635,1.489c-0.333,0.429-0.764,0.765-1.291,1.008C332.423,57.322,331.823,57.443,331.151,57.443z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M735.273,57.191v-1.836l6.786-8.658v0.774l-6.786-0.018v-1.944h8.838v1.818l-6.372,8.172l-0.054-0.27
			l6.426,0.018v1.944H735.273z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M750.204,57.443c-0.744,0-1.425-0.192-2.043-0.576s-1.113-0.922-1.485-1.615s-0.558-1.504-0.558-2.435
			c0-0.96,0.185-1.785,0.554-2.475s0.862-1.218,1.48-1.584c0.618-0.366,1.302-0.549,2.052-0.549c0.696,0,1.298,0.14,1.805,0.418
			s0.915,0.657,1.224,1.134c0.31,0.477,0.518,1.016,0.626,1.616c0.107,0.6,0.113,1.218,0.018,1.854h-6.065V52.08h4.562l-0.414,0.27
			c0.019-0.282,0.005-0.567-0.04-0.855c-0.045-0.288-0.131-0.552-0.257-0.792c-0.126-0.24-0.305-0.434-0.535-0.581
			c-0.231-0.147-0.526-0.221-0.887-0.221c-0.432,0-0.798,0.108-1.098,0.324c-0.301,0.216-0.528,0.51-0.685,0.882
			s-0.233,0.792-0.233,1.26v0.846c0,0.492,0.073,0.927,0.22,1.305c0.147,0.378,0.366,0.675,0.657,0.891
			c0.291,0.216,0.658,0.324,1.103,0.324c0.414,0,0.762-0.096,1.044-0.288c0.282-0.192,0.462-0.48,0.54-0.864h2.143
			c-0.091,0.564-0.302,1.061-0.635,1.489c-0.333,0.429-0.764,0.765-1.292,1.008C751.476,57.322,750.876,57.443,750.204,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M757.728,46.859c-0.414,0-0.737-0.109-0.968-0.329c-0.231-0.219-0.347-0.523-0.347-0.914
			c0-0.378,0.115-0.679,0.347-0.904c0.23-0.225,0.554-0.337,0.968-0.337c0.407,0,0.729,0.112,0.963,0.337
			c0.233,0.225,0.351,0.526,0.351,0.904c0,0.39-0.117,0.695-0.351,0.914C758.457,46.75,758.135,46.859,757.728,46.859z
			 M756.666,57.191v-8.766h2.124v8.766H756.666z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M761.292,49.865v-1.404h5.724v1.404H761.292z M765.341,57.443c-0.516,0-0.988-0.096-1.417-0.288
			c-0.43-0.192-0.771-0.486-1.026-0.882s-0.383-0.9-0.383-1.512V46.14h2.124v8.55c0,0.72,0.342,1.08,1.026,1.08
			c0.21,0,0.42-0.024,0.63-0.072c0.21-0.048,0.45-0.12,0.72-0.216l0.307,1.494c-0.337,0.132-0.663,0.243-0.981,0.333
			S765.689,57.443,765.341,57.443z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M769.49,57.191V44.843h2.124v12.348H769.49z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M778.409,57.443c-0.799,0-1.52-0.171-2.165-0.513c-0.645-0.342-1.156-0.853-1.534-1.534
			s-0.567-1.535-0.567-2.561c0-1.044,0.189-1.908,0.567-2.592c0.378-0.684,0.89-1.194,1.534-1.53
			c0.646-0.336,1.366-0.504,2.165-0.504c0.786,0,1.498,0.168,2.137,0.504c0.64,0.336,1.148,0.846,1.526,1.53
			c0.378,0.684,0.566,1.548,0.566,2.592c0,1.026-0.188,1.879-0.566,2.561s-0.887,1.192-1.526,1.534
			C779.907,57.273,779.195,57.443,778.409,57.443z M778.337,55.589c0.42,0,0.793-0.104,1.12-0.311s0.585-0.514,0.774-0.922
			c0.188-0.408,0.283-0.915,0.283-1.521c0-0.924-0.182-1.62-0.544-2.088c-0.363-0.468-0.859-0.702-1.49-0.702
			c-0.426,0-0.804,0.104-1.134,0.311s-0.589,0.518-0.778,0.932c-0.189,0.414-0.283,0.93-0.283,1.548
			c0,0.912,0.185,1.599,0.553,2.061C777.207,55.358,777.707,55.589,778.337,55.589z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M788.416,57.443c-0.84,0-1.562-0.115-2.164-0.347c-0.604-0.23-1.066-0.563-1.391-0.999
			s-0.489-0.958-0.495-1.571h1.962c0.019,0.432,0.189,0.747,0.514,0.945c0.323,0.198,0.765,0.297,1.322,0.297
			c0.307,0,0.578-0.045,0.814-0.135c0.237-0.09,0.422-0.213,0.554-0.369c0.132-0.156,0.198-0.33,0.198-0.522
			c0-0.246-0.089-0.439-0.266-0.58c-0.177-0.141-0.461-0.271-0.851-0.392l-1.908-0.684c-0.684-0.246-1.209-0.57-1.574-0.972
			c-0.366-0.402-0.55-0.882-0.55-1.44c0-0.738,0.335-1.333,1.004-1.787c0.669-0.453,1.564-0.679,2.687-0.679
			c1.116,0,1.979,0.245,2.588,0.733c0.608,0.489,0.922,1.108,0.94,1.858h-1.998c-0.03-0.288-0.168-0.516-0.414-0.684
			c-0.246-0.167-0.618-0.252-1.116-0.252c-0.45,0-0.803,0.092-1.058,0.275s-0.383,0.397-0.383,0.643
			c0,0.414,0.354,0.726,1.062,0.936l2.07,0.774c0.672,0.229,1.193,0.522,1.565,0.882s0.558,0.816,0.558,1.368
			c0,0.462-0.154,0.899-0.463,1.31c-0.31,0.411-0.739,0.746-1.292,1.004C789.781,57.315,789.142,57.443,788.416,57.443z"
                  />
                </g>
                <g>
                  <g>
                    <path
                      fill="#FFFFFF"
                      d="M876.461,57.151V45.469h7.362v1.836h-5.238v3.078h4.788v1.818h-4.788v4.95H876.461z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M888.979,57.403c-0.744,0-1.425-0.192-2.043-0.576s-1.113-0.922-1.485-1.615s-0.558-1.504-0.558-2.435
				c0-0.96,0.185-1.785,0.554-2.475s0.862-1.218,1.48-1.584c0.618-0.366,1.302-0.549,2.052-0.549c0.696,0,1.298,0.14,1.805,0.418
				s0.915,0.657,1.224,1.134c0.31,0.477,0.518,1.016,0.626,1.616c0.107,0.6,0.113,1.218,0.018,1.854h-6.065v-1.152h4.562
				l-0.414,0.27c0.019-0.282,0.005-0.567-0.04-0.855c-0.045-0.288-0.131-0.552-0.257-0.792c-0.126-0.24-0.305-0.434-0.535-0.581
				c-0.231-0.147-0.526-0.221-0.887-0.221c-0.432,0-0.798,0.108-1.098,0.324c-0.301,0.216-0.528,0.51-0.685,0.882
				S887,51.859,887,52.327v0.846c0,0.492,0.073,0.927,0.22,1.305c0.147,0.378,0.366,0.675,0.657,0.891
				c0.291,0.216,0.658,0.324,1.103,0.324c0.414,0,0.762-0.096,1.044-0.288c0.282-0.192,0.462-0.48,0.54-0.864h2.143
				c-0.091,0.564-0.302,1.061-0.635,1.489c-0.333,0.429-0.764,0.765-1.292,1.008C890.252,57.282,889.651,57.403,888.979,57.403z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M895.082,48.385h2.124v5.364c0,0.57,0.141,0.998,0.423,1.282c0.281,0.285,0.669,0.428,1.161,0.428
				c0.42,0,0.802-0.109,1.147-0.329c0.345-0.219,0.621-0.543,0.828-0.972c0.206-0.429,0.31-0.961,0.31-1.597h0.576
				c0,1.068-0.138,1.953-0.414,2.655c-0.275,0.702-0.656,1.227-1.143,1.575s-1.047,0.522-1.683,0.522
				c-0.625,0-1.188-0.111-1.692-0.333c-0.504-0.222-0.903-0.579-1.197-1.071c-0.294-0.492-0.44-1.14-0.44-1.944V48.385z
				 M901.075,48.385h2.124v8.766h-2.124V48.385z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M909.805,57.403c-0.744,0-1.425-0.192-2.043-0.576s-1.113-0.922-1.485-1.615s-0.558-1.504-0.558-2.435
				c0-0.96,0.185-1.785,0.554-2.475s0.862-1.218,1.48-1.584c0.618-0.366,1.302-0.549,2.052-0.549c0.696,0,1.298,0.14,1.805,0.418
				s0.915,0.657,1.224,1.134c0.31,0.477,0.518,1.016,0.626,1.616c0.107,0.6,0.113,1.218,0.018,1.854h-6.065v-1.152h4.562
				l-0.414,0.27c0.019-0.282,0.005-0.567-0.04-0.855c-0.045-0.288-0.131-0.552-0.257-0.792c-0.126-0.24-0.305-0.434-0.535-0.581
				c-0.231-0.147-0.526-0.221-0.887-0.221c-0.432,0-0.798,0.108-1.098,0.324c-0.301,0.216-0.528,0.51-0.685,0.882
				s-0.233,0.792-0.233,1.26v0.846c0,0.492,0.073,0.927,0.22,1.305c0.147,0.378,0.366,0.675,0.657,0.891
				c0.291,0.216,0.658,0.324,1.103,0.324c0.414,0,0.762-0.096,1.044-0.288c0.282-0.192,0.462-0.48,0.54-0.864h2.143
				c-0.091,0.564-0.302,1.061-0.635,1.489c-0.333,0.429-0.764,0.765-1.292,1.008C911.078,57.282,910.477,57.403,909.805,57.403z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M916.088,57.151v-8.766h2.124v8.766H916.088z M917.689,53.317c0-1.146,0.129-2.094,0.388-2.844
				c0.258-0.75,0.604-1.306,1.039-1.669c0.436-0.363,0.92-0.544,1.454-0.544c0.144,0,0.28,0.009,0.409,0.027
				c0.129,0.018,0.257,0.045,0.383,0.081l-0.036,2.124c-0.156-0.036-0.324-0.066-0.504-0.09c-0.181-0.024-0.342-0.036-0.486-0.036
				c-0.468,0-0.859,0.113-1.175,0.337c-0.314,0.225-0.552,0.557-0.711,0.995s-0.238,0.978-0.238,1.62H917.689z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M922.63,61.669c-0.469,0-0.902-0.051-1.301-0.152c-0.399-0.103-0.806-0.237-1.22-0.405l0.036-1.836
				c0.306,0.149,0.629,0.277,0.968,0.383c0.339,0.104,0.67,0.157,0.994,0.157c0.438,0,0.772-0.126,1.004-0.378
				c0.23-0.252,0.347-0.679,0.347-1.278v-9.774h2.123v9.81c0,1.248-0.259,2.139-0.778,2.673
				C924.285,61.402,923.56,61.669,922.63,61.669z M924.503,46.819c-0.402,0-0.722-0.109-0.959-0.329
				c-0.237-0.219-0.355-0.523-0.355-0.914c0-0.378,0.118-0.679,0.355-0.904s0.557-0.337,0.959-0.337
				c0.407,0,0.729,0.112,0.963,0.337c0.233,0.225,0.351,0.526,0.351,0.904c0,0.39-0.117,0.695-0.351,0.914
				C925.231,46.71,924.91,46.819,924.503,46.819z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M929.003,48.385h2.124v5.364c0,0.57,0.141,0.998,0.423,1.282c0.282,0.285,0.669,0.428,1.161,0.428
				c0.42,0,0.803-0.109,1.147-0.329c0.345-0.219,0.621-0.543,0.828-0.972c0.207-0.429,0.311-0.961,0.311-1.597h0.576
				c0,1.068-0.139,1.953-0.414,2.655c-0.276,0.702-0.657,1.227-1.144,1.575c-0.485,0.348-1.047,0.522-1.683,0.522
				c-0.624,0-1.188-0.111-1.692-0.333c-0.504-0.222-0.903-0.579-1.197-1.071c-0.294-0.492-0.44-1.14-0.44-1.944V48.385z
				 M934.997,48.385h2.124v8.766h-2.124V48.385z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M940.468,57.151v-8.766h2.124v8.766H940.468z M942.071,53.317c0-1.146,0.129-2.094,0.387-2.844
				c0.258-0.75,0.604-1.306,1.039-1.669c0.436-0.363,0.92-0.544,1.454-0.544c0.144,0,0.28,0.009,0.409,0.027
				c0.129,0.018,0.257,0.045,0.383,0.081l-0.036,2.124c-0.156-0.036-0.324-0.066-0.504-0.09c-0.181-0.024-0.342-0.036-0.486-0.036
				c-0.468,0-0.859,0.113-1.175,0.337c-0.314,0.225-0.552,0.557-0.711,0.995c-0.158,0.438-0.238,0.978-0.238,1.62H942.071z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M946.435,49.825v-1.404h5.724v1.404H946.435z M950.485,57.403c-0.516,0-0.988-0.096-1.417-0.288
				c-0.43-0.192-0.771-0.486-1.026-0.882s-0.383-0.9-0.383-1.512v-8.622h2.124v8.55c0,0.72,0.342,1.08,1.026,1.08
				c0.21,0,0.42-0.024,0.63-0.072c0.21-0.048,0.45-0.12,0.72-0.216l0.307,1.494c-0.337,0.132-0.663,0.243-0.981,0.333
				S950.833,57.403,950.485,57.403z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M957.891,57.403c-0.744,0-1.425-0.192-2.043-0.576s-1.112-0.922-1.484-1.615s-0.559-1.504-0.559-2.435
				c0-0.96,0.185-1.785,0.554-2.475s0.862-1.218,1.48-1.584c0.618-0.366,1.302-0.549,2.052-0.549c0.696,0,1.298,0.14,1.805,0.418
				s0.915,0.657,1.225,1.134c0.309,0.477,0.517,1.016,0.625,1.616s0.114,1.218,0.018,1.854h-6.065v-1.152h4.562l-0.414,0.27
				c0.019-0.282,0.005-0.567-0.04-0.855c-0.045-0.288-0.131-0.552-0.257-0.792c-0.126-0.24-0.305-0.434-0.535-0.581
				c-0.231-0.147-0.526-0.221-0.887-0.221c-0.432,0-0.798,0.108-1.098,0.324c-0.3,0.216-0.528,0.51-0.685,0.882
				c-0.155,0.372-0.233,0.792-0.233,1.26v0.846c0,0.492,0.073,0.927,0.221,1.305c0.146,0.378,0.365,0.675,0.656,0.891
				c0.291,0.216,0.659,0.324,1.103,0.324c0.414,0,0.763-0.096,1.044-0.288c0.282-0.192,0.462-0.48,0.54-0.864h2.143
				c-0.09,0.564-0.302,1.061-0.635,1.489c-0.333,0.429-0.764,0.765-1.291,1.008C959.164,57.282,958.563,57.403,957.891,57.403z"
                    />
                  </g>
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1.935,84.86v-1.998c0-0.654,0.099-1.209,0.297-1.665s0.493-0.841,0.887-1.156
			c0.393-0.315,0.886-0.586,1.48-0.814l2.394-0.918c0.522-0.21,0.907-0.468,1.156-0.774c0.249-0.306,0.374-0.684,0.374-1.134
			s-0.164-0.83-0.49-1.139c-0.327-0.309-0.794-0.463-1.4-0.463c-0.582,0-1.069,0.146-1.462,0.437
			c-0.394,0.291-0.62,0.715-0.68,1.273H2.132c0.019-0.798,0.226-1.461,0.621-1.989c0.396-0.528,0.928-0.923,1.594-1.184
			c0.666-0.261,1.415-0.392,2.25-0.392c0.785,0,1.496,0.148,2.133,0.445c0.636,0.297,1.142,0.711,1.517,1.242
			c0.375,0.531,0.562,1.15,0.562,1.858c0,0.6-0.11,1.128-0.329,1.584c-0.219,0.456-0.55,0.855-0.994,1.197
			c-0.444,0.342-1.005,0.645-1.683,0.909l-2.412,0.936c-0.469,0.18-0.808,0.38-1.018,0.599c-0.21,0.219-0.314,0.505-0.314,0.859
			v0.342h6.678v1.944H1.935z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M12.825,84.86v-1.998c0-0.654,0.1-1.209,0.297-1.665c0.198-0.456,0.494-0.841,0.887-1.156
			s0.887-0.586,1.48-0.814l2.395-0.918c0.521-0.21,0.907-0.468,1.156-0.774c0.249-0.306,0.373-0.684,0.373-1.134
			s-0.163-0.83-0.49-1.139c-0.327-0.309-0.794-0.463-1.399-0.463c-0.582,0-1.069,0.146-1.463,0.437
			c-0.393,0.291-0.619,0.715-0.679,1.273h-2.358c0.018-0.798,0.225-1.461,0.621-1.989c0.396-0.528,0.927-0.923,1.593-1.184
			c0.666-0.261,1.416-0.392,2.25-0.392c0.786,0,1.497,0.148,2.133,0.445c0.636,0.297,1.142,0.711,1.517,1.242
			c0.375,0.531,0.562,1.15,0.562,1.858c0,0.6-0.109,1.128-0.328,1.584c-0.22,0.456-0.551,0.855-0.995,1.197
			c-0.443,0.342-1.005,0.645-1.683,0.909l-2.412,0.936c-0.468,0.18-0.807,0.38-1.017,0.599c-0.21,0.219-0.315,0.505-0.315,0.859
			v0.342h6.678v1.944H12.825z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M23.067,83.618c0-0.408,0.138-0.75,0.414-1.026s0.63-0.414,1.062-0.414c0.432,0,0.792,0.138,1.079,0.414
			c0.288,0.276,0.433,0.618,0.433,1.026c0,0.402-0.145,0.737-0.433,1.004c-0.287,0.267-0.647,0.4-1.079,0.4
			c-0.433,0-0.786-0.135-1.062-0.405C23.205,84.347,23.067,84.014,23.067,83.618z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M32.212,85.112c-0.96,0-1.805-0.222-2.534-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.981-1.57,1.71-2.011
			c0.729-0.441,1.574-0.662,2.534-0.662c0.947,0,1.786,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.41,0.9,0.616,2.037,0.616,3.411c0,1.368-0.206,2.502-0.616,3.402c-0.411,0.9-0.981,1.572-1.71,2.016
			C33.998,84.891,33.159,85.112,32.212,85.112z M32.212,83.169c0.84,0,1.49-0.355,1.953-1.066c0.462-0.711,0.692-1.736,0.692-3.074
			s-0.23-2.365-0.692-3.083c-0.463-0.717-1.113-1.076-1.953-1.076c-0.847,0-1.502,0.359-1.967,1.076
			c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074C30.71,82.813,31.365,83.169,32.212,83.169z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M43.596,85.112c-0.96,0-1.805-0.222-2.534-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.981-1.57,1.71-2.011
			c0.729-0.441,1.574-0.662,2.534-0.662c0.947,0,1.786,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.41,0.9,0.616,2.037,0.616,3.411c0,1.368-0.206,2.502-0.616,3.402c-0.411,0.9-0.981,1.572-1.71,2.016
			C45.382,84.891,44.544,85.112,43.596,85.112z M43.596,83.169c0.84,0,1.49-0.355,1.953-1.066c0.462-0.711,0.692-1.736,0.692-3.074
			s-0.23-2.365-0.692-3.083c-0.463-0.717-1.113-1.076-1.953-1.076c-0.847,0-1.502,0.359-1.967,1.076
			c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074C42.094,82.813,42.75,83.169,43.596,83.169z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1.629,199.456v-1.998c0-0.654,0.1-1.209,0.297-1.665c0.198-0.456,0.494-0.841,0.887-1.156
			s0.887-0.586,1.48-0.814l2.395-0.918c0.521-0.21,0.907-0.468,1.156-0.774c0.249-0.306,0.373-0.684,0.373-1.134
			s-0.163-0.83-0.49-1.139c-0.327-0.309-0.794-0.463-1.399-0.463c-0.582,0-1.069,0.146-1.463,0.437
			c-0.393,0.291-0.619,0.715-0.679,1.273H1.827c0.018-0.798,0.225-1.461,0.621-1.989c0.396-0.528,0.927-0.923,1.593-1.184
			c0.666-0.261,1.416-0.392,2.25-0.392c0.786,0,1.497,0.148,2.133,0.445c0.636,0.297,1.142,0.711,1.517,1.242
			c0.375,0.531,0.562,1.15,0.562,1.858c0,0.6-0.109,1.128-0.328,1.584c-0.22,0.456-0.551,0.855-0.995,1.197
			c-0.443,0.342-1.005,0.645-1.683,0.909l-2.412,0.936c-0.468,0.18-0.807,0.38-1.017,0.599c-0.21,0.219-0.315,0.505-0.315,0.859
			v0.342h6.678v1.944H1.629z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M17.073,199.708c-0.618,0-1.215-0.084-1.791-0.252s-1.092-0.417-1.548-0.747s-0.817-0.739-1.085-1.229
			c-0.267-0.489-0.4-1.054-0.4-1.696h2.232c0.023,0.408,0.146,0.76,0.369,1.058c0.222,0.297,0.523,0.525,0.904,0.684
			c0.381,0.159,0.82,0.239,1.318,0.239c0.731,0,1.305-0.168,1.719-0.504s0.621-0.762,0.621-1.278c0-0.336-0.084-0.625-0.252-0.868
			c-0.168-0.243-0.426-0.429-0.773-0.558c-0.349-0.129-0.793-0.194-1.332-0.194h-1.549v-1.674h1.314
			c0.684,0,1.221-0.147,1.611-0.441c0.39-0.294,0.585-0.693,0.585-1.197c0-0.45-0.168-0.827-0.504-1.13
			c-0.337-0.303-0.841-0.455-1.513-0.455c-0.546,0-1.033,0.111-1.462,0.333c-0.43,0.222-0.704,0.609-0.824,1.161h-2.231
			c0.084-0.798,0.325-1.449,0.725-1.953c0.398-0.504,0.916-0.874,1.552-1.111c0.637-0.237,1.354-0.355,2.151-0.355
			c0.846,0,1.599,0.138,2.259,0.414s1.181,0.659,1.562,1.147c0.381,0.489,0.571,1.055,0.571,1.696c0,0.594-0.161,1.112-0.485,1.553
			s-0.805,0.77-1.44,0.985c0.708,0.24,1.275,0.597,1.701,1.071c0.426,0.474,0.639,1.065,0.639,1.773c0,0.69-0.193,1.3-0.58,1.832
			c-0.387,0.531-0.929,0.946-1.624,1.246C18.816,199.557,18.003,199.708,17.073,199.708z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M23.067,198.213c0-0.408,0.138-0.75,0.414-1.026c0.275-0.276,0.63-0.414,1.062-0.414
			s0.792,0.138,1.08,0.414s0.432,0.618,0.432,1.026c0,0.402-0.144,0.737-0.432,1.004c-0.288,0.267-0.648,0.4-1.08,0.4
			s-0.786-0.135-1.062-0.405C23.205,198.942,23.067,198.609,23.067,198.213z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M32.211,199.708c-0.96,0-1.805-0.222-2.533-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.98-1.57,1.71-2.011
			c0.729-0.441,1.573-0.662,2.533-0.662c0.948,0,1.787,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.411,0.9,0.616,2.037,0.616,3.411c0,1.368-0.205,2.502-0.616,3.402c-0.411,0.9-0.98,1.572-1.71,2.016
			C33.998,199.486,33.159,199.708,32.211,199.708z M32.211,197.764c0.84,0,1.491-0.355,1.953-1.066
			c0.462-0.711,0.693-1.736,0.693-3.074s-0.231-2.365-0.693-3.083c-0.462-0.717-1.113-1.076-1.953-1.076
			c-0.846,0-1.501,0.359-1.967,1.076c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074
			C30.71,197.408,31.365,197.764,32.211,197.764z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M43.595,199.708c-0.96,0-1.805-0.222-2.533-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.98-1.57,1.71-2.011
			c0.729-0.441,1.573-0.662,2.533-0.662c0.948,0,1.787,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.411,0.9,0.616,2.037,0.616,3.411c0,1.368-0.205,2.502-0.616,3.402c-0.411,0.9-0.98,1.572-1.71,2.016
			C45.382,199.486,44.544,199.708,43.595,199.708z M43.595,197.764c0.84,0,1.491-0.355,1.953-1.066
			c0.462-0.711,0.693-1.736,0.693-3.074s-0.231-2.365-0.693-3.083c-0.462-0.717-1.113-1.076-1.953-1.076
			c-0.846,0-1.501,0.359-1.967,1.076c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074
			C42.094,197.408,42.75,197.764,43.595,197.764z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M5.572,314.302c-0.96,0-1.805-0.222-2.534-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.981-1.57,1.71-2.011
			c0.729-0.441,1.574-0.662,2.534-0.662c0.947,0,1.786,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.41,0.9,0.616,2.037,0.616,3.411c0,1.368-0.206,2.502-0.616,3.402c-0.411,0.9-0.981,1.572-1.71,2.016
			C7.358,314.081,6.519,314.302,5.572,314.302z M5.572,312.358c0.84,0,1.49-0.355,1.953-1.066c0.462-0.711,0.692-1.736,0.692-3.074
			s-0.23-2.365-0.692-3.083c-0.463-0.717-1.113-1.076-1.953-1.076c-0.847,0-1.502,0.359-1.967,1.076
			c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074C4.07,312.003,4.725,312.358,5.572,312.358z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M16.957,314.302c-0.96,0-1.805-0.222-2.534-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.981-1.57,1.71-2.011
			c0.729-0.441,1.574-0.662,2.534-0.662c0.947,0,1.786,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.41,0.9,0.616,2.037,0.616,3.411c0,1.368-0.206,2.502-0.616,3.402c-0.411,0.9-0.981,1.572-1.71,2.016
			C18.743,314.081,17.904,314.302,16.957,314.302z M16.957,312.358c0.84,0,1.49-0.355,1.953-1.066
			c0.462-0.711,0.692-1.736,0.692-3.074s-0.23-2.365-0.692-3.083c-0.463-0.717-1.113-1.076-1.953-1.076
			c-0.847,0-1.502,0.359-1.967,1.076c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074
			C15.455,312.003,16.11,312.358,16.957,312.358z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M23.067,312.808c0-0.408,0.138-0.75,0.414-1.026s0.63-0.414,1.062-0.414c0.432,0,0.792,0.138,1.079,0.414
			c0.288,0.276,0.433,0.618,0.433,1.026c0,0.402-0.145,0.737-0.433,1.004c-0.287,0.267-0.647,0.4-1.079,0.4
			c-0.433,0-0.786-0.135-1.062-0.405C23.205,313.537,23.067,313.204,23.067,312.808z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M32.212,314.302c-0.96,0-1.805-0.222-2.534-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.981-1.57,1.71-2.011
			c0.729-0.441,1.574-0.662,2.534-0.662c0.947,0,1.786,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.41,0.9,0.616,2.037,0.616,3.411c0,1.368-0.206,2.502-0.616,3.402c-0.411,0.9-0.981,1.572-1.71,2.016
			C33.998,314.081,33.159,314.302,32.212,314.302z M32.212,312.358c0.84,0,1.49-0.355,1.953-1.066
			c0.462-0.711,0.692-1.736,0.692-3.074s-0.23-2.365-0.692-3.083c-0.463-0.717-1.113-1.076-1.953-1.076
			c-0.847,0-1.502,0.359-1.967,1.076c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074
			C30.71,312.003,31.365,312.358,32.212,312.358z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M43.596,314.302c-0.96,0-1.805-0.222-2.534-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.981-1.57,1.71-2.011
			c0.729-0.441,1.574-0.662,2.534-0.662c0.947,0,1.786,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.41,0.9,0.616,2.037,0.616,3.411c0,1.368-0.206,2.502-0.616,3.402c-0.411,0.9-0.981,1.572-1.71,2.016
			C45.382,314.081,44.544,314.302,43.596,314.302z M43.596,312.358c0.84,0,1.49-0.355,1.953-1.066
			c0.462-0.711,0.692-1.736,0.692-3.074s-0.23-2.365-0.692-3.083c-0.463-0.717-1.113-1.076-1.953-1.076
			c-0.847,0-1.502,0.359-1.967,1.076c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074
			C42.094,312.003,42.75,312.358,43.596,312.358z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M10.539,428.898c-0.96,0-1.805-0.222-2.534-0.666c-0.729-0.443-1.299-1.115-1.71-2.016
			s-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.899,0.981-1.57,1.71-2.011
			c0.729-0.441,1.574-0.662,2.534-0.662c0.947,0,1.786,0.221,2.516,0.662c0.729,0.44,1.299,1.111,1.71,2.011
			c0.41,0.9,0.616,2.037,0.616,3.411c0,1.368-0.206,2.502-0.616,3.402c-0.411,0.9-0.981,1.572-1.71,2.016
			C12.325,428.676,11.486,428.898,10.539,428.898z M10.539,426.954c0.84,0,1.49-0.355,1.953-1.066
			c0.462-0.712,0.692-1.736,0.692-3.074s-0.23-2.365-0.692-3.082c-0.463-0.717-1.113-1.076-1.953-1.076
			c-0.847,0-1.502,0.359-1.967,1.076s-0.697,1.744-0.697,3.082s0.232,2.362,0.697,3.074C9.037,426.599,9.692,426.954,10.539,426.954
			z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M19.349,416.892h1.602v11.754h-2.124v-9.216c-0.317,0.145-0.63,0.255-0.936,0.333
			s-0.612,0.117-0.918,0.117v-2.034c0.408-0.042,0.804-0.137,1.188-0.283C18.546,417.415,18.941,417.191,19.349,416.892z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M23.066,427.403c0-0.407,0.138-0.75,0.414-1.025c0.276-0.276,0.63-0.414,1.062-0.414
			c0.432,0,0.792,0.138,1.079,0.414c0.288,0.275,0.433,0.618,0.433,1.025c0,0.402-0.145,0.737-0.433,1.004
			c-0.287,0.267-0.647,0.4-1.079,0.4c-0.433,0-0.786-0.135-1.062-0.405C23.204,428.133,23.066,427.8,23.066,427.403z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M32.211,428.898c-0.96,0-1.805-0.222-2.534-0.666c-0.729-0.443-1.299-1.115-1.71-2.016
			s-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.899,0.981-1.57,1.71-2.011
			c0.729-0.441,1.574-0.662,2.534-0.662c0.947,0,1.786,0.221,2.516,0.662c0.729,0.44,1.299,1.111,1.71,2.011
			c0.41,0.9,0.616,2.037,0.616,3.411c0,1.368-0.206,2.502-0.616,3.402c-0.411,0.9-0.981,1.572-1.71,2.016
			C33.997,428.676,33.158,428.898,32.211,428.898z M32.211,426.954c0.84,0,1.49-0.355,1.953-1.066
			c0.462-0.712,0.692-1.736,0.692-3.074s-0.23-2.365-0.692-3.082c-0.463-0.717-1.113-1.076-1.953-1.076
			c-0.847,0-1.502,0.359-1.967,1.076s-0.697,1.744-0.697,3.082s0.232,2.362,0.697,3.074
			C30.709,426.599,31.364,426.954,32.211,426.954z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M43.595,428.898c-0.96,0-1.805-0.222-2.534-0.666c-0.729-0.443-1.299-1.115-1.71-2.016
			s-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.899,0.981-1.57,1.71-2.011
			c0.729-0.441,1.574-0.662,2.534-0.662c0.947,0,1.786,0.221,2.516,0.662c0.729,0.44,1.299,1.111,1.71,2.011
			c0.41,0.9,0.616,2.037,0.616,3.411c0,1.368-0.206,2.502-0.616,3.402c-0.411,0.9-0.981,1.572-1.71,2.016
			C45.381,428.676,44.543,428.898,43.595,428.898z M43.595,426.954c0.84,0,1.49-0.355,1.953-1.066
			c0.462-0.712,0.692-1.736,0.692-3.074s-0.23-2.365-0.692-3.082c-0.463-0.717-1.113-1.076-1.953-1.076
			c-0.847,0-1.502,0.359-1.967,1.076s-0.697,1.744-0.697,3.082s0.232,2.362,0.697,3.074
			C42.093,426.599,42.749,426.954,43.595,426.954z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M6.066,543.493c-0.96,0-1.805-0.222-2.534-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.981-1.57,1.71-2.011
			c0.729-0.441,1.574-0.662,2.534-0.662c0.947,0,1.786,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.41,0.9,0.616,2.037,0.616,3.411c0,1.368-0.206,2.502-0.616,3.402c-0.411,0.9-0.981,1.572-1.71,2.016
			C7.852,543.271,7.013,543.493,6.066,543.493z M6.066,541.549c0.84,0,1.49-0.355,1.953-1.066c0.462-0.711,0.692-1.736,0.692-3.074
			s-0.23-2.365-0.692-3.083c-0.463-0.717-1.113-1.076-1.953-1.076c-0.847,0-1.502,0.359-1.967,1.076
			c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074C4.564,541.193,5.219,541.549,6.066,541.549z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M12.825,543.241v-1.998c0-0.654,0.099-1.209,0.297-1.665s0.493-0.841,0.887-1.156
			c0.393-0.315,0.886-0.586,1.48-0.814l2.394-0.918c0.522-0.21,0.907-0.468,1.156-0.774c0.249-0.306,0.374-0.684,0.374-1.134
			s-0.164-0.83-0.49-1.139c-0.327-0.309-0.794-0.463-1.4-0.463c-0.582,0-1.069,0.146-1.462,0.437
			c-0.394,0.291-0.62,0.715-0.68,1.273h-2.358c0.019-0.798,0.226-1.461,0.621-1.989c0.396-0.528,0.928-0.923,1.594-1.184
			c0.666-0.261,1.415-0.392,2.25-0.392c0.785,0,1.496,0.148,2.133,0.445c0.636,0.297,1.142,0.711,1.517,1.242
			c0.375,0.531,0.562,1.15,0.562,1.858c0,0.6-0.11,1.128-0.329,1.584c-0.219,0.456-0.55,0.855-0.994,1.197
			c-0.444,0.342-1.005,0.645-1.683,0.909l-2.412,0.936c-0.469,0.18-0.808,0.38-1.018,0.599c-0.21,0.219-0.314,0.505-0.314,0.859
			v0.342h6.678v1.944H12.825z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M23.067,541.999c0-0.408,0.138-0.75,0.414-1.026c0.275-0.276,0.63-0.414,1.062-0.414
			s0.792,0.138,1.08,0.414s0.432,0.618,0.432,1.026c0,0.402-0.144,0.737-0.432,1.004c-0.288,0.267-0.648,0.4-1.08,0.4
			s-0.786-0.135-1.062-0.405C23.205,542.728,23.067,542.395,23.067,541.999z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M32.211,543.493c-0.96,0-1.805-0.222-2.533-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.98-1.57,1.71-2.011
			c0.729-0.441,1.573-0.662,2.533-0.662c0.948,0,1.787,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.411,0.9,0.616,2.037,0.616,3.411c0,1.368-0.205,2.502-0.616,3.402c-0.411,0.9-0.98,1.572-1.71,2.016
			C33.998,543.271,33.159,543.493,32.211,543.493z M32.211,541.549c0.84,0,1.491-0.355,1.953-1.066
			c0.462-0.711,0.693-1.736,0.693-3.074s-0.231-2.365-0.693-3.083c-0.462-0.717-1.113-1.076-1.953-1.076
			c-0.846,0-1.501,0.359-1.967,1.076c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074
			C30.71,541.193,31.365,541.549,32.211,541.549z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M43.595,543.493c-0.96,0-1.805-0.222-2.533-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.98-1.57,1.71-2.011
			c0.729-0.441,1.573-0.662,2.533-0.662c0.948,0,1.787,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.411,0.9,0.616,2.037,0.616,3.411c0,1.368-0.205,2.502-0.616,3.402c-0.411,0.9-0.98,1.572-1.71,2.016
			C45.382,543.271,44.544,543.493,43.595,543.493z M43.595,541.549c0.84,0,1.491-0.355,1.953-1.066
			c0.462-0.711,0.693-1.736,0.693-3.074s-0.231-2.365-0.693-3.083c-0.462-0.717-1.113-1.076-1.953-1.076
			c-0.846,0-1.501,0.359-1.967,1.076c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074
			C42.094,541.193,42.75,541.549,43.595,541.549z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M5.759,658.088c-0.96,0-1.805-0.222-2.533-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.98-1.57,1.71-2.011
			c0.729-0.441,1.573-0.662,2.533-0.662c0.948,0,1.787,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.411,0.9,0.616,2.037,0.616,3.411c0,1.368-0.205,2.502-0.616,3.402c-0.411,0.9-0.98,1.572-1.71,2.016
			C7.546,657.867,6.708,658.088,5.759,658.088z M5.759,656.145c0.84,0,1.491-0.355,1.953-1.066c0.462-0.711,0.693-1.736,0.693-3.074
			s-0.231-2.365-0.693-3.083c-0.462-0.717-1.113-1.076-1.953-1.076c-0.846,0-1.501,0.359-1.967,1.076
			c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074C4.258,655.789,4.914,656.145,5.759,656.145z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M17.072,658.088c-0.617,0-1.215-0.084-1.791-0.252c-0.575-0.168-1.092-0.417-1.548-0.747
			s-0.817-0.739-1.084-1.229c-0.268-0.489-0.4-1.054-0.4-1.696h2.231c0.024,0.408,0.147,0.76,0.369,1.058
			c0.222,0.297,0.523,0.525,0.904,0.684c0.381,0.159,0.82,0.239,1.318,0.239c0.732,0,1.306-0.168,1.72-0.504
			s0.621-0.762,0.621-1.278c0-0.336-0.084-0.625-0.252-0.868c-0.169-0.243-0.427-0.429-0.774-0.558s-0.792-0.194-1.332-0.194h-1.548
			v-1.674h1.313c0.685,0,1.222-0.147,1.611-0.441s0.585-0.693,0.585-1.197c0-0.45-0.168-0.827-0.504-1.13
			c-0.336-0.303-0.84-0.455-1.512-0.455c-0.546,0-1.034,0.111-1.463,0.333c-0.429,0.222-0.703,0.609-0.823,1.161h-2.232
			c0.084-0.798,0.326-1.449,0.725-1.953c0.399-0.504,0.917-0.874,1.553-1.111c0.636-0.237,1.353-0.355,2.151-0.355
			c0.846,0,1.599,0.138,2.259,0.414s1.181,0.659,1.562,1.147c0.381,0.489,0.571,1.055,0.571,1.696c0,0.594-0.162,1.112-0.486,1.553
			c-0.323,0.441-0.804,0.77-1.439,0.985c0.708,0.24,1.274,0.597,1.701,1.071c0.426,0.474,0.639,1.065,0.639,1.773
			c0,0.69-0.193,1.3-0.581,1.832c-0.387,0.531-0.928,0.946-1.624,1.246S18.003,658.088,17.072,658.088z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M23.066,656.594c0-0.408,0.138-0.75,0.414-1.026s0.63-0.414,1.062-0.414c0.432,0,0.792,0.138,1.079,0.414
			c0.288,0.276,0.433,0.618,0.433,1.026c0,0.402-0.145,0.737-0.433,1.004c-0.287,0.267-0.647,0.4-1.079,0.4
			c-0.433,0-0.786-0.135-1.062-0.405C23.204,657.323,23.066,656.99,23.066,656.594z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M32.211,658.088c-0.96,0-1.805-0.222-2.534-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.981-1.57,1.71-2.011
			c0.729-0.441,1.574-0.662,2.534-0.662c0.947,0,1.786,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.41,0.9,0.616,2.037,0.616,3.411c0,1.368-0.206,2.502-0.616,3.402c-0.411,0.9-0.981,1.572-1.71,2.016
			C33.997,657.867,33.158,658.088,32.211,658.088z M32.211,656.145c0.84,0,1.49-0.355,1.953-1.066
			c0.462-0.711,0.692-1.736,0.692-3.074s-0.23-2.365-0.692-3.083c-0.463-0.717-1.113-1.076-1.953-1.076
			c-0.847,0-1.502,0.359-1.967,1.076c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074
			C30.709,655.789,31.364,656.145,32.211,656.145z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M43.595,658.088c-0.96,0-1.805-0.222-2.534-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.981-1.57,1.71-2.011
			c0.729-0.441,1.574-0.662,2.534-0.662c0.947,0,1.786,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.41,0.9,0.616,2.037,0.616,3.411c0,1.368-0.206,2.502-0.616,3.402c-0.411,0.9-0.981,1.572-1.71,2.016
			C45.381,657.867,44.543,658.088,43.595,658.088z M43.595,656.145c0.84,0,1.49-0.355,1.953-1.066
			c0.462-0.711,0.692-1.736,0.692-3.074s-0.23-2.365-0.692-3.083c-0.463-0.717-1.113-1.076-1.953-1.076
			c-0.847,0-1.502,0.359-1.967,1.076c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074
			C42.093,655.789,42.749,656.145,43.595,656.145z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M6.246,772.684c-0.96,0-1.805-0.222-2.533-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.98-1.57,1.71-2.011
			c0.729-0.441,1.573-0.662,2.533-0.662c0.948,0,1.787,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.411,0.9,0.616,2.037,0.616,3.411c0,1.368-0.205,2.502-0.616,3.402c-0.411,0.9-0.98,1.572-1.71,2.016
			C8.033,772.462,7.194,772.684,6.246,772.684z M6.246,770.74c0.84,0,1.491-0.355,1.953-1.066c0.462-0.711,0.693-1.736,0.693-3.074
			s-0.231-2.365-0.693-3.083c-0.462-0.717-1.113-1.076-1.953-1.076c-0.846,0-1.501,0.359-1.967,1.076
			c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074C4.745,770.384,5.4,770.74,6.246,770.74z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M18.296,760.624h2.052v7.398h2.034v1.818h-2.034v2.592h-2.105v-2.592h-5.922v-1.602L18.296,760.624z
			 M18.35,763.773l-3.51,4.464h3.51V763.773z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M23.067,771.19c0-0.408,0.138-0.75,0.414-1.026c0.275-0.276,0.63-0.414,1.062-0.414
			s0.792,0.138,1.08,0.414s0.432,0.618,0.432,1.026c0,0.402-0.144,0.737-0.432,1.004c-0.288,0.267-0.648,0.4-1.08,0.4
			s-0.786-0.135-1.062-0.405C23.205,771.919,23.067,771.586,23.067,771.19z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M32.211,772.684c-0.96,0-1.805-0.222-2.533-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.98-1.57,1.71-2.011
			c0.729-0.441,1.573-0.662,2.533-0.662c0.948,0,1.787,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.411,0.9,0.616,2.037,0.616,3.411c0,1.368-0.205,2.502-0.616,3.402c-0.411,0.9-0.98,1.572-1.71,2.016
			C33.998,772.462,33.159,772.684,32.211,772.684z M32.211,770.74c0.84,0,1.491-0.355,1.953-1.066
			c0.462-0.711,0.693-1.736,0.693-3.074s-0.231-2.365-0.693-3.083c-0.462-0.717-1.113-1.076-1.953-1.076
			c-0.846,0-1.501,0.359-1.967,1.076c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074
			C30.71,770.384,31.365,770.74,32.211,770.74z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M43.595,772.684c-0.96,0-1.805-0.222-2.533-0.666c-0.729-0.444-1.299-1.116-1.71-2.016
			c-0.411-0.9-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.9,0.98-1.57,1.71-2.011
			c0.729-0.441,1.573-0.662,2.533-0.662c0.948,0,1.787,0.221,2.516,0.662c0.729,0.441,1.299,1.111,1.71,2.011
			c0.411,0.9,0.616,2.037,0.616,3.411c0,1.368-0.205,2.502-0.616,3.402c-0.411,0.9-0.98,1.572-1.71,2.016
			C45.382,772.462,44.544,772.684,43.595,772.684z M43.595,770.74c0.84,0,1.491-0.355,1.953-1.066
			c0.462-0.711,0.693-1.736,0.693-3.074s-0.231-2.365-0.693-3.083c-0.462-0.717-1.113-1.076-1.953-1.076
			c-0.846,0-1.501,0.359-1.967,1.076c-0.465,0.717-0.697,1.745-0.697,3.083s0.232,2.362,0.697,3.074
			C42.094,770.384,42.75,770.74,43.595,770.74z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M6.48,887.278c-0.96,0-1.805-0.222-2.533-0.666c-0.729-0.443-1.299-1.115-1.71-2.016
			s-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.899,0.98-1.57,1.71-2.011
			c0.729-0.441,1.573-0.662,2.533-0.662c0.948,0,1.787,0.221,2.516,0.662c0.729,0.44,1.299,1.111,1.71,2.011
			c0.411,0.9,0.616,2.037,0.616,3.411c0,1.368-0.205,2.502-0.616,3.402s-0.98,1.572-1.71,2.016
			C8.267,887.057,7.428,887.278,6.48,887.278z M6.48,885.335c0.84,0,1.491-0.355,1.953-1.066c0.462-0.712,0.693-1.736,0.693-3.074
			s-0.231-2.365-0.693-3.082c-0.462-0.717-1.113-1.076-1.953-1.076c-0.846,0-1.501,0.359-1.967,1.076
			c-0.465,0.717-0.697,1.744-0.697,3.082s0.232,2.362,0.697,3.074C4.979,884.98,5.634,885.335,6.48,885.335z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M17.505,887.278c-0.841,0-1.581-0.146-2.224-0.437c-0.642-0.291-1.147-0.715-1.517-1.273
			c-0.369-0.558-0.562-1.235-0.58-2.034h2.16c0,0.601,0.207,1.051,0.621,1.351c0.414,0.3,0.927,0.45,1.539,0.45
			c0.653,0,1.189-0.212,1.606-0.635s0.625-0.943,0.625-1.562c0-1.2-0.804-1.8-2.412-1.8h-3.563l0.54-5.994h7.002v1.944h-5.292
			l0.414-0.612l-0.343,3.474l-0.18-0.198c0.288-0.065,0.63-0.115,1.026-0.148c0.396-0.032,0.737-0.049,1.025-0.049
			c1.206,0,2.168,0.292,2.885,0.877s1.075,1.421,1.075,2.507c0,0.774-0.183,1.475-0.549,2.102s-0.879,1.123-1.539,1.489
			S18.393,887.278,17.505,887.278z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M23.067,885.784c0-0.407,0.138-0.75,0.414-1.025c0.275-0.276,0.63-0.414,1.062-0.414
			s0.792,0.138,1.08,0.414c0.288,0.275,0.432,0.618,0.432,1.025c0,0.402-0.144,0.737-0.432,1.004s-0.648,0.4-1.08,0.4
			s-0.786-0.135-1.062-0.405C23.205,886.514,23.067,886.181,23.067,885.784z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M32.211,887.278c-0.96,0-1.805-0.222-2.533-0.666c-0.729-0.443-1.299-1.115-1.71-2.016
			s-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.899,0.98-1.57,1.71-2.011
			c0.729-0.441,1.573-0.662,2.533-0.662c0.948,0,1.787,0.221,2.516,0.662c0.729,0.44,1.299,1.111,1.71,2.011
			c0.411,0.9,0.616,2.037,0.616,3.411c0,1.368-0.205,2.502-0.616,3.402s-0.98,1.572-1.71,2.016
			C33.998,887.057,33.159,887.278,32.211,887.278z M32.211,885.335c0.84,0,1.491-0.355,1.953-1.066
			c0.462-0.712,0.693-1.736,0.693-3.074s-0.231-2.365-0.693-3.082s-1.113-1.076-1.953-1.076c-0.846,0-1.501,0.359-1.967,1.076
			c-0.465,0.717-0.697,1.744-0.697,3.082s0.232,2.362,0.697,3.074C30.71,884.98,31.365,885.335,32.211,885.335z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M43.595,887.278c-0.96,0-1.805-0.222-2.533-0.666c-0.729-0.443-1.299-1.115-1.71-2.016
			s-0.616-2.034-0.616-3.402c0-1.374,0.205-2.511,0.616-3.411c0.411-0.899,0.98-1.57,1.71-2.011
			c0.729-0.441,1.573-0.662,2.533-0.662c0.948,0,1.787,0.221,2.516,0.662c0.729,0.44,1.299,1.111,1.71,2.011
			c0.411,0.9,0.616,2.037,0.616,3.411c0,1.368-0.205,2.502-0.616,3.402s-0.98,1.572-1.71,2.016
			C45.382,887.057,44.544,887.278,43.595,887.278z M43.595,885.335c0.84,0,1.491-0.355,1.953-1.066
			c0.462-0.712,0.693-1.736,0.693-3.074s-0.231-2.365-0.693-3.082s-1.113-1.076-1.953-1.076c-0.846,0-1.501,0.359-1.967,1.076
			c-0.465,0.717-0.697,1.744-0.697,3.082s0.232,2.362,0.697,3.074C42.094,884.98,42.75,885.335,43.595,885.335z"
                  />
                </g>
                <g>
                  <path
                    fill="#1E1B39"
                    d="M243.829,158.794c-0.661,0-1.267-0.17-1.815-0.512c-0.55-0.341-0.989-0.82-1.32-1.436
			c-0.33-0.616-0.496-1.337-0.496-2.164c0-0.853,0.164-1.586,0.492-2.2s0.767-1.083,1.316-1.408
			c0.549-0.325,1.157-0.488,1.823-0.488c0.619,0,1.153,0.124,1.604,0.372c0.45,0.248,0.813,0.584,1.088,1.008
			s0.46,0.903,0.556,1.436c0.097,0.533,0.102,1.083,0.017,1.648h-5.393v-1.024h4.057l-0.368,0.24c0.016-0.25,0.004-0.504-0.036-0.76
			s-0.116-0.491-0.228-0.704c-0.112-0.213-0.271-0.385-0.477-0.516c-0.205-0.13-0.468-0.196-0.788-0.196
			c-0.384,0-0.709,0.096-0.976,0.288s-0.47,0.453-0.608,0.784c-0.139,0.331-0.208,0.704-0.208,1.12v0.752
			c0,0.438,0.065,0.824,0.196,1.16s0.325,0.6,0.584,0.792s0.585,0.288,0.979,0.288c0.368,0,0.678-0.085,0.929-0.256
			c0.25-0.171,0.41-0.427,0.479-0.768h1.904c-0.08,0.501-0.268,0.942-0.564,1.324c-0.296,0.381-0.679,0.68-1.147,0.896
			C244.96,158.686,244.426,158.794,243.829,158.794z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M253.461,148.186h2.704l2.929,6.352h-0.16l2.928-6.352h2.608v10.384h-1.904v-8.208h0.288l-3.232,7.072
			h-1.231l-3.216-7.04h0.176v8.176h-1.889V148.186z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M271.341,150.778h1.888v4.768c0,0.507,0.125,0.887,0.376,1.14c0.251,0.253,0.595,0.38,1.032,0.38
			c0.373,0,0.713-0.097,1.02-0.292s0.552-0.483,0.736-0.864c0.184-0.381,0.275-0.854,0.275-1.42h0.513
			c0,0.949-0.123,1.736-0.368,2.36s-0.584,1.091-1.016,1.4c-0.433,0.309-0.932,0.463-1.496,0.463c-0.555,0-1.057-0.099-1.504-0.295
			c-0.448-0.197-0.803-0.515-1.064-0.952c-0.262-0.437-0.392-1.013-0.392-1.728V150.778z M276.669,150.778h1.889v7.792h-1.889
			V150.778z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M287.469,148.186v8.736h4.271v1.648h-6.16v-10.384H287.469z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M300.526,162.586c-0.893,0-1.652-0.122-2.285-0.367c-0.632-0.246-1.116-0.567-1.452-0.965
			c-0.336-0.396-0.504-0.827-0.504-1.292c0-0.549,0.2-0.975,0.6-1.275c0.4-0.301,0.904-0.452,1.513-0.452l1.375,0.512
			c-0.521,0-0.924,0.099-1.207,0.296s-0.424,0.473-0.424,0.824c0,0.368,0.211,0.663,0.637,0.884
			c0.424,0.222,1.006,0.332,1.748,0.332c0.537,0,1.004-0.08,1.398-0.239c0.395-0.16,0.701-0.368,0.916-0.624
			c0.217-0.257,0.324-0.528,0.324-0.816c0-0.192-0.064-0.35-0.191-0.472c-0.129-0.123-0.326-0.185-0.592-0.185h-2.609
			c-0.521,0-0.998-0.084-1.428-0.252c-0.429-0.168-0.771-0.395-1.023-0.68c-0.254-0.285-0.38-0.604-0.38-0.956
			c0-0.325,0.091-0.607,0.271-0.844c0.182-0.237,0.429-0.42,0.74-0.548s0.663-0.192,1.052-0.192l0.48,0.624
			c-0.154,0-0.303,0.031-0.443,0.092c-0.143,0.062-0.258,0.151-0.348,0.268c-0.092,0.117-0.137,0.264-0.137,0.44
			c0,0.213,0.09,0.387,0.271,0.52s0.496,0.2,0.943,0.2h2.609c0.826,0,1.475,0.152,1.947,0.456c0.473,0.304,0.709,0.77,0.709,1.4
			c0,0.56-0.186,1.092-0.553,1.596s-0.889,0.916-1.564,1.236C302.247,162.426,301.448,162.586,300.526,162.586z M300.622,156.17
			c-0.613,0-1.176-0.116-1.686-0.348c-0.508-0.232-0.915-0.556-1.22-0.972c-0.304-0.416-0.456-0.904-0.456-1.464
			c0-0.57,0.152-1.066,0.456-1.488c0.305-0.421,0.712-0.746,1.22-0.972c0.51-0.227,1.072-0.34,1.686-0.34
			c0.596,0,1.148,0.113,1.656,0.34c0.506,0.227,0.914,0.551,1.223,0.972c0.311,0.421,0.465,0.917,0.465,1.488
			c0,0.56-0.154,1.048-0.465,1.464c-0.309,0.416-0.717,0.74-1.223,0.972C301.77,156.054,301.217,156.17,300.622,156.17z
			 M300.637,154.858c0.49,0,0.857-0.141,1.104-0.424c0.246-0.282,0.369-0.632,0.369-1.048c0-0.448-0.125-0.808-0.373-1.08
			s-0.619-0.408-1.115-0.408c-0.48,0-0.85,0.136-1.105,0.408s-0.383,0.632-0.383,1.08c0,0.416,0.129,0.766,0.387,1.048
			C299.78,154.717,300.151,154.858,300.637,154.858z M303.374,151.834l-0.865-0.496c0-0.464,0.109-0.871,0.324-1.22
			c0.217-0.349,0.504-0.62,0.865-0.812c0.359-0.192,0.758-0.288,1.195-0.288l-0.096,1.808c-0.465,0-0.818,0.082-1.061,0.244
			S303.374,151.487,303.374,151.834z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M313.293,158.794c-0.666,0-1.223-0.115-1.668-0.348c-0.445-0.232-0.779-0.537-1.004-0.916
			c-0.224-0.378-0.336-0.789-0.336-1.232c0-0.491,0.129-0.903,0.388-1.236c0.259-0.333,0.626-0.584,1.101-0.752
			c0.475-0.168,1.034-0.252,1.68-0.252c0.422,0,0.82,0.032,1.196,0.096s0.75,0.149,1.124,0.256v1.104
			c-0.294-0.096-0.613-0.17-0.96-0.224s-0.672-0.08-0.977-0.08c-0.447,0-0.815,0.083-1.104,0.248
			c-0.288,0.165-0.432,0.445-0.432,0.84c0,0.288,0.117,0.536,0.352,0.744s0.608,0.312,1.12,0.312c0.389,0,0.72-0.085,0.992-0.256
			c0.271-0.17,0.479-0.384,0.62-0.64c0.141-0.256,0.212-0.512,0.212-0.768h0.447c0,0.56-0.104,1.076-0.312,1.548
			s-0.517,0.85-0.924,1.132C314.401,158.653,313.895,158.794,313.293,158.794z M315.837,158.57l-0.239-1.888v-3.12
			c0-0.544-0.111-0.947-0.332-1.208c-0.222-0.261-0.597-0.392-1.124-0.392c-0.305,0-0.568,0.045-0.792,0.136
			c-0.225,0.091-0.396,0.22-0.517,0.388s-0.18,0.372-0.18,0.612h-2.064c0-0.586,0.142-1.065,0.425-1.436
			c0.282-0.371,0.688-0.643,1.22-0.816c0.53-0.173,1.166-0.26,1.908-0.26c0.672,0,1.258,0.108,1.76,0.324
			c0.501,0.216,0.891,0.548,1.168,0.996c0.277,0.448,0.416,1.021,0.416,1.72v4.944H315.837z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M323.324,152.058v-1.248h5.088v1.248H323.324z M326.924,158.794c-0.459,0-0.879-0.085-1.261-0.256
			c-0.381-0.17-0.685-0.432-0.911-0.784c-0.228-0.352-0.341-0.8-0.341-1.344v-7.664h1.889v7.6c0,0.64,0.304,0.96,0.912,0.96
			c0.187,0,0.373-0.021,0.56-0.064c0.187-0.042,0.4-0.106,0.64-0.192l0.272,1.328c-0.299,0.117-0.59,0.216-0.872,0.295
			C327.529,158.753,327.233,158.794,326.924,158.794z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M337.715,158.794c-0.709,0-1.351-0.151-1.924-0.456s-1.028-0.759-1.364-1.364
			c-0.336-0.605-0.504-1.364-0.504-2.276c0-0.928,0.168-1.696,0.504-2.304c0.336-0.608,0.791-1.062,1.364-1.36
			c0.573-0.298,1.215-0.448,1.924-0.448c0.698,0,1.332,0.149,1.9,0.448c0.567,0.299,1.02,0.752,1.355,1.36
			c0.336,0.608,0.504,1.376,0.504,2.304c0,0.912-0.168,1.671-0.504,2.276c-0.336,0.605-0.788,1.06-1.355,1.364
			C339.047,158.642,338.414,158.794,337.715,158.794z M337.652,157.146c0.373,0,0.705-0.092,0.995-0.276
			c0.291-0.184,0.521-0.457,0.688-0.82s0.252-0.813,0.252-1.352c0-0.821-0.161-1.44-0.484-1.856
			c-0.322-0.416-0.764-0.624-1.323-0.624c-0.379,0-0.715,0.092-1.009,0.276c-0.293,0.184-0.523,0.46-0.691,0.828
			s-0.252,0.827-0.252,1.376c0,0.811,0.164,1.421,0.492,1.832C336.647,156.94,337.091,157.146,337.652,157.146z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M347.763,158.57v-10.384h3.84c1.242,0,2.196,0.291,2.86,0.872c0.663,0.582,0.995,1.352,0.995,2.312
			c0,0.944-0.332,1.706-0.995,2.284c-0.664,0.579-1.618,0.868-2.86,0.868h-1.952v4.048H347.763z M349.651,153.258h1.76
			c0.693,0,1.219-0.159,1.576-0.476s0.536-0.729,0.536-1.236c0-0.517-0.163-0.935-0.488-1.252s-0.803-0.476-1.432-0.476h-1.952
			V153.258z M353.379,158.57l-2.752-4.864h2.017l3.2,4.864H353.379z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M242.806,174.57l0.72-7.662h2.334c0.512,0,0.937,0.096,1.275,0.288c0.338,0.192,0.585,0.467,0.74,0.825
			c0.156,0.357,0.208,0.789,0.156,1.293c-0.068,0.74-0.332,1.322-0.792,1.746s-1.072,0.636-1.836,0.636h-1.266l-0.271,2.874H242.806
			z M244.234,170.676h1.266c0.433,0,0.772-0.126,1.021-0.379c0.248-0.252,0.388-0.579,0.42-0.983
			c0.04-0.416-0.039-0.753-0.237-1.011c-0.197-0.259-0.515-0.388-0.951-0.388h-1.266L244.234,170.676z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M250.822,174.72c-0.452,0-0.848-0.124-1.188-0.372c-0.341-0.248-0.596-0.6-0.766-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.061-0.632,0.217-1.176,0.472-1.632c0.254-0.456,0.572-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.355,0,0.66,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.614,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.058,0.981c-0.009,0.112-0.021,0.229-0.039,0.351
			c-0.019,0.122-0.041,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.092,0.38-0.249,0.709-0.471,0.987c-0.223,0.277-0.492,0.493-0.811,0.645
			C251.551,174.644,251.202,174.72,250.822,174.72z M249.778,171.384l-0.234-0.168h3.049l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.135-0.494-0.288-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243c-0.217,0.162-0.39,0.38-0.52,0.654C249.903,170.743,249.818,171.047,249.778,171.384z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M254.595,174.57l0.546-5.796h1.057l-0.108,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.855-0.297c0.08,0,0.164,0.007,0.252,0.021s0.174,0.039,0.258,0.075l-0.138,1.164
			c-0.104-0.028-0.21-0.051-0.318-0.069c-0.108-0.018-0.214-0.026-0.318-0.026c-0.288,0-0.533,0.086-0.737,0.258
			s-0.365,0.418-0.483,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.239,2.574H254.595z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M258.796,169.584l0.078-0.798h0.804l0.084-0.78c0.036-0.407,0.136-0.738,0.3-0.992
			s0.369-0.441,0.615-0.562s0.505-0.18,0.777-0.18c0.156,0,0.318,0.013,0.489,0.039c0.17,0.025,0.335,0.062,0.494,0.11l-0.288,0.912
			c-0.096-0.04-0.194-0.072-0.297-0.099c-0.102-0.026-0.203-0.039-0.303-0.039c-0.107,0-0.214,0.024-0.318,0.072
			c-0.104,0.048-0.192,0.131-0.267,0.249s-0.123,0.285-0.146,0.501l-0.078,0.768h1.025l-0.078,0.798h-1.025l-0.462,4.986h-1.051
			l0.456-4.986H258.796z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M264.657,174.72c-0.472,0-0.886-0.114-1.241-0.343c-0.356-0.228-0.62-0.566-0.792-1.017
			c-0.173-0.45-0.227-1.009-0.162-1.677c0.063-0.676,0.226-1.24,0.485-1.692c0.261-0.452,0.589-0.791,0.987-1.017
			c0.398-0.227,0.831-0.339,1.299-0.339c0.469,0,0.878,0.114,1.23,0.345c0.352,0.229,0.616,0.57,0.792,1.02
			c0.176,0.45,0.231,1.012,0.168,1.684c-0.068,0.668-0.232,1.227-0.492,1.677s-0.586,0.789-0.978,1.017
			C265.561,174.606,265.13,174.72,264.657,174.72z M264.712,173.7c0.443,0,0.812-0.175,1.104-0.524
			c0.292-0.351,0.473-0.848,0.54-1.491c0.064-0.652-0.009-1.154-0.219-1.506c-0.21-0.353-0.535-0.528-0.975-0.528
			c-0.44,0-0.805,0.175-1.093,0.525c-0.288,0.35-0.464,0.853-0.527,1.509c-0.044,0.424-0.027,0.787,0.051,1.089
			s0.211,0.532,0.398,0.689C264.179,173.621,264.42,173.7,264.712,173.7z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M268.708,174.57l0.546-5.796h1.056l-0.107,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.252,0.021s0.174,0.039,0.258,0.075l-0.138,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.215-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			s-0.365,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H268.708z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M273.004,174.57l0.546-5.784h0.912c0.004,0.176,0.006,0.353,0.006,0.528s0.002,0.354,0.006,0.534
			c0.212-0.4,0.472-0.702,0.78-0.906c0.308-0.204,0.651-0.306,1.032-0.306c0.443,0,0.805,0.115,1.083,0.348
			c0.277,0.232,0.469,0.556,0.572,0.972c0.212-0.456,0.483-0.79,0.813-1.002s0.696-0.317,1.101-0.317
			c0.42,0,0.766,0.103,1.035,0.309c0.271,0.206,0.462,0.5,0.576,0.882s0.146,0.833,0.099,1.354l-0.312,3.39h-1.057l0.312-3.39
			c0.044-0.484-0.027-0.854-0.213-1.11c-0.187-0.256-0.455-0.384-0.808-0.384c-0.287,0-0.539,0.084-0.756,0.252
			c-0.216,0.168-0.39,0.412-0.521,0.731c-0.132,0.32-0.225,0.717-0.276,1.188l-0.246,2.712h-1.056l0.312-3.39
			c0.057-0.496-0.013-0.869-0.207-1.119c-0.193-0.25-0.459-0.375-0.795-0.375c-0.439,0-0.806,0.191-1.098,0.576
			c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H273.004z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M284.733,174.72c-0.42,0-0.767-0.087-1.041-0.259c-0.273-0.172-0.472-0.395-0.594-0.669
			c-0.122-0.273-0.167-0.566-0.135-0.879c0.036-0.38,0.152-0.694,0.351-0.944s0.46-0.438,0.786-0.564s0.693-0.188,1.101-0.188
			c0.253,0,0.5,0.024,0.744,0.074c0.244,0.051,0.477,0.117,0.696,0.201l0.066-0.66c0.052-0.464-0.001-0.802-0.159-1.014
			s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314c-0.108,0.141-0.172,0.314-0.192,0.522h-1.164
			c0.044-0.44,0.178-0.792,0.402-1.056c0.224-0.265,0.508-0.455,0.852-0.573s0.712-0.177,1.104-0.177c0.708,0,1.236,0.18,1.587,0.54
			c0.35,0.359,0.489,0.924,0.417,1.691l-0.349,3.702h-0.911c-0.005-0.147-0.006-0.295-0.003-0.441c0.002-0.146,0-0.293-0.004-0.44
			c-0.199,0.344-0.445,0.602-0.737,0.773S285.121,174.72,284.733,174.72z M284.991,173.82c0.272,0,0.525-0.066,0.76-0.198
			c0.233-0.132,0.425-0.316,0.572-0.555c0.148-0.238,0.23-0.515,0.246-0.831c-0.195-0.063-0.405-0.116-0.63-0.156
			c-0.224-0.04-0.434-0.06-0.63-0.06c-0.324,0-0.602,0.066-0.834,0.201c-0.232,0.134-0.364,0.364-0.396,0.692
			c-0.021,0.256,0.049,0.472,0.207,0.646C284.444,173.733,284.679,173.82,284.991,173.82z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M289.347,174.57l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.472-0.702,0.78-0.906
			c0.308-0.204,0.651-0.306,1.032-0.306c0.42,0,0.766,0.103,1.037,0.309c0.272,0.206,0.467,0.5,0.582,0.882
			c0.116,0.382,0.148,0.833,0.097,1.354l-0.312,3.39h-1.056l0.312-3.39c0.057-0.496-0.013-0.869-0.207-1.119
			c-0.193-0.25-0.459-0.375-0.795-0.375c-0.439,0-0.806,0.191-1.098,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658
			H289.347z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M297.861,174.72c-0.432,0-0.821-0.124-1.17-0.372c-0.348-0.248-0.611-0.601-0.792-1.057
			c-0.18-0.456-0.242-0.996-0.186-1.62c0.06-0.647,0.223-1.196,0.488-1.646c0.267-0.45,0.596-0.794,0.987-1.032
			c0.393-0.237,0.806-0.356,1.242-0.356c0.308,0,0.579,0.055,0.813,0.165c0.233,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.654-0.272c-0.235,0-0.463,0.073-0.681,0.219c-0.218,0.146-0.403,0.366-0.555,0.66
			c-0.152,0.294-0.25,0.663-0.294,1.106c-0.036,0.421-0.012,0.779,0.074,1.077s0.225,0.525,0.414,0.682
			c0.19,0.155,0.417,0.233,0.682,0.233c0.34,0,0.609-0.097,0.811-0.291c0.199-0.193,0.315-0.463,0.348-0.807h1.067
			c-0.032,0.404-0.148,0.769-0.351,1.095s-0.472,0.584-0.811,0.774C298.684,174.625,298.297,174.72,297.861,174.72z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M303.471,174.72c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C304.2,174.644,303.851,174.72,303.471,174.72z M302.427,171.384l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C302.552,170.743,302.467,171.047,302.427,171.384z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M310.623,166.908h1.068l-0.312,3.348l3.216-3.348h1.452l-3.343,3.438l2.814,4.224h-1.368l-2.244-3.402
			l-0.684,0.708l-0.252,2.694h-1.068L310.623,166.908z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M318.543,174.678c-0.38,0-0.711-0.077-0.993-0.231s-0.493-0.395-0.633-0.723s-0.187-0.75-0.138-1.267
			l0.354-3.684h1.056l-0.342,3.576c-0.04,0.432,0.024,0.757,0.192,0.975s0.426,0.327,0.773,0.327c0.288,0,0.552-0.085,0.789-0.255
			c0.238-0.17,0.437-0.411,0.595-0.724c0.157-0.312,0.257-0.682,0.297-1.109l0.264-2.79h1.056l-0.54,5.796h-1.062l0.102-1.092
			c-0.203,0.392-0.458,0.689-0.762,0.894S318.911,174.678,318.543,174.678z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M323.367,174.57l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906s0.652-0.306,1.032-0.306
			c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354l-0.312,3.39h-1.057
			l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375c-0.44,0-0.807,0.191-1.099,0.576
			c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H323.367z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M331.791,174.72c-0.443,0-0.829-0.077-1.154-0.231c-0.326-0.154-0.574-0.372-0.744-0.654
			c-0.17-0.281-0.237-0.614-0.201-0.999h0.996c-0.024,0.309,0.066,0.546,0.272,0.711c0.206,0.166,0.483,0.249,0.831,0.249
			c0.353,0,0.638-0.076,0.855-0.23s0.341-0.347,0.369-0.579c0.023-0.204-0.034-0.361-0.174-0.471
			c-0.141-0.11-0.326-0.205-0.559-0.285l-1.032-0.414c-0.399-0.152-0.702-0.36-0.908-0.624s-0.289-0.584-0.249-0.96
			c0.036-0.324,0.15-0.606,0.345-0.846c0.194-0.24,0.454-0.426,0.78-0.556c0.325-0.13,0.696-0.194,1.112-0.194
			c0.664,0,1.158,0.153,1.482,0.462c0.324,0.308,0.472,0.695,0.444,1.164h-1.015c-0.004-0.229-0.09-0.402-0.258-0.522
			s-0.398-0.18-0.69-0.18c-0.235,0-0.431,0.035-0.585,0.104c-0.153,0.07-0.272,0.159-0.356,0.268
			c-0.084,0.107-0.136,0.224-0.156,0.348c-0.02,0.188,0.037,0.343,0.171,0.465s0.312,0.219,0.531,0.291l1.092,0.45
			c0.384,0.14,0.688,0.331,0.909,0.573s0.315,0.551,0.279,0.927c-0.028,0.296-0.142,0.576-0.339,0.84
			c-0.198,0.264-0.47,0.479-0.813,0.646S332.271,174.72,331.791,174.72z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M337.323,174.72c-0.292,0-0.552-0.062-0.78-0.184c-0.228-0.122-0.398-0.309-0.51-0.561
			c-0.112-0.252-0.152-0.572-0.12-0.96l0.336-3.666l0.246,0.21h-1.056l0.09-0.774h1.044l-0.3,0.271l0.18-1.836h1.056l-0.18,1.836
			l-0.24-0.271h1.8l-0.084,0.774h-1.8l0.294-0.21l-0.342,3.642c-0.024,0.269,0.017,0.468,0.123,0.601
			c0.105,0.132,0.273,0.197,0.501,0.197c0.12,0,0.249-0.017,0.387-0.051c0.139-0.034,0.293-0.085,0.465-0.153l0.072,0.828
			c-0.2,0.093-0.395,0.166-0.585,0.223C337.73,174.692,337.531,174.72,337.323,174.72z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M345.172,174.72c-0.524,0-0.986-0.102-1.387-0.304c-0.399-0.201-0.706-0.477-0.918-0.824
			c-0.212-0.349-0.298-0.74-0.258-1.177c0.052-0.54,0.266-0.962,0.64-1.266s0.887-0.516,1.538-0.636l-0.149-0.162
			c-0.22-0.244-0.39-0.515-0.507-0.811c-0.118-0.296-0.163-0.607-0.136-0.936c0.032-0.344,0.15-0.654,0.354-0.93
			c0.204-0.276,0.48-0.495,0.828-0.657s0.756-0.243,1.224-0.243c0.444,0,0.82,0.082,1.128,0.246
			c0.309,0.164,0.536,0.382,0.685,0.654c0.147,0.271,0.206,0.575,0.174,0.911c-0.044,0.473-0.229,0.887-0.552,1.242
			c-0.324,0.356-0.809,0.674-1.452,0.954l1.403,1.524c0.269-0.32,0.518-0.679,0.747-1.074c0.23-0.396,0.441-0.82,0.634-1.272
			l0.689,0.444c-0.196,0.492-0.416,0.956-0.66,1.392c-0.244,0.437-0.51,0.83-0.798,1.183l0.354,0.366
			c0.111,0.115,0.22,0.202,0.323,0.258c0.104,0.056,0.234,0.084,0.391,0.084c0.172,0,0.331-0.073,0.477-0.219
			c0.146-0.146,0.243-0.386,0.291-0.718h0.973c-0.08,0.612-0.268,1.094-0.562,1.443s-0.691,0.525-1.19,0.525
			c-0.169,0-0.325-0.021-0.472-0.063c-0.146-0.042-0.287-0.104-0.423-0.188s-0.274-0.19-0.414-0.318s-0.292-0.276-0.456-0.444
			c-0.364,0.32-0.757,0.569-1.179,0.747S345.643,174.72,345.172,174.72z M345.279,173.664c0.324,0,0.634-0.058,0.931-0.174
			c0.296-0.116,0.577-0.28,0.846-0.492l-1.704-1.866c-0.512,0.072-0.895,0.211-1.149,0.414c-0.254,0.204-0.395,0.475-0.423,0.811
			c-0.036,0.36,0.075,0.668,0.333,0.924S344.759,173.664,345.279,173.664z M345.843,170.19c0.324-0.156,0.582-0.31,0.773-0.462
			c0.192-0.152,0.335-0.312,0.43-0.478c0.094-0.166,0.152-0.347,0.177-0.543c0.02-0.264-0.053-0.483-0.219-0.66
			c-0.166-0.176-0.397-0.264-0.693-0.264c-0.348,0-0.624,0.1-0.828,0.297c-0.204,0.198-0.317,0.432-0.342,0.699
			c-0.024,0.2,0.002,0.391,0.078,0.573c0.076,0.182,0.2,0.369,0.372,0.561L345.843,170.19z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M245.699,185.584l0.077-0.798h0.805l0.084-0.78c0.036-0.407,0.136-0.738,0.3-0.992
			s0.369-0.441,0.615-0.562s0.505-0.18,0.776-0.18c0.156,0,0.319,0.013,0.489,0.039c0.17,0.025,0.335,0.062,0.495,0.11l-0.288,0.912
			c-0.096-0.04-0.195-0.072-0.297-0.099c-0.103-0.026-0.203-0.039-0.303-0.039c-0.108,0-0.215,0.024-0.318,0.072
			c-0.104,0.048-0.193,0.131-0.267,0.249c-0.074,0.118-0.123,0.285-0.147,0.501l-0.078,0.768h1.026l-0.078,0.798h-1.026
			l-0.462,4.986h-1.05l0.456-4.986H245.699z M249.701,190.57l0.546-5.796h1.056l-0.546,5.796H249.701z M250.888,183.58
			c-0.208,0-0.365-0.063-0.471-0.191c-0.106-0.128-0.153-0.301-0.142-0.517c0.021-0.204,0.096-0.373,0.226-0.507
			s0.301-0.201,0.513-0.201s0.371,0.065,0.478,0.195c0.105,0.13,0.15,0.301,0.135,0.513c-0.021,0.221-0.098,0.394-0.231,0.52
			S251.092,183.58,250.888,183.58z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M254.602,193.552c-0.536,0-0.999-0.084-1.389-0.252c-0.39-0.169-0.686-0.396-0.885-0.685
			c-0.2-0.288-0.284-0.61-0.252-0.966c0.031-0.332,0.154-0.611,0.368-0.837c0.215-0.227,0.481-0.373,0.802-0.441
			c-0.196-0.128-0.34-0.28-0.433-0.459c-0.092-0.178-0.13-0.373-0.113-0.585c0.023-0.275,0.126-0.509,0.306-0.699
			c0.18-0.189,0.402-0.32,0.666-0.393c-0.168-0.188-0.294-0.408-0.378-0.66s-0.112-0.534-0.084-0.846
			c0.044-0.424,0.178-0.793,0.401-1.107c0.225-0.313,0.509-0.557,0.853-0.729s0.714-0.258,1.11-0.258
			c0.252,0,0.485,0.033,0.701,0.102c0.217,0.068,0.406,0.166,0.57,0.294c0.061-0.296,0.169-0.553,0.327-0.771
			c0.158-0.219,0.353-0.389,0.585-0.511s0.486-0.183,0.762-0.183l-0.186,1.14c-0.305,0-0.545,0.062-0.724,0.187
			c-0.178,0.124-0.284,0.322-0.32,0.594c0.104,0.168,0.181,0.356,0.23,0.564s0.065,0.434,0.045,0.678
			c-0.035,0.416-0.168,0.782-0.396,1.098c-0.229,0.316-0.519,0.562-0.87,0.738c-0.352,0.176-0.738,0.264-1.158,0.264
			c-0.164,0-0.329-0.02-0.495-0.06s-0.32-0.097-0.465-0.168c-0.132,0.032-0.252,0.102-0.359,0.21
			c-0.108,0.107-0.175,0.25-0.198,0.426c-0.021,0.176,0.03,0.319,0.152,0.429c0.122,0.11,0.35,0.165,0.682,0.165h1.884
			c0.516,0,0.913,0.112,1.191,0.336c0.277,0.225,0.398,0.559,0.362,1.002c-0.032,0.4-0.188,0.782-0.468,1.146
			s-0.661,0.661-1.143,0.891C255.803,193.437,255.243,193.552,254.602,193.552z M254.644,192.616c0.429,0,0.804-0.065,1.125-0.198
			c0.322-0.132,0.576-0.302,0.763-0.51c0.186-0.208,0.286-0.428,0.303-0.66c0.012-0.18-0.03-0.321-0.126-0.426
			c-0.097-0.104-0.244-0.156-0.444-0.156h-1.974c-0.34,0-0.614,0.09-0.822,0.271c-0.208,0.18-0.32,0.399-0.336,0.66
			c-0.016,0.304,0.11,0.55,0.378,0.737C253.778,192.522,254.156,192.616,254.644,192.616z M255.274,187.996
			c0.248,0,0.461-0.056,0.64-0.168c0.178-0.111,0.318-0.264,0.423-0.456c0.104-0.191,0.166-0.405,0.186-0.642
			c0.036-0.376-0.035-0.682-0.213-0.915c-0.178-0.234-0.442-0.352-0.795-0.352c-0.364,0-0.657,0.118-0.879,0.354
			s-0.349,0.54-0.381,0.912c-0.032,0.359,0.039,0.661,0.213,0.903C254.641,187.875,254.911,187.996,255.274,187.996z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M261.016,190.678c-0.38,0-0.711-0.077-0.993-0.231c-0.281-0.154-0.492-0.395-0.633-0.723
			c-0.14-0.328-0.186-0.75-0.138-1.267l0.354-3.684h1.057l-0.342,3.576c-0.04,0.432,0.023,0.757,0.191,0.975
			s0.426,0.327,0.774,0.327c0.288,0,0.551-0.085,0.789-0.255c0.237-0.17,0.436-0.411,0.594-0.724
			c0.158-0.312,0.257-0.682,0.297-1.109l0.264-2.79h1.057l-0.54,5.796h-1.062l0.103-1.092c-0.204,0.392-0.458,0.689-0.763,0.894
			C261.72,190.576,261.384,190.678,261.016,190.678z M261.148,183.262c-0.195,0-0.348-0.066-0.456-0.198
			c-0.107-0.132-0.151-0.292-0.132-0.48c0.013-0.188,0.084-0.353,0.216-0.495c0.133-0.142,0.301-0.213,0.505-0.213
			c0.176,0,0.323,0.062,0.443,0.187s0.174,0.288,0.162,0.492c-0.02,0.216-0.104,0.388-0.252,0.516S261.325,183.262,261.148,183.262z
			 M263.092,183.255c-0.188,0-0.338-0.064-0.45-0.194c-0.111-0.13-0.158-0.291-0.138-0.483c0.016-0.184,0.091-0.348,0.225-0.492
			c0.135-0.144,0.299-0.216,0.495-0.216c0.172,0,0.318,0.061,0.438,0.181c0.12,0.119,0.174,0.285,0.162,0.497
			c-0.017,0.232-0.1,0.408-0.249,0.528C263.425,183.196,263.264,183.255,263.092,183.255z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M265.84,190.57l0.546-5.796h1.056l-0.107,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.252,0.021s0.174,0.039,0.258,0.075l-0.138,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.215-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			s-0.365,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H265.84z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M270.142,190.57l0.768-8.202h1.062l-0.774,8.202H270.142z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M273.37,190.57l0.546-5.796h1.057l-0.546,5.796H273.37z M274.558,183.58c-0.208,0-0.365-0.063-0.471-0.191
			c-0.106-0.128-0.153-0.301-0.142-0.517c0.021-0.204,0.095-0.373,0.226-0.507c0.13-0.134,0.301-0.201,0.513-0.201
			s0.371,0.065,0.477,0.195c0.106,0.13,0.151,0.301,0.136,0.513c-0.021,0.221-0.098,0.394-0.231,0.52S274.762,183.58,274.558,183.58
			z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M278.56,190.72c-0.433,0-0.822-0.124-1.17-0.372c-0.349-0.248-0.612-0.601-0.792-1.057
			c-0.181-0.456-0.242-0.996-0.187-1.62c0.061-0.647,0.224-1.196,0.489-1.646s0.595-0.794,0.987-1.032
			c0.392-0.237,0.806-0.356,1.241-0.356c0.309,0,0.579,0.055,0.813,0.165c0.234,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.463,0.073-0.682,0.219c-0.218,0.146-0.402,0.366-0.555,0.66
			s-0.25,0.663-0.294,1.106c-0.036,0.421-0.011,0.779,0.075,1.077s0.224,0.525,0.414,0.682c0.189,0.155,0.417,0.233,0.681,0.233
			c0.34,0,0.61-0.097,0.811-0.291c0.199-0.193,0.315-0.463,0.348-0.807h1.068c-0.032,0.404-0.149,0.769-0.352,1.095
			s-0.472,0.584-0.81,0.774C279.382,190.625,278.996,190.72,278.56,190.72z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M282.309,190.57l0.769-8.202h1.062l-0.33,3.42c0.213-0.372,0.47-0.657,0.771-0.854
			c0.302-0.198,0.639-0.297,1.011-0.297c0.42,0,0.767,0.104,1.038,0.312c0.272,0.208,0.466,0.502,0.582,0.882s0.15,0.83,0.103,1.351
			l-0.318,3.39h-1.05l0.312-3.39c0.052-0.492-0.017-0.864-0.207-1.116c-0.189-0.252-0.457-0.378-0.801-0.378
			c-0.284,0-0.538,0.08-0.762,0.24c-0.225,0.159-0.409,0.395-0.556,0.704c-0.146,0.311-0.247,0.689-0.303,1.138l-0.264,2.802
			H282.309z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M290.799,190.72c-0.452,0-0.848-0.124-1.188-0.372c-0.341-0.248-0.596-0.6-0.766-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.061-0.632,0.217-1.176,0.472-1.632c0.254-0.456,0.572-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.355,0,0.66,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.614,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.058,0.981c-0.009,0.112-0.021,0.229-0.039,0.351
			c-0.019,0.122-0.041,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.092,0.38-0.249,0.709-0.471,0.987c-0.223,0.277-0.492,0.493-0.811,0.645
			C291.529,190.644,291.179,190.72,290.799,190.72z M289.755,187.384l-0.234-0.168h3.049l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.135-0.494-0.288-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243c-0.217,0.162-0.39,0.38-0.52,0.654C289.88,186.743,289.796,187.047,289.755,187.384z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M296.38,190.72c-0.444,0-0.829-0.077-1.155-0.231s-0.574-0.372-0.744-0.654
			c-0.17-0.281-0.237-0.614-0.201-0.999h0.996c-0.023,0.309,0.067,0.546,0.273,0.711c0.206,0.166,0.482,0.249,0.831,0.249
			c0.352,0,0.637-0.076,0.854-0.23s0.341-0.347,0.369-0.579c0.024-0.204-0.034-0.361-0.174-0.471
			c-0.141-0.11-0.326-0.205-0.559-0.285l-1.031-0.414c-0.4-0.152-0.703-0.36-0.909-0.624s-0.289-0.584-0.249-0.96
			c0.036-0.324,0.15-0.606,0.345-0.846c0.194-0.24,0.454-0.426,0.78-0.556s0.697-0.194,1.113-0.194c0.663,0,1.157,0.153,1.481,0.462
			c0.324,0.308,0.473,0.695,0.445,1.164h-1.016c-0.004-0.229-0.09-0.402-0.258-0.522s-0.397-0.18-0.689-0.18
			c-0.236,0-0.432,0.035-0.585,0.104c-0.154,0.07-0.273,0.159-0.357,0.268c-0.084,0.107-0.136,0.224-0.156,0.348
			c-0.02,0.188,0.037,0.343,0.171,0.465c0.135,0.122,0.312,0.219,0.531,0.291l1.092,0.45c0.385,0.14,0.688,0.331,0.91,0.573
			c0.221,0.242,0.314,0.551,0.279,0.927c-0.029,0.296-0.143,0.576-0.34,0.84s-0.469,0.479-0.813,0.646
			C297.271,190.637,296.859,190.72,296.38,190.72z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M302.656,190.57l0.09-1.032l4.344-5.538l-3.821-0.023l0.107-1.068h5.143l-0.103,1.026l-4.338,5.538
			l3.811,0.023l-0.097,1.074H302.656z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M311.362,190.72c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C312.09,190.644,311.742,190.72,311.362,190.72z M310.318,187.384l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C310.443,186.743,310.358,187.047,310.318,187.384z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M315.219,190.57l0.546-5.796h1.057l-0.547,5.796H315.219z M316.408,183.58
			c-0.208,0-0.365-0.063-0.472-0.191c-0.105-0.128-0.152-0.301-0.141-0.517c0.02-0.204,0.095-0.373,0.225-0.507
			s0.301-0.201,0.514-0.201c0.212,0,0.37,0.065,0.477,0.195s0.151,0.301,0.135,0.513c-0.02,0.221-0.097,0.394-0.23,0.52
			C316.78,183.517,316.611,183.58,316.408,183.58z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M320.41,190.72c-0.433,0-0.822-0.124-1.171-0.372c-0.348-0.248-0.611-0.601-0.792-1.057
			c-0.18-0.456-0.241-0.996-0.186-1.62c0.06-0.647,0.223-1.196,0.489-1.646c0.266-0.45,0.595-0.794,0.986-1.032
			c0.393-0.237,0.807-0.356,1.242-0.356c0.309,0,0.579,0.055,0.813,0.165c0.233,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.464,0.073-0.682,0.219c-0.218,0.146-0.403,0.366-0.555,0.66
			c-0.152,0.294-0.25,0.663-0.294,1.106c-0.036,0.421-0.012,0.779,0.075,1.077c0.086,0.298,0.224,0.525,0.414,0.682
			c0.189,0.155,0.417,0.233,0.681,0.233c0.34,0,0.609-0.097,0.81-0.291c0.2-0.193,0.316-0.463,0.349-0.807h1.067
			c-0.032,0.404-0.148,0.769-0.351,1.095s-0.472,0.584-0.811,0.774C321.232,190.625,320.845,190.72,320.41,190.72z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M324.159,190.57l0.769-8.202h1.062l-0.33,3.42c0.212-0.372,0.469-0.657,0.771-0.854
			c0.302-0.198,0.639-0.297,1.011-0.297c0.42,0,0.766,0.104,1.038,0.312c0.271,0.208,0.466,0.502,0.582,0.882
			s0.149,0.83,0.102,1.351l-0.317,3.39h-1.051l0.312-3.39c0.052-0.492-0.018-0.864-0.207-1.116c-0.19-0.252-0.457-0.378-0.801-0.378
			c-0.284,0-0.538,0.08-0.763,0.24c-0.224,0.159-0.408,0.395-0.555,0.704c-0.146,0.311-0.247,0.689-0.303,1.138l-0.264,2.802
			H324.159z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M330.783,190.57l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906s0.652-0.306,1.032-0.306
			c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354l-0.312,3.39h-1.057
			l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375c-0.44,0-0.807,0.191-1.099,0.576
			c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H330.783z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M339.267,190.72c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C339.996,190.644,339.647,190.72,339.267,190.72z M338.223,187.384l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C338.348,186.743,338.263,187.047,338.223,187.384z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M343.041,190.57l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906s0.652-0.306,1.032-0.306
			c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354l-0.312,3.39h-1.057
			l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375c-0.44,0-0.807,0.191-1.099,0.576
			c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H343.041z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M263.947,204.57l0.084-0.954l3.246-3.876h-2.886l0.102-0.954h4.062l-0.103,0.954l-3.18,3.876h2.813
			l-0.084,0.954H263.947z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M271.453,204.678c-0.38,0-0.711-0.077-0.993-0.231s-0.493-0.395-0.633-0.723
			c-0.141-0.328-0.187-0.75-0.139-1.267l0.354-3.684h1.056l-0.342,3.576c-0.04,0.432,0.024,0.757,0.192,0.975
			s0.426,0.327,0.773,0.327c0.288,0,0.551-0.085,0.789-0.255s0.437-0.411,0.594-0.724c0.158-0.312,0.258-0.682,0.298-1.109
			l0.264-2.79h1.056l-0.54,5.796h-1.062l0.102-1.092c-0.204,0.392-0.458,0.689-0.762,0.894S271.821,204.678,271.453,204.678z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M278.934,204.57l0.72-7.662h1.057l-0.631,6.636h2.958l-0.096,1.026H278.934z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M284.202,204.57l0.546-5.796h1.057l-0.546,5.796H284.202z M285.39,197.58
			c-0.208,0-0.365-0.063-0.471-0.191c-0.106-0.128-0.153-0.301-0.142-0.517c0.021-0.204,0.095-0.373,0.226-0.507
			c0.13-0.134,0.301-0.201,0.513-0.201s0.371,0.065,0.477,0.195c0.106,0.13,0.151,0.301,0.136,0.513
			c-0.021,0.221-0.098,0.394-0.231,0.52S285.594,197.58,285.39,197.58z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M287.16,198.786h1.206l0.954,4.584l1.818-4.584h1.212l-2.557,5.784h-1.176L287.16,198.786z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M294.882,204.72c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.226-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.267,0.519,0.333,0.828c0.065,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.039,0.351
			c-0.018,0.122-0.041,0.247-0.068,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.188,0.551,0.375,0.729c0.188,0.179,0.446,0.268,0.774,0.268c0.28,0,0.523-0.077,0.731-0.231
			c0.208-0.153,0.349-0.381,0.421-0.681h1.079c-0.092,0.38-0.249,0.709-0.471,0.987c-0.222,0.277-0.492,0.493-0.81,0.645
			C295.611,204.644,295.262,204.72,294.882,204.72z M293.839,201.384l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.134-0.494-0.288-0.666c-0.153-0.172-0.389-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243s-0.389,0.38-0.519,0.654C293.964,200.743,293.878,201.047,293.839,201.384z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M298.35,201.528l0.102-1.02h2.982l-0.097,1.02H298.35z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M302.555,204.57l0.72-7.662h1.519l1.638,4.878l2.568-4.878h1.476l-0.726,7.662h-1.062l0.582-6.324
			l0.103,0.013l-2.772,5.411h-0.695l-1.801-5.58l0.108,0.013l-0.6,6.468H302.555z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M313.673,204.678c-0.38,0-0.711-0.077-0.993-0.231s-0.493-0.395-0.633-0.723s-0.187-0.75-0.138-1.267
			l0.354-3.684h1.056l-0.342,3.576c-0.04,0.432,0.024,0.757,0.192,0.975s0.426,0.327,0.773,0.327c0.288,0,0.552-0.085,0.789-0.255
			c0.238-0.17,0.437-0.411,0.595-0.724c0.157-0.312,0.257-0.682,0.297-1.109l0.264-2.79h1.056l-0.54,5.796h-1.062l0.102-1.092
			c-0.203,0.392-0.458,0.689-0.762,0.894S314.042,204.678,313.673,204.678z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M320.303,204.72c-0.443,0-0.829-0.077-1.154-0.231c-0.326-0.154-0.574-0.372-0.744-0.654
			c-0.17-0.281-0.237-0.614-0.201-0.999h0.996c-0.024,0.309,0.066,0.546,0.272,0.711c0.206,0.166,0.483,0.249,0.831,0.249
			c0.353,0,0.638-0.076,0.855-0.23s0.341-0.347,0.369-0.579c0.023-0.204-0.034-0.361-0.174-0.471
			c-0.141-0.11-0.326-0.205-0.559-0.285l-1.032-0.414c-0.399-0.152-0.702-0.36-0.908-0.624s-0.289-0.584-0.249-0.96
			c0.036-0.324,0.15-0.606,0.345-0.846c0.194-0.24,0.454-0.426,0.78-0.556c0.325-0.13,0.696-0.194,1.112-0.194
			c0.664,0,1.158,0.153,1.482,0.462c0.324,0.308,0.472,0.695,0.444,1.164h-1.015c-0.004-0.229-0.09-0.402-0.258-0.522
			s-0.398-0.18-0.69-0.18c-0.235,0-0.431,0.035-0.585,0.104c-0.153,0.07-0.272,0.159-0.356,0.268
			c-0.084,0.107-0.136,0.224-0.156,0.348c-0.02,0.188,0.037,0.343,0.171,0.465s0.312,0.219,0.531,0.291l1.092,0.45
			c0.384,0.14,0.688,0.331,0.909,0.573s0.315,0.551,0.279,0.927c-0.028,0.296-0.142,0.576-0.339,0.84
			c-0.198,0.264-0.47,0.479-0.813,0.646S320.784,204.72,320.303,204.72z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M324.263,204.57l0.546-5.796h1.057l-0.547,5.796H324.263z M325.452,197.58
			c-0.208,0-0.365-0.063-0.472-0.191c-0.105-0.128-0.152-0.301-0.141-0.517c0.02-0.204,0.095-0.373,0.225-0.507
			s0.301-0.201,0.514-0.201c0.212,0,0.37,0.065,0.477,0.195s0.151,0.301,0.135,0.513c-0.02,0.221-0.097,0.394-0.23,0.52
			C325.824,197.517,325.655,197.58,325.452,197.58z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M331.187,204.57l-1.656-2.946l-0.689,0.582l-0.222,2.364h-1.057l0.769-8.202h1.062l-0.432,4.596
			l2.496-2.178h1.452l-2.557,2.154l2.13,3.63H331.187z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M266.794,224.57v-1.3c0-0.363,0.05-0.678,0.15-0.942c0.1-0.266,0.262-0.493,0.484-0.683
			c0.224-0.19,0.519-0.354,0.886-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.56-0.354c0.117-0.137,0.176-0.302,0.176-0.495
			c0-0.177-0.07-0.334-0.211-0.473c-0.14-0.139-0.347-0.207-0.619-0.207c-0.254,0-0.472,0.076-0.655,0.229s-0.288,0.372-0.315,0.655
			h-1.77c-0.004-0.526,0.119-0.958,0.367-1.293s0.579-0.581,0.992-0.739c0.414-0.159,0.863-0.238,1.351-0.238
			c0.474,0,0.904,0.087,1.292,0.261c0.389,0.173,0.698,0.417,0.931,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.186,0.915s-0.321,0.504-0.595,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.326,0.144-0.397,0.212c-0.072,0.068-0.107,0.158-0.107,0.268v0.075h3.77v1.46H266.794z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M273.114,224.57v-1.3c0-0.363,0.05-0.678,0.149-0.942c0.101-0.266,0.262-0.493,0.485-0.683
			c0.224-0.19,0.519-0.354,0.885-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.561-0.354c0.116-0.137,0.175-0.302,0.175-0.495
			c0-0.177-0.07-0.334-0.21-0.473s-0.347-0.207-0.62-0.207c-0.253,0-0.472,0.076-0.655,0.229c-0.183,0.153-0.288,0.372-0.314,0.655
			h-1.771c-0.003-0.526,0.119-0.958,0.368-1.293c0.248-0.335,0.579-0.581,0.992-0.739c0.413-0.159,0.863-0.238,1.35-0.238
			c0.474,0,0.904,0.087,1.293,0.261c0.388,0.173,0.698,0.417,0.93,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.185,0.915c-0.124,0.267-0.322,0.504-0.596,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.325,0.144-0.397,0.212c-0.071,0.068-0.107,0.158-0.107,0.268v0.075h3.771v1.46H273.114z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M279.048,223.64c0-0.297,0.099-0.544,0.298-0.74c0.198-0.196,0.455-0.295,0.772-0.295
			c0.316,0,0.578,0.099,0.784,0.295c0.207,0.196,0.311,0.443,0.311,0.74s-0.104,0.541-0.311,0.732
			c-0.206,0.191-0.468,0.287-0.784,0.287c-0.317,0-0.574-0.097-0.772-0.29C279.147,224.176,279.048,223.933,279.048,223.64z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M284.709,224.73c-0.373,0-0.735-0.05-1.085-0.147c-0.35-0.099-0.664-0.245-0.942-0.44
			c-0.278-0.194-0.499-0.438-0.663-0.728c-0.163-0.29-0.244-0.626-0.244-1.01h1.704c0.021,0.163,0.08,0.311,0.181,0.442
			c0.1,0.132,0.237,0.236,0.412,0.312c0.175,0.077,0.388,0.115,0.638,0.115c0.34,0,0.611-0.073,0.815-0.22
			c0.203-0.147,0.305-0.334,0.305-0.561c0-0.156-0.04-0.285-0.12-0.385s-0.196-0.174-0.348-0.223
			c-0.151-0.048-0.337-0.072-0.558-0.072h-1.02v-1.255h0.995c0.267,0,0.475-0.066,0.625-0.2c0.149-0.134,0.225-0.309,0.225-0.525
			c0-0.196-0.071-0.358-0.215-0.487c-0.144-0.128-0.379-0.192-0.705-0.192c-0.283,0-0.537,0.059-0.76,0.175
			c-0.224,0.117-0.355,0.304-0.396,0.561h-1.7c0.04-0.5,0.182-0.911,0.426-1.232c0.243-0.322,0.566-0.561,0.97-0.716
			c0.403-0.154,0.863-0.232,1.38-0.232c0.526,0,0.994,0.08,1.402,0.24s0.73,0.384,0.965,0.67c0.235,0.287,0.353,0.622,0.353,1.005
			c0,0.33-0.086,0.622-0.257,0.875c-0.172,0.254-0.432,0.438-0.778,0.556c0.387,0.153,0.691,0.364,0.913,0.632
			c0.222,0.269,0.332,0.591,0.332,0.968c0,0.407-0.12,0.767-0.362,1.08c-0.241,0.313-0.576,0.558-1.005,0.732
			S285.268,224.73,284.709,224.73z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M291.339,224.73c-0.573,0-1.083-0.127-1.527-0.38c-0.445-0.254-0.794-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.251-0.519,0.6-0.903,1.045-1.155
			c0.444-0.252,0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158
			C292.409,224.603,291.905,224.73,291.339,224.73z M291.339,223.275c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532
			s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543
			s0.115,1.188,0.348,1.532C290.618,223.102,290.935,223.275,291.339,223.275z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M295.243,222.145v-1.4h4.695v1.4H295.243z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M303.874,224.73c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C304.944,224.603,304.44,224.73,303.874,224.73z
			 M303.874,223.275c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C303.153,223.102,303.47,223.275,303.874,223.275z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M310.609,224.73c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C311.679,224.603,311.175,224.73,310.609,224.73z
			 M310.609,223.275c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C309.888,223.102,310.206,223.275,310.609,223.275z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M314.139,223.64c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S314.139,223.933,314.139,223.64z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M319.799,224.73c-0.373,0-0.734-0.05-1.085-0.147c-0.35-0.099-0.664-0.245-0.942-0.44
			c-0.278-0.194-0.499-0.438-0.662-0.728c-0.164-0.29-0.245-0.626-0.245-1.01h1.705c0.02,0.163,0.08,0.311,0.18,0.442
			s0.237,0.236,0.412,0.312c0.176,0.077,0.388,0.115,0.638,0.115c0.34,0,0.612-0.073,0.815-0.22
			c0.203-0.147,0.305-0.334,0.305-0.561c0-0.156-0.04-0.285-0.12-0.385s-0.195-0.174-0.348-0.223
			c-0.151-0.048-0.337-0.072-0.558-0.072h-1.02v-1.255h0.995c0.267,0,0.475-0.066,0.625-0.2c0.149-0.134,0.225-0.309,0.225-0.525
			c0-0.196-0.071-0.358-0.215-0.487c-0.144-0.128-0.378-0.192-0.705-0.192c-0.283,0-0.536,0.059-0.76,0.175
			c-0.224,0.117-0.355,0.304-0.396,0.561h-1.699c0.04-0.5,0.182-0.911,0.425-1.232c0.243-0.322,0.566-0.561,0.97-0.716
			c0.403-0.154,0.863-0.232,1.38-0.232c0.527,0,0.994,0.08,1.402,0.24c0.409,0.16,0.73,0.384,0.966,0.67
			c0.234,0.287,0.353,0.622,0.353,1.005c0,0.33-0.086,0.622-0.258,0.875c-0.172,0.254-0.431,0.438-0.777,0.556
			c0.387,0.153,0.69,0.364,0.912,0.632c0.222,0.269,0.333,0.591,0.333,0.968c0,0.407-0.121,0.767-0.363,1.08
			c-0.241,0.313-0.576,0.558-1.005,0.732C320.854,224.642,320.36,224.73,319.799,224.73z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M326.429,224.73c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C327.5,224.603,326.996,224.73,326.429,224.73z
			 M326.429,223.275c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C325.709,223.102,326.026,223.275,326.429,223.275z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M81.271,90.888h3.36c1.056,0,1.98,0.212,2.775,0.636s1.413,1.025,1.856,1.804
			c0.442,0.779,0.664,1.699,0.664,2.76c0,1.051-0.222,1.964-0.664,2.74c-0.443,0.776-1.062,1.377-1.856,1.804
			c-0.795,0.427-1.72,0.64-2.775,0.64h-3.36V90.888z M83.159,92.6v6.976h1.473c0.672,0,1.252-0.152,1.739-0.456
			c0.488-0.304,0.864-0.719,1.128-1.244c0.265-0.525,0.396-1.121,0.396-1.788c0-0.677-0.132-1.277-0.396-1.8
			c-0.264-0.523-0.64-0.935-1.128-1.236c-0.487-0.301-1.067-0.452-1.739-0.452H83.159z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M91.023,100.84v-1.888c0.427,0.245,0.844,0.424,1.252,0.536c0.408,0.112,0.785,0.168,1.132,0.168
			c0.459,0,0.792-0.133,1-0.4c0.208-0.267,0.312-0.672,0.312-1.216v-7.152h1.903v7.152c0,0.837-0.131,1.509-0.392,2.016
			c-0.262,0.506-0.621,0.873-1.08,1.1s-0.986,0.34-1.584,0.34c-0.47,0-0.917-0.062-1.344-0.188
			C91.796,101.183,91.396,101.027,91.023,100.84z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M107.014,101.496c-0.72,0-1.383-0.125-1.987-0.375c-0.605-0.251-1.097-0.632-1.473-1.144
			c-0.376-0.512-0.577-1.163-0.604-1.952h1.855c0,0.4,0.099,0.728,0.296,0.984c0.197,0.256,0.463,0.447,0.796,0.572
			c0.334,0.125,0.7,0.188,1.101,0.188c0.384,0,0.727-0.051,1.028-0.152c0.301-0.102,0.538-0.247,0.712-0.436
			c0.173-0.189,0.26-0.415,0.26-0.676c0-0.347-0.105-0.619-0.316-0.816s-0.548-0.378-1.012-0.544l-2.048-0.816
			c-0.8-0.315-1.413-0.68-1.84-1.096s-0.641-0.989-0.641-1.72c0-0.843,0.334-1.525,1-2.048c0.667-0.522,1.598-0.784,2.792-0.784
			c1.163,0,2.062,0.271,2.696,0.812c0.635,0.541,0.995,1.294,1.08,2.26h-1.856c-0.069-0.411-0.256-0.74-0.56-0.988
			s-0.758-0.372-1.36-0.372c-0.533,0-0.954,0.111-1.264,0.332s-0.464,0.5-0.464,0.836c0,0.288,0.097,0.516,0.292,0.684
			c0.194,0.168,0.497,0.329,0.908,0.484l2.271,0.88c0.769,0.299,1.357,0.675,1.769,1.128c0.41,0.454,0.615,1.021,0.615,1.704
			c0,0.624-0.157,1.164-0.472,1.62s-0.773,0.809-1.376,1.06C108.612,101.371,107.879,101.496,107.014,101.496z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M112.415,93.512h2.145l2.063,6.112h-0.336l1.776-6.112h1.92l-2.977,9.168
			c-0.304,0.917-0.679,1.581-1.124,1.992c-0.445,0.41-0.987,0.615-1.628,0.615c-0.368,0-0.727-0.041-1.076-0.124
			c-0.349-0.082-0.705-0.212-1.067-0.388v-1.664c0.293,0.182,0.577,0.314,0.852,0.4c0.274,0.085,0.551,0.128,0.828,0.128
			c0.251,0,0.471-0.068,0.66-0.204s0.354-0.325,0.492-0.568c0.139-0.242,0.256-0.523,0.352-0.844l0.24-0.752L112.415,93.512z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M121.654,101.272V90.296h1.889v10.976H121.654z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M125.23,93.512h2.177l1.855,6.144h-0.144l1.855-6.144h2.176l-2.896,7.76h-2.128L125.23,93.512z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M135.934,92.088c-0.368,0-0.655-0.097-0.86-0.292c-0.205-0.194-0.308-0.465-0.308-0.812
			c0-0.336,0.103-0.604,0.308-0.804c0.205-0.2,0.492-0.3,0.86-0.3c0.362,0,0.648,0.1,0.856,0.3c0.207,0.2,0.312,0.468,0.312,0.804
			c0,0.347-0.104,0.618-0.312,0.812C136.583,91.991,136.296,92.088,135.934,92.088z M134.99,101.272V93.48h1.889v7.792H134.99z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M142.974,101.496c-0.661,0-1.267-0.17-1.816-0.512c-0.549-0.341-0.989-0.82-1.319-1.436
			c-0.331-0.616-0.496-1.337-0.496-2.164c0-0.853,0.164-1.586,0.492-2.2c0.327-0.613,0.767-1.083,1.315-1.408
			c0.55-0.325,1.157-0.488,1.824-0.488c0.619,0,1.153,0.124,1.604,0.372c0.45,0.248,0.812,0.584,1.088,1.008
			c0.274,0.424,0.46,0.903,0.556,1.436s0.102,1.083,0.017,1.648h-5.393v-1.024h4.056l-0.367,0.24c0.016-0.25,0.004-0.504-0.036-0.76
			s-0.116-0.491-0.229-0.704c-0.111-0.213-0.271-0.385-0.476-0.516c-0.206-0.13-0.468-0.196-0.788-0.196
			c-0.384,0-0.71,0.096-0.977,0.288s-0.469,0.453-0.607,0.784c-0.139,0.331-0.208,0.704-0.208,1.12v0.752
			c0,0.438,0.065,0.824,0.195,1.16c0.131,0.336,0.325,0.6,0.584,0.792s0.586,0.288,0.98,0.288c0.368,0,0.678-0.085,0.928-0.256
			c0.251-0.171,0.411-0.427,0.48-0.768h1.904c-0.08,0.501-0.269,0.942-0.564,1.324s-0.679,0.68-1.147,0.896
			C144.105,101.388,143.572,101.496,142.974,101.496z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M151.965,102.552l4.112-13.2h1.937l-4.145,13.2H151.965z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M162.965,100.84v-1.888c0.427,0.245,0.844,0.424,1.252,0.536c0.408,0.112,0.785,0.168,1.132,0.168
			c0.459,0,0.792-0.133,1-0.4c0.208-0.267,0.312-0.672,0.312-1.216v-7.152h1.904v7.152c0,0.837-0.131,1.509-0.393,2.016
			c-0.262,0.506-0.621,0.873-1.08,1.1s-0.986,0.34-1.584,0.34c-0.469,0-0.917-0.062-1.344-0.188
			C163.739,101.183,163.338,101.027,162.965,100.84z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M174.35,101.496c-0.709,0-1.351-0.151-1.924-0.456s-1.028-0.759-1.364-1.364
			c-0.336-0.605-0.504-1.364-0.504-2.276c0-0.928,0.168-1.696,0.504-2.304c0.336-0.608,0.791-1.062,1.364-1.36
			c0.573-0.298,1.215-0.448,1.924-0.448c0.698,0,1.332,0.149,1.9,0.448c0.567,0.299,1.02,0.752,1.355,1.36
			c0.336,0.608,0.504,1.376,0.504,2.304c0,0.912-0.168,1.671-0.504,2.276c-0.336,0.605-0.788,1.06-1.355,1.364
			C175.682,101.344,175.048,101.496,174.35,101.496z M174.287,99.848c0.373,0,0.705-0.092,0.995-0.276
			c0.291-0.184,0.521-0.457,0.688-0.82s0.252-0.813,0.252-1.352c0-0.821-0.161-1.44-0.484-1.856
			c-0.322-0.416-0.764-0.624-1.323-0.624c-0.379,0-0.715,0.092-1.009,0.276c-0.293,0.184-0.523,0.46-0.691,0.828
			s-0.252,0.827-0.252,1.376c0,0.811,0.164,1.421,0.492,1.832C173.282,99.643,173.726,99.848,174.287,99.848z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M182.75,101.496c-0.666,0-1.223-0.115-1.668-0.348c-0.445-0.232-0.779-0.537-1.004-0.916
			c-0.224-0.378-0.336-0.789-0.336-1.232c0-0.491,0.129-0.903,0.388-1.236c0.259-0.333,0.626-0.584,1.101-0.752
			c0.475-0.168,1.034-0.252,1.68-0.252c0.422,0,0.82,0.032,1.196,0.096s0.75,0.149,1.124,0.256v1.104
			c-0.294-0.096-0.613-0.17-0.96-0.224s-0.672-0.08-0.977-0.08c-0.447,0-0.815,0.083-1.104,0.248
			c-0.288,0.165-0.432,0.445-0.432,0.84c0,0.288,0.117,0.536,0.352,0.744s0.608,0.312,1.12,0.312c0.389,0,0.72-0.085,0.992-0.256
			c0.271-0.17,0.479-0.384,0.62-0.64c0.141-0.256,0.212-0.512,0.212-0.768h0.447c0,0.56-0.104,1.076-0.312,1.548
			s-0.517,0.85-0.924,1.132C183.858,101.355,183.352,101.496,182.75,101.496z M185.294,101.272l-0.239-1.888v-3.12
			c0-0.544-0.111-0.947-0.332-1.208c-0.222-0.261-0.597-0.392-1.124-0.392c-0.305,0-0.568,0.045-0.792,0.136
			c-0.225,0.091-0.396,0.22-0.517,0.388s-0.18,0.372-0.18,0.612h-2.064c0-0.586,0.142-1.065,0.425-1.436
			c0.282-0.371,0.688-0.643,1.22-0.816c0.53-0.173,1.166-0.26,1.908-0.26c0.672,0,1.258,0.108,1.76,0.324
			c0.501,0.216,0.891,0.548,1.168,0.996c0.277,0.448,0.416,1.021,0.416,1.72v4.944H185.294z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M188.414,93.512h2.176l1.855,6.144h-0.144l1.855-6.144h2.177l-2.896,7.76h-2.128L188.414,93.512z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M200.414,101.496c-0.666,0-1.223-0.115-1.668-0.348c-0.445-0.232-0.779-0.537-1.004-0.916
			c-0.224-0.378-0.336-0.789-0.336-1.232c0-0.491,0.129-0.903,0.388-1.236c0.259-0.333,0.626-0.584,1.101-0.752
			c0.475-0.168,1.034-0.252,1.68-0.252c0.422,0,0.82,0.032,1.196,0.096s0.75,0.149,1.124,0.256v1.104
			c-0.294-0.096-0.613-0.17-0.96-0.224s-0.672-0.08-0.977-0.08c-0.447,0-0.815,0.083-1.104,0.248
			c-0.288,0.165-0.432,0.445-0.432,0.84c0,0.288,0.117,0.536,0.352,0.744s0.608,0.312,1.12,0.312c0.389,0,0.72-0.085,0.992-0.256
			c0.271-0.17,0.479-0.384,0.62-0.64c0.141-0.256,0.212-0.512,0.212-0.768h0.447c0,0.56-0.104,1.076-0.312,1.548
			s-0.517,0.85-0.924,1.132C201.522,101.355,201.016,101.496,200.414,101.496z M202.958,101.272l-0.239-1.888v-3.12
			c0-0.544-0.111-0.947-0.332-1.208c-0.222-0.261-0.597-0.392-1.124-0.392c-0.305,0-0.568,0.045-0.792,0.136
			c-0.225,0.091-0.396,0.22-0.517,0.388s-0.18,0.372-0.18,0.612h-2.064c0-0.586,0.142-1.065,0.425-1.436
			c0.282-0.371,0.688-0.643,1.22-0.816c0.53-0.173,1.166-0.26,1.908-0.26c0.672,0,1.258,0.108,1.76,0.324
			c0.501,0.216,0.891,0.548,1.168,0.996c0.277,0.448,0.416,1.021,0.416,1.72v4.944H202.958z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M112.93,109.61h2.137c0.704,0,1.3,0.157,1.788,0.471c0.487,0.314,0.849,0.759,1.083,1.332
			c0.233,0.574,0.314,1.249,0.242,2.025c-0.071,0.776-0.279,1.451-0.624,2.025c-0.344,0.573-0.791,1.019-1.341,1.335
			c-0.55,0.315-1.173,0.474-1.869,0.474h-2.136L112.93,109.61z M113.884,110.666l-0.516,5.557h1.074c0.483,0,0.911-0.121,1.28-0.363
			c0.37-0.242,0.67-0.572,0.897-0.99s0.368-0.895,0.42-1.431s0.003-1.013-0.146-1.431c-0.15-0.418-0.39-0.746-0.718-0.984
			s-0.733-0.357-1.218-0.357H113.884z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M119.567,117.272l0.546-5.796h1.056l-0.546,5.796H119.567z M120.755,110.282
			c-0.208,0-0.364-0.063-0.471-0.191s-0.153-0.301-0.141-0.517c0.02-0.204,0.095-0.373,0.225-0.507s0.301-0.201,0.513-0.201
			s0.371,0.065,0.478,0.195c0.105,0.13,0.15,0.301,0.135,0.513c-0.02,0.221-0.097,0.394-0.23,0.52
			C121.128,110.219,120.959,110.282,120.755,110.282z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M124.667,117.422c-0.444,0-0.829-0.077-1.155-0.231c-0.325-0.154-0.573-0.372-0.743-0.654
			c-0.171-0.281-0.237-0.614-0.201-0.999h0.996c-0.024,0.309,0.066,0.546,0.272,0.711c0.206,0.166,0.483,0.249,0.831,0.249
			c0.353,0,0.637-0.076,0.855-0.23c0.218-0.154,0.341-0.347,0.369-0.579c0.023-0.204-0.034-0.361-0.175-0.471
			c-0.14-0.11-0.326-0.205-0.558-0.285l-1.032-0.414c-0.399-0.152-0.703-0.36-0.909-0.624s-0.289-0.584-0.249-0.96
			c0.036-0.324,0.151-0.606,0.346-0.846c0.193-0.24,0.454-0.426,0.779-0.556c0.326-0.13,0.697-0.194,1.113-0.194
			c0.664,0,1.158,0.153,1.482,0.462c0.323,0.308,0.472,0.695,0.443,1.164h-1.014c-0.004-0.229-0.09-0.402-0.258-0.522
			s-0.398-0.18-0.69-0.18c-0.235,0-0.431,0.035-0.585,0.104c-0.154,0.07-0.272,0.159-0.356,0.268
			c-0.084,0.107-0.137,0.224-0.156,0.348c-0.021,0.188,0.037,0.343,0.171,0.465s0.311,0.219,0.531,0.291l1.092,0.45
			c0.384,0.14,0.687,0.331,0.909,0.573c0.222,0.242,0.314,0.551,0.278,0.927c-0.027,0.296-0.141,0.576-0.339,0.84
			c-0.197,0.264-0.469,0.479-0.812,0.646C125.558,117.339,125.147,117.422,124.667,117.422z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M130.438,117.422c-0.432,0-0.821-0.124-1.17-0.372c-0.348-0.248-0.611-0.601-0.792-1.057
			c-0.18-0.456-0.242-0.996-0.186-1.62c0.06-0.647,0.223-1.196,0.488-1.646c0.267-0.45,0.596-0.794,0.987-1.032
			c0.393-0.237,0.806-0.356,1.242-0.356c0.308,0,0.579,0.055,0.813,0.165c0.233,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.152,0.727l-1.067,0.065c-0.053-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.654-0.272c-0.235,0-0.463,0.073-0.681,0.219c-0.218,0.146-0.403,0.366-0.555,0.66
			c-0.152,0.294-0.25,0.663-0.294,1.106c-0.036,0.421-0.012,0.779,0.074,1.077s0.225,0.525,0.414,0.682
			c0.19,0.155,0.417,0.233,0.682,0.233c0.34,0,0.609-0.097,0.81-0.291c0.2-0.193,0.316-0.463,0.348-0.807h1.068
			c-0.032,0.404-0.149,0.769-0.351,1.095c-0.202,0.326-0.473,0.584-0.811,0.774C131.261,117.327,130.875,117.422,130.438,117.422z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M136.127,117.422c-0.472,0-0.886-0.114-1.242-0.343c-0.355-0.228-0.62-0.566-0.792-1.017
			s-0.226-1.009-0.162-1.677c0.064-0.676,0.227-1.24,0.486-1.692s0.589-0.791,0.987-1.017c0.397-0.227,0.831-0.339,1.299-0.339
			s0.878,0.114,1.229,0.345c0.353,0.229,0.616,0.57,0.792,1.02c0.177,0.45,0.232,1.012,0.168,1.684
			c-0.067,0.668-0.231,1.227-0.491,1.677c-0.261,0.45-0.587,0.789-0.979,1.017C137.031,117.308,136.598,117.422,136.127,117.422z
			 M136.18,116.402c0.444,0,0.812-0.175,1.104-0.524c0.292-0.351,0.472-0.848,0.54-1.491c0.063-0.652-0.01-1.154-0.22-1.506
			c-0.21-0.353-0.534-0.528-0.975-0.528s-0.804,0.175-1.092,0.525c-0.288,0.35-0.464,0.853-0.528,1.509
			c-0.044,0.424-0.026,0.787,0.051,1.089c0.078,0.302,0.211,0.532,0.399,0.689C135.648,116.323,135.888,116.402,136.18,116.402z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M139.87,114.23l0.103-1.02h2.981l-0.096,1.02H139.87z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M144.046,117.272l0.721-7.662h2.334c0.512,0,0.937,0.096,1.274,0.288s0.585,0.467,0.741,0.825
			c0.156,0.357,0.208,0.789,0.156,1.293c-0.068,0.74-0.332,1.322-0.792,1.746s-1.072,0.636-1.836,0.636h-1.267l-0.27,2.874H144.046z
			 M145.475,113.378h1.266c0.433,0,0.772-0.126,1.021-0.379c0.248-0.252,0.388-0.579,0.42-0.983c0.04-0.416-0.039-0.753-0.237-1.011
			c-0.198-0.259-0.515-0.388-0.951-0.388h-1.266L145.475,113.378z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M152.063,117.422c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C152.792,117.346,152.443,117.422,152.063,117.422z M151.019,114.086l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C151.144,113.445,151.059,113.75,151.019,114.086z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M155.837,117.272l0.546-5.796h1.057l-0.108,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.253,0.021c0.088,0.014,0.174,0.039,0.258,0.075l-0.139,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.214-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			c-0.203,0.172-0.364,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H155.837z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M160.138,117.272l0.769-8.202h1.062l-0.773,8.202H160.138z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M165.148,117.422c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C165.877,117.346,165.528,117.422,165.148,117.422z M164.104,114.086l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C164.229,113.445,164.144,113.75,164.104,114.086z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M168.921,117.272l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906
			s0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375
			c-0.44,0-0.807,0.191-1.099,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H168.921z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M123.402,137.432c-0.439,0-0.811-0.074-1.112-0.223s-0.529-0.35-0.683-0.603s-0.229-0.533-0.229-0.84
			c0-0.29,0.08-0.541,0.24-0.753c0.159-0.211,0.397-0.375,0.712-0.492c0.315-0.116,0.702-0.175,1.163-0.175
			c0.279,0,0.527,0.015,0.742,0.045s0.436,0.066,0.662,0.11v0.899c-0.166-0.036-0.339-0.064-0.518-0.085
			c-0.178-0.02-0.353-0.03-0.522-0.03c-0.236,0-0.424,0.038-0.562,0.113c-0.138,0.074-0.207,0.197-0.207,0.367
			c0,0.123,0.062,0.23,0.185,0.322s0.318,0.138,0.585,0.138c0.227,0,0.405-0.045,0.535-0.135s0.222-0.203,0.275-0.34
			c0.053-0.137,0.08-0.272,0.08-0.405h0.375c0,0.38-0.061,0.729-0.181,1.045s-0.307,0.569-0.56,0.758
			C124.129,137.337,123.802,137.432,123.402,137.432z M124.953,137.272l-0.205-1.315v-1.845c0-0.246-0.045-0.438-0.136-0.575
			c-0.09-0.137-0.281-0.205-0.574-0.205c-0.174,0-0.314,0.026-0.423,0.078s-0.188,0.12-0.24,0.205s-0.077,0.177-0.077,0.277h-1.73
			c0-0.387,0.092-0.706,0.275-0.958c0.184-0.251,0.458-0.438,0.825-0.562c0.366-0.123,0.823-0.185,1.37-0.185
			c0.477,0,0.887,0.073,1.232,0.22c0.345,0.146,0.61,0.367,0.797,0.66s0.28,0.662,0.28,1.105v3.1H124.953z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M127.932,137.272v-6.9h1.6v5.615l-0.6,1.285H127.932z M130.717,137.432c-0.283,0-0.548-0.086-0.793-0.258
			s-0.441-0.437-0.59-0.795s-0.223-0.815-0.223-1.372h0.42c0,0.173,0.046,0.333,0.138,0.479s0.209,0.265,0.351,0.353
			c0.142,0.089,0.285,0.133,0.433,0.133c0.203,0,0.377-0.052,0.522-0.155c0.145-0.104,0.257-0.245,0.337-0.425s0.12-0.384,0.12-0.61
			c0-0.217-0.039-0.411-0.117-0.585c-0.079-0.173-0.191-0.31-0.338-0.41c-0.146-0.1-0.325-0.149-0.535-0.149
			c-0.163,0-0.313,0.038-0.452,0.115c-0.139,0.076-0.249,0.184-0.333,0.322c-0.083,0.138-0.125,0.301-0.125,0.487h-0.42
			c0-0.53,0.067-0.971,0.203-1.322c0.135-0.352,0.331-0.615,0.59-0.79c0.258-0.175,0.572-0.263,0.942-0.263
			c0.413,0,0.784,0.108,1.112,0.325s0.589,0.519,0.782,0.907s0.29,0.843,0.29,1.362c0,0.527-0.103,0.989-0.307,1.388
			c-0.205,0.398-0.482,0.708-0.83,0.93C131.546,137.321,131.154,137.432,130.717,137.432z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M136.707,137.272v-1.3c0-0.363,0.05-0.678,0.15-0.942c0.1-0.266,0.262-0.493,0.484-0.683
			c0.224-0.19,0.519-0.354,0.886-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.56-0.354c0.117-0.137,0.176-0.302,0.176-0.495
			c0-0.177-0.07-0.334-0.211-0.473c-0.14-0.139-0.347-0.207-0.619-0.207c-0.254,0-0.472,0.076-0.655,0.229s-0.288,0.372-0.315,0.655
			h-1.77c-0.004-0.526,0.119-0.958,0.367-1.293s0.579-0.581,0.992-0.739c0.414-0.159,0.863-0.238,1.351-0.238
			c0.474,0,0.904,0.087,1.292,0.261c0.389,0.173,0.698,0.417,0.931,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.186,0.915s-0.321,0.504-0.595,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.326,0.144-0.397,0.212c-0.072,0.068-0.107,0.158-0.107,0.268v0.075h3.77v1.46H136.707z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M143.027,137.272v-1.3c0-0.363,0.05-0.678,0.149-0.942c0.101-0.266,0.262-0.493,0.486-0.683
			c0.223-0.19,0.518-0.354,0.885-0.49l1.424-0.53c0.258-0.1,0.443-0.218,0.561-0.354s0.176-0.302,0.176-0.495
			c0-0.177-0.07-0.334-0.211-0.473s-0.346-0.207-0.619-0.207c-0.254,0-0.473,0.076-0.656,0.229s-0.287,0.372-0.314,0.655h-1.77
			c-0.004-0.526,0.119-0.958,0.367-1.293s0.578-0.581,0.992-0.739c0.414-0.159,0.863-0.238,1.35-0.238
			c0.475,0,0.904,0.087,1.293,0.261c0.389,0.173,0.699,0.417,0.93,0.732c0.232,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.061,0.648-0.186,0.915c-0.123,0.267-0.32,0.504-0.594,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.416,0.535
			c-0.193,0.073-0.326,0.144-0.396,0.212c-0.072,0.068-0.107,0.158-0.107,0.268v0.075h3.77v1.46H143.027z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M148.962,136.342c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S148.962,136.635,148.962,136.342z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M154.702,137.432c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C155.772,137.305,155.268,137.432,154.702,137.432z
			 M154.702,135.977c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C153.981,135.804,154.298,135.977,154.702,135.977z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M161.437,137.432c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C162.507,137.305,162.004,137.432,161.437,137.432z
			 M161.437,135.977c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C160.716,135.804,161.034,135.977,161.437,135.977z"
                  />
                </g>
                <g>
                  <path
                    fill="#1E1B39"
                    d="M698.014,99.644V89.26h3.84c1.243,0,2.196,0.291,2.86,0.872c0.664,0.582,0.996,1.352,0.996,2.312
			c0,0.944-0.332,1.706-0.996,2.284s-1.617,0.868-2.86,0.868h-1.952v4.048H698.014z M699.902,94.332h1.761
			c0.692,0,1.218-0.159,1.575-0.476s0.536-0.729,0.536-1.236c0-0.517-0.162-0.935-0.488-1.252c-0.325-0.317-0.803-0.476-1.432-0.476
			h-1.952V94.332z M703.63,99.644l-2.752-4.864h2.016l3.2,4.864H703.63z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M710.711,99.868c-0.667,0-1.224-0.115-1.668-0.348c-0.446-0.232-0.78-0.537-1.005-0.916
			c-0.224-0.378-0.336-0.789-0.336-1.232c0-0.491,0.13-0.903,0.389-1.236c0.259-0.333,0.625-0.584,1.1-0.752
			c0.475-0.168,1.035-0.252,1.68-0.252c0.422,0,0.82,0.032,1.196,0.096s0.751,0.149,1.124,0.256v1.104
			c-0.293-0.096-0.613-0.17-0.96-0.224s-0.672-0.08-0.976-0.08c-0.448,0-0.816,0.083-1.104,0.248
			c-0.288,0.165-0.432,0.445-0.432,0.84c0,0.288,0.117,0.536,0.352,0.744s0.608,0.312,1.12,0.312c0.39,0,0.72-0.085,0.992-0.256
			c0.271-0.17,0.479-0.384,0.62-0.64c0.141-0.256,0.212-0.512,0.212-0.768h0.448c0,0.56-0.104,1.076-0.312,1.548
			s-0.516,0.85-0.924,1.132C711.818,99.727,711.313,99.868,710.711,99.868z M713.255,99.644l-0.24-1.888v-3.12
			c0-0.544-0.111-0.947-0.332-1.208c-0.222-0.261-0.596-0.392-1.124-0.392c-0.304,0-0.568,0.045-0.792,0.136
			c-0.224,0.091-0.396,0.22-0.516,0.388c-0.12,0.168-0.181,0.372-0.181,0.612h-2.063c0-0.586,0.141-1.065,0.424-1.436
			c0.282-0.371,0.689-0.643,1.22-0.816s1.167-0.26,1.908-0.26c0.672,0,1.259,0.108,1.76,0.324c0.501,0.216,0.891,0.548,1.168,0.996
			c0.277,0.448,0.416,1.021,0.416,1.72v4.944H713.255z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M717.51,91.852h1.888v4.768c0,0.507,0.125,0.887,0.376,1.14c0.251,0.253,0.595,0.38,1.032,0.38
			c0.373,0,0.713-0.097,1.02-0.292s0.552-0.483,0.736-0.864c0.184-0.381,0.275-0.854,0.275-1.42h0.513
			c0,0.949-0.123,1.736-0.368,2.36s-0.584,1.091-1.016,1.4c-0.433,0.309-0.932,0.463-1.496,0.463c-0.555,0-1.057-0.099-1.504-0.295
			c-0.448-0.197-0.803-0.515-1.064-0.952c-0.262-0.437-0.392-1.013-0.392-1.728V91.852z M722.838,91.852h1.889v7.792h-1.889V91.852z
			"
                  />
                  <path
                    fill="#1E1B39"
                    d="M727.702,99.644v-7.76h1.648l0.208,1.328c0.256-0.517,0.595-0.905,1.016-1.164
			c0.422-0.258,0.904-0.388,1.448-0.388c0.641,0,1.175,0.143,1.604,0.428c0.429,0.286,0.752,0.687,0.968,1.204
			c0.216,0.518,0.324,1.12,0.324,1.808v4.544h-1.889V95.1c0-0.576-0.139-1.011-0.416-1.304c-0.277-0.293-0.64-0.44-1.088-0.44
			c-0.379,0-0.713,0.104-1.004,0.312s-0.519,0.521-0.684,0.94c-0.166,0.419-0.248,0.94-0.248,1.564v3.472H727.702z M738.247,99.644
			V95.1c0-0.576-0.14-1.011-0.416-1.304c-0.277-0.293-0.641-0.44-1.089-0.44c-0.565,0-1.011,0.229-1.336,0.688
			c-0.325,0.459-0.487,1.152-0.487,2.08h-0.784c0-0.816,0.076-1.508,0.228-2.076c0.152-0.568,0.368-1.026,0.648-1.376
			c0.28-0.349,0.609-0.605,0.988-0.768c0.378-0.163,0.797-0.244,1.256-0.244c0.635,0,1.166,0.143,1.596,0.428
			c0.43,0.286,0.753,0.687,0.972,1.204c0.219,0.518,0.328,1.12,0.328,1.808v4.544H738.247z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M743.99,90.46c-0.368,0-0.655-0.097-0.86-0.292c-0.205-0.194-0.308-0.465-0.308-0.812
			c0-0.336,0.103-0.604,0.308-0.804c0.205-0.2,0.492-0.3,0.86-0.3c0.362,0,0.648,0.1,0.856,0.3c0.207,0.2,0.312,0.468,0.312,0.804
			c0,0.347-0.104,0.618-0.312,0.812C744.638,90.363,744.352,90.46,743.99,90.46z M743.046,99.644v-7.792h1.889v7.792H743.046z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M748.133,99.644v-7.76h1.648l0.24,1.536v6.224H748.133z M753.462,99.644V95.1
			c0-0.576-0.139-1.011-0.416-1.304c-0.277-0.293-0.64-0.44-1.088-0.44c-0.379,0-0.713,0.104-1.004,0.312s-0.519,0.521-0.684,0.94
			c-0.166,0.419-0.248,0.94-0.248,1.564h-0.624c0-1.008,0.125-1.847,0.376-2.516c0.25-0.669,0.605-1.169,1.063-1.5
			c0.459-0.331,0.998-0.496,1.616-0.496c0.641,0,1.175,0.143,1.604,0.428c0.429,0.286,0.752,0.687,0.968,1.204
			c0.216,0.518,0.324,1.12,0.324,1.808v4.544H753.462z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M760.998,99.868c-0.747,0-1.388-0.103-1.924-0.308s-0.948-0.501-1.236-0.888
			c-0.288-0.386-0.435-0.852-0.439-1.396h1.743c0.017,0.384,0.168,0.664,0.456,0.84c0.288,0.176,0.681,0.264,1.177,0.264
			c0.271,0,0.513-0.04,0.724-0.12c0.211-0.08,0.375-0.189,0.492-0.328s0.176-0.293,0.176-0.464c0-0.219-0.079-0.391-0.236-0.516
			s-0.409-0.241-0.756-0.348l-1.696-0.608c-0.607-0.219-1.074-0.507-1.399-0.864c-0.325-0.357-0.488-0.784-0.488-1.28
			c0-0.656,0.298-1.185,0.893-1.588c0.594-0.403,1.391-0.604,2.388-0.604c0.992,0,1.759,0.217,2.3,0.652
			c0.542,0.435,0.82,0.985,0.836,1.652h-1.775c-0.027-0.256-0.149-0.458-0.368-0.608c-0.219-0.149-0.55-0.224-0.992-0.224
			c-0.4,0-0.714,0.082-0.94,0.244s-0.34,0.354-0.34,0.572c0,0.368,0.314,0.645,0.944,0.832l1.84,0.688
			c0.598,0.203,1.062,0.464,1.393,0.784c0.33,0.32,0.496,0.726,0.496,1.216c0,0.411-0.138,0.798-0.412,1.164
			c-0.275,0.366-0.658,0.663-1.148,0.892C762.211,99.753,761.643,99.868,760.998,99.868z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M765.565,93.132v-1.248h5.088v1.248H765.565z M769.166,99.868c-0.459,0-0.879-0.085-1.261-0.256
			c-0.381-0.17-0.685-0.432-0.911-0.784c-0.228-0.352-0.341-0.8-0.341-1.344V89.82h1.889v7.6c0,0.64,0.304,0.96,0.912,0.96
			c0.187,0,0.373-0.021,0.56-0.064c0.187-0.042,0.4-0.106,0.64-0.192l0.272,1.328c-0.299,0.117-0.59,0.216-0.872,0.295
			C769.77,99.828,769.474,99.868,769.166,99.868z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M775.253,99.868c-0.666,0-1.223-0.115-1.668-0.348c-0.445-0.232-0.779-0.537-1.004-0.916
			c-0.224-0.378-0.336-0.789-0.336-1.232c0-0.491,0.129-0.903,0.388-1.236c0.259-0.333,0.626-0.584,1.101-0.752
			c0.475-0.168,1.034-0.252,1.68-0.252c0.422,0,0.82,0.032,1.196,0.096s0.75,0.149,1.124,0.256v1.104
			c-0.294-0.096-0.613-0.17-0.96-0.224s-0.672-0.08-0.977-0.08c-0.447,0-0.815,0.083-1.104,0.248
			c-0.288,0.165-0.432,0.445-0.432,0.84c0,0.288,0.117,0.536,0.352,0.744s0.608,0.312,1.12,0.312c0.389,0,0.72-0.085,0.992-0.256
			c0.271-0.17,0.479-0.384,0.62-0.64c0.141-0.256,0.212-0.512,0.212-0.768h0.447c0,0.56-0.104,1.076-0.312,1.548
			s-0.517,0.85-0.924,1.132C776.361,99.727,775.855,99.868,775.253,99.868z M777.797,99.644l-0.239-1.888v-3.12
			c0-0.544-0.111-0.947-0.332-1.208c-0.222-0.261-0.597-0.392-1.124-0.392c-0.305,0-0.568,0.045-0.792,0.136
			c-0.225,0.091-0.396,0.22-0.517,0.388s-0.18,0.372-0.18,0.612h-2.064c0-0.586,0.142-1.065,0.425-1.436
			c0.282-0.371,0.688-0.643,1.22-0.816c0.53-0.173,1.166-0.26,1.908-0.26c0.672,0,1.258,0.108,1.76,0.324
			c0.501,0.216,0.891,0.548,1.168,0.996c0.277,0.448,0.416,1.021,0.416,1.72v4.944H777.797z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M782.214,99.644V88.668h1.888v10.976H782.214z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M787.086,99.644V88.668h1.888v10.976H787.086z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M794.357,99.868c-0.666,0-1.223-0.115-1.668-0.348c-0.445-0.232-0.779-0.537-1.004-0.916
			c-0.224-0.378-0.336-0.789-0.336-1.232c0-0.491,0.129-0.903,0.388-1.236c0.259-0.333,0.626-0.584,1.101-0.752
			c0.475-0.168,1.034-0.252,1.68-0.252c0.422,0,0.82,0.032,1.196,0.096s0.75,0.149,1.124,0.256v1.104
			c-0.294-0.096-0.613-0.17-0.96-0.224s-0.672-0.08-0.977-0.08c-0.447,0-0.815,0.083-1.104,0.248
			c-0.288,0.165-0.432,0.445-0.432,0.84c0,0.288,0.117,0.536,0.352,0.744s0.608,0.312,1.12,0.312c0.389,0,0.72-0.085,0.992-0.256
			c0.271-0.17,0.479-0.384,0.62-0.64c0.141-0.256,0.212-0.512,0.212-0.768h0.447c0,0.56-0.104,1.076-0.312,1.548
			s-0.517,0.85-0.924,1.132C795.465,99.727,794.96,99.868,794.357,99.868z M796.901,99.644l-0.239-1.888v-3.12
			c0-0.544-0.111-0.947-0.332-1.208c-0.222-0.261-0.597-0.392-1.124-0.392c-0.305,0-0.568,0.045-0.792,0.136
			c-0.225,0.091-0.396,0.22-0.517,0.388s-0.18,0.372-0.18,0.612h-2.064c0-0.586,0.142-1.065,0.425-1.436
			c0.282-0.371,0.688-0.643,1.22-0.816c0.53-0.173,1.166-0.26,1.908-0.26c0.672,0,1.258,0.108,1.76,0.324
			c0.501,0.216,0.891,0.548,1.168,0.996c0.277,0.448,0.416,1.021,0.416,1.72v4.944H796.901z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M800.34,93.132v-1.248h5.088v1.248H800.34z M803.941,99.868c-0.459,0-0.879-0.085-1.261-0.256
			c-0.381-0.17-0.685-0.432-0.911-0.784c-0.228-0.352-0.341-0.8-0.341-1.344V89.82h1.889v7.6c0,0.64,0.304,0.96,0.912,0.96
			c0.187,0,0.373-0.021,0.56-0.064c0.187-0.042,0.4-0.106,0.64-0.192l0.272,1.328c-0.299,0.117-0.59,0.216-0.872,0.295
			C804.546,99.828,804.25,99.868,803.941,99.868z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M808.732,90.46c-0.368,0-0.654-0.097-0.859-0.292c-0.206-0.194-0.309-0.465-0.309-0.812
			c0-0.336,0.103-0.604,0.309-0.804c0.205-0.2,0.491-0.3,0.859-0.3c0.363,0,0.648,0.1,0.856,0.3c0.208,0.2,0.312,0.468,0.312,0.804
			c0,0.347-0.104,0.618-0.312,0.812C809.38,90.363,809.095,90.46,808.732,90.46z M807.789,99.644v-7.792h1.888v7.792H807.789z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M815.932,99.868c-0.709,0-1.351-0.151-1.924-0.456s-1.028-0.759-1.364-1.364
			c-0.336-0.605-0.504-1.364-0.504-2.276c0-0.928,0.168-1.696,0.504-2.304c0.336-0.608,0.791-1.062,1.364-1.36
			c0.573-0.298,1.215-0.448,1.924-0.448c0.698,0,1.332,0.149,1.9,0.448c0.567,0.299,1.02,0.752,1.355,1.36
			c0.336,0.608,0.504,1.376,0.504,2.304c0,0.912-0.168,1.671-0.504,2.276c-0.336,0.605-0.788,1.06-1.355,1.364
			C817.264,99.716,816.63,99.868,815.932,99.868z M815.869,98.22c0.373,0,0.705-0.092,0.995-0.276
			c0.291-0.184,0.521-0.457,0.688-0.82s0.252-0.813,0.252-1.352c0-0.821-0.161-1.44-0.484-1.856
			c-0.322-0.416-0.764-0.624-1.323-0.624c-0.379,0-0.715,0.092-1.009,0.276c-0.293,0.184-0.523,0.46-0.691,0.828
			s-0.252,0.827-0.252,1.376c0,0.811,0.164,1.421,0.492,1.832C814.864,98.015,815.308,98.22,815.869,98.22z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M821.932,99.644v-7.76h1.647l0.24,1.536v6.224H821.932z M827.26,99.644V95.1
			c0-0.576-0.139-1.011-0.416-1.304c-0.277-0.293-0.641-0.44-1.089-0.44c-0.378,0-0.713,0.104-1.004,0.312
			c-0.29,0.208-0.519,0.521-0.684,0.94c-0.165,0.419-0.248,0.94-0.248,1.564h-0.624c0-1.008,0.125-1.847,0.376-2.516
			c0.251-0.669,0.605-1.169,1.064-1.5c0.458-0.331,0.997-0.496,1.615-0.496c0.641,0,1.175,0.143,1.604,0.428
			c0.429,0.286,0.752,0.687,0.968,1.204c0.216,0.518,0.324,1.12,0.324,1.808v4.544H827.26z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M733.517,119.644v-1.3c0-0.363,0.05-0.678,0.149-0.942c0.101-0.266,0.262-0.493,0.485-0.683
			c0.224-0.19,0.519-0.354,0.885-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.561-0.354c0.116-0.137,0.175-0.302,0.175-0.495
			c0-0.177-0.07-0.334-0.21-0.473s-0.347-0.207-0.62-0.207c-0.253,0-0.472,0.076-0.655,0.229c-0.183,0.153-0.288,0.372-0.314,0.655
			h-1.771c-0.003-0.526,0.119-0.958,0.368-1.293c0.248-0.335,0.579-0.581,0.992-0.739c0.413-0.159,0.863-0.238,1.35-0.238
			c0.474,0,0.904,0.087,1.293,0.261c0.388,0.173,0.698,0.417,0.93,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.185,0.915c-0.124,0.267-0.322,0.504-0.596,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.325,0.144-0.397,0.212c-0.071,0.068-0.107,0.158-0.107,0.268v0.075h3.771v1.46H733.517z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M739.837,119.644v-1.3c0-0.363,0.05-0.678,0.15-0.942c0.1-0.266,0.262-0.493,0.484-0.683
			c0.224-0.19,0.519-0.354,0.886-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.56-0.354c0.117-0.137,0.176-0.302,0.176-0.495
			c0-0.177-0.07-0.334-0.211-0.473c-0.14-0.139-0.347-0.207-0.619-0.207c-0.254,0-0.472,0.076-0.655,0.229s-0.288,0.372-0.315,0.655
			h-1.77c-0.004-0.526,0.119-0.958,0.367-1.293s0.579-0.581,0.992-0.739c0.414-0.159,0.863-0.238,1.351-0.238
			c0.474,0,0.904,0.087,1.292,0.261c0.389,0.173,0.698,0.417,0.931,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.186,0.915s-0.321,0.504-0.595,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.326,0.144-0.397,0.212c-0.072,0.068-0.107,0.158-0.107,0.268v0.075h3.77v1.46H739.837z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M745.771,118.714c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			s0.31,0.443,0.31,0.74s-0.103,0.541-0.31,0.732s-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S745.771,119.007,745.771,118.714z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M751.511,119.804c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C752.582,119.677,752.079,119.804,751.511,119.804z M751.511,118.349c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S751.108,118.349,751.511,118.349z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M758.247,119.804c-0.573,0-1.083-0.127-1.527-0.38c-0.445-0.254-0.794-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.251-0.519,0.6-0.903,1.045-1.155
			c0.444-0.252,0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158
			C759.317,119.677,758.813,119.804,758.247,119.804z M758.247,118.349c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532
			s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543
			s0.115,1.188,0.348,1.532C757.526,118.176,757.843,118.349,758.247,118.349z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M762.151,117.219v-1.4h4.695v1.4H762.151z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M770.782,119.804c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C771.852,119.677,771.348,119.804,770.782,119.804z
			 M770.782,118.349c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C770.061,118.176,770.379,118.349,770.782,118.349z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M775.963,112.875h1.16v6.77h-1.601v-4.845c-0.189,0.047-0.367,0.083-0.532,0.109
			c-0.165,0.027-0.331,0.039-0.497,0.035v-1.52c0.243-0.021,0.48-0.072,0.712-0.155S775.689,113.054,775.963,112.875z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M778.152,118.714c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S778.152,119.007,778.152,118.714z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M783.892,119.804c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C784.963,119.677,784.459,119.804,783.892,119.804z
			 M783.892,118.349c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C783.172,118.176,783.489,118.349,783.892,118.349z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M790.628,119.804c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C791.698,119.677,791.194,119.804,790.628,119.804z
			 M790.628,118.349c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C789.907,118.176,790.224,118.349,790.628,118.349z"
                  />
                </g>
                <g>
                  <path
                    fill="#1E1B39"
                    d="M733.725,432.965h1.904v10.384h-1.904V432.965z M739.725,432.965h2.593l-6.849,7.008v-2.576
			L739.725,432.965z M737.422,437.253l5.185,6.096h-2.433l-4.063-4.864L737.422,437.253z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M746.589,443.572c-0.667,0-1.223-0.115-1.668-0.348c-0.445-0.232-0.78-0.537-1.004-0.916
			c-0.224-0.378-0.336-0.789-0.336-1.232c0-0.491,0.129-0.903,0.388-1.236c0.259-0.333,0.625-0.584,1.101-0.752
			c0.475-0.168,1.034-0.252,1.68-0.252c0.421,0,0.82,0.032,1.196,0.096s0.75,0.149,1.124,0.256v1.104
			c-0.294-0.096-0.614-0.17-0.96-0.224c-0.348-0.053-0.673-0.08-0.977-0.08c-0.448,0-0.815,0.083-1.104,0.248
			c-0.288,0.165-0.433,0.445-0.433,0.84c0,0.288,0.117,0.536,0.353,0.744c0.234,0.208,0.607,0.312,1.12,0.312
			c0.389,0,0.72-0.085,0.991-0.256c0.272-0.17,0.479-0.384,0.62-0.64c0.142-0.256,0.212-0.512,0.212-0.768h0.448
			c0,0.56-0.104,1.076-0.312,1.548s-0.517,0.85-0.925,1.132C747.698,443.432,747.192,443.572,746.589,443.572z M749.133,443.349
			l-0.24-1.888v-3.12c0-0.544-0.11-0.947-0.332-1.208c-0.221-0.261-0.596-0.392-1.124-0.392c-0.304,0-0.567,0.045-0.792,0.136
			c-0.224,0.091-0.396,0.22-0.516,0.388s-0.18,0.372-0.18,0.612h-2.064c0-0.586,0.142-1.065,0.424-1.436
			c0.283-0.371,0.689-0.643,1.221-0.816c0.53-0.173,1.166-0.26,1.907-0.26c0.673,0,1.259,0.108,1.761,0.324
			c0.501,0.216,0.891,0.548,1.168,0.996c0.277,0.448,0.416,1.021,0.416,1.72v4.944H749.133z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M753.549,443.349v-7.792h1.888v7.792H753.549z M754.973,439.94c0-1.019,0.115-1.861,0.344-2.528
			c0.229-0.667,0.538-1.161,0.925-1.484c0.386-0.323,0.816-0.484,1.292-0.484c0.128,0,0.249,0.008,0.363,0.024
			c0.115,0.016,0.229,0.04,0.34,0.072l-0.031,1.888c-0.139-0.032-0.288-0.059-0.448-0.08s-0.304-0.032-0.432-0.032
			c-0.416,0-0.765,0.1-1.044,0.3c-0.28,0.2-0.491,0.495-0.633,0.884c-0.141,0.39-0.212,0.87-0.212,1.44H754.973z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M762.229,443.572c-0.667,0-1.223-0.115-1.668-0.348c-0.445-0.232-0.78-0.537-1.004-0.916
			c-0.224-0.378-0.336-0.789-0.336-1.232c0-0.491,0.129-0.903,0.388-1.236c0.259-0.333,0.625-0.584,1.101-0.752
			c0.475-0.168,1.034-0.252,1.68-0.252c0.421,0,0.82,0.032,1.196,0.096s0.751,0.149,1.124,0.256v1.104
			c-0.294-0.096-0.613-0.17-0.96-0.224c-0.348-0.053-0.673-0.08-0.977-0.08c-0.448,0-0.815,0.083-1.104,0.248
			c-0.288,0.165-0.433,0.445-0.433,0.84c0,0.288,0.117,0.536,0.353,0.744c0.234,0.208,0.607,0.312,1.12,0.312
			c0.389,0,0.72-0.085,0.992-0.256c0.271-0.17,0.479-0.384,0.619-0.64c0.142-0.256,0.213-0.512,0.213-0.768h0.447
			c0,0.56-0.104,1.076-0.312,1.548s-0.517,0.85-0.925,1.132C763.338,443.432,762.832,443.572,762.229,443.572z M764.773,443.349
			l-0.239-1.888v-3.12c0-0.544-0.111-0.947-0.332-1.208c-0.222-0.261-0.596-0.392-1.125-0.392c-0.304,0-0.567,0.045-0.792,0.136
			c-0.224,0.091-0.396,0.22-0.516,0.388s-0.18,0.372-0.18,0.612h-2.064c0-0.586,0.142-1.065,0.424-1.436
			c0.283-0.371,0.689-0.643,1.221-0.816c0.53-0.173,1.166-0.26,1.907-0.26c0.673,0,1.26,0.108,1.761,0.324
			c0.501,0.216,0.891,0.548,1.168,0.996c0.277,0.448,0.416,1.021,0.416,1.72v4.944H764.773z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M772.246,443.572c-0.709,0-1.351-0.151-1.924-0.456s-1.028-0.759-1.364-1.364
			c-0.336-0.605-0.504-1.364-0.504-2.276c0-0.928,0.168-1.696,0.504-2.304c0.336-0.608,0.791-1.062,1.364-1.36
			c0.573-0.298,1.215-0.448,1.924-0.448c0.698,0,1.332,0.149,1.9,0.448c0.567,0.299,1.02,0.752,1.355,1.36
			c0.336,0.608,0.504,1.376,0.504,2.304c0,0.912-0.168,1.671-0.504,2.276c-0.336,0.605-0.788,1.06-1.355,1.364
			C773.578,443.421,772.944,443.572,772.246,443.572z M772.182,441.925c0.373,0,0.705-0.092,0.995-0.276
			c0.291-0.184,0.521-0.457,0.688-0.82s0.252-0.813,0.252-1.352c0-0.821-0.161-1.44-0.484-1.856
			c-0.322-0.416-0.764-0.624-1.323-0.624c-0.379,0-0.715,0.092-1.009,0.276c-0.293,0.184-0.523,0.46-0.691,0.828
			s-0.252,0.827-0.252,1.376c0,0.811,0.164,1.421,0.492,1.832C771.177,441.719,771.622,441.925,772.182,441.925z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M778.246,443.349v-10.976h1.888v10.976H778.246z M779.878,438.533l3.328-2.944h2.56l-5.888,4.896V438.533z
			 M783.654,443.349l-3.473-4.944l1.792-0.432l3.984,5.376H783.654z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M790.605,443.572c-0.661,0-1.267-0.17-1.815-0.512c-0.55-0.341-0.989-0.82-1.32-1.436
			c-0.331-0.616-0.496-1.337-0.496-2.164c0-0.853,0.164-1.586,0.492-2.2s0.767-1.083,1.315-1.408
			c0.55-0.325,1.157-0.488,1.824-0.488c0.619,0,1.153,0.124,1.604,0.372c0.45,0.248,0.812,0.584,1.088,1.008
			c0.274,0.424,0.46,0.903,0.556,1.436s0.102,1.083,0.017,1.648h-5.393v-1.024h4.056l-0.367,0.24c0.016-0.25,0.004-0.504-0.036-0.76
			s-0.116-0.491-0.229-0.704c-0.111-0.213-0.271-0.385-0.476-0.516c-0.205-0.13-0.468-0.196-0.788-0.196
			c-0.384,0-0.709,0.096-0.976,0.288c-0.268,0.192-0.47,0.453-0.608,0.784c-0.139,0.331-0.208,0.704-0.208,1.12v0.752
			c0,0.438,0.065,0.824,0.196,1.16c0.13,0.336,0.325,0.6,0.584,0.792s0.585,0.288,0.979,0.288c0.368,0,0.678-0.085,0.928-0.256
			c0.251-0.171,0.411-0.427,0.48-0.768h1.904c-0.08,0.501-0.269,0.942-0.564,1.324s-0.679,0.68-1.147,0.896
			C791.736,443.465,791.203,443.572,790.605,443.572z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M736.014,463.509c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C737.085,463.382,736.582,463.509,736.014,463.509z M736.014,462.054c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S735.611,462.054,736.014,462.054z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M741.195,456.579h1.159v6.77h-1.6v-4.845c-0.19,0.047-0.367,0.083-0.532,0.109
			c-0.165,0.027-0.331,0.039-0.498,0.035v-1.52c0.243-0.021,0.481-0.072,0.713-0.155S740.922,456.759,741.195,456.579z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M743.384,462.419c0-0.297,0.099-0.544,0.298-0.74c0.198-0.196,0.455-0.295,0.772-0.295
			c0.316,0,0.578,0.099,0.784,0.295c0.207,0.196,0.311,0.443,0.311,0.74s-0.104,0.541-0.311,0.732
			c-0.206,0.191-0.468,0.287-0.784,0.287c-0.317,0-0.574-0.097-0.772-0.29C743.483,462.955,743.384,462.712,743.384,462.419z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M749.125,463.509c-0.573,0-1.083-0.127-1.527-0.38c-0.445-0.254-0.794-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.251-0.519,0.6-0.903,1.045-1.155
			c0.444-0.252,0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158
			C750.195,463.382,749.691,463.509,749.125,463.509z M749.125,462.054c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532
			s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543
			s0.115,1.188,0.348,1.532C748.404,461.881,748.721,462.054,749.125,462.054z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M755.859,463.509c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C756.929,463.382,756.426,463.509,755.859,463.509z M755.859,462.054c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S755.456,462.054,755.859,462.054z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M759.764,460.924v-1.4h4.695v1.4H759.764z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M768.394,463.509c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C769.464,463.382,768.961,463.509,768.394,463.509z
			 M768.394,462.054c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C767.673,461.881,767.991,462.054,768.394,462.054z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M775.049,463.509c-0.373,0-0.734-0.05-1.085-0.147c-0.35-0.099-0.664-0.245-0.942-0.44
			c-0.278-0.194-0.499-0.438-0.662-0.728c-0.164-0.29-0.245-0.626-0.245-1.01h1.705c0.02,0.163,0.08,0.311,0.18,0.442
			s0.237,0.236,0.412,0.312c0.176,0.077,0.388,0.115,0.638,0.115c0.34,0,0.612-0.073,0.815-0.22
			c0.203-0.147,0.305-0.334,0.305-0.561c0-0.156-0.04-0.285-0.12-0.385s-0.195-0.174-0.348-0.223
			c-0.151-0.048-0.337-0.072-0.558-0.072h-1.02v-1.255h0.995c0.267,0,0.475-0.066,0.625-0.2c0.149-0.134,0.225-0.309,0.225-0.525
			c0-0.196-0.071-0.358-0.215-0.487c-0.144-0.128-0.378-0.192-0.705-0.192c-0.283,0-0.536,0.059-0.76,0.175
			c-0.224,0.117-0.355,0.304-0.396,0.561h-1.699c0.04-0.5,0.182-0.911,0.425-1.232c0.243-0.322,0.566-0.561,0.97-0.716
			c0.403-0.154,0.863-0.232,1.38-0.232c0.527,0,0.994,0.08,1.402,0.24c0.409,0.16,0.73,0.384,0.966,0.67
			c0.234,0.287,0.353,0.622,0.353,1.005c0,0.33-0.086,0.622-0.258,0.875c-0.172,0.254-0.431,0.438-0.777,0.556
			c0.387,0.153,0.69,0.364,0.912,0.632c0.222,0.269,0.333,0.591,0.333,0.968c0,0.407-0.121,0.767-0.363,1.08
			c-0.241,0.313-0.576,0.558-1.005,0.732C776.104,463.421,775.61,463.509,775.049,463.509z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M778.474,462.419c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S778.474,462.712,778.474,462.419z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M784.214,463.509c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C785.285,463.382,784.781,463.509,784.214,463.509z
			 M784.214,462.054c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C783.494,461.881,783.811,462.054,784.214,462.054z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M790.95,463.509c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C792.02,463.382,791.516,463.509,790.95,463.509z
			 M790.95,462.054c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C790.229,461.881,790.547,462.054,790.95,462.054z"
                  />
                </g>
                <g>
                  <path
                    fill="#1E1B39"
                    d="M565.107,205.486v-1.632h8.32v1.632h-3.641l0.425-0.432v9.184h-1.889v-9.184l0.424,0.432H565.107z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M573.643,214.238v-7.792h1.888v7.792H573.643z M575.067,210.83c0-1.019,0.115-1.861,0.344-2.528
			c0.229-0.667,0.538-1.161,0.925-1.484c0.386-0.323,0.816-0.484,1.292-0.484c0.128,0,0.249,0.008,0.363,0.024
			c0.115,0.016,0.229,0.04,0.34,0.072l-0.031,1.888c-0.139-0.032-0.288-0.059-0.448-0.08s-0.304-0.032-0.432-0.032
			c-0.416,0-0.765,0.1-1.044,0.3c-0.28,0.2-0.491,0.495-0.633,0.884c-0.141,0.39-0.212,0.87-0.212,1.44H575.067z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M581.027,205.054c-0.368,0-0.655-0.097-0.86-0.292c-0.205-0.194-0.308-0.465-0.308-0.812
			c0-0.336,0.103-0.604,0.308-0.804c0.205-0.2,0.492-0.3,0.86-0.3c0.362,0,0.648,0.1,0.856,0.3c0.207,0.2,0.312,0.468,0.312,0.804
			c0,0.347-0.104,0.618-0.312,0.812C581.675,204.957,581.389,205.054,581.027,205.054z M580.083,214.238v-7.792h1.889v7.792H580.083
			z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M585.171,218.03v-11.552h1.552l0.336,1.92v9.632H585.171z M589.058,214.461
			c-0.57,0-1.056-0.122-1.456-0.368c-0.399-0.245-0.705-0.617-0.916-1.116c-0.21-0.499-0.315-1.124-0.315-1.876h0.688
			c0,0.347,0.08,0.643,0.24,0.888c0.16,0.246,0.372,0.434,0.636,0.564c0.265,0.131,0.548,0.196,0.853,0.196
			c0.314,0,0.625-0.087,0.932-0.26s0.562-0.437,0.764-0.792c0.203-0.354,0.305-0.804,0.305-1.348c0-0.496-0.086-0.924-0.256-1.284
			c-0.171-0.36-0.404-0.636-0.7-0.828s-0.634-0.288-1.012-0.288c-0.32,0-0.614,0.072-0.881,0.216
			c-0.267,0.144-0.479,0.353-0.64,0.628c-0.16,0.274-0.24,0.606-0.24,0.996h-0.688c0-0.816,0.125-1.484,0.376-2.004
			c0.25-0.52,0.586-0.905,1.008-1.156c0.421-0.25,0.883-0.376,1.384-0.376c0.613,0,1.19,0.163,1.732,0.488
			c0.541,0.326,0.979,0.793,1.315,1.404c0.336,0.61,0.504,1.345,0.504,2.204c0,0.864-0.17,1.603-0.512,2.216
			c-0.341,0.613-0.788,1.083-1.34,1.408C590.287,214.299,589.693,214.461,589.058,214.461z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M594.93,218.03v-11.552h1.552l0.336,1.92v9.632H594.93z M598.818,214.461c-0.57,0-1.056-0.122-1.456-0.368
			c-0.399-0.245-0.705-0.617-0.916-1.116c-0.21-0.499-0.315-1.124-0.315-1.876h0.688c0,0.347,0.08,0.643,0.24,0.888
			c0.16,0.246,0.372,0.434,0.636,0.564c0.265,0.131,0.548,0.196,0.853,0.196c0.314,0,0.625-0.087,0.932-0.26
			s0.562-0.437,0.764-0.792c0.203-0.354,0.305-0.804,0.305-1.348c0-0.496-0.086-0.924-0.256-1.284
			c-0.171-0.36-0.404-0.636-0.7-0.828s-0.634-0.288-1.012-0.288c-0.32,0-0.614,0.072-0.881,0.216
			c-0.267,0.144-0.479,0.353-0.64,0.628c-0.16,0.274-0.24,0.606-0.24,0.996h-0.688c0-0.816,0.125-1.484,0.376-2.004
			c0.25-0.52,0.586-0.905,1.008-1.156c0.421-0.25,0.883-0.376,1.384-0.376c0.613,0,1.19,0.163,1.732,0.488
			c0.541,0.326,0.979,0.793,1.315,1.404c0.336,0.61,0.504,1.345,0.504,2.204c0,0.864-0.17,1.603-0.512,2.216
			c-0.341,0.613-0.788,1.083-1.34,1.408C600.047,214.299,599.453,214.461,598.818,214.461z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M603.506,206.478h2.144l2.064,6.112h-0.337l1.777-6.112h1.92l-2.977,9.168
			c-0.305,0.917-0.679,1.581-1.124,1.992c-0.445,0.41-0.988,0.615-1.628,0.615c-0.368,0-0.727-0.041-1.076-0.124
			c-0.35-0.082-0.705-0.212-1.068-0.388v-1.664c0.294,0.182,0.577,0.314,0.853,0.4c0.274,0.085,0.551,0.128,0.828,0.128
			c0.25,0,0.47-0.068,0.659-0.204s0.354-0.325,0.492-0.568c0.139-0.242,0.256-0.523,0.353-0.844l0.239-0.752L603.506,206.478z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M615.561,213.806v-1.888c0.427,0.245,0.844,0.424,1.252,0.536c0.408,0.112,0.785,0.168,1.132,0.168
			c0.459,0,0.792-0.133,1-0.4c0.208-0.267,0.312-0.672,0.312-1.216v-7.152h1.904v7.152c0,0.837-0.131,1.509-0.393,2.016
			c-0.262,0.506-0.621,0.873-1.08,1.1s-0.986,0.34-1.584,0.34c-0.469,0-0.917-0.062-1.344-0.188
			C616.335,214.148,615.934,213.993,615.561,213.806z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M626.289,214.461c-0.666,0-1.223-0.115-1.668-0.348c-0.445-0.232-0.779-0.537-1.004-0.916
			c-0.224-0.378-0.336-0.789-0.336-1.232c0-0.491,0.129-0.903,0.388-1.236c0.259-0.333,0.626-0.584,1.101-0.752
			c0.475-0.168,1.034-0.252,1.68-0.252c0.422,0,0.82,0.032,1.196,0.096s0.75,0.149,1.124,0.256v1.104
			c-0.294-0.096-0.613-0.17-0.96-0.224s-0.672-0.08-0.977-0.08c-0.447,0-0.815,0.083-1.104,0.248
			c-0.288,0.165-0.432,0.445-0.432,0.84c0,0.288,0.117,0.536,0.352,0.744s0.608,0.312,1.12,0.312c0.389,0,0.72-0.085,0.992-0.256
			c0.271-0.17,0.479-0.384,0.62-0.64c0.141-0.256,0.212-0.512,0.212-0.768h0.447c0,0.56-0.104,1.076-0.312,1.548
			s-0.517,0.85-0.924,1.132C627.397,214.321,626.891,214.461,626.289,214.461z M628.833,214.238l-0.239-1.888v-3.12
			c0-0.544-0.111-0.947-0.332-1.208c-0.222-0.261-0.597-0.392-1.124-0.392c-0.305,0-0.568,0.045-0.792,0.136
			c-0.225,0.091-0.396,0.22-0.517,0.388s-0.18,0.372-0.18,0.612h-2.064c0-0.586,0.142-1.065,0.425-1.436
			c0.282-0.371,0.688-0.643,1.22-0.816c0.53-0.173,1.166-0.26,1.908-0.26c0.672,0,1.258,0.108,1.76,0.324
			c0.501,0.216,0.891,0.548,1.168,0.996c0.277,0.448,0.416,1.021,0.416,1.72v4.944H628.833z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M632.674,214.238v-1.536l4.032-4.704h-4.032v-1.52h6.112v1.52l-3.904,4.704h3.904v1.536H632.674z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M640.594,214.238v-1.536l4.032-4.704h-4.032v-1.52h6.112v1.52l-3.904,4.704h3.904v1.536H640.594z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M578.554,223.854h1.904v10.384h-1.904V223.854z M584.554,223.854h2.593l-6.849,7.008v-2.576
			L584.554,223.854z M582.251,228.142l5.185,6.096h-2.433l-4.063-4.864L582.251,228.142z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M592.075,234.461c-0.71,0-1.351-0.151-1.924-0.456c-0.574-0.304-1.028-0.758-1.364-1.363
			s-0.504-1.364-0.504-2.276c0-0.928,0.168-1.696,0.504-2.304c0.336-0.608,0.79-1.062,1.364-1.36
			c0.573-0.299,1.214-0.448,1.924-0.448c0.698,0,1.332,0.149,1.899,0.448c0.568,0.299,1.021,0.752,1.356,1.36
			c0.336,0.607,0.504,1.376,0.504,2.304c0,0.912-0.168,1.671-0.504,2.276s-0.788,1.06-1.356,1.363
			C593.407,234.31,592.773,234.461,592.075,234.461z M592.01,232.814c0.373,0,0.705-0.092,0.996-0.276
			c0.291-0.184,0.521-0.457,0.688-0.819c0.168-0.363,0.252-0.813,0.252-1.353c0-0.821-0.162-1.439-0.484-1.855
			s-0.764-0.624-1.324-0.624c-0.378,0-0.715,0.092-1.008,0.275c-0.293,0.185-0.523,0.46-0.691,0.828s-0.253,0.827-0.253,1.376
			c0,0.811,0.164,1.422,0.492,1.832C591.006,232.609,591.451,232.814,592.01,232.814z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M598.074,234.238v-7.76h1.648l0.24,1.536v6.224H598.074z M603.402,234.238v-4.544
			c0-0.576-0.139-1.011-0.416-1.304c-0.277-0.294-0.64-0.44-1.088-0.44c-0.379,0-0.713,0.104-1.004,0.312s-0.519,0.521-0.684,0.939
			c-0.166,0.419-0.248,0.94-0.248,1.564h-0.624c0-1.009,0.125-1.847,0.376-2.517c0.25-0.669,0.605-1.169,1.063-1.5
			c0.459-0.33,0.998-0.496,1.616-0.496c0.641,0,1.175,0.144,1.604,0.429c0.429,0.285,0.752,0.687,0.968,1.204
			c0.216,0.517,0.324,1.119,0.324,1.808v4.544H603.402z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M607.466,234.238v-1.536l4.032-4.704h-4.032v-1.52h6.112v1.52l-3.904,4.704h3.904v1.536H607.466z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M618.858,234.461c-0.661,0-1.267-0.17-1.815-0.512c-0.55-0.341-0.989-0.82-1.32-1.436
			c-0.331-0.616-0.496-1.338-0.496-2.164c0-0.854,0.164-1.587,0.492-2.2s0.767-1.083,1.315-1.408
			c0.55-0.325,1.157-0.488,1.824-0.488c0.619,0,1.153,0.124,1.604,0.372c0.45,0.248,0.812,0.584,1.088,1.009
			c0.274,0.424,0.46,0.902,0.556,1.436s0.102,1.083,0.017,1.648h-5.393v-1.024h4.056l-0.367,0.24
			c0.016-0.251,0.004-0.504-0.036-0.761c-0.04-0.256-0.116-0.49-0.229-0.703c-0.111-0.214-0.271-0.386-0.476-0.517
			s-0.468-0.196-0.788-0.196c-0.384,0-0.709,0.097-0.976,0.288c-0.268,0.192-0.47,0.454-0.608,0.784
			c-0.139,0.331-0.208,0.704-0.208,1.12v0.752c0,0.438,0.065,0.824,0.196,1.16c0.13,0.336,0.325,0.6,0.584,0.792
			s0.585,0.288,0.979,0.288c0.368,0,0.678-0.085,0.928-0.256c0.251-0.171,0.411-0.427,0.48-0.769h1.904
			c-0.08,0.502-0.269,0.943-0.564,1.324c-0.296,0.382-0.679,0.68-1.147,0.896C619.989,234.354,619.456,234.461,618.858,234.461z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M624.442,234.238v-7.792h1.888v7.792H624.442z M625.866,230.83c0-1.019,0.114-1.861,0.344-2.527
			c0.229-0.667,0.537-1.162,0.924-1.484s0.817-0.484,1.292-0.484c0.128,0,0.25,0.008,0.364,0.024c0.114,0.016,0.229,0.04,0.34,0.072
			l-0.032,1.888c-0.139-0.032-0.287-0.059-0.447-0.08s-0.305-0.032-0.433-0.032c-0.416,0-0.764,0.101-1.044,0.3
			c-0.279,0.2-0.49,0.495-0.632,0.885c-0.142,0.389-0.212,0.869-0.212,1.439H625.866z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M629.745,227.726v-1.248h5.088v1.248H629.745z M633.345,234.461c-0.459,0-0.879-0.085-1.261-0.256
			c-0.381-0.17-0.685-0.432-0.911-0.784c-0.228-0.352-0.341-0.8-0.341-1.344v-7.664h1.889v7.601c0,0.64,0.304,0.96,0.912,0.96
			c0.187,0,0.373-0.021,0.56-0.064s0.4-0.106,0.64-0.191l0.272,1.327c-0.299,0.118-0.59,0.217-0.872,0.296
			C633.95,234.421,633.654,234.461,633.345,234.461z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M542.116,250.238l0.546-5.796h1.056l-0.546,5.796H542.116z M543.303,243.248
			c-0.208,0-0.364-0.063-0.471-0.191s-0.153-0.301-0.141-0.517c0.02-0.204,0.095-0.373,0.225-0.507s0.301-0.201,0.513-0.201
			s0.371,0.065,0.478,0.195c0.105,0.13,0.15,0.301,0.135,0.513c-0.02,0.221-0.097,0.394-0.23,0.52
			C543.676,243.185,543.507,243.248,543.303,243.248z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M545.41,250.238l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.472-0.702,0.78-0.906
			c0.308-0.204,0.651-0.306,1.032-0.306c0.42,0,0.766,0.103,1.037,0.309c0.272,0.206,0.467,0.5,0.582,0.882
			c0.116,0.382,0.148,0.833,0.097,1.354l-0.312,3.39h-1.056l0.312-3.39c0.057-0.496-0.013-0.869-0.207-1.119
			c-0.193-0.25-0.459-0.375-0.795-0.375c-0.439,0-0.806,0.191-1.098,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H545.41
			z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M555.658,250.238l-1.656-2.946l-0.689,0.582l-0.223,2.364h-1.056l0.768-8.202h1.062l-0.432,4.596
			l2.495-2.178h1.452l-2.556,2.154l2.13,3.63H555.658z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M558.183,250.238l0.769-8.202h1.062l-0.773,8.202H558.183z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M561.316,250.346c-0.221,0-0.396-0.078-0.525-0.234s-0.183-0.35-0.159-0.582
			c0.028-0.224,0.12-0.414,0.276-0.57c0.155-0.155,0.344-0.233,0.563-0.233c0.216,0,0.392,0.075,0.525,0.225
			c0.134,0.15,0.19,0.343,0.171,0.579c-0.021,0.236-0.112,0.431-0.276,0.585S561.536,250.346,561.316,250.346z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M567.658,250.388c-0.42,0-0.768-0.087-1.041-0.259c-0.274-0.172-0.473-0.395-0.594-0.669
			c-0.122-0.273-0.168-0.566-0.136-0.879c0.036-0.38,0.153-0.694,0.352-0.944c0.197-0.25,0.46-0.438,0.786-0.564
			c0.325-0.126,0.692-0.188,1.101-0.188c0.252,0,0.5,0.024,0.744,0.074c0.244,0.051,0.476,0.117,0.696,0.201l0.065-0.66
			c0.052-0.464-0.001-0.802-0.159-1.014s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314
			c-0.107,0.141-0.172,0.314-0.191,0.522h-1.164c0.044-0.44,0.178-0.792,0.401-1.056c0.225-0.265,0.508-0.455,0.853-0.573
			c0.344-0.118,0.712-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54c0.351,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912
			c-0.004-0.147-0.005-0.295-0.003-0.441c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773
			S568.046,250.388,567.658,250.388z M567.916,249.488c0.271,0,0.525-0.066,0.759-0.198c0.234-0.132,0.425-0.316,0.573-0.555
			c0.147-0.238,0.229-0.515,0.246-0.831c-0.196-0.063-0.406-0.116-0.63-0.156c-0.225-0.04-0.435-0.06-0.63-0.06
			c-0.324,0-0.603,0.066-0.834,0.201c-0.232,0.134-0.364,0.364-0.396,0.692c-0.02,0.256,0.049,0.472,0.207,0.646
			S567.603,249.488,567.916,249.488z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M572.271,250.238l0.546-5.784h0.912l0.013,1.062c0.212-0.4,0.472-0.702,0.779-0.906
			c0.309-0.204,0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882s0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.013-0.869-0.207-1.119s-0.459-0.375-0.795-0.375c-0.44,0-0.807,0.191-1.098,0.576
			c-0.293,0.384-0.473,0.934-0.54,1.649l-0.246,2.658H572.271z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M580.492,253.22c-0.536,0-0.999-0.084-1.39-0.252c-0.39-0.169-0.685-0.396-0.885-0.685
			s-0.284-0.61-0.252-0.966c0.032-0.332,0.155-0.611,0.369-0.837c0.214-0.227,0.48-0.373,0.801-0.441
			c-0.196-0.128-0.34-0.28-0.432-0.459c-0.092-0.178-0.13-0.373-0.114-0.585c0.024-0.275,0.126-0.509,0.306-0.699
			c0.181-0.189,0.402-0.32,0.666-0.393c-0.168-0.188-0.294-0.408-0.378-0.66s-0.111-0.534-0.084-0.846
			c0.044-0.424,0.179-0.793,0.402-1.107c0.224-0.313,0.508-0.557,0.852-0.729c0.345-0.172,0.715-0.258,1.11-0.258
			c0.252,0,0.486,0.033,0.702,0.102s0.406,0.166,0.57,0.294c0.06-0.296,0.169-0.553,0.326-0.771
			c0.158-0.219,0.354-0.389,0.585-0.511c0.232-0.122,0.486-0.183,0.763-0.183l-0.187,1.14c-0.304,0-0.545,0.062-0.723,0.187
			s-0.285,0.322-0.321,0.594c0.104,0.168,0.182,0.356,0.231,0.564s0.064,0.434,0.045,0.678c-0.036,0.416-0.168,0.782-0.396,1.098
			c-0.228,0.316-0.518,0.562-0.869,0.738c-0.353,0.176-0.738,0.264-1.158,0.264c-0.164,0-0.329-0.02-0.495-0.06
			s-0.321-0.097-0.465-0.168c-0.132,0.032-0.252,0.102-0.36,0.21c-0.108,0.107-0.174,0.25-0.198,0.426
			c-0.02,0.176,0.031,0.319,0.153,0.429c0.122,0.11,0.349,0.165,0.681,0.165h1.885c0.516,0,0.912,0.112,1.19,0.336
			c0.278,0.225,0.399,0.559,0.363,1.002c-0.032,0.4-0.188,0.782-0.468,1.146c-0.28,0.364-0.661,0.661-1.144,0.891
			C581.693,253.105,581.131,253.22,580.492,253.22z M580.534,252.284c0.428,0,0.803-0.065,1.125-0.198
			c0.321-0.132,0.576-0.302,0.762-0.51c0.187-0.208,0.287-0.428,0.303-0.66c0.013-0.18-0.029-0.321-0.126-0.426
			c-0.096-0.104-0.244-0.156-0.443-0.156h-1.975c-0.34,0-0.614,0.09-0.821,0.271c-0.208,0.18-0.32,0.399-0.337,0.66
			c-0.016,0.304,0.11,0.55,0.379,0.737C579.668,252.19,580.046,252.284,580.534,252.284z M581.164,247.664
			c0.248,0,0.461-0.056,0.639-0.168c0.178-0.111,0.319-0.264,0.423-0.456c0.104-0.191,0.166-0.405,0.187-0.642
			c0.036-0.376-0.035-0.682-0.213-0.915c-0.179-0.234-0.443-0.352-0.795-0.352c-0.364,0-0.657,0.118-0.879,0.354
			c-0.223,0.236-0.35,0.54-0.382,0.912c-0.031,0.359,0.039,0.661,0.214,0.903C580.531,247.543,580.799,247.664,581.164,247.664z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M586.942,250.388c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.226-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.267,0.519,0.333,0.828c0.065,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.039,0.351
			c-0.018,0.122-0.041,0.247-0.068,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.188,0.551,0.375,0.729c0.188,0.179,0.446,0.268,0.774,0.268c0.28,0,0.523-0.077,0.731-0.231
			c0.208-0.153,0.349-0.381,0.421-0.681h1.079c-0.092,0.38-0.249,0.709-0.471,0.987c-0.222,0.277-0.492,0.493-0.81,0.645
			C587.671,250.312,587.322,250.388,586.942,250.388z M585.898,247.052l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.134-0.494-0.288-0.666c-0.153-0.172-0.389-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243s-0.389,0.38-0.519,0.654C586.023,246.411,585.937,246.715,585.898,247.052z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M590.721,250.238l0.768-8.202h1.062l-0.774,8.202H590.721z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M595.731,250.388c-0.452,0-0.848-0.124-1.188-0.372c-0.341-0.248-0.596-0.6-0.766-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.061-0.632,0.217-1.176,0.472-1.632c0.254-0.456,0.572-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.355,0,0.66,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.614,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.058,0.981c-0.009,0.112-0.021,0.229-0.039,0.351
			c-0.019,0.122-0.041,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.092,0.38-0.249,0.709-0.471,0.987c-0.223,0.277-0.492,0.493-0.811,0.645
			C596.461,250.312,596.111,250.388,595.731,250.388z M594.687,247.052l-0.234-0.168h3.049l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.135-0.494-0.288-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243c-0.217,0.162-0.39,0.38-0.52,0.654C594.812,246.411,594.727,246.715,594.687,247.052z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M599.589,250.238l0.546-5.796h1.057l-0.546,5.796H599.589z M600.777,243.248
			c-0.208,0-0.365-0.063-0.471-0.191c-0.106-0.128-0.153-0.301-0.142-0.517c0.021-0.204,0.095-0.373,0.226-0.507
			c0.13-0.134,0.301-0.201,0.513-0.201s0.371,0.065,0.477,0.195c0.106,0.13,0.151,0.301,0.136,0.513
			c-0.021,0.221-0.098,0.394-0.231,0.52S600.981,243.248,600.777,243.248z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M604.539,250.388c-0.292,0-0.552-0.062-0.78-0.184c-0.228-0.122-0.397-0.309-0.51-0.561
			s-0.152-0.572-0.12-0.96l0.336-3.666l0.246,0.21h-1.056l0.09-0.774h1.044l-0.3,0.271l0.18-1.836h1.057l-0.181,1.836l-0.24-0.271
			h1.801l-0.084,0.774h-1.801l0.294-0.21l-0.342,3.642c-0.023,0.269,0.018,0.468,0.123,0.601c0.106,0.132,0.273,0.197,0.501,0.197
			c0.12,0,0.249-0.017,0.388-0.051c0.138-0.034,0.293-0.085,0.465-0.153l0.071,0.828c-0.199,0.093-0.395,0.166-0.585,0.223
			C604.946,250.36,604.747,250.388,604.539,250.388z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M608.907,250.388c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.226-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.188,0.551,0.375,0.729c0.188,0.179,0.446,0.268,0.774,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C609.635,250.312,609.287,250.388,608.907,250.388z M607.863,247.052l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.547,0.081-0.762,0.243c-0.217,0.162-0.39,0.38-0.52,0.654C607.988,246.411,607.902,246.715,607.863,247.052z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M614.337,250.388c-0.292,0-0.552-0.062-0.78-0.184c-0.228-0.122-0.398-0.309-0.51-0.561
			c-0.112-0.252-0.152-0.572-0.12-0.96l0.336-3.666l0.246,0.21h-1.056l0.09-0.774h1.044l-0.3,0.271l0.18-1.836h1.056l-0.18,1.836
			l-0.24-0.271h1.8l-0.084,0.774h-1.8l0.294-0.21l-0.342,3.642c-0.024,0.269,0.017,0.468,0.123,0.601
			c0.105,0.132,0.273,0.197,0.501,0.197c0.12,0,0.249-0.017,0.387-0.051c0.139-0.034,0.293-0.085,0.465-0.153l0.072,0.828
			c-0.2,0.093-0.395,0.166-0.585,0.223C614.744,250.36,614.545,250.388,614.337,250.388z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M618.705,250.388c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C619.433,250.312,619.085,250.388,618.705,250.388z M617.661,247.052l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C617.786,246.411,617.701,246.715,617.661,247.052z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M622.478,250.238l0.546-5.796h1.057l-0.108,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.253,0.021c0.088,0.014,0.174,0.039,0.258,0.075l-0.139,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.214-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			c-0.203,0.172-0.364,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H622.478z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M630.638,250.238l0.624-6.654h-2.189l0.102-1.008h5.442l-0.103,1.008h-2.196l-0.624,6.654H630.638z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M635.625,250.388c-0.42,0-0.767-0.087-1.04-0.259c-0.274-0.172-0.473-0.395-0.595-0.669
			c-0.122-0.273-0.167-0.566-0.135-0.879c0.036-0.38,0.153-0.694,0.351-0.944c0.198-0.25,0.46-0.438,0.786-0.564
			s0.693-0.188,1.102-0.188c0.252,0,0.5,0.024,0.744,0.074c0.243,0.051,0.476,0.117,0.695,0.201l0.066-0.66
			c0.052-0.464-0.001-0.802-0.159-1.014s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314
			c-0.108,0.141-0.172,0.314-0.192,0.522h-1.164c0.044-0.44,0.179-0.792,0.402-1.056c0.224-0.265,0.508-0.455,0.852-0.573
			c0.345-0.118,0.713-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54c0.35,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912
			c-0.004-0.147-0.005-0.295-0.003-0.441c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773
			S636.013,250.388,635.625,250.388z M635.883,249.488c0.271,0,0.524-0.066,0.759-0.198s0.425-0.316,0.573-0.555
			c0.147-0.238,0.229-0.515,0.246-0.831c-0.196-0.063-0.406-0.116-0.631-0.156c-0.224-0.04-0.434-0.06-0.63-0.06
			c-0.323,0-0.602,0.066-0.834,0.201c-0.231,0.134-0.363,0.364-0.396,0.692c-0.021,0.256,0.049,0.472,0.207,0.646
			S635.571,249.488,635.883,249.488z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M640.239,250.238l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906
			s0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375
			c-0.44,0-0.807,0.191-1.099,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H640.239z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M646.407,250.238l0.084-0.954l3.246-3.876h-2.886l0.102-0.954h4.062l-0.103,0.954l-3.18,3.876h2.814
			l-0.084,0.954H646.407z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M652.083,250.238l0.546-5.796h1.057l-0.108,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.253,0.021c0.088,0.014,0.174,0.039,0.258,0.075l-0.139,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.214-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			c-0.203,0.172-0.364,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H652.083z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M658.245,250.388c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C658.973,250.312,658.625,250.388,658.245,250.388z M657.201,247.052l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C657.326,246.411,657.241,246.715,657.201,247.052z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M662.102,250.238l0.546-5.796h1.057l-0.547,5.796H662.102z M663.291,243.248
			c-0.208,0-0.365-0.063-0.472-0.191c-0.105-0.128-0.152-0.301-0.141-0.517c0.02-0.204,0.095-0.373,0.225-0.507
			s0.301-0.201,0.514-0.201c0.212,0,0.37,0.065,0.477,0.195s0.151,0.301,0.135,0.513c-0.02,0.221-0.097,0.394-0.23,0.52
			C663.663,243.185,663.494,243.248,663.291,243.248z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M667.202,250.388c-0.443,0-0.829-0.077-1.154-0.231c-0.326-0.154-0.574-0.372-0.744-0.654
			c-0.17-0.281-0.237-0.614-0.201-0.999h0.996c-0.024,0.309,0.066,0.546,0.272,0.711c0.206,0.166,0.483,0.249,0.831,0.249
			c0.353,0,0.638-0.076,0.855-0.23s0.341-0.347,0.369-0.579c0.023-0.204-0.034-0.361-0.174-0.471
			c-0.141-0.11-0.326-0.205-0.559-0.285l-1.032-0.414c-0.399-0.152-0.702-0.36-0.908-0.624s-0.289-0.584-0.249-0.96
			c0.036-0.324,0.15-0.606,0.345-0.846c0.194-0.24,0.454-0.426,0.78-0.556c0.325-0.13,0.696-0.194,1.112-0.194
			c0.664,0,1.158,0.153,1.482,0.462c0.324,0.308,0.472,0.695,0.444,1.164h-1.015c-0.004-0.229-0.09-0.402-0.258-0.522
			s-0.398-0.18-0.69-0.18c-0.235,0-0.431,0.035-0.585,0.104c-0.153,0.07-0.272,0.159-0.356,0.268
			c-0.084,0.107-0.136,0.224-0.156,0.348c-0.02,0.188,0.037,0.343,0.171,0.465s0.312,0.219,0.531,0.291l1.092,0.45
			c0.384,0.14,0.688,0.331,0.909,0.573s0.315,0.551,0.279,0.927c-0.028,0.296-0.142,0.576-0.339,0.84
			c-0.198,0.264-0.47,0.479-0.813,0.646S667.682,250.388,667.202,250.388z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M672.944,250.388c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C673.672,250.312,673.324,250.388,672.944,250.388z M671.9,247.052l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C672.025,246.411,671.94,246.715,671.9,247.052z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M578.263,270.238v-1.3c0-0.363,0.05-0.678,0.15-0.942c0.1-0.266,0.262-0.493,0.484-0.683
			c0.224-0.19,0.519-0.354,0.886-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.56-0.354c0.117-0.137,0.176-0.302,0.176-0.495
			c0-0.177-0.07-0.334-0.211-0.473c-0.14-0.139-0.347-0.207-0.619-0.207c-0.254,0-0.472,0.076-0.655,0.229s-0.288,0.372-0.315,0.655
			h-1.77c-0.004-0.526,0.119-0.958,0.367-1.293s0.579-0.581,0.992-0.739c0.414-0.159,0.863-0.238,1.351-0.238
			c0.474,0,0.904,0.087,1.292,0.261c0.389,0.173,0.698,0.417,0.931,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.186,0.915s-0.321,0.504-0.595,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.326,0.144-0.397,0.212c-0.072,0.068-0.107,0.158-0.107,0.268v0.075h3.77v1.46H578.263z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M587.323,270.398c-0.373,0-0.734-0.05-1.085-0.147c-0.35-0.099-0.664-0.245-0.942-0.44
			c-0.278-0.194-0.499-0.438-0.662-0.728s-0.245-0.626-0.245-1.01h1.705c0.02,0.163,0.08,0.311,0.18,0.442
			c0.101,0.132,0.237,0.236,0.413,0.312c0.175,0.077,0.387,0.115,0.637,0.115c0.341,0,0.612-0.073,0.815-0.22
			c0.203-0.147,0.305-0.334,0.305-0.561c0-0.156-0.04-0.285-0.12-0.385c-0.079-0.1-0.195-0.174-0.347-0.223
			c-0.152-0.048-0.338-0.072-0.558-0.072h-1.021v-1.255h0.995c0.267,0,0.476-0.066,0.625-0.2c0.15-0.134,0.226-0.309,0.226-0.525
			c0-0.196-0.072-0.358-0.216-0.487c-0.143-0.128-0.378-0.192-0.705-0.192c-0.283,0-0.536,0.059-0.76,0.175
			c-0.224,0.117-0.354,0.304-0.395,0.561h-1.7c0.04-0.5,0.182-0.911,0.425-1.232c0.243-0.322,0.566-0.561,0.97-0.716
			c0.403-0.154,0.863-0.232,1.381-0.232c0.526,0,0.994,0.08,1.402,0.24s0.729,0.384,0.965,0.67c0.234,0.287,0.353,0.622,0.353,1.005
			c0,0.33-0.086,0.622-0.258,0.875c-0.172,0.254-0.431,0.438-0.777,0.556c0.387,0.153,0.69,0.364,0.912,0.632
			c0.222,0.269,0.333,0.591,0.333,0.968c0,0.407-0.121,0.767-0.362,1.08c-0.242,0.313-0.577,0.558-1.005,0.732
			C588.378,270.31,587.883,270.398,587.323,270.398z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M590.748,269.308c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			s0.31,0.443,0.31,0.74s-0.103,0.541-0.31,0.732s-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S590.748,269.601,590.748,269.308z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M596.488,270.398c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C597.558,270.271,597.055,270.398,596.488,270.398z M596.488,268.943c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S596.085,268.943,596.488,268.943z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M603.223,270.398c-0.573,0-1.083-0.127-1.527-0.38c-0.445-0.254-0.794-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.251-0.519,0.6-0.903,1.045-1.155
			c0.444-0.252,0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158
			C604.294,270.271,603.79,270.398,603.223,270.398z M603.223,268.943c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532
			s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543
			s0.115,1.188,0.348,1.532C602.503,268.77,602.82,268.943,603.223,268.943z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M607.128,267.813v-1.4h4.695v1.4H607.128z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M615.758,270.398c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C616.829,270.271,616.325,270.398,615.758,270.398z
			 M615.758,268.943c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C615.038,268.77,615.355,268.943,615.758,268.943z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M620.939,263.468h1.16v6.77h-1.601v-4.845c-0.189,0.047-0.367,0.083-0.532,0.109
			c-0.165,0.027-0.331,0.039-0.497,0.035v-1.52c0.243-0.021,0.48-0.072,0.712-0.155S620.666,263.648,620.939,263.468z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M623.129,269.308c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S623.129,269.601,623.129,269.308z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M628.869,270.398c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C629.939,270.271,629.435,270.398,628.869,270.398z
			 M628.869,268.943c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C628.148,268.77,628.465,268.943,628.869,268.943z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M635.604,270.398c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C636.674,270.271,636.171,270.398,635.604,270.398z
			 M635.604,268.943c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C634.883,268.77,635.201,268.943,635.604,268.943z"
                  />
                </g>
                <g>
                  <path
                    fill="#1E1B39"
                    d="M276.557,496.1c-0.721,0-1.383-0.125-1.988-0.375c-0.605-0.251-1.096-0.632-1.472-1.144
			c-0.376-0.512-0.578-1.163-0.604-1.952h1.856c0,0.4,0.099,0.728,0.296,0.984c0.197,0.256,0.463,0.447,0.796,0.572
			s0.7,0.188,1.1,0.188c0.385,0,0.727-0.051,1.028-0.152c0.302-0.102,0.539-0.247,0.712-0.436c0.174-0.189,0.26-0.415,0.26-0.676
			c0-0.347-0.105-0.619-0.315-0.816c-0.211-0.197-0.548-0.378-1.013-0.544l-2.048-0.816c-0.8-0.315-1.413-0.68-1.84-1.096
			s-0.64-0.989-0.64-1.72c0-0.843,0.333-1.525,1-2.048c0.666-0.522,1.597-0.784,2.792-0.784c1.162,0,2.062,0.271,2.696,0.812
			c0.634,0.541,0.994,1.294,1.079,2.26h-1.855c-0.069-0.411-0.256-0.74-0.561-0.988c-0.304-0.248-0.757-0.372-1.359-0.372
			c-0.533,0-0.955,0.111-1.265,0.332c-0.309,0.221-0.464,0.5-0.464,0.836c0,0.288,0.098,0.516,0.292,0.684
			c0.195,0.168,0.498,0.329,0.908,0.484l2.272,0.88c0.768,0.299,1.357,0.675,1.768,1.128c0.411,0.454,0.616,1.021,0.616,1.704
			c0,0.624-0.157,1.164-0.472,1.62c-0.315,0.456-0.773,0.809-1.376,1.06C278.154,495.975,277.421,496.1,276.557,496.1z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M285.542,496.1c-0.667,0-1.224-0.115-1.668-0.348c-0.446-0.232-0.78-0.537-1.005-0.916
			c-0.224-0.378-0.336-0.789-0.336-1.232c0-0.491,0.13-0.903,0.389-1.236c0.259-0.333,0.625-0.584,1.1-0.752
			c0.475-0.168,1.035-0.252,1.68-0.252c0.422,0,0.82,0.032,1.196,0.096s0.751,0.149,1.124,0.256v1.104
			c-0.293-0.096-0.613-0.17-0.96-0.224s-0.672-0.08-0.976-0.08c-0.448,0-0.816,0.083-1.104,0.248
			c-0.288,0.165-0.432,0.445-0.432,0.84c0,0.288,0.117,0.536,0.352,0.744s0.608,0.312,1.12,0.312c0.39,0,0.72-0.085,0.992-0.256
			c0.271-0.17,0.479-0.384,0.62-0.64c0.141-0.256,0.212-0.512,0.212-0.768h0.448c0,0.56-0.104,1.076-0.312,1.548
			s-0.516,0.85-0.924,1.132C286.649,495.96,286.144,496.1,285.542,496.1z M288.086,495.877l-0.24-1.888v-3.12
			c0-0.544-0.111-0.947-0.332-1.208c-0.222-0.261-0.596-0.392-1.124-0.392c-0.304,0-0.568,0.045-0.792,0.136
			c-0.224,0.091-0.396,0.22-0.516,0.388c-0.12,0.168-0.181,0.372-0.181,0.612h-2.063c0-0.586,0.141-1.065,0.424-1.436
			c0.282-0.371,0.689-0.643,1.22-0.816s1.167-0.26,1.908-0.26c0.672,0,1.259,0.108,1.76,0.324c0.501,0.216,0.891,0.548,1.168,0.996
			c0.277,0.448,0.416,1.021,0.416,1.72v4.944H288.086z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M292.501,495.877v-7.792h1.889v7.792H292.501z M293.925,492.468c0-1.019,0.114-1.861,0.344-2.528
			c0.229-0.667,0.537-1.161,0.924-1.484s0.817-0.484,1.292-0.484c0.128,0,0.249,0.008,0.364,0.024
			c0.114,0.016,0.228,0.04,0.34,0.072l-0.032,1.888c-0.139-0.032-0.288-0.059-0.448-0.08c-0.159-0.021-0.304-0.032-0.432-0.032
			c-0.416,0-0.764,0.1-1.044,0.3c-0.28,0.2-0.49,0.495-0.632,0.884c-0.142,0.39-0.212,0.87-0.212,1.44H293.925z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M301.837,496.1c-0.709,0-1.351-0.151-1.924-0.456s-1.028-0.759-1.364-1.364
			c-0.336-0.605-0.504-1.364-0.504-2.276c0-0.928,0.168-1.696,0.504-2.304c0.336-0.608,0.791-1.062,1.364-1.36
			c0.573-0.298,1.215-0.448,1.924-0.448c0.698,0,1.332,0.149,1.9,0.448c0.567,0.299,1.02,0.752,1.355,1.36
			c0.336,0.608,0.504,1.376,0.504,2.304c0,0.912-0.168,1.671-0.504,2.276c-0.336,0.605-0.788,1.06-1.355,1.364
			C303.169,495.949,302.535,496.1,301.837,496.1z M301.773,494.453c0.373,0,0.705-0.092,0.995-0.276
			c0.291-0.184,0.521-0.457,0.688-0.82s0.252-0.813,0.252-1.352c0-0.821-0.161-1.44-0.484-1.856
			c-0.322-0.416-0.764-0.624-1.323-0.624c-0.379,0-0.715,0.092-1.009,0.276c-0.293,0.184-0.523,0.46-0.691,0.828
			s-0.252,0.827-0.252,1.376c0,0.811,0.164,1.421,0.492,1.832C300.768,494.247,301.213,494.453,301.773,494.453z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M306.604,495.877l3.024-3.872l-3.024-3.92h2.32l1.904,2.496l1.808-2.496h2.304l-3.008,3.92l3.008,3.872
			h-2.304l-1.937-2.496l-1.775,2.496H306.604z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M319.091,496.1c-0.666,0-1.223-0.115-1.668-0.348c-0.445-0.232-0.779-0.537-1.004-0.916
			c-0.224-0.378-0.336-0.789-0.336-1.232c0-0.491,0.129-0.903,0.388-1.236c0.259-0.333,0.626-0.584,1.101-0.752
			c0.475-0.168,1.034-0.252,1.68-0.252c0.422,0,0.82,0.032,1.196,0.096s0.75,0.149,1.124,0.256v1.104
			c-0.294-0.096-0.613-0.17-0.96-0.224s-0.672-0.08-0.977-0.08c-0.447,0-0.815,0.083-1.104,0.248
			c-0.288,0.165-0.432,0.445-0.432,0.84c0,0.288,0.117,0.536,0.352,0.744s0.608,0.312,1.12,0.312c0.389,0,0.72-0.085,0.992-0.256
			c0.271-0.17,0.479-0.384,0.62-0.64c0.141-0.256,0.212-0.512,0.212-0.768h0.447c0,0.56-0.104,1.076-0.312,1.548
			s-0.517,0.85-0.924,1.132C320.2,495.96,319.694,496.1,319.091,496.1z M321.635,495.877l-0.239-1.888v-3.12
			c0-0.544-0.111-0.947-0.332-1.208c-0.222-0.261-0.597-0.392-1.124-0.392c-0.305,0-0.568,0.045-0.792,0.136
			c-0.225,0.091-0.396,0.22-0.517,0.388s-0.18,0.372-0.18,0.612h-2.064c0-0.586,0.142-1.065,0.425-1.436
			c0.282-0.371,0.688-0.643,1.22-0.816c0.53-0.173,1.166-0.26,1.908-0.26c0.672,0,1.258,0.108,1.76,0.324
			c0.501,0.216,0.891,0.548,1.168,0.996c0.277,0.448,0.416,1.021,0.416,1.72v4.944H321.635z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M227.957,511.877l0.09-1.032l4.345-5.538l-3.822-0.023l0.108-1.068h5.142l-0.103,1.026l-4.338,5.538
			l3.811,0.023l-0.096,1.074H227.957z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M236.663,512.027c-0.452,0-0.848-0.124-1.188-0.372c-0.341-0.248-0.596-0.6-0.766-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.061-0.632,0.217-1.176,0.472-1.632c0.254-0.456,0.572-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.355,0,0.66,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.614,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.058,0.981c-0.009,0.112-0.021,0.229-0.039,0.351
			c-0.019,0.122-0.041,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.092,0.38-0.249,0.709-0.471,0.987c-0.223,0.277-0.492,0.493-0.811,0.645
			C237.392,511.951,237.043,512.027,236.663,512.027z M235.619,508.691l-0.234-0.168h3.049l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.135-0.494-0.288-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243c-0.217,0.162-0.39,0.38-0.52,0.654C235.744,508.049,235.659,508.354,235.619,508.691z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M240.52,511.877l0.546-5.796h1.057l-0.546,5.796H240.52z M241.709,504.886
			c-0.208,0-0.365-0.063-0.471-0.191c-0.106-0.128-0.153-0.301-0.142-0.517c0.021-0.204,0.095-0.373,0.226-0.507
			c0.13-0.134,0.301-0.201,0.513-0.201s0.371,0.065,0.477,0.195c0.106,0.13,0.151,0.301,0.136,0.513
			c-0.021,0.221-0.098,0.394-0.231,0.52S241.913,504.886,241.709,504.886z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M245.711,512.027c-0.433,0-0.822-0.124-1.17-0.372c-0.349-0.248-0.612-0.601-0.792-1.057
			c-0.181-0.456-0.242-0.996-0.187-1.62c0.061-0.647,0.224-1.196,0.489-1.646s0.595-0.794,0.987-1.032
			c0.392-0.237,0.806-0.356,1.241-0.356c0.309,0,0.579,0.055,0.813,0.165c0.234,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.463,0.073-0.682,0.219c-0.218,0.146-0.402,0.366-0.555,0.66
			s-0.25,0.663-0.294,1.106c-0.036,0.421-0.011,0.779,0.075,1.077s0.224,0.525,0.414,0.682c0.189,0.155,0.417,0.233,0.681,0.233
			c0.34,0,0.61-0.097,0.811-0.291c0.199-0.193,0.315-0.463,0.348-0.807h1.068c-0.032,0.404-0.149,0.769-0.352,1.095
			s-0.472,0.584-0.81,0.774C246.533,511.931,246.146,512.027,245.711,512.027z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M249.46,511.877l0.769-8.202h1.062l-0.33,3.42c0.213-0.372,0.47-0.657,0.771-0.854
			c0.302-0.198,0.639-0.297,1.011-0.297c0.42,0,0.767,0.104,1.038,0.312c0.272,0.208,0.466,0.502,0.582,0.882s0.15,0.83,0.103,1.351
			l-0.318,3.39h-1.05l0.312-3.39c0.052-0.492-0.017-0.864-0.207-1.116c-0.189-0.252-0.457-0.378-0.801-0.378
			c-0.284,0-0.538,0.08-0.762,0.24c-0.225,0.159-0.409,0.395-0.556,0.704c-0.146,0.311-0.247,0.689-0.303,1.138l-0.264,2.802H249.46
			z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M256.084,511.877l0.546-5.784h0.912l0.013,1.062c0.212-0.4,0.472-0.702,0.779-0.906
			c0.309-0.204,0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882s0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.013-0.869-0.207-1.119s-0.459-0.375-0.795-0.375c-0.44,0-0.807,0.191-1.098,0.576
			c-0.293,0.384-0.473,0.934-0.54,1.649l-0.246,2.658H256.084z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M264.568,512.027c-0.452,0-0.848-0.124-1.188-0.372c-0.341-0.248-0.596-0.6-0.766-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.061-0.632,0.217-1.176,0.472-1.632c0.254-0.456,0.572-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.355,0,0.66,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.614,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.058,0.981c-0.009,0.112-0.021,0.229-0.039,0.351
			c-0.019,0.122-0.041,0.247-0.069,0.375H263.2l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.092,0.38-0.249,0.709-0.471,0.987c-0.223,0.277-0.492,0.493-0.811,0.645
			C265.297,511.951,264.948,512.027,264.568,512.027z M263.524,508.691l-0.234-0.168h3.049l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.135-0.494-0.288-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243c-0.217,0.162-0.39,0.38-0.52,0.654C263.649,508.049,263.564,508.354,263.524,508.691z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M268.341,511.877l0.546-5.784h0.912l0.013,1.062c0.212-0.4,0.472-0.702,0.779-0.906
			c0.309-0.204,0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882s0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.013-0.869-0.207-1.119s-0.459-0.375-0.795-0.375c-0.44,0-0.807,0.191-1.098,0.576
			c-0.293,0.384-0.473,0.934-0.54,1.649l-0.246,2.658H268.341z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M277.612,511.877l0.546-5.784h0.912c0.004,0.176,0.006,0.353,0.006,0.528s0.002,0.354,0.006,0.534
			c0.212-0.4,0.472-0.702,0.78-0.906c0.308-0.204,0.651-0.306,1.032-0.306c0.443,0,0.805,0.115,1.083,0.348
			c0.277,0.232,0.469,0.556,0.572,0.972c0.212-0.456,0.483-0.79,0.813-1.002s0.696-0.317,1.101-0.317
			c0.42,0,0.766,0.103,1.035,0.309c0.271,0.206,0.462,0.5,0.576,0.882s0.146,0.833,0.099,1.354l-0.312,3.39h-1.057l0.312-3.39
			c0.044-0.484-0.027-0.854-0.213-1.11c-0.187-0.256-0.455-0.384-0.808-0.384c-0.287,0-0.539,0.084-0.756,0.252
			c-0.216,0.168-0.39,0.412-0.521,0.731c-0.132,0.32-0.225,0.717-0.276,1.188l-0.246,2.712h-1.056l0.312-3.39
			c0.057-0.496-0.013-0.869-0.207-1.119c-0.193-0.25-0.459-0.375-0.795-0.375c-0.439,0-0.806,0.191-1.098,0.576
			c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H277.612z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M289.648,512.027c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.226-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.267,0.519,0.333,0.828c0.065,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.039,0.351
			c-0.018,0.122-0.041,0.247-0.068,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.188,0.551,0.375,0.729c0.188,0.179,0.446,0.268,0.774,0.268c0.28,0,0.523-0.077,0.731-0.231
			c0.208-0.153,0.349-0.381,0.421-0.681h1.079c-0.092,0.38-0.249,0.709-0.471,0.987c-0.222,0.277-0.492,0.493-0.81,0.645
			C290.377,511.951,290.028,512.027,289.648,512.027z M288.604,508.691l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.134-0.494-0.288-0.666c-0.153-0.172-0.389-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243s-0.389,0.38-0.519,0.654C288.729,508.049,288.643,508.354,288.604,508.691z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M295.288,512.027c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.226-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.267,0.519,0.333,0.828c0.065,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.039,0.351
			c-0.018,0.122-0.041,0.247-0.068,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.188,0.551,0.375,0.729c0.188,0.179,0.446,0.268,0.774,0.268c0.28,0,0.523-0.077,0.731-0.231
			c0.208-0.153,0.349-0.381,0.421-0.681h1.079c-0.092,0.38-0.249,0.709-0.471,0.987c-0.222,0.277-0.492,0.493-0.81,0.645
			C296.016,511.951,295.668,512.027,295.288,512.027z M294.244,508.691l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.134-0.494-0.288-0.666c-0.153-0.172-0.389-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243s-0.389,0.38-0.519,0.654C294.369,508.049,294.283,508.354,294.244,508.691z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M300.717,512.027c-0.292,0-0.552-0.062-0.78-0.184c-0.228-0.122-0.398-0.309-0.51-0.561
			c-0.112-0.252-0.152-0.572-0.12-0.96l0.336-3.666l0.246,0.21h-1.056l0.09-0.774h1.044l-0.3,0.271l0.18-1.836h1.056l-0.18,1.836
			l-0.24-0.271h1.8l-0.084,0.774h-1.8l0.294-0.21l-0.342,3.642c-0.024,0.269,0.017,0.468,0.123,0.601
			c0.105,0.132,0.273,0.197,0.501,0.197c0.12,0,0.249-0.017,0.387-0.051c0.139-0.034,0.293-0.085,0.465-0.153l0.072,0.828
			c-0.2,0.093-0.395,0.166-0.585,0.223C301.125,511.999,300.925,512.027,300.717,512.027z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M305.025,512.027c-0.443,0-0.829-0.077-1.154-0.231c-0.326-0.154-0.574-0.372-0.744-0.654
			c-0.17-0.281-0.237-0.614-0.201-0.999h0.996c-0.024,0.309,0.066,0.546,0.272,0.711c0.206,0.166,0.483,0.249,0.831,0.249
			c0.353,0,0.638-0.076,0.855-0.23s0.341-0.347,0.369-0.579c0.023-0.204-0.034-0.361-0.174-0.471
			c-0.141-0.11-0.326-0.205-0.559-0.285l-1.032-0.414c-0.399-0.152-0.702-0.36-0.908-0.624s-0.289-0.584-0.249-0.96
			c0.036-0.324,0.15-0.606,0.345-0.846c0.194-0.24,0.454-0.426,0.78-0.556c0.325-0.13,0.696-0.194,1.112-0.194
			c0.664,0,1.158,0.153,1.482,0.462c0.324,0.308,0.472,0.695,0.444,1.164h-1.015c-0.004-0.229-0.09-0.402-0.258-0.522
			s-0.398-0.18-0.69-0.18c-0.235,0-0.431,0.035-0.585,0.104c-0.153,0.07-0.272,0.159-0.356,0.268
			c-0.084,0.107-0.136,0.224-0.156,0.348c-0.02,0.188,0.037,0.343,0.171,0.465s0.312,0.219,0.531,0.291l1.092,0.45
			c0.384,0.14,0.688,0.331,0.909,0.573s0.315,0.551,0.279,0.927c-0.028,0.296-0.142,0.576-0.339,0.84
			c-0.198,0.264-0.47,0.479-0.813,0.646S305.505,512.027,305.025,512.027z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M312.267,504.214h2.136c0.704,0,1.301,0.157,1.788,0.471c0.488,0.314,0.85,0.759,1.083,1.332
			c0.234,0.574,0.315,1.249,0.243,2.025s-0.28,1.451-0.624,2.025c-0.344,0.573-0.791,1.019-1.341,1.335
			c-0.55,0.315-1.173,0.474-1.869,0.474h-2.136L312.267,504.214z M313.221,505.27l-0.516,5.557h1.073
			c0.484,0,0.911-0.121,1.281-0.363s0.669-0.572,0.897-0.99c0.228-0.418,0.368-0.895,0.42-1.431s0.003-1.013-0.147-1.431
			c-0.149-0.418-0.389-0.746-0.717-0.984s-0.734-0.357-1.218-0.357H313.221z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M318.819,511.877l0.546-5.796h1.057l-0.108,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.253,0.021c0.088,0.014,0.174,0.039,0.258,0.075l-0.139,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.214-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			c-0.203,0.172-0.364,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H318.819z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M324.674,512.027c-0.42,0-0.767-0.087-1.04-0.259c-0.274-0.172-0.473-0.395-0.595-0.669
			c-0.122-0.273-0.167-0.566-0.135-0.879c0.036-0.38,0.153-0.694,0.351-0.944c0.198-0.25,0.46-0.438,0.786-0.564
			s0.693-0.188,1.102-0.188c0.252,0,0.5,0.024,0.744,0.074c0.243,0.051,0.476,0.117,0.695,0.201l0.066-0.66
			c0.052-0.464-0.001-0.802-0.159-1.014s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314
			c-0.108,0.141-0.172,0.314-0.192,0.522h-1.164c0.044-0.44,0.179-0.792,0.402-1.056c0.224-0.265,0.508-0.455,0.852-0.573
			c0.345-0.118,0.713-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54c0.35,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912
			c-0.004-0.147-0.005-0.295-0.003-0.441c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773
			S325.063,512.027,324.674,512.027z M324.933,511.127c0.271,0,0.524-0.066,0.759-0.198s0.425-0.316,0.573-0.555
			c0.147-0.238,0.229-0.515,0.246-0.831c-0.196-0.063-0.406-0.116-0.631-0.156c-0.224-0.04-0.434-0.06-0.63-0.06
			c-0.323,0-0.602,0.066-0.834,0.201c-0.231,0.134-0.363,0.364-0.396,0.692c-0.021,0.256,0.049,0.472,0.207,0.646
			S324.621,511.127,324.933,511.127z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M330.891,514.859c-0.536,0-0.999-0.084-1.39-0.252c-0.39-0.169-0.685-0.396-0.885-0.685
			s-0.284-0.61-0.252-0.966c0.032-0.332,0.155-0.611,0.369-0.837c0.214-0.227,0.48-0.373,0.801-0.441
			c-0.196-0.128-0.34-0.28-0.432-0.459c-0.093-0.178-0.131-0.373-0.114-0.585c0.023-0.275,0.126-0.509,0.306-0.699
			c0.181-0.189,0.402-0.32,0.666-0.393c-0.168-0.188-0.294-0.408-0.378-0.66s-0.112-0.534-0.084-0.846
			c0.044-0.424,0.178-0.793,0.402-1.107c0.224-0.313,0.508-0.557,0.852-0.729s0.714-0.258,1.11-0.258
			c0.252,0,0.485,0.033,0.702,0.102c0.216,0.068,0.405,0.166,0.569,0.294c0.061-0.296,0.169-0.553,0.327-0.771
			c0.158-0.219,0.354-0.389,0.585-0.511c0.232-0.122,0.486-0.183,0.763-0.183l-0.187,1.14c-0.304,0-0.545,0.062-0.723,0.187
			c-0.179,0.124-0.285,0.322-0.321,0.594c0.104,0.168,0.181,0.356,0.231,0.564c0.05,0.208,0.064,0.434,0.045,0.678
			c-0.036,0.416-0.168,0.782-0.396,1.098c-0.228,0.316-0.518,0.562-0.87,0.738c-0.352,0.176-0.737,0.264-1.158,0.264
			c-0.164,0-0.328-0.02-0.494-0.06s-0.321-0.097-0.466-0.168c-0.132,0.032-0.252,0.102-0.359,0.21
			c-0.108,0.107-0.174,0.25-0.198,0.426c-0.021,0.176,0.031,0.319,0.153,0.429c0.122,0.11,0.349,0.165,0.681,0.165h1.884
			c0.517,0,0.913,0.112,1.191,0.336c0.277,0.225,0.398,0.559,0.363,1.002c-0.032,0.4-0.188,0.782-0.469,1.146
			c-0.279,0.364-0.661,0.661-1.143,0.891C332.091,514.744,331.531,514.859,330.891,514.859z M330.933,513.922
			c0.428,0,0.803-0.065,1.125-0.198c0.321-0.132,0.575-0.302,0.762-0.51c0.186-0.208,0.287-0.428,0.303-0.66
			c0.012-0.18-0.03-0.321-0.126-0.426s-0.244-0.156-0.444-0.156h-1.974c-0.34,0-0.614,0.09-0.822,0.271
			c-0.208,0.18-0.319,0.399-0.336,0.66c-0.016,0.304,0.11,0.55,0.378,0.737C330.067,513.829,330.445,513.922,330.933,513.922z
			 M331.563,509.302c0.248,0,0.461-0.056,0.639-0.168c0.178-0.111,0.319-0.264,0.423-0.456c0.104-0.191,0.166-0.405,0.187-0.642
			c0.035-0.376-0.035-0.682-0.214-0.915c-0.178-0.234-0.442-0.352-0.795-0.352c-0.363,0-0.656,0.118-0.879,0.354
			c-0.222,0.236-0.349,0.54-0.381,0.912c-0.032,0.359,0.039,0.661,0.213,0.903C330.929,509.181,331.199,509.302,331.563,509.302z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M342.56,513.959c-0.336,0-0.636-0.099-0.899-0.297s-0.49-0.479-0.678-0.843l-0.408-0.792
			c-0.58-0.013-1.08-0.168-1.5-0.469c-0.42-0.3-0.729-0.741-0.927-1.325c-0.198-0.584-0.256-1.314-0.172-2.19
			c0.084-0.892,0.284-1.633,0.601-2.223c0.315-0.59,0.717-1.029,1.203-1.317c0.485-0.288,1.024-0.432,1.617-0.432
			c0.596,0,1.11,0.146,1.545,0.44c0.434,0.294,0.754,0.736,0.96,1.326s0.269,1.325,0.188,2.205c-0.107,1.088-0.384,1.95-0.828,2.586
			c-0.443,0.636-0.992,1.054-1.644,1.254l0.216,0.42c0.1,0.172,0.211,0.293,0.333,0.363s0.249,0.108,0.381,0.117
			c0.185,0,0.361-0.026,0.531-0.078s0.351-0.122,0.543-0.21l-0.12,1.235c-0.172,0.085-0.334,0.144-0.486,0.178
			C342.865,513.941,342.713,513.959,342.56,513.959z M340.749,510.959c0.6,0,1.101-0.249,1.503-0.747s0.649-1.223,0.741-2.175
			c0.092-0.924-0.018-1.64-0.327-2.148c-0.31-0.508-0.765-0.762-1.365-0.762c-0.604,0-1.104,0.254-1.503,0.762
			c-0.397,0.509-0.641,1.227-0.729,2.154c-0.093,0.952,0.015,1.676,0.32,2.172C339.696,510.711,340.149,510.959,340.749,510.959z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M347.174,511.985c-0.38,0-0.711-0.077-0.993-0.231s-0.493-0.395-0.633-0.723s-0.187-0.75-0.138-1.267
			l0.354-3.684h1.056l-0.342,3.576c-0.04,0.432,0.024,0.757,0.192,0.975s0.426,0.327,0.773,0.327c0.288,0,0.552-0.085,0.789-0.255
			c0.238-0.17,0.437-0.411,0.595-0.724c0.157-0.312,0.257-0.682,0.297-1.109l0.264-2.79h1.056l-0.54,5.796h-1.062l0.102-1.092
			c-0.203,0.392-0.458,0.689-0.762,0.894S347.543,511.985,347.174,511.985z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M353.865,512.027c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C354.593,511.951,354.245,512.027,353.865,512.027z M352.821,508.691l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C352.946,508.049,352.861,508.354,352.821,508.691z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M359.505,512.027c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C360.233,511.951,359.884,512.027,359.505,512.027z M358.461,508.691l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C358.586,508.049,358.501,508.354,358.461,508.691z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M363.278,511.877l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906
			s0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375
			c-0.44,0-0.807,0.191-1.099,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H363.278z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M270.854,532.037c-0.573,0-1.083-0.127-1.527-0.38c-0.445-0.254-0.794-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.251-0.519,0.6-0.903,1.045-1.155
			c0.444-0.252,0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158
			C271.924,531.91,271.421,532.037,270.854,532.037z M270.854,530.582c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532
			s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543
			s0.115,1.188,0.348,1.532C270.133,530.409,270.451,530.582,270.854,530.582z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M276.034,525.107h1.16v6.77h-1.601v-4.845c-0.189,0.047-0.367,0.083-0.532,0.109
			c-0.165,0.027-0.331,0.039-0.497,0.035v-1.52c0.243-0.021,0.48-0.072,0.712-0.155C275.508,525.419,275.76,525.287,276.034,525.107
			z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M278.223,530.947c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			s0.31,0.443,0.31,0.74s-0.103,0.541-0.31,0.732s-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29S278.223,531.24,278.223,530.947
			z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M283.883,532.037c-0.373,0-0.734-0.05-1.085-0.147c-0.35-0.099-0.664-0.245-0.942-0.44
			c-0.278-0.194-0.499-0.438-0.662-0.728s-0.245-0.626-0.245-1.01h1.705c0.02,0.163,0.08,0.311,0.18,0.442
			c0.101,0.132,0.237,0.236,0.413,0.312c0.175,0.077,0.387,0.115,0.637,0.115c0.341,0,0.612-0.073,0.815-0.22
			c0.203-0.147,0.305-0.334,0.305-0.561c0-0.156-0.04-0.285-0.12-0.385c-0.079-0.1-0.195-0.174-0.347-0.223
			c-0.152-0.048-0.338-0.072-0.558-0.072h-1.021v-1.255h0.995c0.267,0,0.476-0.066,0.625-0.2c0.15-0.134,0.226-0.309,0.226-0.525
			c0-0.196-0.072-0.358-0.216-0.487c-0.143-0.128-0.378-0.192-0.705-0.192c-0.283,0-0.536,0.059-0.76,0.175
			c-0.224,0.117-0.354,0.304-0.395,0.561h-1.7c0.04-0.5,0.182-0.911,0.425-1.232c0.243-0.322,0.566-0.561,0.97-0.716
			c0.403-0.154,0.863-0.232,1.381-0.232c0.526,0,0.994,0.08,1.402,0.24s0.729,0.384,0.965,0.67c0.234,0.287,0.353,0.622,0.353,1.005
			c0,0.33-0.086,0.622-0.258,0.875c-0.172,0.254-0.431,0.438-0.777,0.556c0.387,0.153,0.69,0.364,0.912,0.632
			c0.222,0.269,0.333,0.591,0.333,0.968c0,0.407-0.121,0.767-0.362,1.08c-0.242,0.313-0.577,0.558-1.005,0.732
			C284.938,531.949,284.444,532.037,283.883,532.037z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M290.513,532.037c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C291.584,531.91,291.081,532.037,290.513,532.037z M290.513,530.582c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S290.11,530.582,290.513,530.582z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M294.419,529.452v-1.4h4.695v1.4H294.419z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M303.048,532.037c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C304.119,531.91,303.615,532.037,303.048,532.037z
			 M303.048,530.582c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C302.328,530.409,302.645,530.582,303.048,530.582z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M306.964,531.877v-1.3c0-0.363,0.051-0.678,0.15-0.942c0.1-0.266,0.262-0.493,0.485-0.683
			c0.223-0.19,0.518-0.354,0.885-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.561-0.354c0.116-0.137,0.175-0.302,0.175-0.495
			c0-0.177-0.07-0.334-0.21-0.473c-0.141-0.139-0.347-0.207-0.62-0.207c-0.254,0-0.472,0.076-0.655,0.229s-0.288,0.372-0.314,0.655
			h-1.771c-0.003-0.526,0.119-0.958,0.367-1.293c0.249-0.335,0.579-0.581,0.993-0.739c0.413-0.159,0.863-0.238,1.35-0.238
			c0.474,0,0.904,0.087,1.293,0.261c0.388,0.173,0.698,0.417,0.93,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.186,0.915c-0.123,0.267-0.321,0.504-0.595,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.326,0.144-0.397,0.212c-0.072,0.068-0.107,0.158-0.107,0.268v0.075h3.77v1.46H306.964z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M312.899,530.947c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S312.899,531.24,312.899,530.947z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M318.559,532.037c-0.373,0-0.734-0.05-1.085-0.147c-0.35-0.099-0.664-0.245-0.942-0.44
			c-0.278-0.194-0.499-0.438-0.662-0.728c-0.164-0.29-0.245-0.626-0.245-1.01h1.705c0.02,0.163,0.08,0.311,0.18,0.442
			s0.237,0.236,0.412,0.312c0.176,0.077,0.388,0.115,0.638,0.115c0.34,0,0.612-0.073,0.815-0.22
			c0.203-0.147,0.305-0.334,0.305-0.561c0-0.156-0.04-0.285-0.12-0.385s-0.195-0.174-0.348-0.223
			c-0.151-0.048-0.337-0.072-0.558-0.072h-1.02v-1.255h0.995c0.267,0,0.475-0.066,0.625-0.2c0.149-0.134,0.225-0.309,0.225-0.525
			c0-0.196-0.071-0.358-0.215-0.487c-0.144-0.128-0.378-0.192-0.705-0.192c-0.283,0-0.536,0.059-0.76,0.175
			c-0.224,0.117-0.355,0.304-0.396,0.561h-1.699c0.04-0.5,0.182-0.911,0.425-1.232c0.243-0.322,0.566-0.561,0.97-0.716
			c0.403-0.154,0.863-0.232,1.38-0.232c0.527,0,0.994,0.08,1.402,0.24c0.409,0.16,0.73,0.384,0.966,0.67
			c0.234,0.287,0.353,0.622,0.353,1.005c0,0.33-0.086,0.622-0.258,0.875c-0.172,0.254-0.431,0.438-0.777,0.556
			c0.387,0.153,0.69,0.364,0.912,0.632c0.222,0.269,0.333,0.591,0.333,0.968c0,0.407-0.121,0.767-0.363,1.08
			c-0.241,0.313-0.576,0.558-1.005,0.732C319.614,531.949,319.12,532.037,318.559,532.037z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M325.189,532.037c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C326.259,531.91,325.755,532.037,325.189,532.037z
			 M325.189,530.582c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C324.468,530.409,324.786,530.582,325.189,530.582z"
                  />
                </g>
                <g>
                  <path
                    fill="#1E1B39"
                    d="M885.687,553.174v-9.086h5.727v1.428h-4.074v2.394h3.725v1.414h-3.725v3.85H885.687z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M895.424,553.37c-0.578,0-1.108-0.149-1.589-0.448s-0.865-0.718-1.155-1.256
			c-0.289-0.539-0.434-1.17-0.434-1.894c0-0.747,0.144-1.389,0.431-1.925s0.671-0.947,1.151-1.232s1.013-0.427,1.596-0.427
			c0.542,0,1.01,0.108,1.403,0.325c0.395,0.217,0.712,0.511,0.952,0.882c0.24,0.371,0.403,0.79,0.487,1.256s0.088,0.947,0.014,1.442
			h-4.718v-0.896h3.549l-0.322,0.21c0.014-0.219,0.004-0.441-0.031-0.665c-0.035-0.224-0.102-0.429-0.199-0.616
			c-0.099-0.187-0.237-0.337-0.417-0.452s-0.409-0.171-0.689-0.171c-0.336,0-0.621,0.084-0.854,0.252s-0.41,0.396-0.531,0.686
			c-0.122,0.29-0.183,0.616-0.183,0.98v0.658c0,0.383,0.058,0.721,0.172,1.015s0.284,0.525,0.511,0.693
			c0.227,0.168,0.513,0.252,0.857,0.252c0.322,0,0.593-0.075,0.812-0.224c0.219-0.149,0.359-0.374,0.42-0.672h1.666
			c-0.07,0.438-0.234,0.825-0.493,1.158c-0.26,0.334-0.595,0.595-1.005,0.784C896.414,553.275,895.947,553.37,895.424,553.37z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M900.171,546.356h1.652v4.172c0,0.443,0.109,0.776,0.329,0.998c0.219,0.222,0.52,0.333,0.902,0.333
			c0.327,0,0.624-0.085,0.893-0.255s0.483-0.422,0.645-0.756c0.161-0.333,0.241-0.748,0.241-1.242h0.448
			c0,0.831-0.107,1.519-0.322,2.065s-0.511,0.954-0.889,1.225c-0.378,0.271-0.814,0.406-1.31,0.406
			c-0.485,0-0.924-0.086-1.315-0.259c-0.393-0.173-0.703-0.451-0.932-0.833c-0.229-0.383-0.343-0.887-0.343-1.512V546.356z
			 M904.833,546.356h1.652v6.818h-1.652V546.356z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M911.623,553.37c-0.579,0-1.108-0.149-1.589-0.448c-0.481-0.299-0.866-0.718-1.155-1.256
			c-0.289-0.539-0.434-1.17-0.434-1.894c0-0.747,0.143-1.389,0.43-1.925s0.671-0.947,1.152-1.232
			c0.48-0.285,1.012-0.427,1.596-0.427c0.541,0,1.009,0.108,1.403,0.325c0.395,0.217,0.712,0.511,0.952,0.882
			c0.24,0.371,0.402,0.79,0.486,1.256s0.089,0.947,0.014,1.442h-4.718v-0.896h3.549l-0.321,0.21
			c0.014-0.219,0.003-0.441-0.032-0.665c-0.035-0.224-0.102-0.429-0.199-0.616s-0.237-0.337-0.416-0.452
			c-0.18-0.114-0.41-0.171-0.69-0.171c-0.336,0-0.62,0.084-0.854,0.252s-0.411,0.396-0.532,0.686s-0.182,0.616-0.182,0.98v0.658
			c0,0.383,0.057,0.721,0.171,1.015c0.115,0.294,0.285,0.525,0.512,0.693c0.226,0.168,0.512,0.252,0.857,0.252
			c0.322,0,0.593-0.075,0.812-0.224c0.22-0.149,0.359-0.374,0.42-0.672h1.666c-0.069,0.438-0.234,0.825-0.493,1.158
			c-0.259,0.334-0.594,0.595-1.005,0.784C912.612,553.275,912.145,553.37,911.623,553.37z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M916.508,553.174v-6.818h1.652v6.818H916.508z M917.755,550.192c0-0.892,0.101-1.628,0.301-2.212
			c0.201-0.583,0.471-1.016,0.809-1.299c0.338-0.282,0.716-0.423,1.131-0.423c0.112,0,0.218,0.007,0.318,0.021
			s0.199,0.035,0.298,0.063l-0.028,1.652c-0.121-0.028-0.252-0.051-0.392-0.07c-0.141-0.019-0.267-0.028-0.378-0.028
			c-0.364,0-0.669,0.087-0.914,0.262c-0.245,0.175-0.43,0.433-0.553,0.773c-0.124,0.341-0.186,0.761-0.186,1.26H917.755z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M924.537,553.37c-0.653,0-1.215-0.09-1.684-0.27c-0.469-0.179-0.829-0.438-1.081-0.777
			s-0.381-0.746-0.386-1.221h1.526c0.014,0.336,0.147,0.581,0.399,0.735c0.252,0.154,0.595,0.231,1.028,0.231
			c0.238,0,0.449-0.035,0.634-0.105s0.328-0.166,0.431-0.287c0.103-0.121,0.154-0.256,0.154-0.406c0-0.191-0.069-0.342-0.207-0.452
			s-0.358-0.211-0.661-0.305l-1.484-0.532c-0.532-0.191-0.94-0.443-1.225-0.756c-0.285-0.312-0.428-0.686-0.428-1.12
			c0-0.574,0.261-1.037,0.781-1.39c0.521-0.352,1.217-0.528,2.089-0.528c0.868,0,1.539,0.19,2.013,0.57s0.718,0.862,0.731,1.445
			h-1.554c-0.023-0.224-0.131-0.401-0.322-0.532s-0.48-0.196-0.868-0.196c-0.35,0-0.624,0.071-0.822,0.214
			c-0.198,0.142-0.298,0.309-0.298,0.5c0,0.322,0.275,0.564,0.826,0.728l1.61,0.602c0.522,0.177,0.929,0.406,1.218,0.686
			c0.289,0.28,0.435,0.634,0.435,1.064c0,0.359-0.12,0.699-0.361,1.018c-0.24,0.32-0.575,0.58-1.004,0.781
			C925.598,553.27,925.101,553.37,924.537,553.37z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M929.387,556.492v-10.108h1.358l0.294,1.68v8.428H929.387z M932.79,553.37
			c-0.499,0-0.924-0.107-1.274-0.322c-0.35-0.215-0.617-0.54-0.801-0.977c-0.185-0.436-0.277-0.983-0.277-1.642h0.603
			c0,0.304,0.07,0.562,0.21,0.777c0.14,0.214,0.325,0.379,0.557,0.493c0.23,0.115,0.479,0.172,0.745,0.172
			c0.275,0,0.547-0.076,0.815-0.228c0.269-0.152,0.491-0.383,0.669-0.693c0.177-0.31,0.266-0.703,0.266-1.179
			c0-0.434-0.074-0.809-0.224-1.124s-0.354-0.557-0.612-0.725c-0.26-0.168-0.555-0.252-0.886-0.252c-0.28,0-0.537,0.063-0.771,0.189
			s-0.42,0.309-0.56,0.549c-0.14,0.241-0.21,0.531-0.21,0.872h-0.603c0-0.714,0.11-1.298,0.329-1.753
			c0.22-0.455,0.514-0.792,0.883-1.012c0.368-0.219,0.771-0.329,1.211-0.329c0.536,0,1.041,0.142,1.515,0.427
			s0.858,0.694,1.152,1.229c0.294,0.535,0.44,1.177,0.44,1.929c0,0.756-0.149,1.402-0.448,1.939
			c-0.298,0.537-0.689,0.947-1.172,1.232C933.864,553.228,933.345,553.37,932.79,553.37z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M938.893,545.138c-0.321,0-0.572-0.085-0.752-0.255s-0.27-0.407-0.27-0.71c0-0.294,0.09-0.528,0.27-0.704
			c0.18-0.175,0.431-0.262,0.752-0.262c0.317,0,0.567,0.087,0.749,0.262c0.183,0.175,0.273,0.41,0.273,0.704
			c0,0.303-0.091,0.54-0.273,0.71C939.461,545.053,939.211,545.138,938.893,545.138z M938.068,553.174v-6.818h1.651v6.818H938.068z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M945.053,553.37c-0.579,0-1.108-0.149-1.589-0.448s-0.866-0.718-1.155-1.256
			c-0.289-0.539-0.434-1.17-0.434-1.894c0-0.747,0.144-1.389,0.431-1.925s0.671-0.947,1.151-1.232s1.013-0.427,1.596-0.427
			c0.541,0,1.009,0.108,1.403,0.325c0.395,0.217,0.712,0.511,0.952,0.882c0.24,0.371,0.402,0.79,0.486,1.256
			s0.089,0.947,0.015,1.442h-4.719v-0.896h3.55l-0.322,0.21c0.014-0.219,0.003-0.441-0.031-0.665
			c-0.035-0.224-0.102-0.429-0.2-0.616c-0.098-0.187-0.236-0.337-0.416-0.452s-0.41-0.171-0.689-0.171
			c-0.336,0-0.621,0.084-0.854,0.252s-0.41,0.396-0.532,0.686c-0.121,0.29-0.182,0.616-0.182,0.98v0.658
			c0,0.383,0.058,0.721,0.172,1.015s0.284,0.525,0.511,0.693c0.227,0.168,0.512,0.252,0.857,0.252c0.322,0,0.593-0.075,0.812-0.224
			c0.219-0.149,0.358-0.374,0.42-0.672h1.666c-0.07,0.438-0.235,0.825-0.494,1.158c-0.259,0.334-0.594,0.595-1.004,0.784
			C946.043,553.275,945.576,553.37,945.053,553.37z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M949.939,553.174v-9.604h1.652v9.604H949.939z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M897.514,561.512h1.182l1.015,6.096l2.105-5.447h0.714l1.104,5.472l2.364-6.12h1.182l-3.126,7.662h-1.116
			l-0.96-4.614l-1.86,4.614h-1.104L897.514,561.512z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M908.884,569.324c-0.42,0-0.768-0.087-1.041-0.259c-0.274-0.172-0.473-0.395-0.594-0.669
			c-0.122-0.273-0.168-0.566-0.136-0.879c0.036-0.38,0.153-0.694,0.352-0.944c0.197-0.25,0.46-0.438,0.786-0.564
			c0.325-0.126,0.692-0.188,1.101-0.188c0.252,0,0.5,0.024,0.744,0.074c0.244,0.051,0.476,0.117,0.696,0.201l0.065-0.66
			c0.052-0.464-0.001-0.802-0.159-1.014s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314
			c-0.107,0.141-0.172,0.314-0.191,0.522h-1.164c0.044-0.44,0.178-0.792,0.401-1.056c0.225-0.265,0.508-0.455,0.853-0.573
			c0.344-0.118,0.712-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54c0.351,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912
			c-0.004-0.147-0.005-0.295-0.003-0.441c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773
			S909.272,569.324,908.884,569.324z M909.142,568.424c0.271,0,0.525-0.066,0.759-0.198c0.234-0.132,0.425-0.316,0.573-0.555
			c0.147-0.238,0.229-0.515,0.246-0.831c-0.196-0.063-0.406-0.116-0.63-0.156c-0.225-0.04-0.435-0.06-0.63-0.06
			c-0.324,0-0.603,0.066-0.834,0.201c-0.232,0.134-0.364,0.364-0.396,0.692c-0.02,0.256,0.049,0.472,0.207,0.646
			S908.83,568.424,909.142,568.424z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M913.504,569.174l0.769-8.202h1.062l-0.773,8.202H913.504z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M920.278,569.174l-1.656-2.946l-0.689,0.582l-0.223,2.364h-1.056l0.768-8.202h1.062l-0.432,4.596
			l2.496-2.178h1.452l-2.557,2.154l2.13,3.63H920.278z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M924.664,569.174l3.084-7.662h1.309l1.571,7.662h-1.115l-0.372-1.806h-2.646l-0.714,1.806H924.664z
			 M926.848,566.468h2.117l-0.714-3.552L926.848,566.468z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M933.82,569.324c-0.433,0-0.822-0.124-1.171-0.372c-0.348-0.248-0.611-0.601-0.792-1.057
			c-0.18-0.456-0.241-0.996-0.186-1.62c0.06-0.647,0.223-1.196,0.489-1.646c0.266-0.45,0.595-0.794,0.986-1.032
			c0.393-0.237,0.807-0.356,1.242-0.356c0.309,0,0.579,0.055,0.813,0.165c0.233,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.464,0.073-0.682,0.219c-0.218,0.146-0.403,0.366-0.555,0.66
			c-0.152,0.294-0.25,0.663-0.294,1.106c-0.036,0.421-0.012,0.779,0.075,1.077c0.086,0.298,0.224,0.525,0.414,0.682
			c0.189,0.155,0.417,0.233,0.681,0.233c0.34,0,0.609-0.097,0.81-0.291c0.2-0.193,0.316-0.463,0.349-0.807h1.067
			c-0.032,0.404-0.148,0.769-0.351,1.095s-0.472,0.584-0.811,0.774C934.642,569.229,934.255,569.324,933.82,569.324z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M939.219,569.324c-0.292,0-0.552-0.062-0.78-0.184c-0.228-0.122-0.398-0.309-0.51-0.561
			c-0.112-0.252-0.152-0.572-0.12-0.96l0.336-3.666l0.246,0.21h-1.056l0.09-0.774h1.044l-0.3,0.271l0.18-1.836h1.056l-0.18,1.836
			l-0.24-0.271h1.8l-0.084,0.774h-1.8l0.294-0.21l-0.342,3.642c-0.024,0.269,0.017,0.468,0.123,0.601
			c0.105,0.132,0.273,0.197,0.501,0.197c0.12,0,0.249-0.017,0.387-0.051c0.139-0.034,0.293-0.085,0.465-0.153l0.072,0.828
			c-0.2,0.093-0.395,0.166-0.585,0.223C939.627,569.296,939.427,569.324,939.219,569.324z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M884.294,589.174l2.37-3.345l-2.37-3.365h1.984l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.57,3.375h-2.025
			l-1.595-2.1l-1.355,2.1H884.294z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M891.568,589.174l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.93l-2.2,3.335l2.57,3.375h-2.024
			l-1.596-2.1l-1.354,2.1H891.568z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M898.833,588.244c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			s0.31,0.443,0.31,0.74s-0.103,0.541-0.31,0.732s-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S898.833,588.537,898.833,588.244z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M901.379,589.174l2.37-3.345l-2.37-3.365h1.984l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.57,3.375h-2.025
			l-1.595-2.1l-1.355,2.1H901.379z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M908.653,589.174l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.93l-2.2,3.335l2.57,3.375h-2.024
			l-1.596-2.1l-1.354,2.1H908.653z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M916.293,586.749v-1.4h4.696v1.4H916.293z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M921.728,589.174l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H921.728z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M929.004,589.174l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H929.004z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M936.269,588.244c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S936.269,588.537,936.269,588.244z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M938.814,589.174l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H938.814z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M946.089,589.174l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H946.089z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M902.711,152.091v-9.086h3.669c0.699,0,1.28,0.108,1.742,0.325c0.462,0.217,0.808,0.508,1.036,0.872
			c0.229,0.364,0.343,0.768,0.343,1.211c0,0.514-0.143,0.952-0.43,1.316c-0.287,0.364-0.689,0.621-1.208,0.77
			c0.341,0.07,0.646,0.198,0.917,0.385s0.484,0.422,0.641,0.707s0.234,0.611,0.234,0.98c0,0.29-0.05,0.581-0.15,0.875
			s-0.273,0.566-0.518,0.815c-0.245,0.25-0.579,0.451-1.001,0.602c-0.423,0.152-0.953,0.228-1.593,0.228H902.711z M904.363,144.433
			v2.408h1.946c0.49,0,0.862-0.108,1.116-0.326c0.255-0.217,0.382-0.514,0.382-0.892c0-0.359-0.125-0.648-0.375-0.865
			c-0.249-0.217-0.619-0.326-1.109-0.326H904.363z M904.363,150.649h1.988c0.527,0,0.913-0.106,1.158-0.319
			c0.245-0.212,0.367-0.498,0.367-0.857s-0.126-0.647-0.378-0.864c-0.252-0.217-0.635-0.326-1.147-0.326h-1.988V150.649z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M914.457,152.287c-0.62,0-1.182-0.133-1.684-0.399c-0.501-0.266-0.899-0.664-1.193-1.193
			s-0.44-1.193-0.44-1.992c0-0.812,0.146-1.484,0.44-2.016c0.294-0.532,0.692-0.929,1.193-1.19c0.502-0.261,1.063-0.392,1.684-0.392
			c0.611,0,1.166,0.13,1.663,0.392c0.497,0.262,0.892,0.658,1.187,1.19c0.294,0.532,0.44,1.204,0.44,2.016
			c0,0.798-0.146,1.462-0.44,1.992c-0.295,0.53-0.689,0.927-1.187,1.193C915.623,152.154,915.068,152.287,914.457,152.287z
			 M914.401,150.845c0.326,0,0.617-0.081,0.871-0.241c0.255-0.161,0.455-0.4,0.603-0.718c0.146-0.317,0.22-0.711,0.22-1.183
			c0-0.718-0.141-1.26-0.423-1.624c-0.282-0.364-0.669-0.546-1.158-0.546c-0.332,0-0.626,0.081-0.883,0.242
			s-0.458,0.402-0.605,0.725c-0.146,0.322-0.22,0.723-0.22,1.204c0,0.709,0.143,1.244,0.43,1.603S913.911,150.845,914.401,150.845z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M919.707,152.091v-6.818h1.652v6.818H919.707z M920.953,149.109c0-0.892,0.101-1.628,0.301-2.212
			c0.201-0.583,0.471-1.016,0.809-1.299c0.339-0.282,0.716-0.423,1.131-0.423c0.112,0,0.218,0.007,0.318,0.021
			s0.199,0.035,0.298,0.063l-0.028,1.652c-0.121-0.028-0.252-0.051-0.392-0.07c-0.141-0.019-0.267-0.028-0.378-0.028
			c-0.364,0-0.669,0.087-0.914,0.262c-0.245,0.175-0.43,0.433-0.553,0.773c-0.124,0.341-0.186,0.761-0.186,1.26H920.953z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M926.168,144.055c-0.321,0-0.572-0.085-0.752-0.255s-0.27-0.407-0.27-0.71c0-0.294,0.09-0.528,0.27-0.704
			c0.18-0.175,0.431-0.262,0.752-0.262c0.317,0,0.567,0.087,0.749,0.262c0.183,0.175,0.273,0.41,0.273,0.704
			c0,0.303-0.091,0.54-0.273,0.71C926.735,143.97,926.485,144.055,926.168,144.055z M925.342,152.091v-6.818h1.651v6.818H925.342z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M932.328,152.287c-0.653,0-1.215-0.09-1.684-0.27c-0.469-0.179-0.829-0.438-1.081-0.777
			s-0.381-0.746-0.386-1.221h1.526c0.014,0.336,0.147,0.581,0.399,0.735c0.252,0.154,0.595,0.231,1.028,0.231
			c0.238,0,0.449-0.035,0.634-0.105s0.328-0.166,0.431-0.287c0.103-0.121,0.154-0.256,0.154-0.406c0-0.191-0.069-0.342-0.207-0.452
			s-0.358-0.211-0.661-0.305l-1.484-0.532c-0.532-0.191-0.94-0.443-1.225-0.756c-0.285-0.312-0.428-0.686-0.428-1.12
			c0-0.574,0.261-1.037,0.781-1.39c0.521-0.352,1.217-0.528,2.089-0.528c0.868,0,1.539,0.19,2.013,0.57s0.718,0.862,0.731,1.445
			h-1.554c-0.023-0.224-0.131-0.401-0.322-0.532s-0.48-0.196-0.868-0.196c-0.35,0-0.624,0.071-0.822,0.214
			c-0.198,0.142-0.298,0.309-0.298,0.5c0,0.322,0.275,0.564,0.826,0.728l1.61,0.602c0.522,0.177,0.929,0.406,1.218,0.686
			c0.289,0.28,0.435,0.634,0.435,1.064c0,0.359-0.12,0.699-0.361,1.018c-0.24,0.32-0.575,0.58-1.004,0.781
			C933.389,152.187,932.892,152.287,932.328,152.287z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M875.984,168.241c-0.469,0-0.891-0.093-1.267-0.276s-0.667-0.461-0.873-0.831s-0.291-0.833-0.255-1.389
			h1.044c-0.032,0.323,0.015,0.591,0.139,0.801c0.123,0.21,0.3,0.365,0.527,0.465c0.229,0.101,0.484,0.15,0.769,0.15
			c0.296,0,0.56-0.048,0.792-0.145c0.231-0.096,0.418-0.226,0.558-0.39s0.22-0.353,0.24-0.564c0.027-0.288-0.028-0.518-0.168-0.689
			c-0.141-0.172-0.368-0.32-0.685-0.444l-1.241-0.552c-0.528-0.224-0.924-0.485-1.186-0.783s-0.369-0.717-0.321-1.257
			c0.064-0.608,0.328-1.102,0.792-1.482c0.465-0.38,1.082-0.569,1.854-0.569c0.74,0,1.296,0.189,1.668,0.569
			c0.372,0.381,0.558,0.9,0.558,1.561h-1.044c-0.02-0.328-0.134-0.587-0.342-0.777c-0.208-0.189-0.52-0.285-0.936-0.285
			c-0.408,0-0.735,0.098-0.981,0.291c-0.246,0.194-0.385,0.438-0.417,0.729c-0.02,0.244,0.035,0.439,0.165,0.588
			c0.13,0.147,0.343,0.28,0.639,0.396l1.41,0.601c0.484,0.2,0.852,0.466,1.102,0.798s0.351,0.756,0.303,1.271
			c-0.04,0.437-0.177,0.82-0.411,1.152s-0.557,0.592-0.969,0.78C877.036,168.147,876.547,168.241,875.984,168.241z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M880.483,168.091l0.546-5.796h1.057l-0.546,5.796H880.483z M881.672,161.101
			c-0.208,0-0.365-0.063-0.471-0.191c-0.106-0.128-0.153-0.301-0.142-0.517c0.021-0.204,0.095-0.373,0.226-0.507
			c0.13-0.134,0.301-0.201,0.513-0.201s0.371,0.065,0.477,0.195c0.106,0.13,0.151,0.301,0.136,0.513
			c-0.021,0.221-0.098,0.394-0.231,0.52S881.876,161.101,881.672,161.101z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M883.777,168.091l0.546-5.784h0.912l0.013,1.062c0.212-0.4,0.472-0.702,0.779-0.906
			c0.309-0.204,0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882s0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.013-0.869-0.207-1.119s-0.459-0.375-0.795-0.375c-0.44,0-0.807,0.191-1.098,0.576
			c-0.293,0.384-0.473,0.934-0.54,1.649l-0.246,2.658H883.777z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M891.998,171.073c-0.536,0-0.999-0.084-1.39-0.252c-0.39-0.169-0.685-0.396-0.885-0.685
			s-0.284-0.61-0.252-0.966c0.032-0.332,0.155-0.611,0.369-0.837c0.214-0.227,0.48-0.373,0.801-0.441
			c-0.196-0.128-0.34-0.28-0.432-0.459c-0.092-0.178-0.13-0.373-0.114-0.585c0.024-0.275,0.126-0.509,0.306-0.699
			c0.181-0.189,0.402-0.32,0.666-0.393c-0.168-0.188-0.294-0.408-0.378-0.66s-0.111-0.534-0.084-0.846
			c0.044-0.424,0.179-0.793,0.402-1.107c0.224-0.313,0.508-0.557,0.852-0.729c0.345-0.172,0.715-0.258,1.11-0.258
			c0.252,0,0.486,0.033,0.702,0.102s0.406,0.166,0.57,0.294c0.06-0.296,0.169-0.553,0.326-0.771
			c0.158-0.219,0.354-0.389,0.585-0.511c0.232-0.122,0.486-0.183,0.763-0.183l-0.187,1.14c-0.304,0-0.545,0.062-0.723,0.187
			s-0.285,0.322-0.321,0.594c0.104,0.168,0.182,0.356,0.231,0.564s0.064,0.434,0.045,0.678c-0.036,0.416-0.168,0.782-0.396,1.098
			c-0.228,0.316-0.518,0.562-0.869,0.738c-0.353,0.176-0.738,0.264-1.158,0.264c-0.164,0-0.329-0.02-0.495-0.06
			s-0.321-0.097-0.465-0.168c-0.132,0.032-0.252,0.102-0.36,0.21c-0.108,0.107-0.174,0.25-0.198,0.426
			c-0.02,0.176,0.031,0.319,0.153,0.429c0.122,0.11,0.349,0.165,0.681,0.165h1.885c0.516,0,0.912,0.112,1.19,0.336
			c0.278,0.225,0.399,0.559,0.363,1.002c-0.032,0.4-0.188,0.782-0.468,1.146c-0.28,0.364-0.661,0.661-1.144,0.891
			C893.199,170.958,892.637,171.073,891.998,171.073z M892.04,170.137c0.428,0,0.803-0.065,1.125-0.198
			c0.321-0.132,0.576-0.302,0.762-0.51c0.187-0.208,0.287-0.428,0.303-0.66c0.013-0.18-0.029-0.321-0.126-0.426
			c-0.096-0.104-0.244-0.156-0.443-0.156h-1.975c-0.34,0-0.614,0.09-0.821,0.271c-0.208,0.18-0.32,0.399-0.337,0.66
			c-0.016,0.304,0.11,0.55,0.379,0.737C891.173,170.043,891.551,170.137,892.04,170.137z M892.67,165.517
			c0.248,0,0.461-0.056,0.639-0.168c0.178-0.111,0.319-0.264,0.423-0.456c0.104-0.191,0.166-0.405,0.187-0.642
			c0.036-0.376-0.035-0.682-0.213-0.915c-0.179-0.234-0.443-0.352-0.795-0.352c-0.364,0-0.657,0.118-0.879,0.354
			c-0.223,0.236-0.35,0.54-0.382,0.912c-0.031,0.359,0.039,0.661,0.214,0.903C892.037,165.396,892.305,165.517,892.67,165.517z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M898.448,168.241c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.226-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.267,0.519,0.333,0.828c0.065,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.039,0.351
			c-0.018,0.122-0.041,0.247-0.068,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.188,0.551,0.375,0.729c0.188,0.179,0.446,0.268,0.774,0.268c0.28,0,0.523-0.077,0.731-0.231
			c0.208-0.153,0.349-0.381,0.421-0.681h1.079c-0.092,0.38-0.249,0.709-0.471,0.987c-0.222,0.277-0.492,0.493-0.81,0.645
			C899.176,168.165,898.828,168.241,898.448,168.241z M897.404,164.905l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.134-0.494-0.288-0.666c-0.153-0.172-0.389-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243s-0.389,0.38-0.519,0.654C897.529,164.264,897.443,164.568,897.404,164.905z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M902.221,168.091l0.546-5.796h1.056l-0.107,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.252,0.021s0.174,0.039,0.258,0.075l-0.138,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.215-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			s-0.365,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H902.221z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M906.211,165.049l0.103-1.02h2.981l-0.096,1.02H906.211z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M912.697,168.241c-0.468,0-0.89-0.093-1.266-0.276s-0.667-0.461-0.873-0.831s-0.291-0.833-0.255-1.389
			h1.044c-0.032,0.323,0.014,0.591,0.138,0.801s0.3,0.365,0.528,0.465c0.228,0.101,0.483,0.15,0.768,0.15
			c0.296,0,0.561-0.048,0.792-0.145c0.232-0.096,0.418-0.226,0.559-0.39c0.14-0.164,0.22-0.353,0.239-0.564
			c0.028-0.288-0.027-0.518-0.168-0.689c-0.14-0.172-0.368-0.32-0.684-0.444l-1.242-0.552c-0.528-0.224-0.923-0.485-1.185-0.783
			c-0.263-0.298-0.369-0.717-0.321-1.257c0.063-0.608,0.328-1.102,0.792-1.482c0.464-0.38,1.082-0.569,1.854-0.569
			c0.74,0,1.297,0.189,1.668,0.569c0.372,0.381,0.559,0.9,0.559,1.561h-1.044c-0.021-0.328-0.134-0.587-0.342-0.777
			c-0.208-0.189-0.521-0.285-0.937-0.285c-0.408,0-0.734,0.098-0.98,0.291c-0.246,0.194-0.386,0.438-0.417,0.729
			c-0.021,0.244,0.034,0.439,0.165,0.588c0.13,0.147,0.343,0.28,0.639,0.396l1.41,0.601c0.483,0.2,0.851,0.466,1.101,0.798
			s0.352,0.756,0.303,1.271c-0.04,0.437-0.177,0.82-0.41,1.152c-0.234,0.332-0.558,0.592-0.97,0.78
			C913.749,168.147,913.261,168.241,912.697,168.241z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M919.057,168.241c-0.473,0-0.887-0.114-1.242-0.343c-0.356-0.228-0.62-0.566-0.792-1.017
			c-0.173-0.45-0.227-1.009-0.162-1.677c0.063-0.676,0.226-1.24,0.485-1.692c0.261-0.452,0.589-0.791,0.987-1.017
			c0.398-0.227,0.831-0.339,1.3-0.339c0.468,0,0.878,0.114,1.229,0.345c0.352,0.229,0.616,0.57,0.792,1.02
			c0.176,0.45,0.232,1.012,0.168,1.684c-0.068,0.668-0.232,1.227-0.492,1.677s-0.586,0.789-0.978,1.017
			C919.961,168.127,919.529,168.241,919.057,168.241z M919.111,167.221c0.444,0,0.812-0.175,1.104-0.524
			c0.291-0.351,0.472-0.848,0.539-1.491c0.064-0.652-0.009-1.154-0.219-1.506c-0.21-0.353-0.535-0.528-0.975-0.528
			c-0.44,0-0.805,0.175-1.093,0.525c-0.288,0.35-0.464,0.853-0.527,1.509c-0.044,0.424-0.027,0.787,0.051,1.089
			s0.211,0.532,0.398,0.689C918.579,167.142,918.819,167.221,919.111,167.221z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M923.107,168.091l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906
			s0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375
			c-0.44,0-0.807,0.191-1.099,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H923.107z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M931.328,171.073c-0.536,0-0.999-0.084-1.39-0.252c-0.39-0.169-0.685-0.396-0.885-0.685
			s-0.284-0.61-0.252-0.966c0.032-0.332,0.155-0.611,0.369-0.837c0.214-0.227,0.48-0.373,0.801-0.441
			c-0.196-0.128-0.34-0.28-0.432-0.459c-0.093-0.178-0.131-0.373-0.114-0.585c0.023-0.275,0.126-0.509,0.306-0.699
			c0.181-0.189,0.402-0.32,0.666-0.393c-0.168-0.188-0.294-0.408-0.378-0.66s-0.112-0.534-0.084-0.846
			c0.044-0.424,0.178-0.793,0.402-1.107c0.224-0.313,0.508-0.557,0.852-0.729s0.714-0.258,1.11-0.258
			c0.252,0,0.485,0.033,0.702,0.102c0.216,0.068,0.405,0.166,0.569,0.294c0.061-0.296,0.169-0.553,0.327-0.771
			c0.158-0.219,0.354-0.389,0.585-0.511c0.232-0.122,0.486-0.183,0.763-0.183l-0.187,1.14c-0.304,0-0.545,0.062-0.723,0.187
			c-0.179,0.124-0.285,0.322-0.321,0.594c0.104,0.168,0.181,0.356,0.231,0.564c0.05,0.208,0.064,0.434,0.045,0.678
			c-0.036,0.416-0.168,0.782-0.396,1.098c-0.228,0.316-0.518,0.562-0.87,0.738c-0.352,0.176-0.737,0.264-1.158,0.264
			c-0.164,0-0.328-0.02-0.494-0.06s-0.321-0.097-0.466-0.168c-0.132,0.032-0.252,0.102-0.359,0.21
			c-0.108,0.107-0.174,0.25-0.198,0.426c-0.021,0.176,0.031,0.319,0.153,0.429c0.122,0.11,0.349,0.165,0.681,0.165h1.884
			c0.517,0,0.913,0.112,1.191,0.336c0.277,0.225,0.398,0.559,0.363,1.002c-0.032,0.4-0.188,0.782-0.469,1.146
			c-0.279,0.364-0.661,0.661-1.143,0.891C932.528,170.958,931.967,171.073,931.328,171.073z M931.37,170.137
			c0.428,0,0.803-0.065,1.125-0.198c0.321-0.132,0.575-0.302,0.762-0.51c0.186-0.208,0.287-0.428,0.303-0.66
			c0.012-0.18-0.03-0.321-0.126-0.426s-0.244-0.156-0.444-0.156h-1.974c-0.34,0-0.614,0.09-0.822,0.271
			c-0.208,0.18-0.319,0.399-0.336,0.66c-0.016,0.304,0.11,0.55,0.378,0.737C930.504,170.043,930.881,170.137,931.37,170.137z
			 M932,165.517c0.248,0,0.461-0.056,0.639-0.168c0.178-0.111,0.319-0.264,0.423-0.456c0.104-0.191,0.166-0.405,0.187-0.642
			c0.035-0.376-0.035-0.682-0.214-0.915c-0.178-0.234-0.442-0.352-0.795-0.352c-0.363,0-0.656,0.118-0.879,0.354
			c-0.222,0.236-0.349,0.54-0.381,0.912c-0.032,0.359,0.039,0.661,0.213,0.903C931.366,165.396,931.635,165.517,932,165.517z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M935.575,162.307h1.104l0.75,4.368l1.494-4.068h0.937l0.726,4.116l1.584-4.416h1.057l-2.25,5.784h-1.038
			l-0.732-3.762l-1.41,3.762h-1.038L935.575,162.307z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M943.909,168.091l0.546-5.796h1.057l-0.108,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.253,0.021c0.088,0.014,0.174,0.039,0.258,0.075l-0.139,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.214-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			c-0.203,0.172-0.364,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H943.909z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M948.289,168.091l0.546-5.796h1.057l-0.547,5.796H948.289z M949.477,161.101
			c-0.208,0-0.365-0.063-0.472-0.191c-0.105-0.128-0.152-0.301-0.141-0.517c0.02-0.204,0.095-0.373,0.225-0.507
			s0.301-0.201,0.514-0.201c0.212,0,0.37,0.065,0.477,0.195s0.151,0.301,0.135,0.513c-0.02,0.221-0.097,0.394-0.23,0.52
			C949.849,161.038,949.68,161.101,949.477,161.101z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M953.239,168.241c-0.292,0-0.552-0.062-0.78-0.184c-0.228-0.122-0.398-0.309-0.51-0.561
			c-0.112-0.252-0.152-0.572-0.12-0.96l0.336-3.666l0.246,0.21h-1.056l0.09-0.774h1.044l-0.3,0.271l0.18-1.836h1.056l-0.18,1.836
			l-0.24-0.271h1.8l-0.084,0.774h-1.8l0.294-0.21l-0.342,3.642c-0.024,0.269,0.017,0.468,0.123,0.601
			c0.105,0.132,0.273,0.197,0.501,0.197c0.12,0,0.249-0.017,0.387-0.051c0.139-0.034,0.293-0.085,0.465-0.153l0.072,0.828
			c-0.2,0.093-0.395,0.166-0.585,0.223C953.646,168.213,953.447,168.241,953.239,168.241z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M957.607,168.241c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C958.336,168.165,957.987,168.241,957.607,168.241z M956.563,164.905l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C956.688,164.264,956.603,164.568,956.563,164.905z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M961.38,168.091l0.546-5.796h1.057l-0.108,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.253,0.021c0.088,0.014,0.174,0.039,0.258,0.075l-0.139,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.214-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			c-0.203,0.172-0.364,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H961.38z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M884.294,188.091l2.37-3.345l-2.37-3.365h1.984l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.57,3.375h-2.025
			l-1.595-2.1l-1.355,2.1H884.294z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M891.568,188.091l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.93l-2.2,3.335l2.57,3.375h-2.024
			l-1.596-2.1l-1.354,2.1H891.568z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M898.833,187.161c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			s0.31,0.443,0.31,0.74s-0.103,0.541-0.31,0.732s-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S898.833,187.454,898.833,187.161z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M901.379,188.091l2.37-3.345l-2.37-3.365h1.984l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.57,3.375h-2.025
			l-1.595-2.1l-1.355,2.1H901.379z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M908.653,188.091l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.93l-2.2,3.335l2.57,3.375h-2.024
			l-1.596-2.1l-1.354,2.1H908.653z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M916.293,185.666v-1.4h4.696v1.4H916.293z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M921.728,188.091l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H921.728z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M929.004,188.091l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H929.004z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M936.269,187.161c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S936.269,187.454,936.269,187.161z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M938.814,188.091l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H938.814z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M946.089,188.091l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H946.089z"
                  />
                </g>
                <g>
                  <path
                    fill="#1E1B39"
                    d="M107.439,321.271v-1.632h8.319v1.632h-3.64l0.424-0.432v9.184h-1.888v-9.184l0.424,0.432H107.439z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M118.214,330.247c-0.667,0-1.223-0.115-1.668-0.348c-0.445-0.232-0.78-0.537-1.004-0.916
			c-0.224-0.378-0.336-0.789-0.336-1.232c0-0.491,0.129-0.903,0.388-1.236c0.259-0.333,0.625-0.584,1.101-0.752
			c0.475-0.168,1.034-0.252,1.68-0.252c0.421,0,0.82,0.032,1.196,0.096s0.75,0.149,1.124,0.256v1.104
			c-0.294-0.096-0.614-0.17-0.96-0.224c-0.348-0.053-0.673-0.08-0.977-0.08c-0.448,0-0.815,0.083-1.104,0.248
			c-0.288,0.165-0.433,0.445-0.433,0.84c0,0.288,0.117,0.536,0.353,0.744c0.234,0.208,0.607,0.312,1.12,0.312
			c0.389,0,0.72-0.085,0.991-0.256c0.272-0.17,0.479-0.384,0.62-0.64c0.142-0.256,0.212-0.512,0.212-0.768h0.448
			c0,0.56-0.104,1.076-0.312,1.548s-0.517,0.85-0.925,1.132C119.323,330.106,118.817,330.247,118.214,330.247z M120.758,330.023
			l-0.24-1.888v-3.12c0-0.544-0.11-0.947-0.332-1.208c-0.221-0.261-0.596-0.392-1.124-0.392c-0.304,0-0.567,0.045-0.792,0.136
			c-0.224,0.091-0.396,0.22-0.516,0.388s-0.18,0.372-0.18,0.612h-2.064c0-0.586,0.142-1.065,0.424-1.436
			c0.283-0.371,0.689-0.643,1.221-0.816c0.53-0.173,1.166-0.26,1.907-0.26c0.673,0,1.259,0.108,1.761,0.324
			c0.501,0.216,0.891,0.548,1.168,0.996c0.277,0.448,0.416,1.021,0.416,1.72v4.944H120.758z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M125.174,330.023v-7.76h1.648l0.24,1.536v6.224H125.174z M130.503,330.023v-4.544
			c0-0.576-0.139-1.011-0.416-1.304c-0.277-0.293-0.64-0.44-1.088-0.44c-0.379,0-0.713,0.104-1.004,0.312s-0.519,0.521-0.684,0.94
			c-0.166,0.419-0.248,0.94-0.248,1.564h-0.624c0-1.008,0.125-1.847,0.376-2.516c0.25-0.669,0.605-1.169,1.063-1.5
			c0.459-0.331,0.998-0.496,1.616-0.496c0.641,0,1.175,0.143,1.604,0.428c0.429,0.286,0.752,0.687,0.968,1.204
			c0.216,0.518,0.324,1.12,0.324,1.808v4.544H130.503z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M134.167,323.511v-1.248h5.088v1.248H134.167z M137.766,330.247c-0.458,0-0.878-0.085-1.26-0.256
			c-0.381-0.17-0.686-0.432-0.912-0.784s-0.34-0.8-0.34-1.344v-7.664h1.888v7.6c0,0.64,0.305,0.96,0.912,0.96
			c0.187,0,0.374-0.021,0.561-0.064c0.187-0.042,0.399-0.106,0.64-0.192l0.272,1.328c-0.299,0.117-0.59,0.216-0.872,0.295
			C138.372,330.207,138.076,330.247,137.766,330.247z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M144.35,330.247c-0.661,0-1.267-0.17-1.816-0.512c-0.549-0.341-0.989-0.82-1.319-1.436
			c-0.331-0.616-0.496-1.337-0.496-2.164c0-0.853,0.164-1.586,0.492-2.2c0.327-0.613,0.767-1.083,1.315-1.408
			c0.55-0.325,1.157-0.488,1.824-0.488c0.619,0,1.153,0.124,1.604,0.372c0.45,0.248,0.812,0.584,1.088,1.008
			c0.274,0.424,0.46,0.903,0.556,1.436s0.102,1.083,0.017,1.648h-5.393v-1.024h4.056l-0.367,0.24c0.016-0.25,0.004-0.504-0.036-0.76
			s-0.116-0.491-0.229-0.704c-0.111-0.213-0.271-0.385-0.476-0.516c-0.205-0.13-0.468-0.196-0.788-0.196
			c-0.384,0-0.709,0.096-0.976,0.288c-0.268,0.192-0.47,0.453-0.608,0.784c-0.139,0.331-0.208,0.704-0.208,1.12v0.752
			c0,0.438,0.065,0.824,0.195,1.16c0.131,0.336,0.326,0.6,0.585,0.792s0.585,0.288,0.979,0.288c0.368,0,0.678-0.085,0.928-0.256
			c0.251-0.171,0.411-0.427,0.48-0.768h1.904c-0.08,0.501-0.269,0.942-0.564,1.324s-0.679,0.68-1.147,0.896
			C145.481,330.139,144.948,330.247,144.35,330.247z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M153.822,319.639h1.888v6.464c0,0.854,0.218,1.469,0.652,1.848c0.435,0.378,0.99,0.568,1.668,0.568
			c0.683,0,1.24-0.189,1.672-0.568c0.432-0.379,0.648-0.995,0.648-1.848v-6.464h1.888v6.464c0,0.875-0.168,1.621-0.504,2.24
			c-0.336,0.619-0.817,1.091-1.444,1.416c-0.627,0.326-1.38,0.488-2.26,0.488s-1.634-0.162-2.26-0.488
			c-0.627-0.325-1.108-0.797-1.444-1.416c-0.336-0.619-0.504-1.365-0.504-2.24V319.639z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M164.046,323.511v-1.248h5.088v1.248H164.046z M167.646,330.247c-0.459,0-0.879-0.085-1.261-0.256
			c-0.381-0.17-0.685-0.432-0.911-0.784c-0.228-0.352-0.341-0.8-0.341-1.344v-7.664h1.889v7.6c0,0.64,0.304,0.96,0.912,0.96
			c0.187,0,0.373-0.021,0.56-0.064c0.187-0.042,0.4-0.106,0.64-0.192l0.272,1.328c-0.299,0.117-0.59,0.216-0.872,0.295
			C168.251,330.207,167.955,330.247,167.646,330.247z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M174.229,330.247c-0.661,0-1.267-0.17-1.815-0.512c-0.55-0.341-0.989-0.82-1.32-1.436
			c-0.331-0.616-0.496-1.337-0.496-2.164c0-0.853,0.164-1.586,0.492-2.2s0.767-1.083,1.315-1.408
			c0.55-0.325,1.157-0.488,1.824-0.488c0.619,0,1.153,0.124,1.604,0.372c0.45,0.248,0.812,0.584,1.088,1.008
			c0.274,0.424,0.46,0.903,0.556,1.436s0.102,1.083,0.017,1.648h-5.393v-1.024h4.056l-0.367,0.24c0.016-0.25,0.004-0.504-0.036-0.76
			s-0.116-0.491-0.229-0.704c-0.111-0.213-0.271-0.385-0.476-0.516c-0.205-0.13-0.468-0.196-0.788-0.196
			c-0.384,0-0.709,0.096-0.976,0.288c-0.268,0.192-0.47,0.453-0.608,0.784c-0.139,0.331-0.208,0.704-0.208,1.12v0.752
			c0,0.438,0.065,0.824,0.196,1.16c0.13,0.336,0.325,0.6,0.584,0.792s0.585,0.288,0.979,0.288c0.368,0,0.678-0.085,0.928-0.256
			c0.251-0.171,0.411-0.427,0.48-0.768h1.904c-0.08,0.501-0.269,0.942-0.564,1.324s-0.679,0.68-1.147,0.896
			C175.36,330.139,174.827,330.247,174.229,330.247z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M110.48,346.023l0.72-7.662h1.057l-0.721,7.662H110.48z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M113.624,346.023l0.546-5.784h0.912c0.004,0.176,0.006,0.353,0.006,0.528s0.002,0.354,0.007,0.534
			c0.212-0.4,0.472-0.702,0.779-0.906c0.309-0.204,0.652-0.306,1.032-0.306c0.444,0,0.805,0.115,1.083,0.348
			s0.469,0.556,0.573,0.972c0.212-0.456,0.482-0.79,0.812-1.002s0.697-0.317,1.102-0.317c0.42,0,0.765,0.103,1.035,0.309
			c0.27,0.206,0.462,0.5,0.575,0.882c0.114,0.382,0.147,0.833,0.1,1.354l-0.312,3.39h-1.056l0.312-3.39
			c0.044-0.484-0.026-0.854-0.213-1.11c-0.186-0.256-0.455-0.384-0.807-0.384c-0.288,0-0.54,0.084-0.756,0.252
			s-0.391,0.412-0.522,0.731c-0.132,0.32-0.224,0.717-0.275,1.188l-0.246,2.712h-1.057l0.312-3.39
			c0.056-0.496-0.013-0.869-0.207-1.119s-0.459-0.375-0.795-0.375c-0.44,0-0.807,0.191-1.098,0.576
			c-0.293,0.384-0.473,0.934-0.54,1.649l-0.246,2.658H113.624z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M123.524,348.855l0.822-8.616h0.863c0.004,0.141,0.01,0.28,0.019,0.42
			c0.008,0.141,0.012,0.278,0.012,0.414c0.204-0.328,0.447-0.574,0.729-0.738c0.281-0.163,0.582-0.245,0.902-0.245
			c0.408,0,0.779,0.119,1.113,0.359s0.592,0.587,0.773,1.041c0.183,0.454,0.241,0.999,0.178,1.635
			c-0.057,0.641-0.219,1.188-0.486,1.642s-0.595,0.802-0.98,1.044c-0.387,0.242-0.792,0.363-1.216,0.363
			c-0.308,0-0.577-0.062-0.807-0.187c-0.23-0.124-0.411-0.306-0.543-0.546l-0.324,3.414H123.524z M126.23,345.117
			c0.231,0,0.465-0.073,0.699-0.222c0.233-0.148,0.437-0.369,0.608-0.663s0.28-0.663,0.324-1.107c0.04-0.42,0.011-0.776-0.087-1.07
			c-0.099-0.294-0.247-0.521-0.447-0.679c-0.2-0.157-0.438-0.236-0.714-0.236c-0.232,0-0.453,0.06-0.663,0.18
			s-0.385,0.294-0.525,0.522c-0.14,0.228-0.226,0.51-0.258,0.846l-0.102,0.996c-0.024,0.304,0.011,0.562,0.104,0.773
			c0.095,0.213,0.233,0.375,0.417,0.489C125.772,345.06,125.986,345.117,126.23,345.117z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M130.297,346.023l0.546-5.796h1.057l-0.108,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.855-0.297c0.08,0,0.164,0.007,0.252,0.021s0.174,0.039,0.258,0.075l-0.138,1.164
			c-0.104-0.028-0.21-0.051-0.318-0.069c-0.108-0.018-0.214-0.026-0.318-0.026c-0.288,0-0.533,0.086-0.737,0.258
			s-0.365,0.418-0.483,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.239,2.574H130.297z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M136.538,346.173c-0.472,0-0.886-0.114-1.242-0.343c-0.355-0.228-0.62-0.566-0.792-1.017
			s-0.226-1.009-0.162-1.677c0.064-0.676,0.227-1.24,0.486-1.692s0.589-0.791,0.987-1.017c0.397-0.227,0.831-0.339,1.299-0.339
			s0.878,0.114,1.229,0.345c0.353,0.229,0.616,0.57,0.792,1.02c0.177,0.45,0.232,1.012,0.168,1.684
			c-0.067,0.668-0.231,1.227-0.491,1.677c-0.261,0.45-0.587,0.789-0.979,1.017C137.442,346.059,137.009,346.173,136.538,346.173z
			 M136.591,345.153c0.444,0,0.812-0.175,1.104-0.524c0.292-0.351,0.472-0.848,0.54-1.491c0.063-0.652-0.01-1.154-0.22-1.506
			c-0.21-0.353-0.534-0.528-0.975-0.528s-0.804,0.175-1.092,0.525c-0.288,0.35-0.464,0.853-0.528,1.509
			c-0.044,0.424-0.026,0.787,0.051,1.089c0.078,0.302,0.211,0.532,0.399,0.689C136.059,345.074,136.299,345.153,136.591,345.153z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M142.244,346.173c-0.292,0-0.552-0.062-0.78-0.184c-0.228-0.122-0.397-0.309-0.51-0.561
			s-0.152-0.572-0.12-0.96l0.336-3.666l0.246,0.21h-1.056l0.09-0.774h1.044l-0.3,0.271l0.18-1.836h1.057l-0.181,1.836l-0.24-0.271
			h1.8l-0.084,0.774h-1.8l0.294-0.21l-0.342,3.642c-0.023,0.269,0.018,0.468,0.123,0.601c0.106,0.132,0.273,0.197,0.501,0.197
			c0.12,0,0.249-0.017,0.388-0.051c0.138-0.034,0.293-0.085,0.465-0.153l0.072,0.828c-0.2,0.093-0.396,0.166-0.586,0.223
			C142.651,346.145,142.452,346.173,142.244,346.173z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M144.752,346.023l0.769-8.202h1.062l-0.33,3.42c0.212-0.372,0.469-0.657,0.771-0.854
			c0.302-0.198,0.639-0.297,1.011-0.297c0.42,0,0.766,0.104,1.038,0.312c0.271,0.208,0.466,0.502,0.582,0.882
			s0.149,0.83,0.102,1.351l-0.317,3.39h-1.051l0.312-3.39c0.052-0.492-0.018-0.864-0.207-1.116c-0.19-0.252-0.457-0.378-0.801-0.378
			c-0.284,0-0.538,0.08-0.763,0.24c-0.224,0.159-0.408,0.395-0.555,0.704c-0.146,0.311-0.247,0.689-0.303,1.138l-0.264,2.802
			H144.752z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M153.242,346.173c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C153.97,346.097,153.622,346.173,153.242,346.173z M152.198,342.837l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C152.323,342.196,152.238,342.501,152.198,342.837z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M158.575,346.173c-0.42,0-0.767-0.087-1.04-0.259c-0.274-0.172-0.473-0.395-0.595-0.669
			c-0.122-0.273-0.167-0.566-0.135-0.879c0.036-0.38,0.153-0.694,0.351-0.944c0.198-0.25,0.46-0.438,0.786-0.564
			s0.693-0.188,1.102-0.188c0.252,0,0.5,0.024,0.744,0.074c0.243,0.051,0.476,0.117,0.695,0.201l0.066-0.66
			c0.052-0.464-0.001-0.802-0.159-1.014s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314
			c-0.108,0.141-0.172,0.314-0.192,0.522h-1.164c0.044-0.44,0.179-0.792,0.402-1.056c0.224-0.265,0.508-0.455,0.852-0.573
			c0.345-0.118,0.713-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54c0.35,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912
			c-0.004-0.147-0.005-0.295-0.003-0.441c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773
			S158.963,346.173,158.575,346.173z M158.834,345.273c0.271,0,0.524-0.066,0.759-0.198s0.425-0.316,0.573-0.555
			c0.147-0.238,0.229-0.515,0.246-0.831c-0.196-0.063-0.406-0.116-0.631-0.156c-0.224-0.04-0.434-0.06-0.63-0.06
			c-0.323,0-0.602,0.066-0.834,0.201c-0.231,0.134-0.363,0.364-0.396,0.692c-0.021,0.256,0.049,0.472,0.207,0.646
			S158.521,345.273,158.834,345.273z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M164.845,346.173c-0.292,0-0.552-0.062-0.78-0.184c-0.228-0.122-0.398-0.309-0.51-0.561
			c-0.112-0.252-0.152-0.572-0.12-0.96l0.336-3.666l0.246,0.21h-1.056l0.09-0.774h1.044l-0.3,0.271l0.18-1.836h1.056l-0.18,1.836
			l-0.24-0.271h1.8l-0.084,0.774h-1.8l0.294-0.21l-0.342,3.642c-0.024,0.269,0.017,0.468,0.123,0.601
			c0.105,0.132,0.273,0.197,0.501,0.197c0.12,0,0.249-0.017,0.387-0.051c0.139-0.034,0.293-0.085,0.465-0.153l0.072,0.828
			c-0.2,0.093-0.395,0.166-0.585,0.223C165.253,346.145,165.053,346.173,164.845,346.173z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M169.213,346.173c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C169.942,346.097,169.593,346.173,169.213,346.173z M168.17,342.837l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C168.295,342.196,168.21,342.501,168.17,342.837z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M172.987,346.023l0.546-5.796h1.057l-0.108,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.253,0.021c0.088,0.014,0.174,0.039,0.258,0.075l-0.139,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.214-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			c-0.203,0.172-0.364,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H172.987z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M114.405,366.183c-0.573,0-1.083-0.127-1.527-0.38c-0.445-0.254-0.794-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.251-0.519,0.6-0.903,1.045-1.155
			c0.444-0.252,0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158
			C115.475,366.056,114.971,366.183,114.405,366.183z M114.405,364.728c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532
			s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543
			s0.115,1.188,0.348,1.532C113.684,364.555,114.002,364.728,114.405,364.728z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M121.139,366.183c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C122.21,366.056,121.707,366.183,121.139,366.183z M121.139,364.728c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S120.736,364.728,121.139,364.728z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M124.67,365.093c0-0.297,0.099-0.544,0.298-0.74c0.198-0.196,0.455-0.295,0.772-0.295
			c0.316,0,0.578,0.099,0.784,0.295c0.207,0.196,0.311,0.443,0.311,0.74s-0.104,0.541-0.311,0.732
			c-0.206,0.191-0.468,0.287-0.784,0.287c-0.317,0-0.574-0.097-0.772-0.29C124.768,365.629,124.67,365.386,124.67,365.093z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M130.41,366.183c-0.573,0-1.083-0.127-1.527-0.38c-0.445-0.254-0.794-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.251-0.519,0.6-0.903,1.045-1.155
			c0.444-0.252,0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158
			C131.48,366.056,130.976,366.183,130.41,366.183z M130.41,364.728c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532
			s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543
			s0.115,1.188,0.348,1.532C129.689,364.555,130.006,364.728,130.41,364.728z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M137.144,366.183c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C138.214,366.056,137.712,366.183,137.144,366.183z M137.144,364.728c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S136.741,364.728,137.144,364.728z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M141.049,363.598v-1.4h4.695v1.4H141.049z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M149.679,366.183c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C150.75,366.056,150.246,366.183,149.679,366.183z
			 M149.679,364.728c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C148.959,364.555,149.276,364.728,149.679,364.728z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M156.415,366.183c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C157.485,366.056,156.981,366.183,156.415,366.183z
			 M156.415,364.728c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C155.694,364.555,156.011,364.728,156.415,364.728z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M159.945,365.093c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S159.945,365.386,159.945,365.093z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M165.836,359.263h1.569v4.035h1.17v1.38h-1.17v1.345h-1.59v-1.345h-3.33v-1.195L165.836,359.263z
			 M165.905,361.558l-1.529,1.925h1.529V361.558z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M171.765,366.183c-0.54,0-1.004-0.091-1.393-0.272s-0.689-0.444-0.902-0.788
			c-0.214-0.343-0.328-0.756-0.345-1.239h1.64c0,0.286,0.095,0.499,0.285,0.637c0.189,0.139,0.428,0.208,0.715,0.208
			c0.276,0,0.516-0.097,0.718-0.29c0.201-0.193,0.302-0.43,0.302-0.71c0-0.521-0.368-0.78-1.104-0.78h-2.295l0.33-3.635h4.319v1.47
			h-3.034l0.305-0.415l-0.17,1.655l-0.101-0.13c0.16-0.037,0.359-0.065,0.598-0.088c0.238-0.021,0.437-0.032,0.593-0.032
			c0.66,0,1.19,0.174,1.59,0.52c0.4,0.348,0.601,0.825,0.601,1.436c0,0.453-0.112,0.866-0.335,1.237
			c-0.224,0.372-0.534,0.668-0.931,0.888S172.292,366.183,171.765,366.183z"
                  />
                </g>
                <g>
                  <path
                    fill="#1E1B39"
                    d="M585.859,101.056c-0.721,0-1.383-0.125-1.988-0.375c-0.605-0.251-1.096-0.632-1.472-1.144
			c-0.376-0.512-0.578-1.163-0.604-1.952h1.856c0,0.4,0.099,0.728,0.296,0.984c0.197,0.256,0.463,0.447,0.796,0.572
			s0.7,0.188,1.1,0.188c0.385,0,0.727-0.051,1.028-0.152c0.302-0.102,0.539-0.247,0.712-0.436c0.174-0.189,0.26-0.415,0.26-0.676
			c0-0.347-0.105-0.619-0.315-0.816c-0.211-0.197-0.548-0.378-1.013-0.544l-2.048-0.816c-0.8-0.315-1.413-0.68-1.84-1.096
			s-0.64-0.989-0.64-1.72c0-0.843,0.333-1.525,1-2.048c0.666-0.522,1.597-0.784,2.792-0.784c1.162,0,2.062,0.271,2.696,0.812
			c0.634,0.541,0.994,1.294,1.079,2.26h-1.855c-0.069-0.411-0.256-0.74-0.561-0.988c-0.304-0.248-0.757-0.372-1.359-0.372
			c-0.533,0-0.955,0.111-1.265,0.332c-0.309,0.221-0.464,0.5-0.464,0.836c0,0.288,0.098,0.516,0.292,0.684
			c0.195,0.168,0.498,0.329,0.908,0.484l2.272,0.88c0.768,0.299,1.357,0.675,1.768,1.128c0.411,0.454,0.616,1.021,0.616,1.704
			c0,0.624-0.157,1.164-0.472,1.62c-0.315,0.456-0.773,0.809-1.376,1.06C587.456,100.931,586.722,101.056,585.859,101.056z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M593.547,91.648c-0.368,0-0.655-0.097-0.86-0.292c-0.205-0.194-0.308-0.465-0.308-0.812
			c0-0.336,0.103-0.604,0.308-0.804c0.205-0.2,0.492-0.3,0.86-0.3c0.362,0,0.648,0.1,0.856,0.3c0.207,0.2,0.312,0.468,0.312,0.804
			c0,0.347-0.104,0.618-0.312,0.812C594.195,91.551,593.909,91.648,593.547,91.648z M592.602,100.832V93.04h1.889v7.792H592.602z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M601.466,101.056c-0.912,0-1.705-0.217-2.38-0.652c-0.675-0.435-1.199-1.057-1.572-1.868
			s-0.56-1.784-0.56-2.92c0-1.173,0.193-2.159,0.58-2.956s0.92-1.4,1.6-1.808s1.457-0.612,2.332-0.612
			c1.211,0,2.156,0.293,2.836,0.88c0.68,0.586,1.116,1.349,1.309,2.288l-2.017,0.368c-0.165-0.544-0.43-0.984-0.792-1.32
			s-0.808-0.504-1.336-0.504c-0.464,0-0.883,0.135-1.256,0.404c-0.373,0.27-0.671,0.676-0.892,1.22
			c-0.222,0.544-0.332,1.224-0.332,2.04c0,0.768,0.097,1.429,0.292,1.984c0.194,0.555,0.476,0.981,0.844,1.28
			c0.368,0.299,0.815,0.448,1.344,0.448c0.678,0,1.201-0.18,1.572-0.54c0.37-0.36,0.556-0.82,0.556-1.38h2.017
			c0,0.773-0.167,1.431-0.5,1.972c-0.334,0.542-0.81,0.956-1.429,1.244C603.064,100.912,602.325,101.056,601.466,101.056z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M607.826,100.832V89.856h1.889v10.976H607.826z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M615.098,101.056c-0.666,0-1.223-0.115-1.668-0.348c-0.445-0.232-0.779-0.537-1.004-0.916
			c-0.224-0.378-0.336-0.789-0.336-1.232c0-0.491,0.129-0.903,0.388-1.236c0.259-0.333,0.626-0.584,1.101-0.752
			c0.475-0.168,1.034-0.252,1.68-0.252c0.422,0,0.82,0.032,1.196,0.096s0.75,0.149,1.124,0.256v1.104
			c-0.294-0.096-0.613-0.17-0.96-0.224s-0.672-0.08-0.977-0.08c-0.447,0-0.815,0.083-1.104,0.248
			c-0.288,0.165-0.432,0.445-0.432,0.84c0,0.288,0.117,0.536,0.352,0.744s0.608,0.312,1.12,0.312c0.389,0,0.72-0.085,0.992-0.256
			c0.271-0.17,0.479-0.384,0.62-0.64c0.141-0.256,0.212-0.512,0.212-0.768h0.447c0,0.56-0.104,1.076-0.312,1.548
			s-0.517,0.85-0.924,1.132C616.207,100.915,615.701,101.056,615.098,101.056z M617.642,100.832l-0.239-1.888v-3.12
			c0-0.544-0.111-0.947-0.332-1.208c-0.222-0.261-0.597-0.392-1.124-0.392c-0.305,0-0.568,0.045-0.792,0.136
			c-0.225,0.091-0.396,0.22-0.517,0.388s-0.18,0.372-0.18,0.612h-2.064c0-0.586,0.142-1.065,0.425-1.436
			c0.282-0.371,0.688-0.643,1.22-0.816c0.53-0.173,1.166-0.26,1.908-0.26c0.672,0,1.258,0.108,1.76,0.324
			c0.501,0.216,0.891,0.548,1.168,0.996c0.277,0.448,0.416,1.021,0.416,1.72v4.944H617.642z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M622.059,100.832V93.04h1.888v7.792H622.059z M623.483,97.424c0-1.019,0.114-1.861,0.344-2.528
			c0.229-0.667,0.537-1.161,0.924-1.484s0.817-0.484,1.292-0.484c0.128,0,0.25,0.008,0.364,0.024s0.229,0.04,0.34,0.072
			l-0.032,1.888c-0.139-0.032-0.287-0.059-0.447-0.08s-0.305-0.032-0.433-0.032c-0.416,0-0.764,0.1-1.044,0.3
			c-0.279,0.2-0.49,0.495-0.632,0.884c-0.142,0.39-0.212,0.87-0.212,1.44H623.483z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M631.394,101.056c-0.709,0-1.351-0.151-1.924-0.456s-1.028-0.759-1.364-1.364
			c-0.336-0.605-0.504-1.364-0.504-2.276c0-0.928,0.168-1.696,0.504-2.304c0.336-0.608,0.791-1.062,1.364-1.36
			c0.573-0.298,1.215-0.448,1.924-0.448c0.698,0,1.332,0.149,1.9,0.448c0.567,0.299,1.02,0.752,1.355,1.36
			c0.336,0.608,0.504,1.376,0.504,2.304c0,0.912-0.168,1.671-0.504,2.276c-0.336,0.605-0.788,1.06-1.355,1.364
			C632.726,100.904,632.092,101.056,631.394,101.056z M631.331,99.408c0.373,0,0.705-0.092,0.995-0.276
			c0.291-0.184,0.521-0.457,0.688-0.82s0.252-0.813,0.252-1.352c0-0.821-0.161-1.44-0.484-1.856
			c-0.322-0.416-0.764-0.624-1.323-0.624c-0.379,0-0.715,0.092-1.009,0.276c-0.293,0.184-0.523,0.46-0.691,0.828
			s-0.252,0.827-0.252,1.376c0,0.811,0.164,1.421,0.492,1.832C630.326,99.203,630.77,99.408,631.331,99.408z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M592.797,116.982c-0.468,0-0.89-0.093-1.266-0.276s-0.667-0.461-0.873-0.831s-0.291-0.833-0.255-1.389
			h1.044c-0.032,0.323,0.014,0.591,0.138,0.801s0.3,0.365,0.528,0.465c0.228,0.101,0.483,0.15,0.768,0.15
			c0.296,0,0.561-0.048,0.792-0.145c0.232-0.096,0.418-0.226,0.559-0.39c0.14-0.164,0.22-0.353,0.239-0.564
			c0.028-0.288-0.027-0.518-0.168-0.689c-0.14-0.172-0.368-0.32-0.684-0.444l-1.242-0.552c-0.528-0.224-0.923-0.485-1.185-0.783
			c-0.263-0.298-0.369-0.717-0.321-1.257c0.063-0.608,0.328-1.102,0.792-1.482c0.464-0.38,1.082-0.569,1.854-0.569
			c0.74,0,1.297,0.189,1.668,0.569c0.372,0.381,0.559,0.9,0.559,1.561h-1.044c-0.021-0.328-0.134-0.587-0.342-0.777
			c-0.208-0.189-0.521-0.285-0.937-0.285c-0.408,0-0.734,0.098-0.98,0.291c-0.246,0.194-0.386,0.438-0.417,0.729
			c-0.021,0.244,0.034,0.439,0.165,0.588c0.13,0.147,0.343,0.28,0.639,0.396l1.41,0.601c0.483,0.2,0.851,0.466,1.101,0.798
			s0.352,0.756,0.303,1.271c-0.04,0.437-0.177,0.82-0.41,1.152c-0.234,0.332-0.558,0.592-0.97,0.78
			C593.848,116.888,593.361,116.982,592.797,116.982z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M599.079,116.982c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.226-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.267,0.519,0.333,0.828c0.065,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.039,0.351
			c-0.018,0.122-0.041,0.247-0.068,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.188,0.551,0.375,0.729c0.188,0.179,0.446,0.268,0.774,0.268c0.28,0,0.523-0.077,0.731-0.231
			c0.208-0.153,0.349-0.381,0.421-0.681h1.079c-0.092,0.38-0.249,0.709-0.471,0.987c-0.222,0.277-0.492,0.493-0.81,0.645
			C599.807,116.906,599.459,116.982,599.079,116.982z M598.035,113.647l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.134-0.494-0.288-0.666c-0.153-0.172-0.389-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243s-0.389,0.38-0.519,0.654C598.16,113.005,598.074,113.31,598.035,113.647z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M602.03,116.832l2.274-2.886l-1.729-2.91h1.302l1.188,2.021l1.518-2.021h1.284l-2.273,2.91l1.722,2.886
			h-1.284l-1.206-2.028l-1.494,2.028H602.03z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M610.262,116.982c-0.292,0-0.552-0.062-0.78-0.184c-0.228-0.122-0.398-0.309-0.51-0.561
			c-0.112-0.252-0.152-0.572-0.12-0.96l0.336-3.666l0.246,0.21h-1.057l0.09-0.774h1.045l-0.3,0.271l0.18-1.836h1.056l-0.18,1.836
			l-0.24-0.271h1.8l-0.084,0.774h-1.8l0.294-0.21l-0.342,3.642c-0.024,0.269,0.017,0.468,0.123,0.601
			c0.105,0.132,0.273,0.197,0.501,0.197c0.12,0,0.249-0.017,0.387-0.051c0.139-0.034,0.293-0.085,0.465-0.153l0.072,0.828
			c-0.2,0.093-0.395,0.166-0.585,0.223C610.67,116.954,610.47,116.982,610.262,116.982z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M614.324,116.982c-0.42,0-0.767-0.087-1.04-0.259c-0.274-0.172-0.473-0.395-0.595-0.669
			c-0.122-0.273-0.167-0.566-0.135-0.879c0.036-0.38,0.153-0.694,0.351-0.944c0.198-0.25,0.46-0.438,0.786-0.564
			s0.693-0.188,1.102-0.188c0.252,0,0.5,0.024,0.744,0.074c0.243,0.051,0.476,0.117,0.695,0.201l0.066-0.66
			c0.052-0.464-0.001-0.802-0.159-1.014s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314
			c-0.108,0.141-0.172,0.314-0.192,0.522h-1.164c0.044-0.44,0.179-0.792,0.402-1.056c0.224-0.265,0.508-0.455,0.852-0.573
			c0.345-0.118,0.713-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54c0.35,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912
			c-0.004-0.147-0.005-0.295-0.003-0.441c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773
			S614.713,116.982,614.324,116.982z M614.583,116.082c0.271,0,0.524-0.066,0.759-0.198s0.425-0.316,0.573-0.555
			c0.147-0.238,0.229-0.515,0.246-0.831c-0.196-0.063-0.406-0.116-0.631-0.156c-0.224-0.04-0.434-0.06-0.63-0.06
			c-0.323,0-0.602,0.066-0.834,0.201c-0.231,0.134-0.363,0.364-0.396,0.692c-0.021,0.256,0.049,0.472,0.207,0.646
			S614.27,116.082,614.583,116.082z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M618.944,116.832l0.769-8.202h1.062l-0.773,8.202H618.944z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M625.718,116.832l-1.656-2.946l-0.689,0.582l-0.222,2.364h-1.057l0.769-8.202h1.062l-0.432,4.596
			l2.496-2.178h1.452l-2.557,2.154l2.13,3.63H625.718z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M577.911,136.832v-1.3c0-0.363,0.05-0.678,0.15-0.942c0.1-0.266,0.262-0.493,0.484-0.683
			c0.224-0.19,0.519-0.354,0.886-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.56-0.354c0.117-0.137,0.176-0.302,0.176-0.495
			c0-0.177-0.07-0.334-0.211-0.473c-0.14-0.139-0.347-0.207-0.619-0.207c-0.254,0-0.472,0.076-0.655,0.229s-0.288,0.372-0.315,0.655
			h-1.77c-0.004-0.526,0.119-0.958,0.367-1.293s0.579-0.581,0.992-0.739c0.414-0.159,0.863-0.238,1.351-0.238
			c0.474,0,0.904,0.087,1.292,0.261c0.389,0.173,0.698,0.417,0.931,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.186,0.915s-0.321,0.504-0.595,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.326,0.144-0.397,0.212c-0.072,0.068-0.107,0.158-0.107,0.268v0.075h3.77v1.46H577.911z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M584.231,136.832v-1.3c0-0.363,0.05-0.678,0.149-0.942c0.101-0.266,0.262-0.493,0.485-0.683
			c0.224-0.19,0.519-0.354,0.885-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.561-0.354c0.116-0.137,0.175-0.302,0.175-0.495
			c0-0.177-0.07-0.334-0.21-0.473s-0.347-0.207-0.62-0.207c-0.253,0-0.472,0.076-0.655,0.229c-0.183,0.153-0.288,0.372-0.314,0.655
			h-1.771c-0.003-0.526,0.119-0.958,0.368-1.293c0.248-0.335,0.579-0.581,0.992-0.739c0.413-0.159,0.863-0.238,1.35-0.238
			c0.474,0,0.904,0.087,1.293,0.261c0.388,0.173,0.698,0.417,0.93,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.185,0.915c-0.124,0.267-0.322,0.504-0.596,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.325,0.144-0.397,0.212c-0.071,0.068-0.107,0.158-0.107,0.268v0.075h3.771v1.46H584.231z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M590.166,135.902c0-0.297,0.099-0.544,0.298-0.74c0.198-0.196,0.455-0.295,0.772-0.295
			c0.316,0,0.578,0.099,0.784,0.295c0.207,0.196,0.311,0.443,0.311,0.74s-0.104,0.541-0.311,0.732
			c-0.206,0.191-0.468,0.287-0.784,0.287c-0.317,0-0.574-0.097-0.772-0.29C590.264,136.439,590.166,136.195,590.166,135.902z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M595.906,136.992c-0.573,0-1.083-0.127-1.527-0.38c-0.445-0.254-0.794-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.251-0.519,0.6-0.903,1.045-1.155
			c0.444-0.252,0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158
			C596.976,136.865,596.472,136.992,595.906,136.992z M595.906,135.537c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532
			s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543
			s0.115,1.188,0.348,1.532C595.185,135.364,595.503,135.537,595.906,135.537z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M602.64,136.992c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C603.711,136.865,603.208,136.992,602.64,136.992z M602.64,135.537c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S602.237,135.537,602.64,135.537z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M606.546,134.407v-1.4h4.695v1.4H606.546z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M612.355,136.832v-1.3c0-0.363,0.051-0.678,0.15-0.942c0.1-0.266,0.262-0.493,0.485-0.683
			c0.223-0.19,0.518-0.354,0.885-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.561-0.354c0.116-0.137,0.175-0.302,0.175-0.495
			c0-0.177-0.07-0.334-0.21-0.473c-0.141-0.139-0.347-0.207-0.62-0.207c-0.254,0-0.472,0.076-0.655,0.229s-0.288,0.372-0.314,0.655
			h-1.771c-0.003-0.526,0.119-0.958,0.367-1.293c0.249-0.335,0.579-0.581,0.993-0.739c0.413-0.159,0.863-0.238,1.35-0.238
			c0.474,0,0.904,0.087,1.293,0.261c0.388,0.173,0.698,0.417,0.93,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.186,0.915c-0.123,0.267-0.321,0.504-0.595,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.326,0.144-0.397,0.212c-0.072,0.068-0.107,0.158-0.107,0.268v0.075h3.77v1.46H612.355z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M618.675,136.832v-1.3c0-0.363,0.051-0.678,0.15-0.942c0.1-0.266,0.262-0.493,0.485-0.683
			c0.223-0.19,0.518-0.354,0.885-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.561-0.354c0.116-0.137,0.175-0.302,0.175-0.495
			c0-0.177-0.07-0.334-0.21-0.473c-0.141-0.139-0.347-0.207-0.62-0.207c-0.254,0-0.472,0.076-0.655,0.229s-0.288,0.372-0.314,0.655
			h-1.771c-0.003-0.526,0.119-0.958,0.367-1.293c0.249-0.335,0.579-0.581,0.993-0.739c0.413-0.159,0.863-0.238,1.35-0.238
			c0.474,0,0.904,0.087,1.293,0.261c0.388,0.173,0.698,0.417,0.93,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.186,0.915c-0.123,0.267-0.321,0.504-0.595,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.326,0.144-0.397,0.212c-0.072,0.068-0.107,0.158-0.107,0.268v0.075h3.77v1.46H618.675z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M624.611,135.902c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S624.611,136.195,624.611,135.902z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M630.502,130.072h1.569v4.035h1.17v1.38h-1.17v1.345h-1.59v-1.345h-3.33v-1.195L630.502,130.072z
			 M630.571,132.367l-1.529,1.925h1.529V132.367z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M636.431,136.992c-0.54,0-1.004-0.091-1.393-0.272s-0.689-0.444-0.902-0.788
			c-0.214-0.343-0.328-0.756-0.345-1.239h1.64c0,0.286,0.095,0.499,0.285,0.637c0.189,0.139,0.428,0.208,0.715,0.208
			c0.276,0,0.516-0.097,0.718-0.29c0.201-0.193,0.302-0.43,0.302-0.71c0-0.521-0.368-0.78-1.104-0.78h-2.295l0.33-3.635h4.319v1.47
			h-3.034l0.305-0.415l-0.17,1.655l-0.101-0.13c0.16-0.037,0.359-0.065,0.598-0.088c0.238-0.021,0.437-0.032,0.593-0.032
			c0.66,0,1.19,0.174,1.59,0.52c0.4,0.348,0.601,0.825,0.601,1.436c0,0.453-0.112,0.866-0.335,1.237
			c-0.224,0.372-0.534,0.668-0.931,0.888S636.958,136.992,636.431,136.992z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M744.673,673.809v-10.384h6.544v1.632h-4.656v2.736h4.256v1.616h-4.256v4.4H744.673z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M753.417,673.809v-10.384h3.841c1.242,0,2.195,0.291,2.859,0.872c0.664,0.582,0.996,1.352,0.996,2.312
			c0,0.944-0.332,1.706-0.996,2.284s-1.617,0.868-2.859,0.868h-1.952v4.048H753.417z M755.305,668.497h1.76
			c0.693,0,1.219-0.159,1.576-0.476s0.536-0.729,0.536-1.236c0-0.517-0.163-0.935-0.488-1.252s-0.803-0.476-1.432-0.476h-1.952
			V668.497z M759.033,673.809l-2.752-4.864h2.017l3.199,4.864H759.033z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M763.393,673.809v-1.632l6.032-7.696v0.688l-6.032-0.016v-1.728h7.856v1.616l-5.664,7.264l-0.049-0.24
			l5.713,0.016v1.728H763.393z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M773.737,663.425h2.063l4.721,7.408h-0.145v-7.408h1.889v10.384h-1.92l-5.024-7.856h0.304v7.856h-1.888
			V663.425z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M720.663,689.809l0.72-7.662h1.057l-0.721,7.662H720.663z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M723.806,689.809l0.546-5.784h0.912l0.013,1.062c0.212-0.4,0.472-0.702,0.779-0.906
			c0.309-0.204,0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882s0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.013-0.869-0.207-1.119s-0.459-0.375-0.795-0.375c-0.44,0-0.807,0.191-1.098,0.576
			c-0.293,0.384-0.473,0.934-0.54,1.649l-0.246,2.658H723.806z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M732.309,689.959c-0.4,0-0.758-0.109-1.071-0.327c-0.314-0.218-0.562-0.523-0.744-0.915
			c-0.182-0.393-0.273-0.849-0.273-1.368c0-0.42,0.059-0.835,0.175-1.245c0.115-0.41,0.284-0.783,0.507-1.119
			c0.222-0.336,0.492-0.604,0.81-0.807c0.318-0.202,0.679-0.303,1.083-0.303c0.344,0,0.638,0.079,0.882,0.236
			c0.244,0.158,0.437,0.392,0.576,0.699l0.307-3.204h1.062l-0.774,8.202h-0.863l-0.048-0.942c-0.196,0.364-0.438,0.638-0.727,0.819
			S732.621,689.959,732.309,689.959z M732.548,688.902c0.212,0,0.424-0.061,0.637-0.183c0.212-0.122,0.395-0.298,0.549-0.528
			c0.153-0.229,0.245-0.505,0.272-0.825l0.09-0.96c0.009-0.036,0.013-0.063,0.013-0.084c0-0.02,0-0.046,0-0.078
			c0.004-0.287-0.051-0.529-0.162-0.726c-0.112-0.196-0.258-0.344-0.438-0.444c-0.18-0.1-0.376-0.149-0.588-0.149
			c-0.284,0-0.529,0.073-0.734,0.219c-0.206,0.146-0.373,0.336-0.501,0.57c-0.129,0.233-0.224,0.484-0.285,0.753
			c-0.062,0.268-0.094,0.521-0.094,0.762c0,0.508,0.116,0.914,0.349,1.218C731.886,688.751,732.185,688.902,732.548,688.902z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M738.777,689.917c-0.38,0-0.711-0.077-0.993-0.231s-0.493-0.395-0.633-0.723
			c-0.141-0.328-0.187-0.75-0.139-1.267l0.354-3.684h1.056l-0.342,3.576c-0.04,0.432,0.024,0.757,0.192,0.975
			s0.426,0.327,0.773,0.327c0.288,0,0.551-0.085,0.789-0.255s0.437-0.411,0.594-0.724c0.158-0.312,0.258-0.682,0.298-1.109
			l0.264-2.79h1.056l-0.54,5.796h-1.062l0.102-1.092c-0.204,0.392-0.458,0.689-0.762,0.894S739.145,689.917,738.777,689.917z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M745.407,689.959c-0.444,0-0.829-0.077-1.155-0.231s-0.574-0.372-0.744-0.654
			c-0.17-0.281-0.237-0.614-0.201-0.999h0.996c-0.023,0.309,0.067,0.546,0.273,0.711c0.206,0.166,0.482,0.249,0.831,0.249
			c0.352,0,0.637-0.076,0.854-0.23s0.341-0.347,0.369-0.579c0.024-0.204-0.034-0.361-0.174-0.471
			c-0.141-0.11-0.326-0.205-0.559-0.285l-1.031-0.414c-0.4-0.152-0.703-0.36-0.909-0.624s-0.289-0.584-0.249-0.96
			c0.036-0.324,0.15-0.606,0.345-0.846c0.194-0.24,0.454-0.426,0.78-0.556s0.697-0.194,1.113-0.194c0.663,0,1.157,0.153,1.481,0.462
			c0.324,0.308,0.472,0.695,0.444,1.164h-1.015c-0.004-0.229-0.09-0.402-0.258-0.522s-0.397-0.18-0.689-0.18
			c-0.236,0-0.432,0.035-0.585,0.104c-0.154,0.07-0.273,0.159-0.357,0.268c-0.084,0.107-0.136,0.224-0.156,0.348
			c-0.02,0.188,0.037,0.343,0.171,0.465c0.135,0.122,0.312,0.219,0.531,0.291l1.092,0.45c0.385,0.14,0.688,0.331,0.909,0.573
			c0.223,0.242,0.315,0.551,0.279,0.927c-0.028,0.296-0.141,0.576-0.339,0.84s-0.469,0.479-0.813,0.646
			C746.298,689.876,745.886,689.959,745.407,689.959z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M750.939,689.959c-0.292,0-0.553-0.062-0.78-0.184c-0.229-0.122-0.398-0.309-0.51-0.561
			c-0.112-0.252-0.152-0.572-0.12-0.96l0.336-3.666l0.246,0.21h-1.057l0.09-0.774h1.045l-0.301,0.271l0.181-1.836h1.056l-0.18,1.836
			l-0.24-0.271h1.8l-0.084,0.774h-1.8l0.294-0.21l-0.342,3.642c-0.024,0.269,0.017,0.468,0.123,0.601
			c0.105,0.132,0.272,0.197,0.501,0.197c0.12,0,0.249-0.017,0.387-0.051s0.293-0.085,0.465-0.153l0.072,0.828
			c-0.2,0.093-0.396,0.166-0.585,0.223C751.345,689.931,751.146,689.959,750.939,689.959z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M753.441,689.809l0.546-5.796h1.056l-0.107,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.252,0.021s0.174,0.039,0.258,0.075l-0.138,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.215-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			s-0.365,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H753.441z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M757.821,689.809l0.546-5.796h1.056l-0.546,5.796H757.821z M759.008,682.818
			c-0.208,0-0.364-0.063-0.471-0.191s-0.153-0.301-0.141-0.517c0.02-0.204,0.095-0.373,0.225-0.507s0.301-0.201,0.513-0.201
			s0.371,0.065,0.478,0.195c0.105,0.13,0.15,0.301,0.135,0.513c-0.02,0.221-0.097,0.394-0.23,0.52
			C759.381,682.756,759.213,682.818,759.008,682.818z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M762.674,689.959c-0.42,0-0.767-0.087-1.041-0.259c-0.273-0.172-0.472-0.395-0.594-0.669
			c-0.122-0.273-0.167-0.566-0.135-0.879c0.036-0.38,0.152-0.694,0.351-0.944s0.46-0.438,0.786-0.564s0.693-0.188,1.101-0.188
			c0.253,0,0.501,0.024,0.745,0.074c0.243,0.051,0.476,0.117,0.695,0.201l0.066-0.66c0.052-0.464-0.001-0.802-0.159-1.014
			s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314c-0.108,0.141-0.172,0.314-0.192,0.522h-1.164
			c0.044-0.44,0.178-0.792,0.402-1.056c0.224-0.265,0.508-0.455,0.852-0.573s0.712-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54
			c0.35,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912c-0.004-0.147-0.005-0.295-0.003-0.441
			c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773S763.062,689.959,762.674,689.959z M762.932,689.059
			c0.272,0,0.525-0.066,0.76-0.198s0.425-0.316,0.573-0.555c0.147-0.238,0.229-0.515,0.246-0.831
			c-0.196-0.063-0.406-0.116-0.63-0.156c-0.225-0.04-0.435-0.06-0.631-0.06c-0.324,0-0.602,0.066-0.834,0.201
			c-0.232,0.134-0.364,0.364-0.396,0.692c-0.021,0.256,0.049,0.472,0.207,0.646C762.385,688.972,762.621,689.059,762.932,689.059z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M767.295,689.809l0.769-8.202h1.062l-0.773,8.202H767.295z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M774.303,689.809l0.624-6.654h-2.189l0.102-1.008h5.442l-0.103,1.008h-2.196l-0.624,6.654H774.303z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M779.715,689.959c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C780.444,689.883,780.095,689.959,779.715,689.959z M778.672,686.623l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C778.797,685.982,778.712,686.286,778.672,686.623z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M785.386,689.959c-0.433,0-0.822-0.124-1.171-0.372c-0.348-0.248-0.611-0.601-0.792-1.057
			c-0.18-0.456-0.241-0.996-0.186-1.62c0.06-0.647,0.223-1.196,0.489-1.646c0.266-0.45,0.595-0.794,0.986-1.032
			c0.393-0.237,0.807-0.356,1.242-0.356c0.309,0,0.579,0.055,0.813,0.165c0.233,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.464,0.073-0.682,0.219c-0.218,0.146-0.403,0.366-0.555,0.66
			c-0.152,0.294-0.25,0.663-0.294,1.106c-0.036,0.421-0.012,0.779,0.075,1.077c0.086,0.298,0.224,0.525,0.414,0.682
			c0.189,0.155,0.417,0.233,0.681,0.233c0.34,0,0.609-0.097,0.81-0.291c0.2-0.193,0.316-0.463,0.349-0.807h1.067
			c-0.032,0.404-0.148,0.769-0.351,1.095s-0.472,0.584-0.811,0.774C786.209,689.863,785.822,689.959,785.386,689.959z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M789.134,689.809l0.769-8.202h1.062l-0.33,3.42c0.212-0.372,0.469-0.657,0.771-0.854
			c0.302-0.198,0.639-0.297,1.011-0.297c0.42,0,0.766,0.104,1.038,0.312c0.271,0.208,0.466,0.502,0.582,0.882
			s0.149,0.83,0.102,1.351l-0.317,3.39h-1.051l0.312-3.39c0.052-0.492-0.018-0.864-0.207-1.116c-0.19-0.252-0.457-0.378-0.801-0.378
			c-0.284,0-0.538,0.08-0.763,0.24c-0.224,0.159-0.408,0.395-0.555,0.704c-0.146,0.311-0.247,0.689-0.303,1.138l-0.264,2.802
			H789.134z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M795.759,689.809l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906
			s0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375
			c-0.44,0-0.807,0.191-1.099,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H795.759z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M804.321,689.959c-0.473,0-0.887-0.114-1.242-0.343c-0.356-0.228-0.62-0.566-0.792-1.017
			s-0.227-1.009-0.162-1.677c0.063-0.676,0.226-1.24,0.486-1.692c0.26-0.452,0.589-0.791,0.986-1.017
			c0.398-0.227,0.831-0.339,1.3-0.339c0.468,0,0.878,0.114,1.229,0.345c0.352,0.229,0.616,0.57,0.792,1.02
			c0.176,0.45,0.232,1.012,0.168,1.684c-0.068,0.668-0.232,1.227-0.492,1.677s-0.586,0.789-0.978,1.017
			C805.224,689.845,804.793,689.959,804.321,689.959z M804.375,688.939c0.444,0,0.812-0.175,1.104-0.524
			c0.291-0.351,0.472-0.848,0.539-1.491c0.064-0.652-0.009-1.154-0.219-1.506c-0.21-0.353-0.535-0.528-0.975-0.528
			c-0.44,0-0.804,0.175-1.092,0.525c-0.288,0.35-0.465,0.853-0.528,1.509c-0.044,0.424-0.027,0.787,0.051,1.089
			s0.211,0.532,0.399,0.689C803.842,688.859,804.083,688.939,804.375,688.939z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M734.767,709.969c-0.573,0-1.083-0.127-1.527-0.38c-0.445-0.254-0.794-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.251-0.519,0.6-0.903,1.045-1.155
			c0.444-0.252,0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158
			C735.838,709.842,735.334,709.969,734.767,709.969z M734.767,708.514c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532
			s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543
			s0.115,1.188,0.348,1.532C734.047,708.341,734.364,708.514,734.767,708.514z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M741.422,709.969c-0.373,0-0.734-0.05-1.085-0.147c-0.35-0.099-0.664-0.245-0.942-0.44
			c-0.278-0.194-0.499-0.438-0.662-0.728s-0.245-0.626-0.245-1.01h1.705c0.02,0.163,0.08,0.311,0.18,0.442
			c0.101,0.132,0.237,0.236,0.413,0.312c0.175,0.077,0.387,0.115,0.637,0.115c0.341,0,0.612-0.073,0.815-0.22
			c0.203-0.147,0.305-0.334,0.305-0.561c0-0.156-0.04-0.285-0.12-0.385c-0.079-0.1-0.195-0.174-0.347-0.223
			c-0.152-0.048-0.338-0.072-0.558-0.072h-1.021v-1.255h0.995c0.267,0,0.476-0.066,0.625-0.2c0.15-0.134,0.226-0.309,0.226-0.525
			c0-0.196-0.072-0.358-0.216-0.487c-0.143-0.128-0.378-0.192-0.705-0.192c-0.283,0-0.536,0.059-0.76,0.175
			c-0.224,0.117-0.354,0.304-0.395,0.561h-1.7c0.04-0.5,0.182-0.911,0.425-1.232c0.243-0.322,0.566-0.561,0.97-0.716
			c0.403-0.154,0.863-0.232,1.381-0.232c0.526,0,0.994,0.08,1.402,0.24s0.729,0.384,0.965,0.67c0.234,0.287,0.353,0.622,0.353,1.005
			c0,0.33-0.086,0.622-0.258,0.875c-0.172,0.254-0.431,0.438-0.777,0.556c0.387,0.153,0.69,0.364,0.912,0.632
			c0.222,0.269,0.333,0.591,0.333,0.968c0,0.407-0.121,0.767-0.362,1.08c-0.242,0.313-0.577,0.558-1.005,0.732
			C742.476,709.881,741.982,709.969,741.422,709.969z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M744.846,708.879c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			s0.31,0.443,0.31,0.74s-0.103,0.541-0.31,0.732s-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S744.846,709.172,744.846,708.879z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M750.587,709.969c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C751.657,709.842,751.154,709.969,750.587,709.969z M750.587,708.514c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S750.183,708.514,750.587,708.514z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M757.322,709.969c-0.573,0-1.083-0.127-1.527-0.38c-0.445-0.254-0.794-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.251-0.519,0.6-0.903,1.045-1.155
			c0.444-0.252,0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158
			C758.392,709.842,757.888,709.969,757.322,709.969z M757.322,708.514c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532
			s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543
			s0.115,1.188,0.348,1.532C756.601,708.341,756.919,708.514,757.322,708.514z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M761.226,707.384v-1.4h4.696v1.4H761.226z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M769.857,709.969c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C770.927,709.842,770.423,709.969,769.857,709.969z
			 M769.857,708.514c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C769.136,708.341,769.454,708.514,769.857,708.514z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M776.743,703.049h1.569v4.035h1.17v1.38h-1.17v1.345h-1.59v-1.345h-3.33v-1.195L776.743,703.049z
			 M776.812,705.344l-1.529,1.925h1.529V705.344z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M779.722,708.879c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S779.722,709.172,779.722,708.879z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M785.463,709.969c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C786.533,709.842,786.029,709.969,785.463,709.969z
			 M785.463,708.514c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C784.742,708.341,785.059,708.514,785.463,708.514z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M792.198,709.969c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C793.268,709.842,792.764,709.969,792.198,709.969z
			 M792.198,708.514c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C791.477,708.341,791.795,708.514,792.198,708.514z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M749.305,778.02h3.36c1.056,0,1.98,0.212,2.775,0.636s1.413,1.025,1.856,1.804
			c0.442,0.779,0.664,1.699,0.664,2.76c0,1.051-0.222,1.964-0.664,2.74c-0.443,0.776-1.062,1.377-1.856,1.804
			c-0.795,0.427-1.72,0.64-2.775,0.64h-3.36V778.02z M751.193,779.732v6.976h1.473c0.672,0,1.252-0.152,1.739-0.456
			c0.488-0.304,0.864-0.719,1.128-1.244c0.265-0.525,0.396-1.121,0.396-1.788c0-0.677-0.132-1.277-0.396-1.8
			c-0.264-0.523-0.64-0.935-1.128-1.236c-0.487-0.301-1.067-0.452-1.739-0.452H751.193z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M761.393,779.22c-0.368,0-0.655-0.097-0.86-0.292c-0.205-0.194-0.308-0.465-0.308-0.812
			c0-0.336,0.103-0.604,0.308-0.804c0.205-0.2,0.492-0.3,0.86-0.3c0.362,0,0.648,0.1,0.856,0.3c0.207,0.2,0.312,0.468,0.312,0.804
			c0,0.347-0.104,0.618-0.312,0.812C762.042,779.123,761.755,779.22,761.393,779.22z M760.449,788.404v-7.792h1.889v7.792H760.449z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M768.401,788.628c-0.65,0-1.243-0.172-1.779-0.516c-0.536-0.344-0.963-0.827-1.28-1.448
			c-0.317-0.621-0.476-1.348-0.476-2.18c0-0.821,0.153-1.537,0.46-2.148s0.717-1.083,1.231-1.416s1.087-0.5,1.716-0.5
			c0.576,0,1.075,0.137,1.496,0.412c0.422,0.274,0.744,0.681,0.969,1.22c0.224,0.539,0.336,1.2,0.336,1.984h-0.593
			c0-0.405-0.082-0.75-0.248-1.036c-0.165-0.286-0.383-0.504-0.651-0.656c-0.27-0.152-0.562-0.228-0.876-0.228
			c-0.379,0-0.714,0.099-1.004,0.296c-0.291,0.198-0.52,0.474-0.685,0.828c-0.165,0.355-0.248,0.77-0.248,1.244
			c0,0.491,0.083,0.917,0.248,1.28c0.165,0.363,0.394,0.646,0.685,0.848c0.29,0.203,0.62,0.304,0.987,0.304
			c0.299,0,0.586-0.082,0.86-0.244s0.499-0.391,0.672-0.684c0.174-0.293,0.26-0.635,0.26-1.024h0.593
			c0,0.816-0.122,1.495-0.364,2.036c-0.243,0.541-0.567,0.948-0.972,1.22C769.332,788.492,768.886,788.628,768.401,788.628z
			 M770.834,788.404l-0.353-1.92v-9.056h1.904v10.976H770.834z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M776.465,779.22c-0.368,0-0.654-0.097-0.859-0.292c-0.206-0.194-0.309-0.465-0.309-0.812
			c0-0.336,0.103-0.604,0.309-0.804c0.205-0.2,0.491-0.3,0.859-0.3c0.363,0,0.648,0.1,0.856,0.3c0.208,0.2,0.312,0.468,0.312,0.804
			c0,0.347-0.104,0.618-0.312,0.812C777.114,779.123,776.829,779.22,776.465,779.22z M775.522,788.404v-7.792h1.888v7.792H775.522z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M730.998,804.404l0.72-7.662h2.688c0.516,0,0.937,0.091,1.263,0.273c0.326,0.182,0.558,0.426,0.696,0.731
			c0.138,0.307,0.188,0.644,0.152,1.011c-0.04,0.433-0.184,0.808-0.432,1.125c-0.248,0.318-0.584,0.537-1.008,0.657
			c0.264,0.057,0.493,0.166,0.687,0.33c0.194,0.164,0.339,0.368,0.436,0.612c0.096,0.244,0.13,0.514,0.102,0.81
			c-0.02,0.252-0.081,0.503-0.183,0.753c-0.103,0.25-0.254,0.478-0.456,0.682s-0.465,0.368-0.789,0.492s-0.72,0.186-1.188,0.186
			H730.998z M732.143,803.378h1.614c0.46,0,0.802-0.104,1.026-0.314c0.224-0.21,0.352-0.487,0.384-0.831
			c0.04-0.344-0.041-0.62-0.243-0.828s-0.519-0.312-0.951-0.312h-1.613L732.143,803.378z M732.672,797.75l-0.216,2.322h1.59
			c0.396,0,0.715-0.104,0.957-0.312s0.379-0.496,0.411-0.864c0.032-0.353-0.05-0.631-0.246-0.837s-0.498-0.31-0.906-0.31H732.672z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M737.843,804.404l0.546-5.796h1.057l-0.108,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.855-0.297c0.08,0,0.164,0.007,0.252,0.021s0.174,0.039,0.258,0.075l-0.138,1.164
			c-0.104-0.028-0.21-0.051-0.318-0.069c-0.108-0.018-0.214-0.026-0.318-0.026c-0.288,0-0.533,0.086-0.737,0.258
			s-0.365,0.418-0.483,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.239,2.574H737.843z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M744.005,804.554c-0.452,0-0.848-0.124-1.188-0.372c-0.341-0.248-0.596-0.6-0.766-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.061-0.632,0.217-1.176,0.472-1.632c0.254-0.456,0.572-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.355,0,0.66,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.614,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.058,0.981c-0.009,0.112-0.021,0.229-0.039,0.351
			c-0.019,0.122-0.041,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.092,0.38-0.249,0.709-0.471,0.987c-0.223,0.277-0.492,0.493-0.811,0.645
			C744.735,804.478,744.385,804.554,744.005,804.554z M742.962,801.218l-0.234-0.168h3.049l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.135-0.494-0.288-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243c-0.217,0.162-0.39,0.38-0.52,0.654C743.087,800.577,743.002,800.881,742.962,801.218z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M749.339,804.554c-0.42,0-0.768-0.087-1.041-0.259c-0.274-0.172-0.473-0.395-0.594-0.669
			c-0.122-0.273-0.168-0.566-0.136-0.879c0.036-0.38,0.153-0.694,0.352-0.944c0.197-0.25,0.46-0.438,0.786-0.564
			c0.325-0.126,0.692-0.188,1.101-0.188c0.252,0,0.5,0.024,0.744,0.074c0.244,0.051,0.476,0.117,0.696,0.201l0.065-0.66
			c0.052-0.464-0.001-0.802-0.159-1.014s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314
			c-0.107,0.141-0.172,0.314-0.191,0.522h-1.164c0.044-0.44,0.178-0.792,0.401-1.056c0.225-0.265,0.508-0.455,0.853-0.573
			c0.344-0.118,0.712-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54c0.351,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912
			c-0.004-0.147-0.005-0.295-0.003-0.441c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773
			S749.727,804.554,749.339,804.554z M749.597,803.654c0.271,0,0.525-0.066,0.759-0.198c0.234-0.132,0.425-0.316,0.573-0.555
			c0.147-0.238,0.229-0.515,0.246-0.831c-0.196-0.063-0.406-0.116-0.63-0.156c-0.225-0.04-0.435-0.06-0.63-0.06
			c-0.324,0-0.603,0.066-0.834,0.201c-0.232,0.134-0.364,0.364-0.396,0.692c-0.02,0.256,0.049,0.472,0.207,0.646
			S749.285,803.654,749.597,803.654z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M757.583,804.404l-1.655-2.946l-0.69,0.582l-0.222,2.364h-1.057l0.769-8.202h1.062l-0.432,4.596
			l2.496-2.178h1.452l-2.557,2.154l2.131,3.63H757.583z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M763.967,804.404l0.623-6.654h-2.189l0.102-1.008h5.443l-0.104,1.008h-2.195l-0.625,6.654H763.967z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M769.38,804.554c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C770.108,804.478,769.759,804.554,769.38,804.554z M768.336,801.218l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C768.461,800.577,768.376,800.881,768.336,801.218z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M775.048,804.554c-0.433,0-0.822-0.124-1.171-0.372c-0.348-0.248-0.611-0.601-0.792-1.057
			c-0.18-0.456-0.241-0.996-0.186-1.62c0.06-0.647,0.223-1.196,0.489-1.646c0.266-0.45,0.595-0.794,0.986-1.032
			c0.393-0.237,0.807-0.356,1.242-0.356c0.309,0,0.579,0.055,0.813,0.165c0.233,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.464,0.073-0.682,0.219c-0.218,0.146-0.403,0.366-0.555,0.66
			c-0.152,0.294-0.25,0.663-0.294,1.106c-0.036,0.421-0.012,0.779,0.075,1.077c0.086,0.298,0.224,0.525,0.414,0.682
			c0.189,0.155,0.417,0.233,0.681,0.233c0.34,0,0.609-0.097,0.81-0.291c0.2-0.193,0.316-0.463,0.349-0.807h1.067
			c-0.032,0.404-0.148,0.769-0.351,1.095s-0.472,0.584-0.811,0.774C775.871,804.459,775.484,804.554,775.048,804.554z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M778.798,804.404l0.769-8.202h1.062l-0.33,3.42c0.212-0.372,0.469-0.657,0.771-0.854
			c0.302-0.198,0.639-0.297,1.011-0.297c0.42,0,0.766,0.104,1.038,0.312c0.271,0.208,0.466,0.502,0.582,0.882
			s0.149,0.83,0.102,1.351l-0.317,3.39h-1.051l0.312-3.39c0.052-0.492-0.018-0.864-0.207-1.116c-0.19-0.252-0.457-0.378-0.801-0.378
			c-0.284,0-0.538,0.08-0.763,0.24c-0.224,0.159-0.408,0.395-0.555,0.704c-0.146,0.311-0.247,0.689-0.303,1.138l-0.264,2.802
			H778.798z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M785.422,804.404l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906
			s0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375
			c-0.44,0-0.807,0.191-1.099,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H785.422z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M793.985,804.554c-0.473,0-0.887-0.114-1.242-0.343c-0.356-0.228-0.62-0.566-0.792-1.017
			s-0.227-1.009-0.162-1.677c0.063-0.676,0.226-1.24,0.486-1.692c0.26-0.452,0.589-0.791,0.986-1.017
			c0.398-0.227,0.831-0.339,1.3-0.339c0.468,0,0.878,0.114,1.229,0.345c0.352,0.229,0.616,0.57,0.792,1.02
			c0.176,0.45,0.232,1.012,0.168,1.684c-0.068,0.668-0.232,1.227-0.492,1.677s-0.586,0.789-0.978,1.017
			C794.888,804.44,794.457,804.554,793.985,804.554z M794.039,803.534c0.444,0,0.812-0.175,1.104-0.524
			c0.291-0.351,0.472-0.848,0.539-1.491c0.064-0.652-0.009-1.154-0.219-1.506c-0.21-0.353-0.535-0.528-0.975-0.528
			c-0.44,0-0.804,0.175-1.092,0.525c-0.288,0.35-0.465,0.853-0.528,1.509c-0.044,0.424-0.027,0.787,0.051,1.089
			s0.211,0.532,0.399,0.689C793.506,803.455,793.747,803.534,794.039,803.534z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M735.04,824.564c-0.573,0-1.083-0.127-1.527-0.38c-0.445-0.254-0.794-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.251-0.519,0.6-0.903,1.045-1.155
			c0.444-0.252,0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158
			C736.11,824.437,735.606,824.564,735.04,824.564z M735.04,823.109c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532
			s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543
			s0.115,1.188,0.348,1.532C734.319,822.936,734.636,823.109,735.04,823.109z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M741.924,817.644h1.57v4.035h1.17v1.38h-1.17v1.345h-1.591v-1.345h-3.329v-1.195L741.924,817.644z
			 M741.995,819.939l-1.53,1.925h1.53V819.939z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M744.904,823.474c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			s0.31,0.443,0.31,0.74s-0.103,0.541-0.31,0.732s-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S744.904,823.767,744.904,823.474z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M750.644,824.564c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C751.714,824.437,751.212,824.564,750.644,824.564z M750.644,823.109c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S750.241,823.109,750.644,823.109z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M757.38,824.564c-0.573,0-1.083-0.127-1.527-0.38c-0.445-0.254-0.794-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.251-0.519,0.6-0.903,1.045-1.155
			c0.444-0.252,0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158
			C758.45,824.437,757.946,824.564,757.38,824.564z M757.38,823.109c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532
			s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543
			s0.115,1.188,0.348,1.532C756.659,822.936,756.976,823.109,757.38,823.109z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M761.284,821.979v-1.4h4.695v1.4H761.284z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M769.915,824.564c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C770.985,824.437,770.481,824.564,769.915,824.564z
			 M769.915,823.109c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C769.194,822.936,769.511,823.109,769.915,823.109z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M776.395,824.564c-0.54,0-1.004-0.091-1.393-0.272s-0.689-0.444-0.902-0.788
			c-0.214-0.343-0.328-0.756-0.345-1.239h1.64c0,0.286,0.095,0.499,0.285,0.637c0.189,0.139,0.428,0.208,0.715,0.208
			c0.276,0,0.516-0.097,0.718-0.29c0.201-0.193,0.302-0.43,0.302-0.71c0-0.521-0.368-0.78-1.104-0.78h-2.295l0.33-3.635h4.319v1.47
			h-3.034l0.305-0.415l-0.17,1.655l-0.101-0.13c0.16-0.037,0.359-0.065,0.598-0.088c0.238-0.021,0.437-0.032,0.593-0.032
			c0.66,0,1.19,0.174,1.59,0.52c0.4,0.348,0.601,0.825,0.601,1.436c0,0.453-0.112,0.866-0.335,1.237
			c-0.224,0.372-0.534,0.668-0.931,0.888S776.922,824.564,776.395,824.564z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M779.45,823.474c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S779.45,823.767,779.45,823.474z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M785.19,824.564c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C786.26,824.437,785.756,824.564,785.19,824.564z
			 M785.19,823.109c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C784.469,822.936,784.787,823.109,785.19,823.109z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M791.925,824.564c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C792.996,824.437,792.492,824.564,791.925,824.564z
			 M791.925,823.109c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C791.205,822.936,791.522,823.109,791.925,823.109z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M746.637,902.566v-1.888c0.427,0.245,0.844,0.424,1.252,0.536s0.785,0.168,1.132,0.168
			c0.459,0,0.792-0.133,1-0.4c0.208-0.267,0.312-0.672,0.312-1.216v-7.151h1.903v7.151c0,0.838-0.131,1.51-0.392,2.017
			c-0.262,0.506-0.621,0.873-1.08,1.1s-0.986,0.34-1.584,0.34c-0.47,0-0.917-0.062-1.344-0.188
			C747.411,902.909,747.01,902.754,746.637,902.566z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M758.021,903.223c-0.71,0-1.351-0.151-1.924-0.456c-0.574-0.304-1.028-0.759-1.364-1.364
			c-0.336-0.604-0.504-1.363-0.504-2.275c0-0.928,0.168-1.696,0.504-2.304c0.336-0.608,0.79-1.062,1.364-1.36
			c0.573-0.299,1.214-0.448,1.924-0.448c0.698,0,1.332,0.149,1.899,0.448c0.568,0.299,1.021,0.752,1.356,1.36
			c0.336,0.607,0.504,1.376,0.504,2.304c0,0.912-0.168,1.671-0.504,2.275c-0.336,0.605-0.788,1.061-1.356,1.364
			C759.353,903.071,758.719,903.223,758.021,903.223z M757.957,901.575c0.373,0,0.705-0.093,0.996-0.276s0.521-0.457,0.688-0.82
			c0.168-0.362,0.252-0.812,0.252-1.352c0-0.821-0.162-1.44-0.484-1.856s-0.764-0.624-1.324-0.624c-0.378,0-0.715,0.093-1.008,0.276
			s-0.523,0.46-0.691,0.828s-0.253,0.826-0.253,1.376c0,0.811,0.164,1.421,0.492,1.832
			C756.953,901.369,757.397,901.575,757.957,901.575z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M764.021,902.999v-7.76h1.647l0.24,1.535v6.225H764.021z M769.349,902.999v-4.544
			c0-0.576-0.139-1.011-0.416-1.305c-0.277-0.293-0.641-0.439-1.089-0.439c-0.378,0-0.713,0.104-1.004,0.312
			c-0.29,0.208-0.519,0.521-0.684,0.94s-0.248,0.939-0.248,1.563h-0.624c0-1.008,0.125-1.847,0.376-2.516s0.605-1.169,1.064-1.5
			c0.458-0.331,0.997-0.496,1.615-0.496c0.641,0,1.175,0.143,1.604,0.428c0.429,0.286,0.752,0.688,0.968,1.204
			c0.216,0.518,0.324,1.12,0.324,1.809v4.544H769.349z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M772.804,895.239h2.145l2.063,6.111h-0.336l1.776-6.111h1.92l-2.976,9.168
			c-0.305,0.917-0.68,1.581-1.124,1.992c-0.446,0.41-0.988,0.615-1.629,0.615c-0.367,0-0.727-0.041-1.075-0.124
			c-0.35-0.082-0.706-0.212-1.068-0.388v-1.664c0.293,0.182,0.577,0.314,0.852,0.4c0.275,0.085,0.551,0.128,0.828,0.128
			c0.251,0,0.471-0.068,0.66-0.204s0.354-0.325,0.492-0.568c0.139-0.242,0.256-0.523,0.352-0.844l0.24-0.752L772.804,895.239z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M728.06,918.999l0.624-6.654h-2.189l0.102-1.008h5.442l-0.103,1.008h-2.195l-0.624,6.654H728.06z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M733.472,919.149c-0.452,0-0.848-0.124-1.188-0.372c-0.341-0.248-0.596-0.6-0.766-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.061-0.632,0.217-1.176,0.472-1.632c0.254-0.456,0.572-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.355,0,0.66,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.614,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.058,0.981c-0.009,0.112-0.021,0.229-0.039,0.351
			c-0.019,0.122-0.041,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.092,0.38-0.249,0.709-0.471,0.987c-0.223,0.277-0.492,0.493-0.811,0.645
			C734.202,919.073,733.852,919.149,733.472,919.149z M732.428,915.814l-0.234-0.168h3.049l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.135-0.494-0.288-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243c-0.217,0.162-0.39,0.38-0.52,0.654C732.553,915.172,732.468,915.477,732.428,915.814z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M739.142,919.149c-0.433,0-0.822-0.124-1.17-0.372c-0.349-0.248-0.612-0.601-0.792-1.057
			c-0.181-0.456-0.242-0.996-0.187-1.62c0.061-0.647,0.224-1.196,0.489-1.646s0.595-0.794,0.987-1.032
			c0.392-0.237,0.806-0.356,1.241-0.356c0.309,0,0.579,0.055,0.813,0.165c0.234,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.463,0.073-0.682,0.219c-0.218,0.146-0.402,0.366-0.555,0.66
			s-0.25,0.663-0.294,1.106c-0.036,0.421-0.011,0.779,0.075,1.077s0.224,0.525,0.414,0.682c0.189,0.155,0.417,0.233,0.681,0.233
			c0.34,0,0.61-0.097,0.811-0.291c0.199-0.193,0.315-0.463,0.348-0.807h1.068c-0.032,0.404-0.149,0.769-0.352,1.095
			s-0.472,0.584-0.81,0.774C739.964,919.054,739.578,919.149,739.142,919.149z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M742.891,918.999l0.769-8.202h1.062l-0.33,3.42c0.213-0.372,0.47-0.657,0.771-0.854
			c0.302-0.198,0.639-0.297,1.011-0.297c0.42,0,0.767,0.104,1.038,0.312c0.272,0.208,0.466,0.502,0.582,0.882s0.15,0.83,0.103,1.351
			l-0.318,3.39h-1.05l0.312-3.39c0.052-0.492-0.017-0.864-0.207-1.116c-0.189-0.252-0.457-0.378-0.801-0.378
			c-0.284,0-0.538,0.08-0.762,0.24c-0.225,0.159-0.409,0.395-0.556,0.704c-0.146,0.311-0.247,0.689-0.303,1.138l-0.264,2.802
			H742.891z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M749.515,918.999l0.546-5.784h0.912l0.013,1.062c0.212-0.4,0.472-0.702,0.779-0.906
			c0.309-0.204,0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882s0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.013-0.869-0.207-1.119s-0.459-0.375-0.795-0.375c-0.44,0-0.807,0.191-1.098,0.576
			c-0.293,0.384-0.473,0.934-0.54,1.649l-0.246,2.658H749.515z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M758.078,919.149c-0.472,0-0.886-0.114-1.242-0.343c-0.355-0.228-0.62-0.566-0.792-1.017
			s-0.226-1.009-0.162-1.677c0.064-0.676,0.227-1.24,0.486-1.692s0.589-0.791,0.987-1.017c0.397-0.227,0.831-0.339,1.299-0.339
			s0.878,0.114,1.229,0.345c0.353,0.229,0.616,0.57,0.792,1.02c0.177,0.45,0.232,1.012,0.168,1.684
			c-0.067,0.668-0.231,1.227-0.491,1.677c-0.261,0.45-0.587,0.789-0.979,1.017C758.982,919.035,758.549,919.149,758.078,919.149z
			 M758.131,918.129c0.444,0,0.812-0.175,1.104-0.524c0.292-0.351,0.472-0.848,0.54-1.491c0.063-0.652-0.01-1.154-0.22-1.506
			c-0.21-0.353-0.534-0.528-0.975-0.528s-0.804,0.175-1.092,0.525c-0.288,0.35-0.464,0.853-0.528,1.509
			c-0.044,0.424-0.026,0.787,0.051,1.089c0.078,0.302,0.211,0.532,0.399,0.689C757.599,918.05,757.839,918.129,758.131,918.129z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M764.787,918.999l0.72-7.662h2.688c0.516,0,0.937,0.091,1.263,0.273c0.326,0.182,0.559,0.426,0.696,0.731
			c0.138,0.307,0.189,0.644,0.153,1.011c-0.04,0.433-0.185,0.808-0.433,1.125c-0.248,0.318-0.584,0.537-1.008,0.657
			c0.264,0.057,0.493,0.166,0.688,0.33c0.193,0.164,0.339,0.368,0.435,0.612s0.13,0.514,0.102,0.81
			c-0.02,0.252-0.08,0.503-0.183,0.753c-0.102,0.25-0.254,0.478-0.456,0.682s-0.465,0.368-0.789,0.492s-0.72,0.186-1.188,0.186
			H764.787z M765.933,917.973h1.613c0.46,0,0.803-0.104,1.026-0.314s0.352-0.487,0.384-0.831c0.04-0.344-0.041-0.62-0.243-0.828
			c-0.201-0.208-0.519-0.312-0.95-0.312h-1.614L765.933,917.973z M766.461,912.345l-0.216,2.322h1.59
			c0.396,0,0.715-0.104,0.957-0.312s0.379-0.496,0.411-0.864c0.032-0.353-0.05-0.631-0.246-0.837
			c-0.195-0.206-0.498-0.31-0.905-0.31H766.461z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M773.577,919.149c-0.473,0-0.887-0.114-1.242-0.343c-0.356-0.228-0.62-0.566-0.792-1.017
			s-0.227-1.009-0.162-1.677c0.063-0.676,0.226-1.24,0.486-1.692c0.26-0.452,0.589-0.791,0.986-1.017
			c0.398-0.227,0.831-0.339,1.3-0.339c0.468,0,0.878,0.114,1.229,0.345c0.352,0.229,0.616,0.57,0.792,1.02
			c0.176,0.45,0.232,1.012,0.168,1.684c-0.068,0.668-0.232,1.227-0.492,1.677s-0.586,0.789-0.978,1.017
			C774.48,919.035,774.048,919.149,773.577,919.149z M773.63,918.129c0.444,0,0.812-0.175,1.104-0.524
			c0.291-0.351,0.472-0.848,0.539-1.491c0.064-0.652-0.009-1.154-0.219-1.506c-0.21-0.353-0.535-0.528-0.975-0.528
			c-0.44,0-0.804,0.175-1.092,0.525c-0.288,0.35-0.465,0.853-0.528,1.509c-0.044,0.424-0.027,0.787,0.051,1.089
			s0.211,0.532,0.399,0.689C773.098,918.05,773.339,918.129,773.63,918.129z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M779.457,919.107c-0.38,0-0.711-0.077-0.993-0.231s-0.493-0.395-0.633-0.723s-0.187-0.75-0.138-1.267
			l0.354-3.684h1.056l-0.342,3.576c-0.04,0.432,0.024,0.757,0.192,0.975s0.426,0.327,0.773,0.327c0.288,0,0.552-0.085,0.789-0.255
			c0.238-0.17,0.437-0.411,0.595-0.724c0.157-0.312,0.257-0.682,0.297-1.109l0.264-2.79h1.056l-0.54,5.796h-1.062l0.102-1.092
			c-0.203,0.392-0.458,0.689-0.762,0.894S779.825,919.107,779.457,919.107z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M784.281,918.999l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906
			s0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375
			c-0.44,0-0.807,0.191-1.099,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H784.281z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M792.795,919.149c-0.433,0-0.822-0.124-1.171-0.372c-0.348-0.248-0.611-0.601-0.792-1.057
			c-0.18-0.456-0.241-0.996-0.186-1.62c0.06-0.647,0.223-1.196,0.489-1.646c0.266-0.45,0.595-0.794,0.986-1.032
			c0.393-0.237,0.807-0.356,1.242-0.356c0.309,0,0.579,0.055,0.813,0.165c0.233,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.464,0.073-0.682,0.219c-0.218,0.146-0.403,0.366-0.555,0.66
			c-0.152,0.294-0.25,0.663-0.294,1.106c-0.036,0.421-0.012,0.779,0.075,1.077c0.086,0.298,0.224,0.525,0.414,0.682
			c0.189,0.155,0.417,0.233,0.681,0.233c0.34,0,0.609-0.097,0.81-0.291c0.2-0.193,0.316-0.463,0.349-0.807h1.067
			c-0.032,0.404-0.148,0.769-0.351,1.095s-0.472,0.584-0.811,0.774C793.617,919.054,793.23,919.149,792.795,919.149z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M798.405,919.149c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C799.133,919.073,798.785,919.149,798.405,919.149z M797.361,915.814l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C797.486,915.172,797.401,915.477,797.361,915.814z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M743.987,939.159c-0.44,0-0.811-0.074-1.112-0.223s-0.529-0.35-0.683-0.603s-0.23-0.533-0.23-0.84
			c0-0.29,0.08-0.541,0.24-0.753c0.16-0.211,0.397-0.375,0.713-0.492c0.314-0.116,0.702-0.175,1.162-0.175
			c0.28,0,0.527,0.015,0.742,0.045s0.437,0.066,0.663,0.11v0.899c-0.167-0.036-0.34-0.064-0.518-0.085
			c-0.179-0.02-0.353-0.03-0.522-0.03c-0.237,0-0.425,0.038-0.562,0.113c-0.139,0.074-0.208,0.197-0.208,0.367
			c0,0.123,0.062,0.23,0.186,0.322c0.123,0.092,0.318,0.138,0.585,0.138c0.227,0,0.404-0.045,0.535-0.135
			c0.13-0.09,0.222-0.203,0.274-0.34c0.054-0.137,0.08-0.272,0.08-0.405h0.375c0,0.38-0.06,0.729-0.18,1.045
			s-0.307,0.569-0.561,0.758C744.714,939.065,744.386,939.159,743.987,939.159z M745.537,938.999l-0.205-1.315v-1.845
			c0-0.246-0.045-0.438-0.135-0.575s-0.282-0.205-0.575-0.205c-0.173,0-0.314,0.026-0.423,0.078s-0.188,0.12-0.239,0.205
			s-0.078,0.177-0.078,0.277h-1.729c0-0.387,0.092-0.706,0.274-0.958c0.184-0.251,0.459-0.438,0.825-0.562
			c0.367-0.123,0.823-0.185,1.37-0.185c0.477,0,0.888,0.073,1.232,0.22s0.611,0.367,0.798,0.66s0.28,0.662,0.28,1.105v3.1H745.537z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M748.517,938.999v-6.9h1.6v5.615l-0.6,1.285H748.517z M751.301,939.159c-0.283,0-0.547-0.086-0.792-0.258
			s-0.441-0.437-0.59-0.795s-0.223-0.815-0.223-1.372h0.42c0,0.173,0.046,0.333,0.138,0.479s0.208,0.265,0.35,0.353
			c0.142,0.089,0.286,0.133,0.433,0.133c0.203,0,0.378-0.052,0.522-0.155c0.146-0.104,0.258-0.245,0.338-0.425s0.12-0.384,0.12-0.61
			c0-0.217-0.04-0.411-0.118-0.585c-0.078-0.173-0.19-0.31-0.337-0.41c-0.147-0.1-0.325-0.149-0.535-0.149
			c-0.164,0-0.314,0.038-0.453,0.115c-0.138,0.076-0.249,0.184-0.332,0.322c-0.083,0.138-0.125,0.301-0.125,0.487h-0.42
			c0-0.53,0.067-0.971,0.202-1.322s0.332-0.615,0.59-0.79c0.259-0.175,0.573-0.263,0.942-0.263c0.414,0,0.784,0.108,1.113,0.325
			c0.328,0.217,0.589,0.519,0.782,0.907s0.29,0.843,0.29,1.362c0,0.527-0.103,0.989-0.308,1.388s-0.481,0.708-0.83,0.93
			S751.738,939.159,751.301,939.159z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M760.111,939.159c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C761.181,939.032,760.678,939.159,760.111,939.159z M760.111,937.704c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S759.708,937.704,760.111,937.704z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M766.591,939.159c-0.54,0-1.004-0.091-1.393-0.272s-0.689-0.444-0.902-0.788
			c-0.214-0.343-0.329-0.756-0.345-1.239h1.64c0,0.286,0.095,0.499,0.285,0.637c0.189,0.139,0.428,0.208,0.715,0.208
			c0.276,0,0.516-0.097,0.718-0.29c0.201-0.193,0.302-0.43,0.302-0.71c0-0.521-0.368-0.78-1.104-0.78h-2.295l0.33-3.635h4.319v1.47
			h-3.034l0.305-0.415l-0.17,1.655l-0.101-0.13c0.16-0.037,0.359-0.065,0.598-0.088c0.238-0.021,0.437-0.032,0.593-0.032
			c0.66,0,1.19,0.174,1.59,0.52c0.4,0.348,0.601,0.825,0.601,1.436c0,0.453-0.112,0.866-0.335,1.237
			c-0.224,0.372-0.534,0.668-0.931,0.888S767.118,939.159,766.591,939.159z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M769.646,938.069c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S769.646,938.362,769.646,938.069z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M775.386,939.159c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C776.457,939.032,775.953,939.159,775.386,939.159z
			 M775.386,937.704c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C774.666,937.531,774.983,937.704,775.386,937.704z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M782.122,939.159c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C783.192,939.032,782.688,939.159,782.122,939.159z
			 M782.122,937.704c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C781.401,937.531,781.718,937.704,782.122,937.704z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M101.566,236.595c-0.63,0-1.21-0.11-1.739-0.329c-0.529-0.22-0.959-0.553-1.288-1.001
			c-0.329-0.448-0.505-1.018-0.528-1.708h1.624c0,0.35,0.086,0.637,0.259,0.861s0.405,0.391,0.696,0.5
			c0.292,0.11,0.613,0.165,0.963,0.165c0.336,0,0.636-0.044,0.899-0.133c0.264-0.088,0.472-0.216,0.623-0.381
			s0.228-0.363,0.228-0.591c0-0.303-0.092-0.542-0.276-0.714c-0.185-0.172-0.479-0.331-0.886-0.476l-1.792-0.714
			c-0.7-0.275-1.236-0.595-1.609-0.959c-0.374-0.364-0.561-0.865-0.561-1.505c0-0.737,0.292-1.334,0.875-1.792
			c0.583-0.457,1.397-0.686,2.443-0.686c1.017,0,1.804,0.237,2.358,0.71c0.556,0.474,0.871,1.133,0.945,1.978h-1.624
			c-0.061-0.359-0.224-0.647-0.49-0.864c-0.266-0.217-0.662-0.326-1.189-0.326c-0.467,0-0.836,0.097-1.106,0.291
			c-0.271,0.194-0.406,0.438-0.406,0.731c0,0.252,0.086,0.452,0.256,0.599s0.436,0.288,0.795,0.423l1.987,0.77
			c0.673,0.261,1.188,0.59,1.548,0.987c0.358,0.397,0.538,0.894,0.538,1.491c0,0.546-0.138,1.019-0.412,1.417
			c-0.275,0.399-0.677,0.708-1.204,0.928C102.964,236.485,102.323,236.595,101.566,236.595z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M107.327,239.717v-10.108h1.358l0.294,1.68v8.428H107.327z M110.729,236.595
			c-0.499,0-0.924-0.107-1.273-0.322c-0.351-0.215-0.617-0.54-0.802-0.977c-0.185-0.436-0.276-0.983-0.276-1.642h0.602
			c0,0.304,0.07,0.562,0.21,0.777c0.141,0.214,0.325,0.379,0.557,0.493c0.231,0.115,0.479,0.172,0.745,0.172
			c0.275,0,0.548-0.076,0.816-0.228c0.268-0.152,0.49-0.383,0.668-0.693c0.178-0.31,0.266-0.703,0.266-1.179
			c0-0.434-0.074-0.809-0.224-1.124s-0.354-0.557-0.612-0.725s-0.555-0.252-0.886-0.252c-0.28,0-0.536,0.063-0.77,0.189
			s-0.42,0.309-0.561,0.549c-0.14,0.241-0.21,0.531-0.21,0.872h-0.602c0-0.714,0.109-1.298,0.329-1.753
			c0.219-0.455,0.513-0.792,0.882-1.012c0.368-0.219,0.772-0.329,1.211-0.329c0.536,0,1.042,0.142,1.516,0.427
			s0.857,0.694,1.151,1.229c0.294,0.535,0.44,1.177,0.44,1.929c0,0.756-0.149,1.402-0.447,1.939
			c-0.299,0.537-0.689,0.947-1.173,1.232S111.285,236.595,110.729,236.595z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M118.401,236.595c-0.579,0-1.108-0.149-1.589-0.448c-0.481-0.299-0.866-0.718-1.155-1.256
			c-0.289-0.539-0.434-1.17-0.434-1.894c0-0.747,0.143-1.389,0.43-1.925s0.671-0.947,1.152-1.232
			c0.48-0.285,1.012-0.427,1.596-0.427c0.541,0,1.009,0.108,1.403,0.325c0.395,0.217,0.712,0.511,0.952,0.882
			c0.24,0.371,0.402,0.79,0.486,1.256s0.089,0.947,0.014,1.442h-4.718v-0.896h3.549l-0.321,0.21
			c0.014-0.219,0.003-0.441-0.032-0.665c-0.035-0.224-0.102-0.429-0.199-0.616s-0.237-0.337-0.416-0.452
			c-0.18-0.114-0.41-0.171-0.69-0.171c-0.336,0-0.62,0.084-0.854,0.252s-0.411,0.396-0.532,0.686s-0.182,0.616-0.182,0.98v0.658
			c0,0.383,0.057,0.721,0.171,1.015c0.115,0.294,0.285,0.525,0.512,0.693c0.226,0.168,0.512,0.252,0.857,0.252
			c0.322,0,0.593-0.075,0.812-0.224c0.22-0.149,0.359-0.374,0.42-0.672h1.666c-0.069,0.438-0.234,0.825-0.493,1.158
			c-0.259,0.334-0.594,0.595-1.005,0.784C119.39,236.501,118.923,236.595,118.401,236.595z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M125.863,236.595c-0.569,0-1.098-0.148-1.586-0.445c-0.487-0.296-0.882-0.714-1.183-1.253
			s-0.451-1.172-0.451-1.9c0-0.77,0.15-1.422,0.451-1.957s0.695-0.939,1.183-1.215c0.488-0.275,1.017-0.413,1.586-0.413
			c0.443,0,0.834,0.071,1.172,0.213c0.339,0.143,0.627,0.333,0.865,0.574c0.237,0.241,0.424,0.508,0.56,0.802
			s0.222,0.59,0.259,0.889l-1.666,0.042c-0.074-0.299-0.213-0.546-0.416-0.742s-0.466-0.294-0.788-0.294
			c-0.266,0-0.516,0.073-0.748,0.22c-0.233,0.147-0.424,0.375-0.571,0.683c-0.146,0.308-0.22,0.707-0.22,1.197
			c0,0.467,0.068,0.855,0.206,1.166c0.138,0.31,0.323,0.543,0.557,0.7c0.233,0.157,0.492,0.235,0.776,0.235
			c0.421,0,0.726-0.102,0.917-0.305s0.287-0.475,0.287-0.815h1.666c0,0.48-0.109,0.919-0.329,1.316
			c-0.219,0.396-0.541,0.713-0.966,0.948C126.999,236.477,126.479,236.595,125.863,236.595z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M131.666,228.363c-0.322,0-0.572-0.085-0.752-0.255s-0.27-0.407-0.27-0.71c0-0.294,0.09-0.528,0.27-0.704
			c0.18-0.175,0.43-0.262,0.752-0.262c0.317,0,0.567,0.087,0.749,0.262c0.183,0.175,0.273,0.41,0.273,0.704
			c0,0.303-0.091,0.54-0.273,0.71C132.233,228.278,131.983,228.363,131.666,228.363z M130.839,236.399v-6.818h1.652v6.818H130.839z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M137.391,236.595c-0.583,0-1.069-0.102-1.459-0.305s-0.683-0.47-0.879-0.802
			c-0.195-0.331-0.294-0.69-0.294-1.078c0-0.43,0.113-0.79,0.34-1.082c0.227-0.292,0.547-0.511,0.962-0.658
			c0.416-0.147,0.906-0.221,1.471-0.221c0.368,0,0.717,0.028,1.046,0.084c0.329,0.056,0.657,0.13,0.983,0.224v0.966
			c-0.256-0.084-0.536-0.149-0.84-0.196c-0.303-0.046-0.588-0.07-0.854-0.07c-0.393,0-0.714,0.072-0.966,0.217
			c-0.252,0.145-0.378,0.39-0.378,0.735c0,0.252,0.103,0.469,0.308,0.651s0.532,0.273,0.979,0.273c0.341,0,0.631-0.075,0.868-0.224
			c0.238-0.149,0.419-0.336,0.543-0.56c0.123-0.224,0.186-0.448,0.186-0.672h0.392c0,0.49-0.091,0.941-0.272,1.354
			c-0.183,0.413-0.452,0.743-0.809,0.991C138.361,236.471,137.919,236.595,137.391,236.595z M139.618,236.399l-0.21-1.652v-2.73
			c0-0.476-0.097-0.829-0.291-1.057c-0.193-0.229-0.521-0.343-0.983-0.343c-0.266,0-0.497,0.04-0.692,0.119
			c-0.196,0.08-0.347,0.192-0.452,0.339c-0.104,0.147-0.157,0.326-0.157,0.536h-1.806c0-0.513,0.123-0.932,0.371-1.256
			c0.247-0.324,0.603-0.562,1.067-0.714c0.464-0.152,1.021-0.228,1.669-0.228c0.588,0,1.102,0.094,1.54,0.283
			s0.779,0.479,1.022,0.872c0.242,0.392,0.363,0.894,0.363,1.505v4.326H139.618z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M143.481,236.399v-9.604h1.652v9.604H143.481z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M154.605,236.595c-0.742,0-1.412-0.196-2.009-0.588c-0.598-0.392-1.072-0.939-1.425-1.642
			c-0.353-0.702-0.528-1.522-0.528-2.46c0-0.742,0.108-1.407,0.325-1.995c0.217-0.588,0.52-1.09,0.906-1.505
			c0.388-0.415,0.843-0.731,1.365-0.948c0.522-0.217,1.09-0.326,1.701-0.326c0.85,0,1.562,0.185,2.139,0.553s1.025,0.95,1.348,1.743
			l-1.694,0.392c-0.238-0.387-0.513-0.683-0.822-0.885c-0.311-0.203-0.662-0.305-1.054-0.305c-0.448,0-0.856,0.132-1.225,0.396
			c-0.369,0.264-0.663,0.641-0.883,1.13c-0.219,0.49-0.329,1.074-0.329,1.75c0,0.635,0.098,1.19,0.291,1.666
			s0.466,0.847,0.815,1.113c0.35,0.266,0.756,0.399,1.218,0.399c0.393,0,0.743-0.087,1.054-0.263
			c0.311-0.175,0.556-0.426,0.735-0.752s0.27-0.721,0.27-1.183h0.363c0,0.756-0.09,1.412-0.27,1.967
			c-0.18,0.555-0.458,0.984-0.836,1.288C155.683,236.443,155.198,236.595,154.605,236.595z M157.041,236.399l-0.237-2.002v-1.568
			l-2.044-0.042v-1.05h3.626v4.662H157.041z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M160.625,229.581h1.652v4.172c0,0.443,0.109,0.776,0.328,0.998c0.22,0.222,0.521,0.333,0.903,0.333
			c0.327,0,0.624-0.085,0.893-0.255s0.483-0.422,0.644-0.756c0.161-0.333,0.242-0.748,0.242-1.242h0.448
			c0,0.831-0.107,1.519-0.322,2.065s-0.511,0.954-0.89,1.225c-0.378,0.271-0.813,0.406-1.309,0.406
			c-0.485,0-0.924-0.086-1.316-0.259c-0.392-0.173-0.702-0.451-0.931-0.833c-0.229-0.383-0.343-0.887-0.343-1.512V229.581z
			 M165.287,229.581h1.651v6.818h-1.651V229.581z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M172.077,236.595c-0.579,0-1.108-0.149-1.589-0.448s-0.866-0.718-1.155-1.256
			c-0.289-0.539-0.434-1.17-0.434-1.894c0-0.747,0.144-1.389,0.431-1.925s0.671-0.947,1.151-1.232s1.013-0.427,1.596-0.427
			c0.541,0,1.009,0.108,1.403,0.325c0.395,0.217,0.712,0.511,0.952,0.882c0.24,0.371,0.402,0.79,0.486,1.256
			s0.089,0.947,0.015,1.442h-4.719v-0.896h3.55l-0.322,0.21c0.014-0.219,0.003-0.441-0.031-0.665
			c-0.035-0.224-0.102-0.429-0.2-0.616c-0.098-0.187-0.236-0.337-0.416-0.452s-0.41-0.171-0.689-0.171
			c-0.336,0-0.621,0.084-0.854,0.252s-0.41,0.396-0.532,0.686c-0.121,0.29-0.182,0.616-0.182,0.98v0.658
			c0,0.383,0.058,0.721,0.172,1.015s0.284,0.525,0.511,0.693c0.227,0.168,0.512,0.252,0.857,0.252c0.322,0,0.593-0.075,0.812-0.224
			c0.219-0.149,0.358-0.374,0.42-0.672h1.666c-0.07,0.438-0.235,0.825-0.494,1.158c-0.259,0.334-0.594,0.595-1.004,0.784
			C173.066,236.501,172.599,236.595,172.077,236.595z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M179.497,236.595c-0.653,0-1.215-0.09-1.684-0.27c-0.469-0.179-0.829-0.438-1.081-0.777
			s-0.381-0.746-0.386-1.221h1.526c0.014,0.336,0.147,0.581,0.399,0.735c0.252,0.154,0.595,0.231,1.028,0.231
			c0.238,0,0.449-0.035,0.634-0.105s0.328-0.166,0.431-0.287c0.103-0.121,0.154-0.256,0.154-0.406c0-0.191-0.069-0.342-0.207-0.452
			s-0.358-0.211-0.661-0.305l-1.484-0.532c-0.532-0.191-0.94-0.443-1.225-0.756c-0.285-0.312-0.428-0.686-0.428-1.12
			c0-0.574,0.261-1.037,0.781-1.39c0.521-0.352,1.217-0.528,2.089-0.528c0.868,0,1.539,0.19,2.013,0.57s0.718,0.862,0.731,1.445
			h-1.554c-0.023-0.224-0.131-0.401-0.322-0.532s-0.48-0.196-0.868-0.196c-0.35,0-0.624,0.071-0.822,0.214
			c-0.198,0.142-0.298,0.309-0.298,0.5c0,0.322,0.275,0.564,0.826,0.728l1.61,0.602c0.522,0.177,0.929,0.406,1.218,0.686
			c0.289,0.28,0.435,0.634,0.435,1.064c0,0.359-0.12,0.699-0.361,1.018c-0.24,0.32-0.575,0.58-1.004,0.781
			C180.558,236.495,180.061,236.595,179.497,236.595z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M183.494,230.701v-1.092h4.452v1.092H183.494z M186.643,236.595c-0.401,0-0.769-0.075-1.103-0.224
			c-0.333-0.149-0.6-0.378-0.798-0.686s-0.297-0.7-0.297-1.176v-6.706h1.651v6.65c0,0.56,0.267,0.84,0.798,0.84
			c0.164,0,0.327-0.019,0.49-0.056c0.163-0.037,0.351-0.093,0.561-0.168l0.237,1.162c-0.261,0.103-0.516,0.189-0.763,0.259
			C187.173,236.56,186.914,236.595,186.643,236.595z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M85.374,252.399l0.72-7.662h1.057l-0.631,6.636h2.958l-0.096,1.026H85.374z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M92.118,252.549c-0.42,0-0.768-0.087-1.041-0.259c-0.274-0.172-0.473-0.395-0.594-0.669
			c-0.122-0.273-0.168-0.566-0.136-0.879c0.036-0.38,0.153-0.694,0.352-0.944c0.197-0.25,0.46-0.438,0.786-0.564
			c0.325-0.126,0.692-0.188,1.101-0.188c0.252,0,0.5,0.024,0.744,0.074c0.244,0.051,0.476,0.117,0.696,0.201l0.065-0.66
			c0.052-0.464-0.001-0.802-0.159-1.014s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314
			c-0.107,0.141-0.172,0.314-0.191,0.522H90.78c0.044-0.44,0.178-0.792,0.401-1.056c0.225-0.265,0.508-0.455,0.853-0.573
			c0.344-0.118,0.712-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54c0.351,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912
			c-0.004-0.147-0.005-0.295-0.003-0.441c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773
			S92.505,252.549,92.118,252.549z M92.376,251.649c0.271,0,0.525-0.066,0.759-0.198c0.234-0.132,0.425-0.316,0.573-0.555
			c0.147-0.238,0.229-0.515,0.246-0.831c-0.196-0.063-0.406-0.116-0.63-0.156c-0.225-0.04-0.435-0.06-0.63-0.06
			c-0.324,0-0.603,0.066-0.834,0.201c-0.232,0.134-0.364,0.364-0.396,0.692c-0.02,0.256,0.049,0.472,0.207,0.646
			S92.063,251.649,92.376,251.649z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M98.538,252.549c-0.444,0-0.829-0.077-1.155-0.231s-0.574-0.372-0.744-0.654
			c-0.17-0.281-0.237-0.614-0.201-0.999h0.996c-0.023,0.309,0.067,0.546,0.273,0.711c0.206,0.166,0.482,0.249,0.831,0.249
			c0.352,0,0.637-0.076,0.854-0.23s0.341-0.347,0.369-0.579c0.024-0.204-0.034-0.361-0.174-0.471
			c-0.141-0.11-0.326-0.205-0.559-0.285l-1.031-0.414c-0.4-0.152-0.703-0.36-0.909-0.624s-0.289-0.584-0.249-0.96
			c0.036-0.324,0.15-0.606,0.345-0.846c0.194-0.24,0.454-0.426,0.78-0.556s0.697-0.194,1.113-0.194c0.663,0,1.157,0.153,1.481,0.462
			c0.324,0.308,0.472,0.695,0.444,1.164h-1.015c-0.004-0.229-0.09-0.402-0.258-0.522s-0.397-0.18-0.689-0.18
			c-0.236,0-0.432,0.035-0.585,0.104c-0.154,0.07-0.273,0.159-0.357,0.268c-0.084,0.107-0.136,0.224-0.156,0.348
			c-0.02,0.188,0.037,0.343,0.171,0.465c0.135,0.122,0.312,0.219,0.531,0.291l1.092,0.45c0.385,0.14,0.688,0.331,0.909,0.573
			c0.223,0.242,0.315,0.551,0.279,0.927c-0.028,0.296-0.141,0.576-0.339,0.84s-0.469,0.479-0.813,0.646
			C99.429,252.466,99.017,252.549,98.538,252.549z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M104.219,252.549c-0.444,0-0.829-0.077-1.155-0.231c-0.325-0.154-0.573-0.372-0.743-0.654
			c-0.171-0.281-0.237-0.614-0.201-0.999h0.996c-0.024,0.309,0.066,0.546,0.272,0.711c0.206,0.166,0.483,0.249,0.831,0.249
			c0.353,0,0.637-0.076,0.855-0.23c0.218-0.154,0.341-0.347,0.369-0.579c0.023-0.204-0.034-0.361-0.175-0.471
			c-0.14-0.11-0.326-0.205-0.558-0.285l-1.032-0.414c-0.399-0.152-0.703-0.36-0.909-0.624s-0.289-0.584-0.249-0.96
			c0.036-0.324,0.151-0.606,0.346-0.846c0.193-0.24,0.454-0.426,0.779-0.556c0.326-0.13,0.697-0.194,1.113-0.194
			c0.664,0,1.158,0.153,1.482,0.462c0.323,0.308,0.472,0.695,0.443,1.164h-1.014c-0.004-0.229-0.09-0.402-0.258-0.522
			s-0.398-0.18-0.69-0.18c-0.235,0-0.431,0.035-0.585,0.104c-0.154,0.07-0.272,0.159-0.356,0.268
			c-0.084,0.107-0.137,0.224-0.156,0.348c-0.021,0.188,0.037,0.343,0.171,0.465s0.311,0.219,0.531,0.291l1.092,0.45
			c0.384,0.14,0.687,0.331,0.909,0.573c0.222,0.242,0.314,0.551,0.278,0.927c-0.027,0.296-0.141,0.576-0.339,0.84
			c-0.197,0.264-0.469,0.479-0.812,0.646C105.111,252.466,104.7,252.549,104.219,252.549z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M112.631,252.549c-0.4,0-0.757-0.109-1.071-0.327c-0.313-0.218-0.562-0.523-0.744-0.915
			c-0.182-0.393-0.272-0.849-0.272-1.368c0-0.42,0.058-0.835,0.174-1.245s0.285-0.783,0.507-1.119
			c0.223-0.336,0.492-0.604,0.811-0.807c0.317-0.202,0.679-0.303,1.083-0.303c0.344,0,0.638,0.079,0.882,0.236
			c0.244,0.158,0.436,0.392,0.576,0.699l0.306-3.204h1.062l-0.774,8.202h-0.864l-0.048-0.942c-0.196,0.364-0.438,0.638-0.726,0.819
			S112.944,252.549,112.631,252.549z M112.872,251.493c0.212,0,0.424-0.061,0.636-0.183s0.395-0.298,0.549-0.528
			c0.154-0.229,0.245-0.505,0.273-0.825l0.09-0.96c0.008-0.036,0.012-0.063,0.012-0.084c0-0.02,0-0.046,0-0.078
			c0.004-0.287-0.05-0.529-0.162-0.726c-0.111-0.196-0.258-0.344-0.438-0.444c-0.18-0.1-0.376-0.149-0.588-0.149
			c-0.284,0-0.529,0.073-0.735,0.219c-0.206,0.146-0.373,0.336-0.501,0.57c-0.128,0.233-0.223,0.484-0.285,0.753
			c-0.062,0.268-0.093,0.521-0.093,0.762c0,0.508,0.116,0.914,0.348,1.218C112.21,251.341,112.507,251.493,112.872,251.493z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M117.353,252.399l0.546-5.796h1.057l-0.546,5.796H117.353z M118.542,245.409
			c-0.208,0-0.365-0.063-0.471-0.191c-0.106-0.128-0.153-0.301-0.142-0.517c0.021-0.204,0.095-0.373,0.226-0.507
			c0.13-0.134,0.301-0.201,0.513-0.201s0.371,0.065,0.477,0.195c0.106,0.13,0.151,0.301,0.136,0.513
			c-0.021,0.221-0.098,0.394-0.231,0.52S118.746,245.409,118.542,245.409z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M122.544,252.549c-0.433,0-0.822-0.124-1.17-0.372c-0.349-0.248-0.612-0.601-0.792-1.057
			c-0.181-0.456-0.242-0.996-0.187-1.62c0.061-0.647,0.224-1.196,0.489-1.646s0.595-0.794,0.987-1.032
			c0.392-0.237,0.806-0.356,1.241-0.356c0.309,0,0.579,0.055,0.813,0.165c0.234,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.463,0.073-0.682,0.219c-0.218,0.146-0.402,0.366-0.555,0.66
			s-0.25,0.663-0.294,1.106c-0.036,0.421-0.011,0.779,0.075,1.077s0.224,0.525,0.414,0.682c0.189,0.155,0.417,0.233,0.681,0.233
			c0.34,0,0.61-0.097,0.811-0.291c0.199-0.193,0.315-0.463,0.348-0.807h1.068c-0.032,0.404-0.149,0.769-0.352,1.095
			s-0.472,0.584-0.81,0.774C123.366,252.454,122.979,252.549,122.544,252.549z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M126.293,252.399l0.769-8.202h1.062l-0.33,3.42c0.213-0.372,0.47-0.657,0.771-0.854
			c0.302-0.198,0.639-0.297,1.011-0.297c0.42,0,0.767,0.104,1.038,0.312c0.272,0.208,0.466,0.502,0.582,0.882s0.15,0.83,0.103,1.351
			l-0.318,3.39h-1.05l0.312-3.39c0.052-0.492-0.017-0.864-0.207-1.116c-0.189-0.252-0.457-0.378-0.801-0.378
			c-0.284,0-0.538,0.08-0.762,0.24c-0.225,0.159-0.409,0.395-0.556,0.704c-0.146,0.311-0.247,0.689-0.303,1.138l-0.264,2.802
			H126.293z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M137.399,252.507c-0.38,0-0.711-0.077-0.993-0.231s-0.493-0.395-0.633-0.723
			c-0.141-0.328-0.187-0.75-0.139-1.267l0.354-3.684h1.056l-0.342,3.576c-0.04,0.432,0.024,0.757,0.192,0.975
			s0.426,0.327,0.773,0.327c0.288,0,0.551-0.085,0.789-0.255s0.437-0.411,0.594-0.724c0.158-0.312,0.258-0.682,0.298-1.109
			l0.264-2.79h1.056l-0.54,5.796h-1.062l0.102-1.092c-0.204,0.392-0.458,0.689-0.762,0.894S137.767,252.507,137.399,252.507z
			 M137.531,245.091c-0.196,0-0.348-0.066-0.456-0.198s-0.152-0.292-0.132-0.48c0.012-0.188,0.084-0.353,0.216-0.495
			c0.132-0.142,0.3-0.213,0.504-0.213c0.176,0,0.324,0.062,0.444,0.187s0.174,0.288,0.162,0.492
			c-0.021,0.216-0.104,0.388-0.252,0.516C137.869,245.027,137.707,245.091,137.531,245.091z M139.475,245.085
			c-0.188,0-0.339-0.064-0.45-0.194c-0.112-0.13-0.158-0.291-0.139-0.483c0.017-0.184,0.092-0.348,0.226-0.492
			c0.134-0.144,0.299-0.216,0.495-0.216c0.172,0,0.317,0.061,0.438,0.181c0.12,0.119,0.174,0.285,0.162,0.497
			c-0.016,0.232-0.099,0.408-0.249,0.528C139.808,245.025,139.647,245.085,139.475,245.085z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M144.737,252.549c-0.336,0-0.633-0.098-0.891-0.291c-0.258-0.194-0.449-0.483-0.574-0.867l-0.336,1.008
			h-0.708l0.769-8.202h1.062l-0.312,3.216c0.191-0.304,0.428-0.538,0.709-0.702c0.279-0.163,0.586-0.245,0.918-0.245
			c0.383,0,0.727,0.104,1.031,0.314s0.543,0.508,0.721,0.895c0.176,0.386,0.264,0.841,0.264,1.364c0,0.364-0.055,0.75-0.162,1.158
			s-0.271,0.79-0.49,1.146c-0.217,0.355-0.494,0.646-0.826,0.87C145.575,252.437,145.184,252.549,144.737,252.549z M144.682,251.493
			c0.281,0,0.523-0.072,0.727-0.219c0.203-0.146,0.373-0.337,0.508-0.573c0.133-0.235,0.232-0.491,0.297-0.765
			c0.062-0.274,0.096-0.537,0.096-0.789c0-0.5-0.117-0.897-0.352-1.191s-0.533-0.44-0.896-0.44c-0.229,0-0.451,0.061-0.666,0.183
			c-0.217,0.122-0.398,0.3-0.547,0.534s-0.236,0.517-0.264,0.849l-0.066,0.732c-0.027,0.324,0.004,0.612,0.094,0.864
			s0.229,0.451,0.414,0.597C144.212,251.42,144.43,251.493,144.682,251.493z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M150.557,252.549c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C151.286,252.473,150.937,252.549,150.557,252.549z M149.513,249.213l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C149.638,248.572,149.553,248.877,149.513,249.213z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M154.331,252.399l0.546-5.796h1.057l-0.108,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.253,0.021c0.088,0.014,0.174,0.039,0.258,0.075l-0.139,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.214-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			c-0.203,0.172-0.364,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H154.331z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M158.627,252.399l0.546-5.796h1.057l-0.108,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.253,0.021c0.088,0.014,0.174,0.039,0.258,0.075l-0.139,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.214-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			c-0.203,0.172-0.364,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H158.627z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M164.482,252.549c-0.42,0-0.767-0.087-1.04-0.259c-0.274-0.172-0.473-0.395-0.595-0.669
			c-0.122-0.273-0.167-0.566-0.135-0.879c0.036-0.38,0.153-0.694,0.351-0.944c0.198-0.25,0.46-0.438,0.786-0.564
			s0.693-0.188,1.102-0.188c0.252,0,0.5,0.024,0.744,0.074c0.243,0.051,0.476,0.117,0.695,0.201l0.066-0.66
			c0.052-0.464-0.001-0.802-0.159-1.014s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314
			c-0.108,0.141-0.172,0.314-0.192,0.522h-1.164c0.044-0.44,0.179-0.792,0.402-1.056c0.224-0.265,0.508-0.455,0.852-0.573
			c0.345-0.118,0.713-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54c0.35,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912
			c-0.004-0.147-0.005-0.295-0.003-0.441c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773
			S164.871,252.549,164.482,252.549z M164.741,251.649c0.271,0,0.524-0.066,0.759-0.198s0.425-0.316,0.573-0.555
			c0.147-0.238,0.229-0.515,0.246-0.831c-0.196-0.063-0.406-0.116-0.631-0.156c-0.224-0.04-0.434-0.06-0.63-0.06
			c-0.323,0-0.602,0.066-0.834,0.201c-0.231,0.134-0.363,0.364-0.396,0.692c-0.021,0.256,0.049,0.472,0.207,0.646
			S164.428,251.649,164.741,251.649z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M170.902,252.549c-0.443,0-0.829-0.077-1.154-0.231c-0.326-0.154-0.574-0.372-0.744-0.654
			c-0.17-0.281-0.237-0.614-0.201-0.999h0.996c-0.024,0.309,0.066,0.546,0.272,0.711c0.206,0.166,0.483,0.249,0.831,0.249
			c0.353,0,0.638-0.076,0.855-0.23s0.341-0.347,0.369-0.579c0.023-0.204-0.034-0.361-0.174-0.471
			c-0.141-0.11-0.326-0.205-0.559-0.285l-1.032-0.414c-0.399-0.152-0.702-0.36-0.908-0.624s-0.289-0.584-0.249-0.96
			c0.036-0.324,0.15-0.606,0.345-0.846c0.194-0.24,0.454-0.426,0.78-0.556c0.325-0.13,0.696-0.194,1.112-0.194
			c0.664,0,1.158,0.153,1.482,0.462c0.324,0.308,0.472,0.695,0.444,1.164h-1.015c-0.004-0.229-0.09-0.402-0.258-0.522
			s-0.398-0.18-0.69-0.18c-0.235,0-0.431,0.035-0.585,0.104c-0.153,0.07-0.272,0.159-0.356,0.268
			c-0.084,0.107-0.136,0.224-0.156,0.348c-0.02,0.188,0.037,0.343,0.171,0.465s0.312,0.219,0.531,0.291l1.092,0.45
			c0.384,0.14,0.688,0.331,0.909,0.573s0.315,0.551,0.279,0.927c-0.028,0.296-0.142,0.576-0.339,0.84
			c-0.198,0.264-0.47,0.479-0.813,0.646S171.382,252.549,170.902,252.549z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M176.674,252.549c-0.433,0-0.822-0.124-1.171-0.372c-0.348-0.248-0.611-0.601-0.792-1.057
			c-0.18-0.456-0.241-0.996-0.186-1.62c0.06-0.647,0.223-1.196,0.489-1.646c0.266-0.45,0.595-0.794,0.986-1.032
			c0.393-0.237,0.807-0.356,1.242-0.356c0.309,0,0.579,0.055,0.813,0.165c0.233,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.464,0.073-0.682,0.219c-0.218,0.146-0.403,0.366-0.555,0.66
			c-0.152,0.294-0.25,0.663-0.294,1.106c-0.036,0.421-0.012,0.779,0.075,1.077c0.086,0.298,0.224,0.525,0.414,0.682
			c0.189,0.155,0.417,0.233,0.681,0.233c0.34,0,0.609-0.097,0.81-0.291c0.2-0.193,0.316-0.463,0.349-0.807h1.067
			c-0.032,0.404-0.148,0.769-0.351,1.095s-0.472,0.584-0.811,0.774C177.497,252.454,177.11,252.549,176.674,252.549z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M180.423,252.399l0.769-8.202h1.062l-0.33,3.42c0.212-0.372,0.469-0.657,0.771-0.854
			c0.302-0.198,0.639-0.297,1.011-0.297c0.42,0,0.766,0.104,1.038,0.312c0.271,0.208,0.466,0.502,0.582,0.882
			s0.149,0.83,0.102,1.351l-0.317,3.39h-1.051l0.312-3.39c0.052-0.492-0.018-0.864-0.207-1.116c-0.19-0.252-0.457-0.378-0.801-0.378
			c-0.284,0-0.538,0.08-0.763,0.24c-0.224,0.159-0.408,0.395-0.555,0.704c-0.146,0.311-0.247,0.689-0.303,1.138l-0.264,2.802
			H180.423z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M188.914,252.549c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C189.642,252.473,189.294,252.549,188.914,252.549z M187.87,249.213l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C187.995,248.572,187.91,248.877,187.87,249.213z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M192.687,252.399l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906
			s0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375
			c-0.44,0-0.807,0.191-1.099,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H192.687z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M199.815,252.507c-0.224,0-0.399-0.08-0.527-0.24c-0.129-0.16-0.183-0.349-0.162-0.564
			c0.02-0.224,0.107-0.416,0.264-0.576c0.156-0.159,0.348-0.239,0.576-0.239c0.228,0,0.407,0.08,0.537,0.239
			c0.13,0.16,0.183,0.353,0.159,0.576c-0.024,0.221-0.114,0.409-0.271,0.567S200.044,252.507,199.815,252.507z M199.605,250.035
			l0.378-5.376h1.135l-0.624,5.376H199.605z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M108.485,272.399l2.37-3.345l-2.37-3.365h1.984l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.57,3.375h-2.025
			l-1.595-2.1l-1.355,2.1H108.485z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M115.759,272.399l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.93l-2.2,3.335l2.57,3.375h-2.024
			l-1.596-2.1l-1.354,2.1H115.759z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M123.024,271.469c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			s0.31,0.443,0.31,0.74s-0.103,0.541-0.31,0.732s-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S123.024,271.762,123.024,271.469z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M125.57,272.399l2.37-3.345l-2.37-3.365h1.984l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.57,3.375h-2.025
			l-1.595-2.1l-1.355,2.1H125.57z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M132.844,272.399l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.93l-2.2,3.335l2.57,3.375h-2.024
			l-1.596-2.1l-1.354,2.1H132.844z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M140.484,269.974v-1.4h4.696v1.4H140.484z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M145.92,272.399l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H145.92z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M153.195,272.399l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H153.195z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M160.461,271.469c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S160.461,271.762,160.461,271.469z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M163.005,272.399l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H163.005z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M170.281,272.399l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H170.281z"
                  />
                </g>
                <g>
                  <path
                    fill="#1E1B39"
                    d="M100.688,459.569v-1.428h7.279v1.428h-3.185l0.371-0.378v8.036h-1.652v-8.036l0.371,0.378H100.688z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M109.346,467.228v-9.604h1.652v9.604H109.346z M114.008,467.228v-3.976c0-0.504-0.121-0.884-0.363-1.141
			c-0.243-0.256-0.561-0.385-0.952-0.385c-0.332,0-0.624,0.091-0.879,0.273c-0.254,0.182-0.454,0.456-0.599,0.822
			c-0.145,0.367-0.217,0.823-0.217,1.369h-0.546c0-0.882,0.109-1.616,0.329-2.201c0.219-0.586,0.529-1.023,0.931-1.312
			c0.401-0.29,0.873-0.434,1.414-0.434c0.561,0,1.028,0.125,1.403,0.375c0.376,0.25,0.658,0.601,0.848,1.053
			c0.188,0.453,0.283,0.98,0.283,1.582v3.976H114.008z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M120.602,467.424c-0.578,0-1.108-0.149-1.589-0.448s-0.865-0.718-1.155-1.256
			c-0.289-0.539-0.434-1.17-0.434-1.894c0-0.747,0.144-1.389,0.431-1.925s0.671-0.947,1.151-1.232s1.013-0.427,1.596-0.427
			c0.542,0,1.01,0.108,1.403,0.325c0.395,0.217,0.712,0.511,0.952,0.882c0.24,0.371,0.403,0.79,0.487,1.256s0.088,0.947,0.014,1.442
			h-4.718v-0.896h3.549l-0.322,0.21c0.014-0.219,0.004-0.441-0.031-0.665c-0.035-0.224-0.102-0.429-0.199-0.616
			c-0.099-0.187-0.237-0.337-0.417-0.452s-0.409-0.171-0.689-0.171c-0.336,0-0.621,0.084-0.854,0.252s-0.41,0.396-0.531,0.686
			c-0.122,0.29-0.183,0.616-0.183,0.98v0.658c0,0.383,0.058,0.721,0.172,1.015s0.284,0.525,0.511,0.693
			c0.227,0.168,0.513,0.252,0.857,0.252c0.322,0,0.593-0.075,0.812-0.224c0.219-0.149,0.359-0.374,0.42-0.672h1.666
			c-0.07,0.438-0.234,0.825-0.493,1.158c-0.26,0.334-0.595,0.595-1.005,0.784C121.591,467.329,121.125,467.424,120.602,467.424z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M128.163,467.424c-0.621,0-1.183-0.133-1.684-0.399c-0.502-0.266-0.899-0.664-1.193-1.193
			s-0.441-1.193-0.441-1.992c0-0.812,0.147-1.484,0.441-2.016c0.294-0.532,0.691-0.929,1.193-1.19
			c0.501-0.261,1.062-0.392,1.684-0.392c0.611,0,1.165,0.13,1.662,0.392c0.497,0.262,0.893,0.658,1.187,1.19
			c0.294,0.532,0.441,1.204,0.441,2.016c0,0.798-0.147,1.462-0.441,1.992s-0.689,0.927-1.187,1.193
			C129.328,467.291,128.774,467.424,128.163,467.424z M128.106,465.982c0.327,0,0.617-0.081,0.872-0.241
			c0.254-0.161,0.455-0.4,0.602-0.718c0.147-0.317,0.221-0.711,0.221-1.183c0-0.718-0.142-1.26-0.424-1.624
			c-0.282-0.364-0.668-0.546-1.158-0.546c-0.331,0-0.625,0.081-0.882,0.242s-0.459,0.402-0.605,0.725
			c-0.147,0.322-0.221,0.723-0.221,1.204c0,0.709,0.144,1.244,0.431,1.603S127.617,465.982,128.106,465.982z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M140.426,467.424c-0.808,0-1.521-0.178-2.139-0.532s-1.103-0.884-1.452-1.589
			c-0.351-0.705-0.525-1.58-0.525-2.625c0-1.064,0.175-1.945,0.525-2.643c0.35-0.698,0.834-1.218,1.452-1.561
			c0.618-0.343,1.331-0.515,2.139-0.515c0.812,0,1.528,0.176,2.148,0.529c0.621,0.352,1.107,0.878,1.457,1.578
			c0.35,0.7,0.525,1.57,0.525,2.611c0,1.059-0.176,1.941-0.525,2.646s-0.836,1.231-1.457,1.578
			C141.955,467.25,141.238,467.424,140.426,467.424z M140.426,465.926c0.742,0,1.331-0.28,1.768-0.84s0.654-1.367,0.654-2.422
			c0-1.017-0.218-1.808-0.654-2.373c-0.437-0.564-1.025-0.847-1.768-0.847c-0.729,0-1.312,0.284-1.75,0.851
			s-0.658,1.362-0.658,2.384c0,1.05,0.22,1.854,0.658,2.411C139.115,465.647,139.698,465.926,140.426,465.926z M143.213,469.747
			c-0.462,0-0.89-0.123-1.281-0.37c-0.392-0.248-0.735-0.596-1.029-1.043l-0.938-1.415l1.315-0.616l0.812,1.26
			c0.135,0.196,0.289,0.334,0.462,0.413s0.351,0.124,0.532,0.133c0.233,0,0.459-0.029,0.676-0.087
			c0.217-0.059,0.443-0.137,0.682-0.235v1.708c-0.219,0.089-0.428,0.153-0.627,0.193
			C143.62,469.728,143.418,469.747,143.213,469.747z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M146.419,460.41h1.652v4.172c0,0.443,0.109,0.776,0.328,0.998c0.22,0.222,0.521,0.333,0.903,0.333
			c0.327,0,0.624-0.085,0.893-0.255s0.483-0.422,0.644-0.756c0.161-0.333,0.242-0.748,0.242-1.242h0.448
			c0,0.831-0.107,1.519-0.322,2.065s-0.511,0.954-0.89,1.225c-0.378,0.271-0.813,0.406-1.309,0.406
			c-0.485,0-0.924-0.086-1.316-0.259c-0.392-0.173-0.702-0.451-0.931-0.833c-0.229-0.383-0.343-0.887-0.343-1.512V460.41z
			 M151.081,460.41h1.651v6.818h-1.651V460.41z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M157.437,467.424c-0.584,0-1.07-0.102-1.46-0.305s-0.683-0.47-0.878-0.802
			c-0.196-0.331-0.295-0.69-0.295-1.078c0-0.43,0.113-0.79,0.34-1.082c0.227-0.292,0.547-0.511,0.963-0.658
			c0.415-0.147,0.905-0.221,1.47-0.221c0.368,0,0.718,0.028,1.047,0.084c0.329,0.056,0.656,0.13,0.983,0.224v0.966
			c-0.257-0.084-0.537-0.149-0.84-0.196c-0.304-0.046-0.589-0.07-0.854-0.07c-0.392,0-0.714,0.072-0.966,0.217
			c-0.252,0.145-0.378,0.39-0.378,0.735c0,0.252,0.103,0.469,0.308,0.651s0.532,0.273,0.98,0.273c0.34,0,0.63-0.075,0.868-0.224
			c0.237-0.149,0.418-0.336,0.542-0.56c0.124-0.224,0.186-0.448,0.186-0.672h0.392c0,0.49-0.091,0.941-0.272,1.354
			s-0.451,0.743-0.809,0.991C158.406,467.3,157.964,467.424,157.437,467.424z M159.663,467.228l-0.21-1.652v-2.73
			c0-0.476-0.097-0.829-0.29-1.057c-0.194-0.229-0.521-0.343-0.984-0.343c-0.266,0-0.496,0.04-0.692,0.119
			c-0.196,0.08-0.347,0.192-0.452,0.339c-0.104,0.147-0.157,0.326-0.157,0.536h-1.806c0-0.513,0.123-0.932,0.371-1.256
			c0.247-0.324,0.603-0.562,1.067-0.714c0.464-0.152,1.021-0.228,1.669-0.228c0.589,0,1.102,0.094,1.54,0.283
			c0.439,0.189,0.779,0.479,1.022,0.872c0.242,0.392,0.364,0.894,0.364,1.505v4.326H159.663z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M163.526,467.228v-6.79h1.442l0.21,1.344v5.446H163.526z M168.188,467.228v-3.976
			c0-0.504-0.121-0.884-0.364-1.141c-0.242-0.256-0.56-0.385-0.951-0.385c-0.332,0-0.625,0.091-0.879,0.273
			c-0.255,0.182-0.454,0.456-0.599,0.822c-0.145,0.367-0.217,0.823-0.217,1.369h-0.546c0-0.882,0.109-1.616,0.329-2.201
			c0.219-0.586,0.529-1.023,0.931-1.312c0.401-0.29,0.873-0.434,1.414-0.434c0.56,0,1.027,0.125,1.403,0.375
			c0.376,0.25,0.658,0.601,0.847,1.053c0.189,0.453,0.284,0.98,0.284,1.582v3.976H168.188z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M174.754,467.424c-0.569,0-1.088-0.15-1.557-0.452c-0.47-0.301-0.843-0.724-1.12-1.267
			c-0.278-0.543-0.417-1.18-0.417-1.908c0-0.718,0.134-1.345,0.402-1.879c0.269-0.534,0.628-0.947,1.078-1.239
			c0.45-0.292,0.951-0.438,1.502-0.438c0.504,0,0.94,0.12,1.309,0.36c0.369,0.24,0.651,0.596,0.847,1.067
			c0.196,0.472,0.295,1.05,0.295,1.736h-0.519c0-0.355-0.072-0.657-0.217-0.907s-0.335-0.441-0.57-0.574
			c-0.236-0.133-0.491-0.199-0.767-0.199c-0.332,0-0.624,0.086-0.879,0.259c-0.254,0.173-0.454,0.415-0.599,0.725
			c-0.145,0.311-0.217,0.673-0.217,1.088c0,0.43,0.072,0.803,0.217,1.12s0.345,0.565,0.599,0.742
			c0.255,0.177,0.543,0.266,0.864,0.266c0.262,0,0.513-0.071,0.753-0.214c0.24-0.142,0.437-0.342,0.588-0.598
			c0.151-0.257,0.228-0.556,0.228-0.896h0.519c0,0.714-0.106,1.308-0.319,1.781c-0.212,0.474-0.495,0.83-0.851,1.067
			C175.568,467.305,175.178,467.424,174.754,467.424z M176.881,467.228l-0.308-1.68v-7.924h1.666v9.604H176.881z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M179.99,461.529v-1.092h4.452v1.092H179.99z M183.139,467.424c-0.401,0-0.769-0.075-1.103-0.224
			c-0.333-0.149-0.6-0.378-0.798-0.686s-0.297-0.7-0.297-1.176v-6.706h1.651v6.65c0,0.56,0.267,0.84,0.798,0.84
			c0.164,0,0.327-0.019,0.49-0.056c0.163-0.037,0.351-0.093,0.561-0.168l0.237,1.162c-0.261,0.103-0.516,0.189-0.763,0.259
			C183.67,467.389,183.41,467.424,183.139,467.424z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M121.706,475.565h1.182l1.015,6.096l2.105-5.447h0.714l1.104,5.472l2.364-6.12h1.182l-3.126,7.662h-1.116
			l-0.96-4.614l-1.86,4.614h-1.104L121.706,475.565z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M133.076,483.378c-0.42,0-0.768-0.087-1.041-0.259c-0.274-0.172-0.473-0.395-0.594-0.669
			c-0.122-0.273-0.168-0.566-0.136-0.879c0.036-0.38,0.153-0.694,0.352-0.944c0.197-0.25,0.46-0.438,0.786-0.564
			c0.325-0.126,0.692-0.188,1.101-0.188c0.252,0,0.5,0.024,0.744,0.074c0.244,0.051,0.476,0.117,0.696,0.201l0.065-0.66
			c0.052-0.464-0.001-0.802-0.159-1.014s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314
			c-0.107,0.141-0.172,0.314-0.191,0.522h-1.164c0.044-0.44,0.178-0.792,0.401-1.056c0.225-0.265,0.508-0.455,0.853-0.573
			c0.344-0.118,0.712-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54c0.351,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912
			c-0.004-0.147-0.005-0.295-0.003-0.441c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773
			S133.463,483.378,133.076,483.378z M133.334,482.478c0.271,0,0.525-0.066,0.759-0.198c0.234-0.132,0.425-0.316,0.573-0.555
			c0.147-0.238,0.229-0.515,0.246-0.831c-0.196-0.063-0.406-0.116-0.63-0.156c-0.225-0.04-0.435-0.06-0.63-0.06
			c-0.324,0-0.603,0.066-0.834,0.201c-0.232,0.134-0.364,0.364-0.396,0.692c-0.02,0.256,0.049,0.472,0.207,0.646
			S133.021,482.478,133.334,482.478z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M137.695,483.228l0.769-8.202h1.062l-0.773,8.202H137.695z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M144.469,483.228l-1.656-2.946l-0.689,0.582l-0.223,2.364h-1.056l0.768-8.202h1.062l-0.432,4.596
			l2.496-2.178h1.452l-2.557,2.154l2.13,3.63H144.469z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M148.855,483.228l3.084-7.662h1.309l1.571,7.662h-1.115l-0.372-1.806h-2.646l-0.714,1.806H148.855z
			 M151.04,480.522h2.117l-0.714-3.552L151.04,480.522z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M158.011,483.378c-0.433,0-0.822-0.124-1.171-0.372c-0.348-0.248-0.611-0.601-0.792-1.057
			c-0.18-0.456-0.241-0.996-0.186-1.62c0.06-0.647,0.223-1.196,0.489-1.646c0.266-0.45,0.595-0.794,0.986-1.032
			c0.393-0.237,0.807-0.356,1.242-0.356c0.309,0,0.579,0.055,0.813,0.165c0.233,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.464,0.073-0.682,0.219c-0.218,0.146-0.403,0.366-0.555,0.66
			c-0.152,0.294-0.25,0.663-0.294,1.106c-0.036,0.421-0.012,0.779,0.075,1.077c0.086,0.298,0.224,0.525,0.414,0.682
			c0.189,0.155,0.417,0.233,0.681,0.233c0.34,0,0.609-0.097,0.81-0.291c0.2-0.193,0.316-0.463,0.349-0.807h1.067
			c-0.032,0.404-0.148,0.769-0.351,1.095s-0.472,0.584-0.811,0.774C158.834,483.282,158.447,483.378,158.011,483.378z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M163.411,483.378c-0.292,0-0.552-0.062-0.78-0.184c-0.228-0.122-0.398-0.309-0.51-0.561
			c-0.112-0.252-0.152-0.572-0.12-0.96l0.336-3.666l0.246,0.21h-1.056l0.09-0.774h1.044l-0.3,0.271l0.18-1.836h1.056l-0.18,1.836
			l-0.24-0.271h1.8l-0.084,0.774h-1.8l0.294-0.21l-0.342,3.642c-0.024,0.269,0.017,0.468,0.123,0.601
			c0.105,0.132,0.273,0.197,0.501,0.197c0.12,0,0.249-0.017,0.387-0.051c0.139-0.034,0.293-0.085,0.465-0.153l0.072,0.828
			c-0.2,0.093-0.395,0.166-0.585,0.223C163.818,483.35,163.619,483.378,163.411,483.378z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M108.485,503.228l2.37-3.345l-2.37-3.365h1.984l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.57,3.375h-2.025
			l-1.595-2.1l-1.355,2.1H108.485z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M115.759,503.228l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.93l-2.2,3.335l2.57,3.375h-2.024
			l-1.596-2.1l-1.354,2.1H115.759z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M123.024,502.298c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			s0.31,0.443,0.31,0.74s-0.103,0.541-0.31,0.732s-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S123.024,502.591,123.024,502.298z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M125.57,503.228l2.37-3.345l-2.37-3.365h1.984l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.57,3.375h-2.025
			l-1.595-2.1l-1.355,2.1H125.57z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M132.844,503.228l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.93l-2.2,3.335l2.57,3.375h-2.024
			l-1.596-2.1l-1.354,2.1H132.844z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M140.484,500.803v-1.4h4.696v1.4H140.484z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M145.92,503.228l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H145.92z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M153.195,503.228l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H153.195z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M160.461,502.298c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S160.461,502.591,160.461,502.298z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M163.005,503.228l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H163.005z"
                  />
                  <path
                    fill="#1E1B39"
                    d="M170.281,503.228l2.37-3.345l-2.37-3.365h1.985l1.46,2.11l1.17-2.11h1.931l-2.2,3.335l2.569,3.375h-2.024
			l-1.595-2.1l-1.355,2.1H170.281z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M442.376,101.273V90.889h3.664c1.168,0,2.079,0.288,2.732,0.864s0.979,1.392,0.979,2.448
			c0,1.045-0.326,1.856-0.979,2.432c-0.653,0.576-1.564,0.864-2.732,0.864h-1.76v3.776H442.376z M444.28,95.849h1.76
			c0.587,0,1.029-0.155,1.328-0.464c0.299-0.309,0.448-0.704,0.448-1.184c0-0.491-0.149-0.894-0.448-1.208
			c-0.299-0.315-0.741-0.472-1.328-0.472h-1.76V95.849z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M452.816,92.088c-0.368,0-0.655-0.097-0.86-0.292c-0.205-0.194-0.308-0.465-0.308-0.812
			c0-0.336,0.103-0.604,0.308-0.804c0.205-0.2,0.492-0.3,0.86-0.3c0.362,0,0.648,0.1,0.856,0.3c0.208,0.2,0.312,0.468,0.312,0.804
			c0,0.347-0.104,0.618-0.312,0.812C453.464,91.991,453.178,92.088,452.816,92.088z M451.872,101.273v-7.792h1.889v7.792H451.872z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M456.961,101.273V90.296h1.888v10.976H456.961z M458.592,96.457l3.328-2.944h2.56l-5.888,4.896V96.457z
			 M462.369,101.273l-3.473-4.944l1.792-0.432l3.984,5.376H462.369z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M400.175,117.273l0.72-7.662h4.272l-0.108,1.008h-3.21l-0.216,2.292h2.958l-0.09,1.009h-2.964
			l-0.307,3.354H400.175z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M407.513,117.381c-0.38,0-0.711-0.077-0.993-0.231c-0.281-0.154-0.492-0.395-0.633-0.723
			c-0.14-0.328-0.186-0.75-0.138-1.267l0.354-3.684h1.057l-0.342,3.576c-0.04,0.432,0.023,0.757,0.191,0.975
			s0.426,0.327,0.774,0.327c0.288,0,0.551-0.085,0.789-0.255c0.237-0.17,0.436-0.411,0.594-0.724
			c0.158-0.312,0.257-0.682,0.297-1.109l0.264-2.79h1.057l-0.54,5.796h-1.062l0.103-1.092c-0.204,0.392-0.458,0.689-0.763,0.894
			C408.217,117.278,407.881,117.381,407.513,117.381z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M412.338,117.273l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.472-0.702,0.78-0.906
			c0.308-0.204,0.651-0.306,1.032-0.306c0.42,0,0.766,0.103,1.037,0.309c0.272,0.206,0.467,0.5,0.582,0.882
			c0.116,0.382,0.148,0.833,0.097,1.354l-0.312,3.39h-1.056l0.312-3.39c0.057-0.496-0.013-0.869-0.207-1.119
			c-0.193-0.25-0.459-0.375-0.795-0.375c-0.439,0-0.806,0.191-1.098,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658
			H412.338z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M422.586,117.273l-1.656-2.946l-0.689,0.582l-0.223,2.364h-1.056l0.768-8.202h1.062l-0.432,4.596
			l2.495-2.178h1.452l-2.556,2.154l2.13,3.63H422.586z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M424.992,120.255c-0.232,0-0.455-0.034-0.669-0.103c-0.215-0.068-0.43-0.168-0.646-0.3l0.103-1.05
			c0.176,0.147,0.352,0.256,0.527,0.323c0.176,0.068,0.358,0.103,0.546,0.103c0.192,0,0.363-0.064,0.514-0.192
			c0.149-0.128,0.282-0.296,0.398-0.504s0.218-0.436,0.307-0.684l0.216-0.576l-1.566-5.784h1.194l1.002,4.386l1.578-4.386h1.073
			l-2.628,6.882c-0.159,0.424-0.338,0.775-0.533,1.054c-0.196,0.277-0.41,0.485-0.643,0.624
			C425.534,120.186,425.275,120.255,424.992,120.255z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M430.158,118.736l-0.738-0.432c0.231-0.12,0.437-0.261,0.615-0.42c0.178-0.16,0.303-0.345,0.375-0.553
			c-0.181-0.031-0.328-0.108-0.444-0.23s-0.164-0.283-0.144-0.483c0.023-0.204,0.111-0.378,0.264-0.521
			c0.151-0.145,0.34-0.216,0.563-0.216c0.181,0,0.33,0.051,0.45,0.152c0.12,0.103,0.208,0.236,0.265,0.402
			c0.056,0.166,0.073,0.349,0.054,0.549c-0.021,0.212-0.082,0.428-0.187,0.648c-0.104,0.22-0.246,0.427-0.426,0.621
			C430.626,118.447,430.41,118.608,430.158,118.736z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M435.425,117.273l0.72-7.662h2.688c0.516,0,0.937,0.091,1.263,0.273c0.326,0.182,0.558,0.426,0.696,0.731
			c0.138,0.307,0.188,0.644,0.152,1.011c-0.04,0.433-0.184,0.808-0.432,1.125c-0.248,0.318-0.584,0.537-1.008,0.657
			c0.264,0.057,0.493,0.166,0.687,0.33c0.194,0.164,0.339,0.368,0.436,0.612c0.096,0.244,0.13,0.514,0.102,0.81
			c-0.02,0.252-0.081,0.503-0.183,0.753c-0.103,0.25-0.254,0.478-0.456,0.682s-0.465,0.368-0.789,0.492s-0.72,0.186-1.188,0.186
			H435.425z M436.571,116.246h1.614c0.46,0,0.802-0.104,1.026-0.314c0.224-0.21,0.352-0.487,0.384-0.831
			c0.04-0.344-0.041-0.62-0.243-0.828s-0.519-0.312-0.951-0.312h-1.613L436.571,116.246z M437.099,110.618l-0.216,2.322h1.59
			c0.396,0,0.715-0.104,0.957-0.312s0.379-0.496,0.411-0.864c0.032-0.353-0.05-0.631-0.246-0.837s-0.498-0.31-0.906-0.31H437.099z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M444.215,117.423c-0.472,0-0.886-0.114-1.242-0.343c-0.355-0.228-0.62-0.566-0.792-1.017
			s-0.226-1.009-0.162-1.677c0.064-0.676,0.227-1.24,0.486-1.692s0.589-0.791,0.987-1.017c0.397-0.227,0.831-0.339,1.299-0.339
			s0.878,0.114,1.229,0.345c0.353,0.229,0.616,0.57,0.792,1.02c0.177,0.45,0.232,1.012,0.168,1.684
			c-0.067,0.668-0.231,1.227-0.491,1.677c-0.261,0.45-0.587,0.789-0.979,1.017C445.12,117.309,444.687,117.423,444.215,117.423z
			 M444.269,116.402c0.444,0,0.812-0.175,1.104-0.524c0.292-0.351,0.472-0.848,0.54-1.491c0.063-0.652-0.01-1.154-0.22-1.506
			c-0.21-0.353-0.534-0.528-0.975-0.528s-0.804,0.175-1.092,0.525c-0.288,0.35-0.464,0.853-0.528,1.509
			c-0.044,0.424-0.026,0.787,0.051,1.089c0.078,0.302,0.211,0.532,0.399,0.689C443.737,116.323,443.977,116.402,444.269,116.402z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M450.095,117.381c-0.38,0-0.711-0.077-0.993-0.231c-0.281-0.154-0.492-0.395-0.633-0.723
			c-0.14-0.328-0.186-0.75-0.138-1.267l0.354-3.684h1.057l-0.342,3.576c-0.04,0.432,0.023,0.757,0.191,0.975
			s0.426,0.327,0.774,0.327c0.288,0,0.551-0.085,0.789-0.255c0.237-0.17,0.436-0.411,0.594-0.724
			c0.158-0.312,0.257-0.682,0.297-1.109l0.264-2.79h1.057l-0.54,5.796h-1.062l0.103-1.092c-0.204,0.392-0.458,0.689-0.763,0.894
			C450.799,117.278,450.464,117.381,450.095,117.381z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M454.92,117.273l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906s0.652-0.306,1.032-0.306
			c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354l-0.312,3.39h-1.057
			l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375c-0.44,0-0.807,0.191-1.099,0.576
			c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H454.92z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M463.434,117.423c-0.433,0-0.822-0.124-1.171-0.372c-0.348-0.248-0.611-0.601-0.792-1.057
			c-0.18-0.456-0.241-0.996-0.186-1.62c0.06-0.647,0.223-1.196,0.489-1.646c0.266-0.45,0.595-0.794,0.986-1.032
			c0.393-0.237,0.807-0.356,1.242-0.356c0.309,0,0.579,0.055,0.813,0.165c0.233,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.464,0.073-0.682,0.219c-0.218,0.146-0.403,0.366-0.555,0.66
			c-0.152,0.294-0.25,0.663-0.294,1.106c-0.036,0.421-0.012,0.779,0.075,1.077c0.086,0.298,0.224,0.525,0.414,0.682
			c0.189,0.155,0.417,0.233,0.681,0.233c0.34,0,0.609-0.097,0.81-0.291c0.2-0.193,0.316-0.463,0.349-0.807h1.067
			c-0.032,0.404-0.148,0.769-0.351,1.095s-0.472,0.584-0.811,0.774C464.256,117.327,463.87,117.423,463.434,117.423z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M467.231,120.255c-0.231,0-0.455-0.034-0.669-0.103s-0.429-0.168-0.645-0.3l0.102-1.05
			c0.176,0.147,0.353,0.256,0.528,0.323c0.176,0.068,0.357,0.103,0.546,0.103c0.192,0,0.363-0.064,0.513-0.192
			c0.15-0.128,0.283-0.296,0.399-0.504s0.218-0.436,0.306-0.684l0.216-0.576l-1.565-5.784h1.194l1.002,4.386l1.577-4.386h1.074
			l-2.628,6.882c-0.16,0.424-0.338,0.775-0.534,1.054c-0.195,0.277-0.41,0.485-0.642,0.624
			C467.773,120.186,467.515,120.255,467.231,120.255z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M476.376,117.273l0.624-6.654h-2.189l0.102-1.008h5.442l-0.103,1.008h-2.196l-0.624,6.654H476.376z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M481.788,117.423c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C482.516,117.347,482.168,117.423,481.788,117.423z M480.744,114.087l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C480.869,113.445,480.784,113.75,480.744,114.087z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M487.458,117.423c-0.433,0-0.822-0.124-1.171-0.372c-0.348-0.248-0.611-0.601-0.792-1.057
			c-0.18-0.456-0.241-0.996-0.186-1.62c0.06-0.647,0.223-1.196,0.489-1.646c0.266-0.45,0.595-0.794,0.986-1.032
			c0.393-0.237,0.807-0.356,1.242-0.356c0.309,0,0.579,0.055,0.813,0.165c0.233,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.464,0.073-0.682,0.219c-0.218,0.146-0.403,0.366-0.555,0.66
			c-0.152,0.294-0.25,0.663-0.294,1.106c-0.036,0.421-0.012,0.779,0.075,1.077c0.086,0.298,0.224,0.525,0.414,0.682
			c0.189,0.155,0.417,0.233,0.681,0.233c0.34,0,0.609-0.097,0.81-0.291c0.2-0.193,0.316-0.463,0.349-0.807h1.067
			c-0.032,0.404-0.148,0.769-0.351,1.095s-0.472,0.584-0.811,0.774C488.28,117.327,487.893,117.423,487.458,117.423z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M491.207,117.273l0.769-8.202h1.062l-0.33,3.42c0.212-0.372,0.469-0.657,0.771-0.854
			c0.302-0.198,0.639-0.297,1.011-0.297c0.42,0,0.766,0.104,1.038,0.312c0.271,0.208,0.466,0.502,0.582,0.882
			s0.149,0.83,0.102,1.351l-0.317,3.39h-1.051l0.312-3.39c0.052-0.492-0.018-0.864-0.207-1.116c-0.19-0.252-0.457-0.378-0.801-0.378
			c-0.284,0-0.538,0.08-0.763,0.24c-0.224,0.159-0.408,0.395-0.555,0.704c-0.146,0.311-0.247,0.689-0.303,1.138l-0.264,2.802
			H491.207z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M497.831,117.273l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906
			s0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375
			c-0.44,0-0.807,0.191-1.099,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H497.831z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M506.393,117.423c-0.473,0-0.887-0.114-1.242-0.343c-0.356-0.228-0.62-0.566-0.792-1.017
			s-0.227-1.009-0.162-1.677c0.063-0.676,0.226-1.24,0.486-1.692c0.26-0.452,0.589-0.791,0.986-1.017
			c0.398-0.227,0.831-0.339,1.3-0.339c0.468,0,0.878,0.114,1.229,0.345c0.352,0.229,0.616,0.57,0.792,1.02
			c0.176,0.45,0.232,1.012,0.168,1.684c-0.068,0.668-0.232,1.227-0.492,1.677s-0.586,0.789-0.978,1.017
			C507.297,117.309,506.865,117.423,506.393,117.423z M506.447,116.402c0.444,0,0.812-0.175,1.104-0.524
			c0.291-0.351,0.472-0.848,0.539-1.491c0.064-0.652-0.009-1.154-0.219-1.506c-0.21-0.353-0.535-0.528-0.975-0.528
			c-0.44,0-0.804,0.175-1.092,0.525c-0.288,0.35-0.465,0.853-0.528,1.509c-0.044,0.424-0.027,0.787,0.051,1.089
			s0.211,0.532,0.399,0.689C505.915,116.323,506.155,116.402,506.447,116.402z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M421.629,137.273v-1.3c0-0.363,0.05-0.678,0.15-0.942c0.1-0.266,0.262-0.493,0.484-0.683
			c0.224-0.19,0.519-0.354,0.886-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.56-0.354c0.117-0.137,0.176-0.302,0.176-0.495
			c0-0.177-0.07-0.334-0.211-0.473c-0.14-0.139-0.347-0.207-0.619-0.207c-0.254,0-0.472,0.076-0.655,0.229s-0.288,0.372-0.315,0.655
			h-1.77c-0.004-0.526,0.119-0.958,0.367-1.293s0.579-0.581,0.992-0.739c0.414-0.159,0.863-0.238,1.351-0.238
			c0.474,0,0.904,0.087,1.292,0.261c0.389,0.173,0.698,0.417,0.931,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.186,0.915s-0.321,0.504-0.595,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.326,0.144-0.397,0.212c-0.072,0.068-0.107,0.158-0.107,0.268v0.075h3.77v1.46H421.629z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M427.949,137.273v-1.3c0-0.363,0.05-0.678,0.149-0.942c0.101-0.266,0.262-0.493,0.485-0.683
			c0.224-0.19,0.519-0.354,0.885-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.561-0.354c0.116-0.137,0.175-0.302,0.175-0.495
			c0-0.177-0.07-0.334-0.21-0.473s-0.347-0.207-0.62-0.207c-0.253,0-0.472,0.076-0.655,0.229c-0.183,0.153-0.288,0.372-0.314,0.655
			h-1.771c-0.003-0.526,0.119-0.958,0.368-1.293c0.248-0.335,0.579-0.581,0.992-0.739c0.413-0.159,0.863-0.238,1.35-0.238
			c0.474,0,0.904,0.087,1.293,0.261c0.388,0.173,0.698,0.417,0.93,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.185,0.915c-0.124,0.267-0.322,0.504-0.596,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.325,0.144-0.397,0.212c-0.071,0.068-0.107,0.158-0.107,0.268v0.075h3.771v1.46H427.949z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M433.883,136.343c0-0.297,0.099-0.544,0.298-0.74c0.198-0.196,0.455-0.295,0.772-0.295
			c0.316,0,0.578,0.099,0.784,0.295c0.207,0.196,0.311,0.443,0.311,0.74s-0.104,0.541-0.311,0.732
			c-0.206,0.191-0.468,0.287-0.784,0.287c-0.317,0-0.574-0.097-0.772-0.29C433.982,136.879,433.883,136.636,433.883,136.343z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M439.624,137.433c-0.573,0-1.083-0.127-1.527-0.38c-0.445-0.254-0.794-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.251-0.519,0.6-0.903,1.045-1.155
			c0.444-0.252,0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158
			C440.694,137.306,440.19,137.433,439.624,137.433z M439.624,135.978c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532
			s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543
			s0.115,1.188,0.348,1.532C438.903,135.805,439.22,135.978,439.624,135.978z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M446.358,137.433c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C447.428,137.306,446.925,137.433,446.358,137.433z M446.358,135.978c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S445.955,135.978,446.358,135.978z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M450.263,134.848v-1.4h4.695v1.4H450.263z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M458.893,137.433c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C459.964,137.306,459.46,137.433,458.893,137.433z
			 M458.893,135.978c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C458.172,135.805,458.49,135.978,458.893,135.978z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M465.629,137.433c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C466.699,137.306,466.195,137.433,465.629,137.433z
			 M465.629,135.978c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C464.908,135.805,465.225,135.978,465.629,135.978z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M469.159,136.343c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S469.159,136.636,469.159,136.343z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M474.819,137.433c-0.373,0-0.734-0.05-1.085-0.147c-0.35-0.099-0.664-0.245-0.942-0.44
			c-0.278-0.194-0.499-0.438-0.662-0.728c-0.164-0.29-0.245-0.626-0.245-1.01h1.705c0.02,0.163,0.08,0.311,0.18,0.442
			s0.237,0.236,0.412,0.312c0.176,0.077,0.388,0.115,0.638,0.115c0.34,0,0.612-0.073,0.815-0.22
			c0.203-0.147,0.305-0.334,0.305-0.561c0-0.156-0.04-0.285-0.12-0.385s-0.195-0.174-0.348-0.223
			c-0.151-0.048-0.337-0.072-0.558-0.072h-1.02v-1.255h0.995c0.267,0,0.475-0.066,0.625-0.2c0.149-0.134,0.225-0.309,0.225-0.525
			c0-0.196-0.071-0.358-0.215-0.487c-0.144-0.128-0.378-0.192-0.705-0.192c-0.283,0-0.536,0.059-0.76,0.175
			c-0.224,0.117-0.355,0.304-0.396,0.561h-1.699c0.04-0.5,0.182-0.911,0.425-1.232c0.243-0.322,0.566-0.561,0.97-0.716
			c0.403-0.154,0.863-0.232,1.38-0.232c0.527,0,0.994,0.08,1.402,0.24c0.409,0.16,0.73,0.384,0.966,0.67
			c0.234,0.287,0.353,0.622,0.353,1.005c0,0.33-0.086,0.622-0.258,0.875c-0.172,0.254-0.431,0.438-0.777,0.556
			c0.387,0.153,0.69,0.364,0.912,0.632c0.222,0.269,0.333,0.591,0.333,0.968c0,0.407-0.121,0.767-0.363,1.08
			c-0.241,0.313-0.576,0.558-1.005,0.732C475.874,137.345,475.38,137.433,474.819,137.433z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M481.449,137.433c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C482.519,137.306,482.015,137.433,481.449,137.433z
			 M481.449,135.978c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C480.728,135.805,481.046,135.978,481.449,135.978z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M398.125,377.379h1.888v4.336h4.528v-4.336h1.888v10.384h-1.888v-4.4h-4.528v4.4h-1.888V377.379z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M412.308,387.986c-0.661,0-1.267-0.17-1.816-0.512c-0.549-0.341-0.989-0.82-1.319-1.436
			c-0.331-0.616-0.496-1.337-0.496-2.164c0-0.853,0.164-1.586,0.492-2.2c0.327-0.613,0.767-1.083,1.315-1.408
			c0.55-0.325,1.157-0.488,1.824-0.488c0.618,0,1.153,0.124,1.604,0.372c0.451,0.248,0.813,0.584,1.089,1.008
			c0.274,0.424,0.46,0.903,0.556,1.436s0.102,1.083,0.016,1.648h-5.392v-1.024h4.056l-0.368,0.24c0.017-0.25,0.005-0.504-0.035-0.76
			s-0.116-0.491-0.229-0.704s-0.271-0.385-0.476-0.516c-0.206-0.13-0.469-0.196-0.788-0.196c-0.384,0-0.71,0.096-0.977,0.288
			s-0.469,0.453-0.607,0.784c-0.139,0.331-0.208,0.704-0.208,1.12v0.752c0,0.438,0.065,0.824,0.195,1.16
			c0.131,0.336,0.325,0.6,0.584,0.792s0.586,0.288,0.98,0.288c0.368,0,0.677-0.085,0.928-0.256c0.251-0.171,0.411-0.427,0.48-0.768
			h1.903c-0.079,0.501-0.268,0.942-0.563,1.324s-0.679,0.68-1.148,0.896C413.439,387.879,412.906,387.986,412.308,387.986z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M417.892,387.763v-7.76h1.648l0.239,1.536v6.224H417.892z M423.22,387.763v-4.544
			c0-0.576-0.139-1.011-0.416-1.304c-0.277-0.293-0.641-0.44-1.088-0.44c-0.379,0-0.714,0.104-1.004,0.312
			c-0.291,0.208-0.52,0.521-0.685,0.94c-0.165,0.419-0.248,0.94-0.248,1.564h-0.624c0-1.008,0.126-1.847,0.376-2.516
			c0.251-0.669,0.605-1.169,1.064-1.5c0.458-0.331,0.997-0.496,1.616-0.496c0.64,0,1.174,0.143,1.604,0.428
			c0.43,0.286,0.752,0.687,0.968,1.204c0.217,0.518,0.324,1.12,0.324,1.808v4.544H423.22z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M430.852,391.778c-0.891,0-1.652-0.122-2.284-0.367c-0.632-0.246-1.116-0.567-1.452-0.965
			c-0.336-0.396-0.504-0.827-0.504-1.292c0-0.549,0.2-0.975,0.6-1.275c0.4-0.301,0.904-0.452,1.513-0.452l1.376,0.512
			c-0.523,0-0.926,0.099-1.208,0.296c-0.283,0.197-0.424,0.473-0.424,0.824c0,0.368,0.212,0.663,0.636,0.884
			c0.424,0.222,1.007,0.332,1.748,0.332c0.538,0,1.005-0.08,1.399-0.239c0.395-0.16,0.7-0.368,0.916-0.624
			c0.217-0.257,0.324-0.528,0.324-0.816c0-0.192-0.063-0.35-0.191-0.472c-0.129-0.123-0.326-0.185-0.593-0.185H430.1
			c-0.523,0-0.999-0.084-1.429-0.252c-0.429-0.168-0.771-0.395-1.023-0.68c-0.254-0.285-0.38-0.604-0.38-0.956
			c0-0.325,0.091-0.607,0.271-0.844c0.182-0.237,0.429-0.42,0.74-0.548c0.312-0.128,0.663-0.192,1.052-0.192l0.48,0.624
			c-0.155,0-0.303,0.031-0.444,0.092c-0.142,0.062-0.257,0.151-0.348,0.268s-0.136,0.264-0.136,0.44c0,0.213,0.09,0.387,0.271,0.52
			s0.496,0.2,0.944,0.2h2.607c0.827,0,1.477,0.152,1.948,0.456c0.472,0.304,0.708,0.77,0.708,1.4c0,0.56-0.184,1.092-0.552,1.596
			s-0.89,0.916-1.564,1.236S431.775,391.778,430.852,391.778z M430.948,385.363c-0.613,0-1.175-0.116-1.684-0.348
			c-0.51-0.232-0.916-0.556-1.221-0.972c-0.304-0.416-0.456-0.904-0.456-1.464c0-0.57,0.152-1.066,0.456-1.488
			c0.305-0.421,0.711-0.746,1.221-0.972c0.509-0.227,1.07-0.34,1.684-0.34c0.598,0,1.149,0.113,1.656,0.34s0.914,0.551,1.224,0.972
			s0.464,0.917,0.464,1.488c0,0.56-0.154,1.048-0.464,1.464s-0.717,0.74-1.224,0.972S431.546,385.363,430.948,385.363z
			 M430.964,384.051c0.491,0,0.859-0.141,1.104-0.424c0.245-0.282,0.368-0.632,0.368-1.048c0-0.448-0.124-0.808-0.372-1.08
			s-0.62-0.408-1.116-0.408c-0.479,0-0.848,0.136-1.104,0.408c-0.257,0.272-0.385,0.632-0.385,1.08c0,0.416,0.13,0.766,0.389,1.048
			C430.107,383.91,430.478,384.051,430.964,384.051z M433.7,381.027l-0.864-0.496c0-0.464,0.108-0.871,0.324-1.22
			c0.216-0.349,0.504-0.62,0.864-0.812c0.36-0.192,0.759-0.288,1.196-0.288l-0.097,1.808c-0.464,0-0.817,0.082-1.06,0.244
			C433.821,380.425,433.7,380.68,433.7,381.027z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M440.068,387.986c-0.747,0-1.388-0.103-1.924-0.308s-0.948-0.501-1.236-0.888
			c-0.288-0.386-0.435-0.852-0.439-1.396h1.743c0.017,0.384,0.168,0.664,0.456,0.84c0.288,0.176,0.681,0.264,1.177,0.264
			c0.271,0,0.513-0.04,0.724-0.12c0.211-0.08,0.375-0.189,0.492-0.328s0.176-0.293,0.176-0.464c0-0.219-0.079-0.391-0.236-0.516
			s-0.409-0.241-0.756-0.348l-1.696-0.608c-0.607-0.219-1.074-0.507-1.399-0.864c-0.325-0.357-0.488-0.784-0.488-1.28
			c0-0.656,0.298-1.185,0.893-1.588c0.594-0.403,1.391-0.604,2.388-0.604c0.992,0,1.759,0.217,2.3,0.652s0.82,0.985,0.836,1.652
			H441.3c-0.027-0.256-0.149-0.458-0.368-0.608c-0.219-0.149-0.55-0.224-0.992-0.224c-0.4,0-0.714,0.082-0.94,0.244
			s-0.34,0.354-0.34,0.572c0,0.368,0.314,0.645,0.944,0.832l1.84,0.688c0.598,0.203,1.062,0.464,1.392,0.784
			c0.331,0.32,0.496,0.726,0.496,1.216c0,0.411-0.137,0.798-0.412,1.164c-0.274,0.366-0.657,0.663-1.147,0.892
			C441.281,387.872,440.714,387.986,440.068,387.986z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M444.636,381.251v-1.248h5.088v1.248H444.636z M448.236,387.986c-0.459,0-0.879-0.085-1.26-0.256
			c-0.382-0.17-0.686-0.432-0.912-0.784s-0.34-0.8-0.34-1.344v-7.664h1.888v7.6c0,0.64,0.304,0.96,0.912,0.96
			c0.187,0,0.373-0.021,0.56-0.064c0.187-0.042,0.4-0.106,0.641-0.192l0.271,1.328c-0.299,0.117-0.589,0.216-0.872,0.295
			C448.841,387.946,448.546,387.986,448.236,387.986z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M454.82,387.986c-0.661,0-1.267-0.17-1.816-0.512c-0.549-0.341-0.989-0.82-1.319-1.436
			c-0.331-0.616-0.496-1.337-0.496-2.164c0-0.853,0.164-1.586,0.492-2.2c0.327-0.613,0.767-1.083,1.315-1.408
			c0.55-0.325,1.157-0.488,1.824-0.488c0.619,0,1.153,0.124,1.604,0.372c0.45,0.248,0.812,0.584,1.088,1.008
			c0.274,0.424,0.46,0.903,0.556,1.436s0.102,1.083,0.017,1.648h-5.393v-1.024h4.056l-0.367,0.24c0.016-0.25,0.004-0.504-0.036-0.76
			s-0.116-0.491-0.229-0.704c-0.111-0.213-0.271-0.385-0.476-0.516c-0.205-0.13-0.468-0.196-0.788-0.196
			c-0.384,0-0.709,0.096-0.976,0.288c-0.268,0.192-0.47,0.453-0.608,0.784c-0.139,0.331-0.208,0.704-0.208,1.12v0.752
			c0,0.438,0.065,0.824,0.195,1.16c0.131,0.336,0.326,0.6,0.585,0.792s0.585,0.288,0.979,0.288c0.368,0,0.678-0.085,0.928-0.256
			c0.251-0.171,0.411-0.427,0.48-0.768h1.904c-0.08,0.501-0.269,0.942-0.564,1.324s-0.679,0.68-1.147,0.896
			C455.951,387.879,455.418,387.986,454.82,387.986z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M460.404,387.763v-7.792h1.888v7.792H460.404z M461.828,384.355c0-1.019,0.114-1.861,0.344-2.528
			c0.229-0.667,0.537-1.161,0.924-1.484s0.817-0.484,1.292-0.484c0.128,0,0.25,0.008,0.364,0.024s0.229,0.04,0.34,0.072
			l-0.032,1.888c-0.139-0.032-0.287-0.059-0.447-0.08s-0.305-0.032-0.433-0.032c-0.416,0-0.764,0.1-1.044,0.3
			c-0.279,0.2-0.49,0.495-0.632,0.884c-0.142,0.39-0.212,0.87-0.212,1.44H461.828z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M466.683,387.763v-7.76h1.647l0.208,1.328c0.256-0.517,0.595-0.905,1.017-1.164
			c0.421-0.258,0.903-0.388,1.447-0.388c0.641,0,1.175,0.143,1.604,0.428c0.429,0.286,0.752,0.687,0.968,1.204
			c0.216,0.518,0.324,1.12,0.324,1.808v4.544h-1.888v-4.544c0-0.576-0.139-1.011-0.416-1.304c-0.277-0.293-0.641-0.44-1.089-0.44
			c-0.378,0-0.713,0.104-1.004,0.312c-0.29,0.208-0.519,0.521-0.684,0.94c-0.165,0.419-0.248,0.94-0.248,1.564v3.472H466.683z
			 M477.227,387.763v-4.544c0-0.576-0.139-1.011-0.416-1.304c-0.277-0.293-0.641-0.44-1.088-0.44c-0.565,0-1.011,0.229-1.336,0.688
			c-0.326,0.459-0.488,1.152-0.488,2.08h-0.784c0-0.816,0.076-1.508,0.229-2.076c0.151-0.568,0.367-1.026,0.647-1.376
			c0.28-0.349,0.609-0.605,0.988-0.768c0.379-0.163,0.797-0.244,1.256-0.244c0.635,0,1.167,0.143,1.596,0.428
			c0.43,0.286,0.754,0.687,0.973,1.204c0.218,0.518,0.327,1.12,0.327,1.808v4.544H477.227z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M484.266,387.986c-0.666,0-1.223-0.115-1.668-0.348c-0.445-0.232-0.779-0.537-1.004-0.916
			c-0.224-0.378-0.336-0.789-0.336-1.232c0-0.491,0.129-0.903,0.388-1.236c0.259-0.333,0.626-0.584,1.101-0.752
			c0.475-0.168,1.034-0.252,1.68-0.252c0.422,0,0.82,0.032,1.196,0.096s0.75,0.149,1.124,0.256v1.104
			c-0.294-0.096-0.613-0.17-0.96-0.224s-0.672-0.08-0.977-0.08c-0.447,0-0.815,0.083-1.104,0.248
			c-0.288,0.165-0.432,0.445-0.432,0.84c0,0.288,0.117,0.536,0.352,0.744s0.608,0.312,1.12,0.312c0.389,0,0.72-0.085,0.992-0.256
			c0.271-0.17,0.479-0.384,0.62-0.64c0.141-0.256,0.212-0.512,0.212-0.768h0.447c0,0.56-0.104,1.076-0.312,1.548
			s-0.517,0.85-0.924,1.132C485.375,387.846,484.869,387.986,484.266,387.986z M486.81,387.763l-0.239-1.888v-3.12
			c0-0.544-0.111-0.947-0.332-1.208c-0.222-0.261-0.597-0.392-1.124-0.392c-0.305,0-0.568,0.045-0.792,0.136
			c-0.225,0.091-0.396,0.22-0.517,0.388s-0.18,0.372-0.18,0.612h-2.064c0-0.586,0.142-1.065,0.425-1.436
			c0.282-0.371,0.688-0.643,1.22-0.816c0.53-0.173,1.166-0.26,1.908-0.26c0.672,0,1.258,0.108,1.76,0.324
			c0.501,0.216,0.891,0.548,1.168,0.996c0.277,0.448,0.416,1.021,0.416,1.72v4.944H486.81z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M491.226,387.763v-7.76h1.647l0.24,1.536v6.224H491.226z M496.554,387.763v-4.544
			c0-0.576-0.139-1.011-0.416-1.304c-0.277-0.293-0.641-0.44-1.089-0.44c-0.378,0-0.713,0.104-1.004,0.312
			c-0.29,0.208-0.519,0.521-0.684,0.94c-0.165,0.419-0.248,0.94-0.248,1.564h-0.624c0-1.008,0.125-1.847,0.376-2.516
			c0.251-0.669,0.605-1.169,1.064-1.5c0.458-0.331,0.997-0.496,1.615-0.496c0.641,0,1.175,0.143,1.604,0.428
			c0.429,0.286,0.752,0.687,0.968,1.204c0.216,0.518,0.324,1.12,0.324,1.808v4.544H496.554z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M501.194,387.763v-7.76h1.647l0.24,1.536v6.224H501.194z M506.522,387.763v-4.544
			c0-0.576-0.139-1.011-0.416-1.304c-0.277-0.293-0.641-0.44-1.089-0.44c-0.378,0-0.713,0.104-1.004,0.312
			c-0.29,0.208-0.519,0.521-0.684,0.94c-0.165,0.419-0.248,0.94-0.248,1.564h-0.624c0-1.008,0.125-1.847,0.376-2.516
			c0.251-0.669,0.605-1.169,1.064-1.5c0.458-0.331,0.997-0.496,1.615-0.496c0.641,0,1.175,0.143,1.604,0.428
			c0.429,0.286,0.752,0.687,0.968,1.204c0.216,0.518,0.324,1.12,0.324,1.808v4.544H506.522z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M389.766,403.763l0.721-7.662h1.056l-0.72,7.662H389.766z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M392.911,403.763l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.472-0.702,0.78-0.906
			c0.308-0.204,0.651-0.306,1.032-0.306c0.42,0,0.766,0.103,1.037,0.309c0.272,0.206,0.467,0.5,0.582,0.882
			c0.116,0.382,0.148,0.833,0.097,1.354l-0.312,3.39h-1.056l0.312-3.39c0.057-0.496-0.013-0.869-0.207-1.119
			c-0.193-0.25-0.459-0.375-0.795-0.375c-0.439,0-0.806,0.191-1.098,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658
			H392.911z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M401.413,403.913c-0.4,0-0.757-0.109-1.071-0.327c-0.313-0.218-0.562-0.523-0.744-0.915
			c-0.182-0.393-0.272-0.849-0.272-1.368c0-0.42,0.058-0.835,0.174-1.245s0.285-0.783,0.507-1.119
			c0.223-0.336,0.492-0.604,0.811-0.807c0.317-0.202,0.679-0.303,1.083-0.303c0.344,0,0.638,0.079,0.882,0.236
			c0.244,0.158,0.436,0.392,0.576,0.699l0.306-3.204h1.062l-0.774,8.202h-0.864l-0.048-0.942c-0.196,0.364-0.438,0.638-0.726,0.819
			S401.725,403.913,401.413,403.913z M401.653,402.857c0.212,0,0.424-0.061,0.636-0.183s0.395-0.298,0.549-0.528
			c0.154-0.229,0.245-0.505,0.273-0.825l0.09-0.96c0.008-0.036,0.012-0.063,0.012-0.084c0-0.02,0-0.046,0-0.078
			c0.004-0.287-0.05-0.529-0.162-0.726c-0.111-0.196-0.258-0.344-0.438-0.444c-0.18-0.1-0.376-0.149-0.588-0.149
			c-0.284,0-0.529,0.073-0.735,0.219c-0.206,0.146-0.373,0.336-0.501,0.57c-0.128,0.233-0.223,0.484-0.285,0.753
			c-0.062,0.268-0.093,0.521-0.093,0.762c0,0.508,0.116,0.914,0.348,1.218C400.991,402.705,401.289,402.857,401.653,402.857z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M406.134,403.763l0.546-5.796h1.057l-0.546,5.796H406.134z M407.323,396.773
			c-0.208,0-0.365-0.063-0.471-0.191c-0.106-0.128-0.153-0.301-0.142-0.517c0.021-0.204,0.095-0.373,0.226-0.507
			c0.13-0.134,0.301-0.201,0.513-0.201s0.371,0.065,0.477,0.195c0.106,0.13,0.151,0.301,0.136,0.513
			c-0.021,0.221-0.098,0.394-0.231,0.52S407.527,396.773,407.323,396.773z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M411.295,403.913c-0.452,0-0.848-0.124-1.188-0.372c-0.341-0.248-0.596-0.6-0.766-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.061-0.632,0.217-1.176,0.472-1.632c0.254-0.456,0.572-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.355,0,0.66,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.614,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.058,0.981c-0.009,0.112-0.021,0.229-0.039,0.351
			c-0.019,0.122-0.041,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.092,0.38-0.249,0.709-0.471,0.987c-0.223,0.277-0.492,0.493-0.811,0.645
			C412.024,403.837,411.674,403.913,411.295,403.913z M410.251,400.577l-0.234-0.168h3.049l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.135-0.494-0.288-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243c-0.217,0.162-0.39,0.38-0.52,0.654C410.376,399.936,410.291,400.24,410.251,400.577z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M418.434,396.101h2.136c0.704,0,1.3,0.157,1.788,0.471c0.488,0.314,0.849,0.759,1.083,1.332
			c0.234,0.574,0.315,1.249,0.243,2.025s-0.28,1.451-0.624,2.025c-0.344,0.573-0.791,1.019-1.341,1.335
			c-0.55,0.315-1.173,0.474-1.869,0.474h-2.136L418.434,396.101z M419.388,397.156l-0.517,5.557h1.074
			c0.484,0,0.911-0.121,1.281-0.363s0.669-0.572,0.897-0.99c0.228-0.418,0.367-0.895,0.42-1.431
			c0.052-0.536,0.003-1.013-0.147-1.431s-0.389-0.746-0.717-0.984s-0.734-0.357-1.218-0.357H419.388z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M426.547,403.913c-0.42,0-0.768-0.087-1.041-0.259c-0.274-0.172-0.473-0.395-0.594-0.669
			c-0.122-0.273-0.168-0.566-0.136-0.879c0.036-0.38,0.153-0.694,0.352-0.944c0.197-0.25,0.46-0.438,0.786-0.564
			c0.325-0.126,0.692-0.188,1.101-0.188c0.252,0,0.5,0.024,0.744,0.074c0.244,0.051,0.476,0.117,0.696,0.201l0.065-0.66
			c0.052-0.464-0.001-0.802-0.159-1.014s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314
			c-0.107,0.141-0.172,0.314-0.191,0.522h-1.164c0.044-0.44,0.178-0.792,0.401-1.056c0.225-0.265,0.508-0.455,0.853-0.573
			c0.344-0.118,0.712-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54c0.351,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912
			c-0.004-0.147-0.005-0.295-0.003-0.441c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773
			S426.934,403.913,426.547,403.913z M426.804,403.013c0.271,0,0.525-0.066,0.759-0.198c0.234-0.132,0.425-0.316,0.573-0.555
			c0.147-0.238,0.229-0.515,0.246-0.831c-0.196-0.063-0.406-0.116-0.63-0.156c-0.225-0.04-0.435-0.06-0.63-0.06
			c-0.324,0-0.603,0.066-0.834,0.201c-0.232,0.134-0.364,0.364-0.396,0.692c-0.02,0.256,0.049,0.472,0.207,0.646
			S426.492,403.013,426.804,403.013z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M431.16,403.763l0.546-5.784h0.912l0.013,1.062c0.212-0.4,0.472-0.702,0.779-0.906
			c0.309-0.204,0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882s0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.013-0.869-0.207-1.119s-0.459-0.375-0.795-0.375c-0.44,0-0.807,0.191-1.098,0.576
			c-0.293,0.384-0.473,0.934-0.54,1.649l-0.246,2.658H431.16z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M439.674,403.913c-0.433,0-0.822-0.124-1.17-0.372c-0.349-0.248-0.612-0.601-0.792-1.057
			c-0.181-0.456-0.242-0.996-0.187-1.62c0.061-0.647,0.224-1.196,0.489-1.646s0.595-0.794,0.987-1.032
			c0.392-0.237,0.806-0.356,1.241-0.356c0.309,0,0.579,0.055,0.813,0.165c0.234,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.463,0.073-0.682,0.219c-0.218,0.146-0.402,0.366-0.555,0.66
			s-0.25,0.663-0.294,1.106c-0.036,0.421-0.011,0.779,0.075,1.077s0.224,0.525,0.414,0.682c0.189,0.155,0.417,0.233,0.681,0.233
			c0.34,0,0.61-0.097,0.811-0.291c0.199-0.193,0.315-0.463,0.348-0.807h1.068c-0.032,0.404-0.149,0.769-0.352,1.095
			s-0.472,0.584-0.81,0.774C440.497,403.817,440.11,403.913,439.674,403.913z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M445.284,403.913c-0.452,0-0.848-0.124-1.188-0.372c-0.341-0.248-0.596-0.6-0.766-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.061-0.632,0.217-1.176,0.472-1.632c0.254-0.456,0.572-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.355,0,0.66,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.614,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.058,0.981c-0.009,0.112-0.021,0.229-0.039,0.351
			c-0.019,0.122-0.041,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.092,0.38-0.249,0.709-0.471,0.987c-0.223,0.277-0.492,0.493-0.811,0.645
			C446.013,403.837,445.664,403.913,445.284,403.913z M444.24,400.577l-0.234-0.168h3.049l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.135-0.494-0.288-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243c-0.217,0.162-0.39,0.38-0.52,0.654C444.365,399.936,444.28,400.24,444.24,400.577z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M451.17,404.687l3.666-9.773h1.08l-3.679,9.773H451.17z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M459.954,396.101h2.136c0.704,0,1.301,0.157,1.788,0.471c0.488,0.314,0.85,0.759,1.083,1.332
			c0.234,0.574,0.315,1.249,0.243,2.025s-0.28,1.451-0.624,2.025c-0.344,0.573-0.791,1.019-1.341,1.335
			c-0.55,0.315-1.173,0.474-1.869,0.474h-2.136L459.954,396.101z M460.908,397.156l-0.516,5.557h1.073
			c0.484,0,0.911-0.121,1.281-0.363s0.669-0.572,0.897-0.99c0.228-0.418,0.368-0.895,0.42-1.431s0.003-1.013-0.147-1.431
			c-0.149-0.418-0.389-0.746-0.717-0.984s-0.734-0.357-1.218-0.357H460.908z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M468.065,403.913c-0.42,0-0.767-0.087-1.04-0.259c-0.274-0.172-0.473-0.395-0.595-0.669
			c-0.122-0.273-0.167-0.566-0.135-0.879c0.036-0.38,0.153-0.694,0.351-0.944c0.198-0.25,0.46-0.438,0.786-0.564
			s0.693-0.188,1.102-0.188c0.252,0,0.5,0.024,0.744,0.074c0.243,0.051,0.476,0.117,0.695,0.201l0.066-0.66
			c0.052-0.464-0.001-0.802-0.159-1.014s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314
			c-0.108,0.141-0.172,0.314-0.192,0.522h-1.164c0.044-0.44,0.179-0.792,0.402-1.056c0.224-0.265,0.508-0.455,0.852-0.573
			c0.345-0.118,0.713-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54c0.35,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912
			c-0.004-0.147-0.005-0.295-0.003-0.441c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773
			S468.454,403.913,468.065,403.913z M468.324,403.013c0.271,0,0.524-0.066,0.759-0.198s0.425-0.316,0.573-0.555
			c0.147-0.238,0.229-0.515,0.246-0.831c-0.196-0.063-0.406-0.116-0.631-0.156c-0.224-0.04-0.434-0.06-0.63-0.06
			c-0.323,0-0.602,0.066-0.834,0.201c-0.231,0.134-0.363,0.364-0.396,0.692c-0.021,0.256,0.049,0.472,0.207,0.646
			S468.011,403.013,468.324,403.013z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M472.679,403.763l0.546-5.796h1.057l-0.108,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.253,0.021c0.088,0.014,0.174,0.039,0.258,0.075l-0.139,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.214-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			c-0.203,0.172-0.364,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H472.679z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M480.605,403.763l-1.656-2.946l-0.689,0.582l-0.222,2.364h-1.057l0.769-8.202h1.062l-0.432,4.596
			l2.496-2.178h1.452l-2.557,2.154l2.13,3.63H480.605z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M482.79,397.979h1.104l0.75,4.368l1.494-4.068h0.937l0.726,4.116l1.584-4.416h1.057l-2.25,5.784h-1.038
			l-0.732-3.762l-1.41,3.762h-1.038L482.79,397.979z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M492.683,403.913c-0.42,0-0.767-0.087-1.04-0.259c-0.274-0.172-0.473-0.395-0.595-0.669
			c-0.122-0.273-0.167-0.566-0.135-0.879c0.036-0.38,0.153-0.694,0.351-0.944c0.198-0.25,0.46-0.438,0.786-0.564
			s0.693-0.188,1.102-0.188c0.252,0,0.5,0.024,0.744,0.074c0.243,0.051,0.476,0.117,0.695,0.201l0.066-0.66
			c0.052-0.464-0.001-0.802-0.159-1.014s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314
			c-0.108,0.141-0.172,0.314-0.192,0.522h-1.164c0.044-0.44,0.179-0.792,0.402-1.056c0.224-0.265,0.508-0.455,0.852-0.573
			c0.345-0.118,0.713-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54c0.35,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912
			c-0.004-0.147-0.005-0.295-0.003-0.441c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773
			S493.072,403.913,492.683,403.913z M492.942,403.013c0.271,0,0.524-0.066,0.759-0.198s0.425-0.316,0.573-0.555
			c0.147-0.238,0.229-0.515,0.246-0.831c-0.196-0.063-0.406-0.116-0.631-0.156c-0.224-0.04-0.434-0.06-0.63-0.06
			c-0.323,0-0.602,0.066-0.834,0.201c-0.231,0.134-0.363,0.364-0.396,0.692c-0.021,0.256,0.049,0.472,0.207,0.646
			S492.63,403.013,492.942,403.013z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M496.962,397.979h1.206l0.954,4.584l1.817-4.584h1.213l-2.557,5.784h-1.176L496.962,397.979z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M504.683,403.913c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C505.412,403.837,505.063,403.913,504.683,403.913z M503.639,400.577l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C503.764,399.936,503.679,400.24,503.639,400.577z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M510.569,404.687l3.666-9.773h1.08l-3.678,9.773H510.569z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M404.665,417.763l0.72-7.662h2.334c0.512,0,0.937,0.096,1.275,0.288c0.338,0.192,0.585,0.467,0.74,0.825
			c0.156,0.357,0.208,0.789,0.156,1.293c-0.068,0.74-0.332,1.322-0.792,1.746s-1.072,0.636-1.836,0.636h-1.266l-0.271,2.874H404.665
			z M406.092,413.869h1.266c0.433,0,0.772-0.126,1.021-0.379c0.248-0.252,0.388-0.579,0.42-0.983
			c0.04-0.416-0.039-0.753-0.237-1.011c-0.197-0.259-0.515-0.388-0.951-0.388h-1.266L406.092,413.869z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M410.814,417.763l0.546-5.796h1.057l-0.108,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.855-0.297c0.08,0,0.164,0.007,0.252,0.021s0.174,0.039,0.258,0.075l-0.138,1.164
			c-0.104-0.028-0.21-0.051-0.318-0.069c-0.108-0.018-0.214-0.026-0.318-0.026c-0.288,0-0.533,0.086-0.737,0.258
			s-0.365,0.418-0.483,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.239,2.574H410.814z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M417.054,417.913c-0.472,0-0.886-0.114-1.242-0.343c-0.355-0.228-0.62-0.566-0.792-1.017
			s-0.226-1.009-0.162-1.677c0.064-0.676,0.227-1.24,0.486-1.692s0.589-0.791,0.987-1.017c0.397-0.227,0.831-0.339,1.299-0.339
			s0.878,0.114,1.229,0.345c0.353,0.229,0.616,0.57,0.792,1.02c0.177,0.45,0.232,1.012,0.168,1.684
			c-0.067,0.668-0.231,1.227-0.491,1.677c-0.261,0.45-0.587,0.789-0.979,1.017C417.959,417.799,417.526,417.913,417.054,417.913z
			 M417.108,416.893c0.444,0,0.812-0.175,1.104-0.524c0.292-0.351,0.472-0.848,0.54-1.491c0.063-0.652-0.01-1.154-0.22-1.506
			c-0.21-0.353-0.534-0.528-0.975-0.528s-0.804,0.175-1.092,0.525c-0.288,0.35-0.464,0.853-0.528,1.509
			c-0.044,0.424-0.026,0.787,0.051,1.089c0.078,0.302,0.211,0.532,0.399,0.689C416.576,416.814,416.816,416.893,417.108,416.893z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M422.707,420.745c-0.536,0-0.999-0.084-1.39-0.252c-0.39-0.169-0.685-0.396-0.885-0.685
			s-0.284-0.61-0.252-0.966c0.032-0.332,0.155-0.611,0.369-0.837c0.214-0.227,0.48-0.373,0.801-0.441
			c-0.196-0.128-0.34-0.28-0.432-0.459c-0.092-0.178-0.13-0.373-0.114-0.585c0.024-0.275,0.126-0.509,0.306-0.699
			c0.181-0.189,0.402-0.32,0.666-0.393c-0.168-0.188-0.294-0.408-0.378-0.66s-0.111-0.534-0.084-0.846
			c0.044-0.424,0.179-0.793,0.402-1.107c0.224-0.313,0.508-0.557,0.852-0.729c0.345-0.172,0.715-0.258,1.11-0.258
			c0.252,0,0.486,0.033,0.702,0.102s0.406,0.166,0.57,0.294c0.06-0.296,0.169-0.553,0.326-0.771
			c0.158-0.219,0.354-0.389,0.585-0.511c0.232-0.122,0.486-0.183,0.763-0.183l-0.187,1.14c-0.304,0-0.545,0.062-0.723,0.187
			s-0.285,0.322-0.321,0.594c0.104,0.168,0.182,0.356,0.231,0.564s0.064,0.434,0.045,0.678c-0.036,0.416-0.168,0.782-0.396,1.098
			c-0.228,0.316-0.518,0.562-0.869,0.738c-0.353,0.176-0.738,0.264-1.158,0.264c-0.164,0-0.329-0.02-0.495-0.06
			s-0.321-0.097-0.465-0.168c-0.132,0.032-0.252,0.102-0.36,0.21c-0.108,0.107-0.174,0.25-0.198,0.426
			c-0.02,0.176,0.031,0.319,0.153,0.429c0.122,0.11,0.349,0.165,0.681,0.165h1.885c0.516,0,0.912,0.112,1.19,0.336
			c0.278,0.225,0.399,0.559,0.363,1.002c-0.032,0.4-0.188,0.782-0.468,1.146c-0.28,0.364-0.661,0.661-1.144,0.891
			C423.908,420.63,423.346,420.745,422.707,420.745z M422.749,419.809c0.428,0,0.803-0.065,1.125-0.198
			c0.321-0.132,0.576-0.302,0.762-0.51c0.187-0.208,0.287-0.428,0.303-0.66c0.013-0.18-0.029-0.321-0.126-0.426
			c-0.096-0.104-0.244-0.156-0.443-0.156h-1.975c-0.34,0-0.614,0.09-0.821,0.271c-0.208,0.18-0.32,0.399-0.337,0.66
			c-0.016,0.304,0.11,0.55,0.379,0.737C421.882,419.715,422.26,419.809,422.749,419.809z M423.379,415.189
			c0.248,0,0.461-0.056,0.639-0.168c0.178-0.111,0.319-0.264,0.423-0.456c0.104-0.191,0.166-0.405,0.187-0.642
			c0.036-0.376-0.035-0.682-0.213-0.915c-0.179-0.234-0.443-0.352-0.795-0.352c-0.364,0-0.657,0.118-0.879,0.354
			c-0.223,0.236-0.35,0.54-0.382,0.912c-0.031,0.359,0.039,0.661,0.214,0.903C422.746,415.067,423.014,415.189,423.379,415.189z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M427.291,417.763l0.546-5.796h1.056l-0.107,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.252,0.021s0.174,0.039,0.258,0.075l-0.138,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.215-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			s-0.365,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H427.291z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M433.453,417.913c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.226-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.267,0.519,0.333,0.828c0.065,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.039,0.351
			c-0.018,0.122-0.041,0.247-0.068,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.188,0.551,0.375,0.729c0.188,0.179,0.446,0.268,0.774,0.268c0.28,0,0.523-0.077,0.731-0.231
			c0.208-0.153,0.349-0.381,0.421-0.681h1.079c-0.092,0.38-0.249,0.709-0.471,0.987c-0.222,0.277-0.492,0.493-0.81,0.645
			C434.181,417.837,433.833,417.913,433.453,417.913z M432.409,414.577l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.038-0.271-0.134-0.494-0.288-0.666c-0.153-0.172-0.389-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.762,0.243s-0.389,0.38-0.519,0.654C432.534,413.936,432.448,414.24,432.409,414.577z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M439.032,417.913c-0.444,0-0.829-0.077-1.155-0.231c-0.325-0.154-0.573-0.372-0.743-0.654
			c-0.171-0.281-0.237-0.614-0.201-0.999h0.996c-0.024,0.309,0.066,0.546,0.272,0.711c0.206,0.166,0.483,0.249,0.831,0.249
			c0.353,0,0.637-0.076,0.855-0.23c0.218-0.154,0.341-0.347,0.369-0.579c0.023-0.204-0.034-0.361-0.175-0.471
			c-0.14-0.11-0.326-0.205-0.558-0.285l-1.032-0.414c-0.399-0.152-0.703-0.36-0.909-0.624s-0.289-0.584-0.249-0.96
			c0.036-0.324,0.151-0.606,0.346-0.846c0.193-0.24,0.454-0.426,0.779-0.556c0.326-0.13,0.697-0.194,1.113-0.194
			c0.664,0,1.158,0.153,1.482,0.462c0.323,0.308,0.472,0.695,0.443,1.164h-1.014c-0.004-0.229-0.09-0.402-0.258-0.522
			s-0.398-0.18-0.69-0.18c-0.235,0-0.431,0.035-0.585,0.104c-0.154,0.07-0.272,0.159-0.356,0.268
			c-0.084,0.107-0.137,0.224-0.156,0.348c-0.021,0.188,0.037,0.343,0.171,0.465s0.311,0.219,0.531,0.291l1.092,0.45
			c0.384,0.14,0.687,0.331,0.909,0.573c0.222,0.242,0.314,0.551,0.278,0.927c-0.027,0.296-0.141,0.576-0.339,0.84
			c-0.197,0.264-0.469,0.479-0.812,0.646C439.923,417.83,439.512,417.913,439.032,417.913z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M444.714,417.913c-0.444,0-0.829-0.077-1.155-0.231s-0.574-0.372-0.744-0.654
			c-0.17-0.281-0.237-0.614-0.201-0.999h0.996c-0.023,0.309,0.067,0.546,0.273,0.711c0.206,0.166,0.482,0.249,0.831,0.249
			c0.352,0,0.637-0.076,0.854-0.23s0.341-0.347,0.369-0.579c0.024-0.204-0.034-0.361-0.174-0.471
			c-0.141-0.11-0.326-0.205-0.559-0.285l-1.031-0.414c-0.4-0.152-0.703-0.36-0.909-0.624s-0.289-0.584-0.249-0.96
			c0.036-0.324,0.15-0.606,0.345-0.846c0.194-0.24,0.454-0.426,0.78-0.556s0.697-0.194,1.113-0.194c0.663,0,1.157,0.153,1.481,0.462
			c0.324,0.308,0.472,0.695,0.444,1.164h-1.015c-0.004-0.229-0.09-0.402-0.258-0.522s-0.397-0.18-0.689-0.18
			c-0.236,0-0.432,0.035-0.585,0.104c-0.154,0.07-0.273,0.159-0.357,0.268c-0.084,0.107-0.136,0.224-0.156,0.348
			c-0.02,0.188,0.037,0.343,0.171,0.465c0.135,0.122,0.312,0.219,0.531,0.291l1.092,0.45c0.385,0.14,0.688,0.331,0.909,0.573
			c0.223,0.242,0.315,0.551,0.279,0.927c-0.028,0.296-0.141,0.576-0.339,0.84s-0.469,0.479-0.813,0.646
			C445.606,417.83,445.194,417.913,444.714,417.913z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M448.674,417.763l0.546-5.796h1.056l-0.546,5.796H448.674z M449.862,410.773
			c-0.208,0-0.364-0.063-0.471-0.191s-0.153-0.301-0.141-0.517c0.02-0.204,0.095-0.373,0.225-0.507s0.301-0.201,0.513-0.201
			s0.371,0.065,0.478,0.195c0.105,0.13,0.15,0.301,0.135,0.513c-0.02,0.221-0.097,0.394-0.23,0.52
			C450.235,410.71,450.066,410.773,449.862,410.773z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M451.632,411.979h1.206l0.954,4.584l1.817-4.584h1.213l-2.557,5.784h-1.176L451.632,411.979z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M459.354,417.913c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C460.083,417.837,459.734,417.913,459.354,417.913z M458.31,414.577l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C458.435,413.936,458.35,414.24,458.31,414.577z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M466.992,417.763l0.624-6.654h-2.189l0.102-1.008h5.442l-0.103,1.008h-2.196l-0.624,6.654H466.992z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M472.404,417.913c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C473.132,417.837,472.784,417.913,472.404,417.913z M471.36,414.577l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C471.485,413.936,471.4,414.24,471.36,414.577z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M478.075,417.913c-0.433,0-0.822-0.124-1.171-0.372c-0.348-0.248-0.611-0.601-0.792-1.057
			c-0.18-0.456-0.241-0.996-0.186-1.62c0.06-0.647,0.223-1.196,0.489-1.646c0.266-0.45,0.595-0.794,0.986-1.032
			c0.393-0.237,0.807-0.356,1.242-0.356c0.309,0,0.579,0.055,0.813,0.165c0.233,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.464,0.073-0.682,0.219c-0.218,0.146-0.403,0.366-0.555,0.66
			c-0.152,0.294-0.25,0.663-0.294,1.106c-0.036,0.421-0.012,0.779,0.075,1.077c0.086,0.298,0.224,0.525,0.414,0.682
			c0.189,0.155,0.417,0.233,0.681,0.233c0.34,0,0.609-0.097,0.81-0.291c0.2-0.193,0.316-0.463,0.349-0.807h1.067
			c-0.032,0.404-0.148,0.769-0.351,1.095s-0.472,0.584-0.811,0.774C478.897,417.817,478.51,417.913,478.075,417.913z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M481.823,417.763l0.769-8.202h1.062l-0.33,3.42c0.212-0.372,0.469-0.657,0.771-0.854
			c0.302-0.198,0.639-0.297,1.011-0.297c0.42,0,0.766,0.104,1.038,0.312c0.271,0.208,0.466,0.502,0.582,0.882
			s0.149,0.83,0.102,1.351l-0.317,3.39h-1.051l0.312-3.39c0.052-0.492-0.018-0.864-0.207-1.116c-0.19-0.252-0.457-0.378-0.801-0.378
			c-0.284,0-0.538,0.08-0.763,0.24c-0.224,0.159-0.408,0.395-0.555,0.704c-0.146,0.311-0.247,0.689-0.303,1.138l-0.264,2.802
			H481.823z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M488.448,417.763l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906
			s0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375
			c-0.44,0-0.807,0.191-1.099,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H488.448z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M497.009,417.913c-0.473,0-0.887-0.114-1.242-0.343c-0.356-0.228-0.62-0.566-0.792-1.017
			s-0.227-1.009-0.162-1.677c0.063-0.676,0.226-1.24,0.486-1.692c0.26-0.452,0.589-0.791,0.986-1.017
			c0.398-0.227,0.831-0.339,1.3-0.339c0.468,0,0.878,0.114,1.229,0.345c0.352,0.229,0.616,0.57,0.792,1.02
			c0.176,0.45,0.232,1.012,0.168,1.684c-0.068,0.668-0.232,1.227-0.492,1.677s-0.586,0.789-0.978,1.017
			C497.913,417.799,497.481,417.913,497.009,417.913z M497.063,416.893c0.444,0,0.812-0.175,1.104-0.524
			c0.291-0.351,0.472-0.848,0.539-1.491c0.064-0.652-0.009-1.154-0.219-1.506c-0.21-0.353-0.535-0.528-0.975-0.528
			c-0.44,0-0.804,0.175-1.092,0.525c-0.288,0.35-0.465,0.853-0.528,1.509c-0.044,0.424-0.027,0.787,0.051,1.089
			s0.211,0.532,0.399,0.689C496.531,416.814,496.771,416.893,497.063,416.893z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M424.553,437.923c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C425.624,437.796,425.121,437.923,424.553,437.923z M424.553,436.468c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S424.15,436.468,424.553,436.468z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M431.289,437.923c-0.573,0-1.083-0.127-1.527-0.38c-0.445-0.254-0.794-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.251-0.519,0.6-0.903,1.045-1.155
			c0.444-0.252,0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158
			C432.359,437.796,431.855,437.923,431.289,437.923z M431.289,436.468c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532
			s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543
			s0.115,1.188,0.348,1.532C430.568,436.295,430.885,436.468,431.289,436.468z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M434.818,436.833c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			s0.31,0.443,0.31,0.74s-0.103,0.541-0.31,0.732s-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S434.818,437.126,434.818,436.833z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M440.478,437.923c-0.373,0-0.734-0.05-1.085-0.147c-0.35-0.099-0.664-0.245-0.942-0.44
			c-0.278-0.194-0.499-0.438-0.662-0.728s-0.245-0.626-0.245-1.01h1.705c0.02,0.163,0.08,0.311,0.18,0.442
			c0.101,0.132,0.237,0.236,0.413,0.312c0.175,0.077,0.387,0.115,0.637,0.115c0.341,0,0.612-0.073,0.815-0.22
			c0.203-0.147,0.305-0.334,0.305-0.561c0-0.156-0.04-0.285-0.12-0.385c-0.079-0.1-0.195-0.174-0.347-0.223
			c-0.152-0.048-0.338-0.072-0.558-0.072h-1.021v-1.255h0.995c0.267,0,0.476-0.066,0.625-0.2c0.15-0.134,0.226-0.309,0.226-0.525
			c0-0.196-0.072-0.358-0.216-0.487c-0.143-0.128-0.378-0.192-0.705-0.192c-0.283,0-0.536,0.059-0.76,0.175
			c-0.224,0.117-0.354,0.304-0.395,0.561h-1.7c0.04-0.5,0.182-0.911,0.425-1.232c0.243-0.322,0.566-0.561,0.97-0.716
			c0.403-0.154,0.863-0.232,1.381-0.232c0.526,0,0.994,0.08,1.402,0.24s0.729,0.384,0.965,0.67c0.234,0.287,0.353,0.622,0.353,1.005
			c0,0.33-0.086,0.622-0.258,0.875c-0.172,0.254-0.431,0.438-0.777,0.556c0.387,0.153,0.69,0.364,0.912,0.632
			c0.222,0.269,0.333,0.591,0.333,0.968c0,0.407-0.121,0.767-0.362,1.08c-0.242,0.313-0.577,0.558-1.005,0.732
			C441.533,437.835,441.039,437.923,440.478,437.923z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M447.108,437.923c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C448.178,437.796,447.675,437.923,447.108,437.923z M447.108,436.468c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S446.705,436.468,447.108,436.468z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M451.013,435.338v-1.4h4.695v1.4H451.013z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M459.643,437.923c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C460.714,437.796,460.21,437.923,459.643,437.923z
			 M459.643,436.468c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C458.922,436.295,459.24,436.468,459.643,436.468z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M463.558,437.763v-1.3c0-0.363,0.051-0.678,0.15-0.942c0.1-0.266,0.262-0.493,0.485-0.683
			c0.223-0.19,0.518-0.354,0.885-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.561-0.354c0.116-0.137,0.175-0.302,0.175-0.495
			c0-0.177-0.07-0.334-0.21-0.473c-0.141-0.139-0.347-0.207-0.62-0.207c-0.254,0-0.472,0.076-0.655,0.229s-0.288,0.372-0.314,0.655
			h-1.771c-0.003-0.526,0.119-0.958,0.367-1.293c0.249-0.335,0.579-0.581,0.993-0.739c0.413-0.159,0.863-0.238,1.35-0.238
			c0.474,0,0.904,0.087,1.293,0.261c0.388,0.173,0.698,0.417,0.93,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.186,0.915c-0.123,0.267-0.321,0.504-0.595,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.326,0.144-0.397,0.212c-0.072,0.068-0.107,0.158-0.107,0.268v0.075h3.77v1.46H463.558z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M469.494,436.833c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S469.494,437.126,469.494,436.833z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M475.154,437.923c-0.373,0-0.734-0.05-1.085-0.147c-0.35-0.099-0.664-0.245-0.942-0.44
			c-0.278-0.194-0.499-0.438-0.662-0.728c-0.164-0.29-0.245-0.626-0.245-1.01h1.705c0.02,0.163,0.08,0.311,0.18,0.442
			s0.237,0.236,0.412,0.312c0.176,0.077,0.388,0.115,0.638,0.115c0.34,0,0.612-0.073,0.815-0.22
			c0.203-0.147,0.305-0.334,0.305-0.561c0-0.156-0.04-0.285-0.12-0.385s-0.195-0.174-0.348-0.223
			c-0.151-0.048-0.337-0.072-0.558-0.072h-1.02v-1.255h0.995c0.267,0,0.475-0.066,0.625-0.2c0.149-0.134,0.225-0.309,0.225-0.525
			c0-0.196-0.071-0.358-0.215-0.487c-0.144-0.128-0.378-0.192-0.705-0.192c-0.283,0-0.536,0.059-0.76,0.175
			c-0.224,0.117-0.355,0.304-0.396,0.561h-1.699c0.04-0.5,0.182-0.911,0.425-1.232c0.243-0.322,0.566-0.561,0.97-0.716
			c0.403-0.154,0.863-0.232,1.38-0.232c0.527,0,0.994,0.08,1.402,0.24c0.409,0.16,0.73,0.384,0.966,0.67
			c0.234,0.287,0.353,0.622,0.353,1.005c0,0.33-0.086,0.622-0.258,0.875c-0.172,0.254-0.431,0.438-0.777,0.556
			c0.387,0.153,0.69,0.364,0.912,0.632c0.222,0.269,0.333,0.591,0.333,0.968c0,0.407-0.121,0.767-0.363,1.08
			c-0.241,0.313-0.576,0.558-1.005,0.732C476.209,437.835,475.714,437.923,475.154,437.923z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M481.784,437.923c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C482.854,437.796,482.35,437.923,481.784,437.923z
			 M481.784,436.468c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C481.063,436.295,481.38,436.468,481.784,436.468z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M438.772,606.567h3.36c1.056,0,1.98,0.212,2.775,0.636s1.413,1.025,1.856,1.804
			c0.442,0.779,0.664,1.699,0.664,2.76c0,1.051-0.222,1.964-0.664,2.74c-0.443,0.776-1.062,1.377-1.856,1.804
			c-0.795,0.427-1.72,0.64-2.775,0.64h-3.36V606.567z M440.66,608.279v6.976h1.473c0.672,0,1.252-0.152,1.739-0.456
			c0.488-0.304,0.864-0.719,1.128-1.244c0.265-0.525,0.396-1.121,0.396-1.788c0-0.677-0.132-1.277-0.396-1.8
			c-0.264-0.523-0.64-0.935-1.128-1.236c-0.487-0.301-1.067-0.452-1.739-0.452H440.66z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M450.86,607.767c-0.368,0-0.655-0.097-0.86-0.292c-0.205-0.194-0.308-0.465-0.308-0.812
			c0-0.336,0.103-0.604,0.308-0.804c0.205-0.2,0.492-0.3,0.86-0.3c0.362,0,0.648,0.1,0.856,0.3c0.207,0.2,0.312,0.468,0.312,0.804
			c0,0.347-0.104,0.618-0.312,0.812C451.508,607.669,451.222,607.767,450.86,607.767z M449.916,616.951v-7.792h1.889v7.792H449.916z
			"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M457.868,617.174c-0.65,0-1.243-0.172-1.779-0.516c-0.536-0.344-0.963-0.827-1.28-1.448
			c-0.317-0.621-0.476-1.348-0.476-2.18c0-0.821,0.153-1.537,0.46-2.148s0.717-1.083,1.231-1.416s1.087-0.5,1.716-0.5
			c0.576,0,1.075,0.137,1.496,0.412c0.422,0.274,0.744,0.681,0.969,1.22c0.224,0.539,0.336,1.2,0.336,1.984h-0.593
			c0-0.405-0.082-0.75-0.248-1.036c-0.165-0.286-0.383-0.504-0.651-0.656c-0.27-0.152-0.562-0.228-0.876-0.228
			c-0.379,0-0.714,0.099-1.004,0.296c-0.291,0.198-0.52,0.474-0.685,0.828c-0.165,0.355-0.248,0.77-0.248,1.244
			c0,0.491,0.083,0.917,0.248,1.28c0.165,0.363,0.394,0.646,0.685,0.848c0.29,0.203,0.62,0.304,0.987,0.304
			c0.299,0,0.586-0.082,0.86-0.244s0.499-0.391,0.672-0.684c0.174-0.293,0.26-0.635,0.26-1.024h0.593
			c0,0.816-0.122,1.495-0.364,2.036c-0.243,0.541-0.567,0.948-0.972,1.22C458.798,617.039,458.353,617.174,457.868,617.174z
			 M460.3,616.951l-0.353-1.92v-9.056h1.904v10.976H460.3z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M465.932,607.767c-0.368,0-0.654-0.097-0.859-0.292c-0.206-0.194-0.309-0.465-0.309-0.812
			c0-0.336,0.103-0.604,0.309-0.804c0.205-0.2,0.491-0.3,0.859-0.3c0.363,0,0.648,0.1,0.856,0.3c0.208,0.2,0.312,0.468,0.312,0.804
			c0,0.347-0.104,0.618-0.312,0.812C466.581,607.669,466.296,607.767,465.932,607.767z M464.989,616.951v-7.792h1.888v7.792H464.989
			z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M400.689,633.101c-0.557,0-1.044-0.161-1.464-0.483c-0.421-0.322-0.736-0.776-0.948-1.362
			s-0.28-1.274-0.204-2.066c0.063-0.648,0.203-1.223,0.417-1.723s0.486-0.922,0.816-1.266c0.329-0.344,0.699-0.604,1.109-0.783
			c0.41-0.178,0.841-0.267,1.293-0.267c0.588,0,1.081,0.139,1.479,0.417c0.397,0.278,0.698,0.717,0.902,1.316l-1.086,0.324
			c-0.176-0.332-0.385-0.581-0.627-0.747s-0.517-0.249-0.825-0.249c-0.376,0-0.731,0.119-1.067,0.357s-0.619,0.579-0.85,1.022
			c-0.229,0.444-0.375,0.977-0.435,1.597c-0.052,0.56-0.018,1.052,0.102,1.476c0.12,0.424,0.313,0.756,0.579,0.996
			c0.267,0.24,0.596,0.36,0.987,0.36c0.312,0,0.603-0.074,0.873-0.223c0.27-0.147,0.494-0.371,0.672-0.669s0.281-0.667,0.31-1.106
			c0.008-0.064,0.01-0.125,0.006-0.184c-0.004-0.058-0.017-0.141-0.036-0.249l0.312,0.324l-1.908-0.065l0.072-0.763h2.658
			l-0.354,3.864h-0.858c-0.004-0.244-0.005-0.49-0.003-0.738s-0.001-0.492-0.009-0.731l0.084-0.019
			c-0.181,0.517-0.436,0.918-0.766,1.206S401.181,633.101,400.689,633.101z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M405.363,632.951l0.546-5.796h1.056l-0.107,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.252,0.021s0.174,0.039,0.258,0.075l-0.138,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.215-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			s-0.365,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H405.363z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M411.602,633.101c-0.472,0-0.886-0.114-1.241-0.343c-0.356-0.228-0.62-0.566-0.792-1.017
			c-0.173-0.45-0.227-1.009-0.162-1.677c0.063-0.676,0.226-1.24,0.485-1.692c0.261-0.452,0.589-0.791,0.987-1.017
			c0.398-0.227,0.831-0.339,1.299-0.339c0.469,0,0.878,0.114,1.23,0.345c0.352,0.229,0.616,0.57,0.792,1.02
			c0.176,0.45,0.231,1.012,0.168,1.684c-0.068,0.668-0.232,1.227-0.492,1.677s-0.586,0.789-0.978,1.017
			C412.506,632.987,412.075,633.101,411.602,633.101z M411.657,632.081c0.443,0,0.812-0.175,1.104-0.524
			c0.292-0.351,0.473-0.848,0.54-1.491c0.064-0.652-0.009-1.154-0.219-1.506c-0.21-0.353-0.535-0.528-0.975-0.528
			c-0.44,0-0.805,0.175-1.093,0.525c-0.288,0.35-0.464,0.853-0.527,1.509c-0.044,0.424-0.027,0.787,0.051,1.089
			s0.211,0.532,0.398,0.689C411.125,632.002,411.365,632.081,411.657,632.081z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M417.596,633.101c-0.472,0-0.886-0.114-1.241-0.343c-0.356-0.228-0.62-0.566-0.792-1.017
			c-0.173-0.45-0.227-1.009-0.162-1.677c0.063-0.676,0.226-1.24,0.485-1.692c0.261-0.452,0.589-0.791,0.987-1.017
			c0.398-0.227,0.831-0.339,1.299-0.339c0.469,0,0.878,0.114,1.23,0.345c0.352,0.229,0.616,0.57,0.792,1.02
			c0.176,0.45,0.231,1.012,0.168,1.684c-0.068,0.668-0.232,1.227-0.492,1.677s-0.586,0.789-0.978,1.017
			C418.501,632.987,418.069,633.101,417.596,633.101z M417.651,632.081c0.443,0,0.812-0.175,1.104-0.524
			c0.292-0.351,0.473-0.848,0.54-1.491c0.064-0.652-0.009-1.154-0.219-1.506c-0.21-0.353-0.535-0.528-0.975-0.528
			c-0.44,0-0.805,0.175-1.093,0.525c-0.288,0.35-0.464,0.853-0.527,1.509c-0.044,0.424-0.027,0.787,0.051,1.089
			s0.211,0.532,0.398,0.689C417.119,632.002,417.359,632.081,417.651,632.081z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M421.311,627.167h1.206l0.954,4.584l1.817-4.584h1.212l-2.556,5.784h-1.176L421.311,627.167z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M427.221,635.933c-0.232,0-0.455-0.034-0.669-0.103c-0.215-0.068-0.43-0.168-0.646-0.3l0.103-1.05
			c0.176,0.147,0.352,0.256,0.527,0.323c0.176,0.068,0.358,0.103,0.546,0.103c0.192,0,0.363-0.064,0.514-0.192
			c0.149-0.128,0.282-0.296,0.398-0.504s0.218-0.436,0.307-0.684l0.216-0.576l-1.566-5.784h1.194l1.002,4.386l1.578-4.386h1.073
			l-2.628,6.882c-0.159,0.424-0.338,0.775-0.533,1.054c-0.196,0.277-0.41,0.485-0.643,0.624
			C427.763,635.864,427.505,635.933,427.221,635.933z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M434.613,633.875l3.666-9.773h1.08l-3.679,9.773H434.613z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M442.688,632.951l0.721-7.662h2.688c0.517,0,0.938,0.091,1.264,0.273c0.325,0.182,0.558,0.426,0.695,0.731
			c0.139,0.307,0.189,0.644,0.153,1.011c-0.04,0.433-0.185,0.808-0.432,1.125c-0.248,0.318-0.585,0.537-1.009,0.657
			c0.265,0.057,0.493,0.166,0.688,0.33c0.193,0.164,0.339,0.368,0.435,0.612c0.097,0.244,0.13,0.514,0.103,0.81
			c-0.021,0.252-0.081,0.503-0.184,0.753c-0.102,0.25-0.254,0.478-0.456,0.682c-0.201,0.204-0.465,0.368-0.789,0.492
			c-0.323,0.124-0.72,0.186-1.188,0.186H442.688z M443.835,631.924h1.614c0.46,0,0.802-0.104,1.025-0.314
			c0.225-0.21,0.353-0.487,0.385-0.831c0.039-0.344-0.042-0.62-0.243-0.828c-0.202-0.208-0.52-0.312-0.951-0.312h-1.614
			L443.835,631.924z M444.363,626.296l-0.217,2.322h1.591c0.396,0,0.715-0.104,0.957-0.312c0.241-0.208,0.379-0.496,0.41-0.864
			c0.032-0.353-0.05-0.631-0.245-0.837c-0.196-0.206-0.498-0.31-0.906-0.31H444.363z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M449.535,632.951l0.546-5.796h1.056l-0.107,1.122c0.188-0.416,0.411-0.724,0.669-0.921
			c0.258-0.198,0.543-0.297,0.854-0.297c0.08,0,0.164,0.007,0.252,0.021s0.175,0.039,0.259,0.075l-0.139,1.164
			c-0.104-0.028-0.21-0.051-0.317-0.069c-0.108-0.018-0.215-0.026-0.318-0.026c-0.288,0-0.534,0.086-0.738,0.258
			s-0.365,0.418-0.482,0.737c-0.118,0.32-0.197,0.706-0.237,1.158l-0.24,2.574H449.535z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M455.697,633.101c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.226-0.99-0.165-1.61c0.061-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C456.425,633.025,456.077,633.101,455.697,633.101z M454.653,629.765l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C454.778,629.124,454.693,629.428,454.653,629.765z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M461.03,633.101c-0.42,0-0.767-0.087-1.04-0.259c-0.274-0.172-0.473-0.395-0.595-0.669
			c-0.122-0.273-0.167-0.566-0.135-0.879c0.036-0.38,0.153-0.694,0.351-0.944c0.198-0.25,0.46-0.438,0.786-0.564
			s0.693-0.188,1.102-0.188c0.252,0,0.5,0.024,0.744,0.074c0.243,0.051,0.476,0.117,0.695,0.201l0.066-0.66
			c0.052-0.464-0.001-0.802-0.159-1.014s-0.423-0.318-0.795-0.318c-0.216,0-0.41,0.035-0.582,0.105s-0.312,0.175-0.42,0.314
			c-0.108,0.141-0.172,0.314-0.192,0.522h-1.164c0.044-0.44,0.179-0.792,0.402-1.056c0.224-0.265,0.508-0.455,0.852-0.573
			c0.345-0.118,0.713-0.177,1.104-0.177c0.708,0,1.237,0.18,1.587,0.54c0.35,0.359,0.489,0.924,0.417,1.691l-0.348,3.702h-0.912
			c-0.004-0.147-0.005-0.295-0.003-0.441c0.002-0.146,0.001-0.293-0.003-0.44c-0.2,0.344-0.446,0.602-0.738,0.773
			S461.419,633.101,461.03,633.101z M461.289,632.201c0.271,0,0.524-0.066,0.759-0.198s0.425-0.316,0.573-0.555
			c0.147-0.238,0.229-0.515,0.246-0.831c-0.196-0.063-0.406-0.116-0.631-0.156c-0.224-0.04-0.434-0.06-0.63-0.06
			c-0.323,0-0.602,0.066-0.834,0.201c-0.231,0.134-0.363,0.364-0.396,0.692c-0.021,0.256,0.049,0.472,0.207,0.646
			S460.976,632.201,461.289,632.201z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M469.274,632.951l-1.656-2.946l-0.689,0.582l-0.222,2.364h-1.057l0.769-8.202h1.062l-0.432,4.596
			l2.496-2.178h1.452l-2.557,2.154l2.13,3.63H469.274z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M475.659,632.951l0.624-6.654h-2.189l0.102-1.008h5.442l-0.103,1.008h-2.196l-0.624,6.654H475.659z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M481.071,633.101c-0.452,0-0.849-0.124-1.188-0.372s-0.595-0.6-0.765-1.054
			c-0.17-0.453-0.225-0.99-0.165-1.61c0.06-0.632,0.217-1.176,0.471-1.632s0.573-0.807,0.957-1.051
			c0.384-0.243,0.802-0.365,1.254-0.365c0.356,0,0.661,0.069,0.915,0.21c0.254,0.14,0.459,0.331,0.615,0.572
			c0.156,0.242,0.268,0.519,0.333,0.828c0.066,0.311,0.085,0.638,0.057,0.981c-0.008,0.112-0.021,0.229-0.038,0.351
			c-0.019,0.122-0.042,0.247-0.069,0.375h-3.744l0.294-0.21c-0.048,0.38-0.041,0.725,0.021,1.032
			c0.062,0.308,0.187,0.551,0.375,0.729c0.188,0.179,0.445,0.268,0.773,0.268c0.28,0,0.524-0.077,0.732-0.231
			c0.208-0.153,0.348-0.381,0.42-0.681h1.08c-0.093,0.38-0.249,0.709-0.472,0.987c-0.222,0.277-0.491,0.493-0.81,0.645
			C481.799,633.025,481.451,633.101,481.071,633.101z M480.027,629.765l-0.234-0.168h3.048l-0.294,0.144
			c0.044-0.304,0.047-0.592,0.009-0.864c-0.037-0.271-0.134-0.494-0.287-0.666c-0.154-0.172-0.39-0.258-0.705-0.258
			c-0.292,0-0.546,0.081-0.763,0.243c-0.216,0.162-0.389,0.38-0.519,0.654C480.152,629.124,480.067,629.428,480.027,629.765z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M486.742,633.101c-0.433,0-0.822-0.124-1.171-0.372c-0.348-0.248-0.611-0.601-0.792-1.057
			c-0.18-0.456-0.241-0.996-0.186-1.62c0.06-0.647,0.223-1.196,0.489-1.646c0.266-0.45,0.595-0.794,0.986-1.032
			c0.393-0.237,0.807-0.356,1.242-0.356c0.309,0,0.579,0.055,0.813,0.165c0.233,0.109,0.432,0.258,0.594,0.443
			c0.162,0.187,0.286,0.397,0.372,0.633c0.086,0.236,0.137,0.479,0.153,0.727l-1.068,0.065c-0.052-0.292-0.16-0.528-0.324-0.711
			c-0.164-0.182-0.382-0.272-0.653-0.272c-0.236,0-0.464,0.073-0.682,0.219c-0.218,0.146-0.403,0.366-0.555,0.66
			c-0.152,0.294-0.25,0.663-0.294,1.106c-0.036,0.421-0.012,0.779,0.075,1.077c0.086,0.298,0.224,0.525,0.414,0.682
			c0.189,0.155,0.417,0.233,0.681,0.233c0.34,0,0.609-0.097,0.81-0.291c0.2-0.193,0.316-0.463,0.349-0.807h1.067
			c-0.032,0.404-0.148,0.769-0.351,1.095s-0.472,0.584-0.811,0.774C487.564,633.005,487.177,633.101,486.742,633.101z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M490.49,632.951l0.769-8.202h1.062l-0.33,3.42c0.212-0.372,0.469-0.657,0.771-0.854
			c0.302-0.198,0.639-0.297,1.011-0.297c0.42,0,0.766,0.104,1.038,0.312c0.271,0.208,0.466,0.502,0.582,0.882
			s0.149,0.83,0.102,1.351l-0.317,3.39h-1.051l0.312-3.39c0.052-0.492-0.018-0.864-0.207-1.116c-0.19-0.252-0.457-0.378-0.801-0.378
			c-0.284,0-0.538,0.08-0.763,0.24c-0.224,0.159-0.408,0.395-0.555,0.704c-0.146,0.311-0.247,0.689-0.303,1.138l-0.264,2.802H490.49
			z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M497.115,632.951l0.546-5.784h0.912l0.012,1.062c0.212-0.4,0.473-0.702,0.78-0.906
			s0.652-0.306,1.032-0.306c0.42,0,0.766,0.103,1.038,0.309c0.271,0.206,0.466,0.5,0.582,0.882c0.115,0.382,0.147,0.833,0.096,1.354
			l-0.312,3.39h-1.057l0.312-3.39c0.056-0.496-0.014-0.869-0.207-1.119c-0.194-0.25-0.459-0.375-0.795-0.375
			c-0.44,0-0.807,0.191-1.099,0.576c-0.292,0.384-0.472,0.934-0.54,1.649l-0.246,2.658H497.115z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M505.676,633.101c-0.473,0-0.887-0.114-1.242-0.343c-0.356-0.228-0.62-0.566-0.792-1.017
			s-0.227-1.009-0.162-1.677c0.063-0.676,0.226-1.24,0.486-1.692c0.26-0.452,0.589-0.791,0.986-1.017
			c0.398-0.227,0.831-0.339,1.3-0.339c0.468,0,0.878,0.114,1.229,0.345c0.352,0.229,0.616,0.57,0.792,1.02
			c0.176,0.45,0.232,1.012,0.168,1.684c-0.068,0.668-0.232,1.227-0.492,1.677s-0.586,0.789-0.978,1.017
			C506.58,632.987,506.148,633.101,505.676,633.101z M505.73,632.081c0.444,0,0.812-0.175,1.104-0.524
			c0.291-0.351,0.472-0.848,0.539-1.491c0.064-0.652-0.009-1.154-0.219-1.506c-0.21-0.353-0.535-0.528-0.975-0.528
			c-0.44,0-0.804,0.175-1.092,0.525c-0.288,0.35-0.465,0.853-0.528,1.509c-0.044,0.424-0.027,0.787,0.051,1.089
			s0.211,0.532,0.399,0.689C505.198,632.002,505.438,632.081,505.73,632.081z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M424.441,653.111c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C425.511,652.984,425.008,653.111,424.441,653.111z M424.441,651.656c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S424.038,651.656,424.441,651.656z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M428.356,652.951v-1.3c0-0.363,0.05-0.678,0.15-0.942c0.1-0.266,0.262-0.493,0.484-0.683
			c0.224-0.19,0.519-0.354,0.886-0.49l1.425-0.53c0.257-0.1,0.443-0.218,0.56-0.354c0.117-0.137,0.176-0.302,0.176-0.495
			c0-0.177-0.07-0.334-0.211-0.473c-0.14-0.139-0.347-0.207-0.619-0.207c-0.254,0-0.472,0.076-0.655,0.229s-0.288,0.372-0.315,0.655
			h-1.77c-0.004-0.526,0.119-0.958,0.367-1.293s0.579-0.581,0.992-0.739c0.414-0.159,0.863-0.238,1.351-0.238
			c0.474,0,0.904,0.087,1.292,0.261c0.389,0.173,0.698,0.417,0.931,0.732c0.231,0.314,0.348,0.688,0.348,1.122
			c0,0.344-0.062,0.648-0.186,0.915s-0.321,0.504-0.595,0.713c-0.273,0.208-0.637,0.397-1.09,0.567l-1.415,0.535
			c-0.193,0.073-0.326,0.144-0.397,0.212c-0.072,0.068-0.107,0.158-0.107,0.268v0.075h3.77v1.46H428.356z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M434.291,652.021c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			s0.31,0.443,0.31,0.74s-0.103,0.541-0.31,0.732s-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S434.291,652.314,434.291,652.021z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M439.951,653.111c-0.373,0-0.734-0.05-1.085-0.147c-0.35-0.099-0.664-0.245-0.942-0.44
			c-0.278-0.194-0.499-0.438-0.662-0.728s-0.245-0.626-0.245-1.01h1.705c0.02,0.163,0.08,0.311,0.18,0.442
			c0.101,0.132,0.237,0.236,0.413,0.312c0.175,0.077,0.387,0.115,0.637,0.115c0.341,0,0.612-0.073,0.815-0.22
			c0.203-0.147,0.305-0.334,0.305-0.561c0-0.156-0.04-0.285-0.12-0.385c-0.079-0.1-0.195-0.174-0.347-0.223
			c-0.152-0.048-0.338-0.072-0.558-0.072h-1.021v-1.255h0.995c0.267,0,0.476-0.066,0.625-0.2c0.15-0.134,0.226-0.309,0.226-0.525
			c0-0.196-0.072-0.358-0.216-0.487c-0.143-0.128-0.378-0.192-0.705-0.192c-0.283,0-0.536,0.059-0.76,0.175
			c-0.224,0.117-0.354,0.304-0.395,0.561h-1.7c0.04-0.5,0.182-0.911,0.425-1.232c0.243-0.322,0.566-0.561,0.97-0.716
			c0.403-0.154,0.863-0.232,1.381-0.232c0.526,0,0.994,0.08,1.402,0.24s0.729,0.384,0.965,0.67c0.234,0.287,0.353,0.622,0.353,1.005
			c0,0.33-0.086,0.622-0.258,0.875c-0.172,0.254-0.431,0.438-0.777,0.556c0.387,0.153,0.69,0.364,0.912,0.632
			c0.222,0.269,0.333,0.591,0.333,0.968c0,0.407-0.121,0.767-0.362,1.08c-0.242,0.313-0.577,0.558-1.005,0.732
			C441.005,653.023,440.511,653.111,439.951,653.111z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M446.581,653.111c-0.573,0-1.082-0.127-1.527-0.38c-0.444-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.377-1.174-0.377-1.967c0-0.804,0.125-1.465,0.377-1.982c0.252-0.519,0.601-0.903,1.045-1.155
			c0.445-0.252,0.954-0.378,1.527-0.378c0.567,0,1.07,0.126,1.511,0.378c0.439,0.252,0.786,0.637,1.04,1.155
			c0.253,0.518,0.38,1.179,0.38,1.982c0,0.793-0.127,1.449-0.38,1.967c-0.254,0.519-0.601,0.904-1.04,1.158
			C447.651,652.984,447.148,653.111,446.581,653.111z M446.581,651.656c0.387,0,0.697-0.173,0.931-0.518s0.35-0.855,0.35-1.532
			s-0.116-1.191-0.35-1.543s-0.544-0.527-0.931-0.527c-0.403,0-0.721,0.176-0.952,0.527s-0.348,0.866-0.348,1.543
			s0.116,1.188,0.348,1.532S446.177,651.656,446.581,651.656z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M450.486,650.526v-1.4h4.695v1.4H450.486z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M459.116,653.111c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C460.186,652.984,459.682,653.111,459.116,653.111z
			 M459.116,651.656c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C458.395,651.483,458.713,651.656,459.116,651.656z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M466.002,646.191h1.569v4.035h1.17v1.38h-1.17v1.345h-1.59v-1.345h-3.33v-1.195L466.002,646.191z
			 M466.071,648.486l-1.529,1.925h1.529V648.486z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M468.981,652.021c0-0.297,0.1-0.544,0.298-0.74s0.456-0.295,0.772-0.295s0.578,0.099,0.785,0.295
			c0.206,0.196,0.31,0.443,0.31,0.74s-0.104,0.541-0.31,0.732c-0.207,0.191-0.469,0.287-0.785,0.287s-0.574-0.097-0.772-0.29
			S468.981,652.314,468.981,652.021z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M474.721,653.111c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C475.792,652.984,475.288,653.111,474.721,653.111z
			 M474.721,651.656c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C474.001,651.483,474.318,651.656,474.721,651.656z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M481.457,653.111c-0.573,0-1.082-0.127-1.527-0.38c-0.445-0.254-0.793-0.64-1.045-1.158
			c-0.252-0.518-0.378-1.174-0.378-1.967c0-0.804,0.126-1.465,0.378-1.982c0.252-0.519,0.6-0.903,1.045-1.155
			s0.954-0.378,1.527-0.378c0.566,0,1.07,0.126,1.51,0.378c0.44,0.252,0.787,0.637,1.04,1.155c0.254,0.518,0.38,1.179,0.38,1.982
			c0,0.793-0.126,1.449-0.38,1.967c-0.253,0.519-0.6,0.904-1.04,1.158C482.527,652.984,482.023,653.111,481.457,653.111z
			 M481.457,651.656c0.387,0,0.696-0.173,0.93-0.518s0.351-0.855,0.351-1.532s-0.117-1.191-0.351-1.543s-0.543-0.527-0.93-0.527
			c-0.403,0-0.721,0.176-0.952,0.527c-0.232,0.352-0.348,0.866-0.348,1.543s0.115,1.188,0.348,1.532
			C480.736,651.483,481.053,651.656,481.457,651.656z"
                  />
                </g>
                <g>
                  <path
                    fill="#00E9B6"
                    d="M142.188,18h-8c-2.761,0-5-2.239-5-5V5c0-2.761,2.239-5,5-5h8c2.761,0,5,2.239,5,5v8
			C147.188,15.761,144.95,18,142.188,18z"
                  />
                  <g>
                    <path
                      fill="#FFFFFF"
                      d="M159.413,2.646h1.917l2.89,9.954h-0.468l2.915-8.946h1.161l2.935,8.847h-0.433l3.195-9.855h1.926
				l-4.005,11.466h-1.8l-2.475-7.344h0.242l-2.528,7.344h-1.791L159.413,2.646z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M180.059,14.346c-0.786,0-1.489-0.171-2.11-0.513s-1.111-0.852-1.472-1.53
				c-0.359-0.678-0.54-1.515-0.54-2.511c0-1.014,0.181-1.859,0.54-2.534c0.36-0.675,0.851-1.184,1.472-1.525
				c0.621-0.342,1.324-0.513,2.11-0.513c0.774,0,1.471,0.171,2.088,0.513c0.618,0.342,1.105,0.851,1.463,1.525
				c0.356,0.675,0.535,1.52,0.535,2.534c0,0.996-0.179,1.833-0.535,2.511c-0.357,0.678-0.845,1.188-1.463,1.53
				C181.53,14.175,180.834,14.346,180.059,14.346z M180.005,12.852c0.498,0,0.929-0.12,1.292-0.36
				c0.362-0.24,0.642-0.589,0.837-1.048c0.194-0.459,0.292-1.009,0.292-1.651c0-0.984-0.199-1.746-0.599-2.286
				c-0.398-0.54-0.974-0.81-1.724-0.81c-0.504,0-0.938,0.122-1.3,0.365c-0.363,0.243-0.643,0.594-0.837,1.053
				c-0.195,0.459-0.293,1.019-0.293,1.679c0,0.966,0.201,1.717,0.604,2.254C178.679,12.584,179.255,12.852,180.005,12.852z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M186.792,14.112V5.427h1.71v8.685H186.792z M188.034,10.242c0-1.11,0.115-2.032,0.347-2.768
				c0.23-0.735,0.555-1.282,0.972-1.642c0.417-0.36,0.907-0.54,1.472-0.54c0.168,0,0.325,0.012,0.473,0.036
				c0.146,0.024,0.292,0.057,0.437,0.099l-0.063,1.701c-0.162-0.042-0.337-0.08-0.526-0.113c-0.188-0.033-0.361-0.049-0.518-0.049
				c-0.492,0-0.894,0.13-1.206,0.392c-0.312,0.261-0.543,0.636-0.692,1.125c-0.15,0.489-0.226,1.075-0.226,1.759H188.034z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M193.533,14.112V1.809h1.71v12.303H193.533z M195,8.973l4.113-3.519h2.349L195,10.764V8.973z
				 M199.581,14.112l-4.014-5.49l1.674-0.351l4.428,5.841H199.581z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M207.059,14.346c-0.84,0-1.548-0.119-2.124-0.356s-1.016-0.567-1.318-0.99
				c-0.303-0.423-0.461-0.919-0.473-1.49h1.611c0.023,0.498,0.224,0.867,0.599,1.107c0.375,0.24,0.883,0.36,1.525,0.36
				c0.342,0,0.652-0.054,0.932-0.162c0.278-0.108,0.501-0.255,0.666-0.441s0.247-0.396,0.247-0.63c0-0.294-0.107-0.526-0.324-0.697
				c-0.216-0.171-0.563-0.322-1.044-0.455l-1.772-0.603c-0.708-0.24-1.251-0.56-1.629-0.958s-0.567-0.877-0.567-1.436
				c0-0.714,0.318-1.288,0.954-1.723s1.479-0.653,2.529-0.653c1.086,0,1.924,0.229,2.515,0.688c0.592,0.459,0.899,1.034,0.923,1.724
				h-1.646c-0.042-0.342-0.212-0.604-0.509-0.788s-0.725-0.274-1.282-0.274c-0.522,0-0.932,0.114-1.229,0.342
				c-0.297,0.229-0.446,0.486-0.446,0.774c0,0.516,0.444,0.9,1.332,1.152l1.863,0.657c0.708,0.229,1.254,0.522,1.638,0.882
				c0.385,0.36,0.576,0.816,0.576,1.368c0,0.444-0.146,0.864-0.44,1.26s-0.707,0.719-1.238,0.967
				C208.396,14.221,207.773,14.346,207.059,14.346z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M213.368,14.112V1.809h1.71v12.303H213.368z M219.434,14.112V9.036c0-0.75-0.179-1.318-0.536-1.706
				c-0.356-0.387-0.838-0.58-1.444-0.58c-0.479,0-0.898,0.13-1.255,0.391c-0.357,0.261-0.634,0.642-0.828,1.143
				c-0.195,0.501-0.293,1.112-0.293,1.832h-0.612c0-1.062,0.137-1.956,0.41-2.682c0.272-0.726,0.664-1.276,1.174-1.651
				c0.511-0.375,1.122-0.562,1.837-0.562c0.695,0,1.285,0.156,1.768,0.468c0.483,0.312,0.851,0.754,1.103,1.327
				c0.253,0.573,0.379,1.247,0.379,2.021v5.076H219.434z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M227.641,14.346c-0.786,0-1.489-0.171-2.11-0.513s-1.111-0.852-1.472-1.53
				c-0.359-0.678-0.54-1.515-0.54-2.511c0-1.014,0.181-1.859,0.54-2.534c0.36-0.675,0.851-1.184,1.472-1.525
				c0.621-0.342,1.324-0.513,2.11-0.513c0.774,0,1.471,0.171,2.088,0.513c0.618,0.342,1.105,0.851,1.463,1.525
				c0.356,0.675,0.535,1.52,0.535,2.534c0,0.996-0.179,1.833-0.535,2.511c-0.357,0.678-0.845,1.188-1.463,1.53
				C229.112,14.175,228.416,14.346,227.641,14.346z M227.588,12.852c0.498,0,0.929-0.12,1.292-0.36
				c0.362-0.24,0.642-0.589,0.837-1.048c0.194-0.459,0.292-1.009,0.292-1.651c0-0.984-0.199-1.746-0.599-2.286
				c-0.398-0.54-0.974-0.81-1.724-0.81c-0.504,0-0.938,0.122-1.3,0.365c-0.363,0.243-0.643,0.594-0.837,1.053
				c-0.195,0.459-0.293,1.019-0.293,1.679c0,0.966,0.201,1.717,0.604,2.254C226.261,12.584,226.838,12.852,227.588,12.852z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M234.374,18.36V5.454h1.413l0.297,2.079V18.36H234.374z M238.478,14.346
				c-0.631,0-1.177-0.143-1.639-0.428s-0.814-0.705-1.058-1.26c-0.242-0.555-0.364-1.243-0.364-2.065h0.666
				c0,0.474,0.103,0.875,0.307,1.202c0.203,0.327,0.472,0.576,0.805,0.747c0.333,0.171,0.688,0.256,1.066,0.256
				c0.396,0,0.782-0.112,1.157-0.337s0.683-0.562,0.922-1.013c0.24-0.45,0.36-1.008,0.36-1.674c0-0.636-0.106-1.179-0.319-1.629
				c-0.213-0.45-0.503-0.795-0.869-1.035c-0.365-0.24-0.776-0.36-1.232-0.36c-0.384,0-0.744,0.091-1.08,0.274
				s-0.606,0.45-0.81,0.801c-0.204,0.351-0.307,0.779-0.307,1.283h-0.666c0-0.87,0.14-1.593,0.419-2.169
				c0.278-0.576,0.655-1.007,1.129-1.292c0.475-0.285,0.999-0.428,1.575-0.428c0.678,0,1.314,0.182,1.908,0.544
				c0.594,0.363,1.075,0.884,1.444,1.562c0.369,0.678,0.554,1.494,0.554,2.448s-0.187,1.773-0.559,2.457s-0.859,1.208-1.462,1.57
				C239.823,14.165,239.173,14.346,238.478,14.346z"
                    />
                  </g>
                </g>
                <g>
                  <rect
                    x="209.857"
                    y="165.033"
                    fill="none"
                    width="2.105"
                    height="0"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M23.598,940.682h2.124v3.492h3.51v1.854h-3.51v3.492h-2.124v-3.492h-3.51v-1.854h3.51V940.682z"
                  />
                </g>
              </g>
              <g id="guides" display="none"></g>
              <g id="cmyk_x5F_Lines" display="none"></g>
              <g id="cmyk_x5F_Grafik" display="none"></g>
              <g id="cmyk_x5F_Text" display="none"></g>
            </svg>
          </div>
          <section className="event-location">
            <h2>Veranstaltungsort</h2>
            <p>
              Das ganze Spektakel findet im Innen- und Außenbereich der{" "}
              <div className="next-links-wrapper">
                <a href="https://goo.gl/maps/U25vbbJ8G31zEXm39">
                  Sputnikhalle
                  <i className="icon-link-extern" />
                </a>
              </div>{" "}
              am Hawerkamp in Münster statt. Im angesagten Zwiebellook bist du
              bestens für alle Programmpunkte vorbereitet, genügend Standplätze
              fürs Rad gibt’s auch – super.
            </p>
          </section>
          <svg
            className="siteplan"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 792 509.559"
            enable-background="new 0 0 792 509.559"
          >
            <g id="BG" display="none">
              <rect
                display="inline"
                fill="#1E1B39"
                width="792"
                height="509.559"
              />
            </g>
            <g id="Lines">
              <rect
                x="216.973"
                y="253.779"
                transform="matrix(6.123234e-17 -1 1 6.123234e-17 216.9733 726.5322)"
                fill="#FFFFFF"
                width="509.559"
                height="2"
              />
            </g>
            <g id="Grafik">
              <g id="grundriss">
                <rect
                  x="718.271"
                  y="99.905"
                  fill="#E62E80"
                  width="71.773"
                  height="122"
                />

                <rect
                  x="489.544"
                  y="190.072"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  width="68.649"
                  height="31.825"
                />
                <rect
                  x="489.544"
                  y="43.532"
                  opacity="0.1"
                  fill="#FFFFFF"
                  width="95.5"
                  height="56.873"
                />
                <rect
                  x="756.044"
                  y="99.905"
                  opacity="0.2"
                  fill="#FFFFFF"
                  width="34"
                  height="34"
                />
                <polygon
                  opacity="0.1"
                  fill="#FFFFFF"
                  points="690.976,160.901 690.976,194.602 616.544,194.602 616.544,221.897 690.976,221.897 
			718.271,221.897 718.271,194.602 718.271,160.901 		"
                />
                <polygon
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-linejoin="bevel"
                  stroke-miterlimit="10"
                  points="558.193,190.072 
			489.544,221.897 489.544,190.072 558.193,221.897 		"
                />
                <rect
                  x="585.044"
                  y="43.169"
                  opacity="0.3"
                  fill="#FFFFFF"
                  width="133.227"
                  height="40.486"
                />
                <g>
                  <rect
                    x="85.007"
                    y="386.738"
                    fill="#E62E80"
                    width="181.884"
                    height="52.167"
                  />
                  <rect
                    x="357.364"
                    y="77.463"
                    fill="#E62E80"
                    width="96.639"
                    height="155.61"
                  />
                  <path
                    fill="#E62E80"
                    d="M408.003,507.492L408.003,507.492c-25.406,0-46-20.595-46-46v-22.587h92v22.587
				C454.003,486.897,433.409,507.492,408.003,507.492z"
                  />

                  <rect
                    x="420.54"
                    y="43.169"
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    width="33.464"
                    height="33.464"
                  />
                  <rect
                    x="357.364"
                    y="233.073"
                    opacity="0.1"
                    fill="#FFFFFF"
                    width="96.639"
                    height="69.665"
                  />
                  <rect
                    x="266.891"
                    y="386.738"
                    opacity="0.1"
                    fill="#FFFFFF"
                    width="97.115"
                    height="52.167"
                  />
                  <rect
                    x="54.058"
                    y="142.341"
                    opacity="0.1"
                    fill="#FFFFFF"
                    width="63.948"
                    height="41.761"
                  />

                  <rect
                    x="54.058"
                    y="279.469"
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    width="33.464"
                    height="23.784"
                  />
                  <path
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    d="M113.106,302.738L113.106,302.738
				c0-16.292,13.208-29.5,29.5-29.5h0c16.292,0,29.5,13.208,29.5,29.5v0"
                  />
                  <g>
                    <polyline
                      fill="none"
                      stroke="#FFFFFF"
                      stroke-width="4"
                      stroke-miterlimit="10"
                      points="54.058,236.841 2.003,236.841 
					2.003,302.738 54.058,302.738 				"
                    />

                    <line
                      fill="none"
                      stroke="#FFFFFF"
                      stroke-width="4"
                      stroke-miterlimit="10"
                      x1="240.391"
                      y1="302.738"
                      x2="250.891"
                      y2="302.738"
                    />
                    <polyline
                      fill="none"
                      stroke="#FFFFFF"
                      stroke-width="4"
                      stroke-miterlimit="10"
                      points="54.058,263.803 54.058,302.738 
					216.391,302.738 224.391,302.738 				"
                    />
                    <polyline
                      fill="none"
                      stroke="#FFFFFF"
                      stroke-width="4"
                      stroke-miterlimit="10"
                      points="266.891,302.738 288.84,302.738 
					454.004,302.738 454.004,43.169 288.84,43.169 288.84,205.841 264.84,205.841 216.391,205.841 216.391,166.905 216.391,142.341 
					166.127,142.341 166.127,166.905 118.007,166.905 118.007,142.341 54.058,142.341 54.058,166.905 54.058,247.803 				"
                    />
                  </g>
                  <g>
                    <g>
                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        x1="85.007"
                        y1="386.738"
                        x2="85.007"
                        y2="394.738"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        stroke-dasharray="18.0833,9.0416"
                        x1="85.007"
                        y1="403.78"
                        x2="85.007"
                        y2="426.384"
                      />
                      <polyline
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        points="85.007,430.904 85.007,438.904 
						93.007,438.904 					"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        stroke-dasharray="15.3527,7.6763"
                        x1="100.684"
                        y1="438.904"
                        x2="350.165"
                        y2="438.904"
                      />
                      <polyline
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        points="354.003,438.904 362.003,438.904 
						362.003,446.904 					"
                      />
                      <path
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        stroke-dasharray="15.4932,7.7466"
                        d="
						M362.003,454.651v6.864c0,25.405,20.595,46,46,46s46-20.595,46-46v-22.611V314.611"
                      />
                      <polyline
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        points="454.004,310.738 454.004,302.738 
						446.004,302.738 					"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        stroke-dasharray="15.5556,7.7778"
                        x1="438.226"
                        y1="302.738"
                        x2="278.78"
                        y2="302.738"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        x1="274.891"
                        y1="302.738"
                        x2="266.891"
                        y2="302.738"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        x1="2.004"
                        y1="327.302"
                        x2="10.004"
                        y2="327.302"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        stroke-dasharray="13.4007,6.7003"
                        x1="16.704"
                        y1="327.302"
                        x2="73.657"
                        y2="327.302"
                      />
                      <polyline
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        points="77.007,327.302 85.007,327.302 
						85.007,335.302 					"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        x1="85.007"
                        y1="346.738"
                        x2="85.007"
                        y2="354.738"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        x1="224.392"
                        y1="302.738"
                        x2="216.392"
                        y2="302.738"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        stroke-dasharray="16.511,8.2555"
                        x1="208.136"
                        y1="302.738"
                        x2="14.132"
                        y2="302.738"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="4"
                        stroke-miterlimit="10"
                        x1="10.004"
                        y1="302.738"
                        x2="2.004"
                        y2="302.738"
                      />
                    </g>
                  </g>
                  <g>
                    <line
                      fill="none"
                      stroke="#FFFFFF"
                      stroke-width="4"
                      stroke-miterlimit="10"
                      stroke-dasharray="10.4999"
                      x1="250.891"
                      y1="302.738"
                      x2="240.392"
                      y2="302.738"
                    />
                  </g>
                  <polygon
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="2"
                    stroke-linejoin="bevel"
                    stroke-miterlimit="10"
                    points="87.522,279.469 
				54.058,303.253 54.058,279.469 87.522,303.253 			"
                  />
                  <polygon
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="2"
                    stroke-linejoin="bevel"
                    stroke-miterlimit="10"
                    points="454.003,43.532 
				420.54,76.633 420.54,43.532 454.003,76.633 			"
                  />

                  <line
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="2"
                    stroke-linejoin="bevel"
                    stroke-miterlimit="10"
                    x1="124.472"
                    y1="279.469"
                    x2="172.106"
                    y2="302.738"
                  />

                  <line
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="2"
                    stroke-linejoin="bevel"
                    stroke-miterlimit="10"
                    x1="160.739"
                    y1="279.469"
                    x2="113.106"
                    y2="302.738"
                  />

                  <rect
                    x="241.344"
                    y="183.924"
                    transform="matrix(6.123234e-17 -1 1 6.123234e-17 104.9163 513.2491)"
                    opacity="0.3"
                    fill="#FFFFFF"
                    width="135.477"
                    height="40.486"
                  />

                  <line
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    x1="288.84"
                    y1="277.655"
                    x2="288.84"
                    y2="205.841"
                  />

                  <line
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    x1="288.84"
                    y1="302.738"
                    x2="288.84"
                    y2="293.655"
                  />

                  <line
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    x1="54.058"
                    y1="268.228"
                    x2="54.058"
                    y2="301.176"
                  />
                  <polyline
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    points="264.84,249.073 264.84,254.29 
				216.391,254.29 			"
                  />

                  <line
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    x1="264.84"
                    y1="227.073"
                    x2="264.84"
                    y2="233.073"
                  />

                  <line
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    x1="264.84"
                    y1="205.841"
                    x2="264.84"
                    y2="211.073"
                  />

                  <line
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    x1="216.391"
                    y1="277.655"
                    x2="216.391"
                    y2="205.841"
                  />

                  <line
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    x1="216.391"
                    y1="302.738"
                    x2="216.391"
                    y2="293.655"
                  />
                  <path
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    d="M203.674,266.271h12.718v-47.198v-76.732h-50.265
				v76.732v19.435c0,13.195,9.208,24.232,21.547,27.056"
                  />
                </g>

                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="4"
                  stroke-miterlimit="10"
                  x1="718.271"
                  y1="119.537"
                  x2="718.271"
                  y2="99.905"
                />

                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="4"
                  stroke-miterlimit="10"
                  x1="718.271"
                  y1="151.537"
                  x2="718.271"
                  y2="221.905"
                />
                <polyline
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="4"
                  stroke-miterlimit="10"
                  points="489.544,251.319 489.544,259.905 
			508.044,259.905 		"
                />
                <polyline
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="4"
                  stroke-miterlimit="10"
                  points="562.044,259.905 600.511,259.905 
			600.511,221.905 718.271,221.905 718.271,221.897 790.044,221.897 790.044,99.905 718.271,99.905 718.271,43.169 489.544,43.169 
			489.544,221.905 489.544,231.328 		"
                />

                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="4"
                  stroke-miterlimit="10"
                  x1="524.044"
                  y1="259.905"
                  x2="546.044"
                  y2="259.905"
                />
                <polyline
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="4"
                  stroke-miterlimit="10"
                  points="508.044,259.905 489.544,259.905 
			489.544,283.905 600.511,283.905 600.511,259.905 562.044,259.905 		"
                />

                <line
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-width="4"
                  stroke-miterlimit="10"
                  x1="546.044"
                  y1="259.905"
                  x2="524.044"
                  y2="259.905"
                />
              </g>
            </g>
            <g id="Text">
              <g>
                <path
                  fill="#FFFFFF"
                  d="M201.61,367.37v-11.682h7.362v1.836h-5.238v3.078h4.788v1.818h-4.788v4.95H201.61z"
                />
                <path
                  fill="#FFFFFF"
                  d="M211.447,367.37v-8.766h2.124v8.766H211.447z M213.049,363.536c0-1.146,0.129-2.094,0.387-2.844
			c0.258-0.75,0.604-1.306,1.04-1.669c0.435-0.363,0.919-0.544,1.453-0.544c0.144,0,0.28,0.009,0.409,0.027
			c0.129,0.018,0.257,0.045,0.383,0.081l-0.036,2.124c-0.156-0.036-0.324-0.066-0.504-0.09s-0.342-0.036-0.486-0.036
			c-0.468,0-0.859,0.113-1.174,0.337c-0.315,0.225-0.553,0.557-0.711,0.995c-0.159,0.438-0.239,0.978-0.239,1.62H213.049z"
                />
                <path
                  fill="#FFFFFF"
                  d="M219.754,357.038c-0.414,0-0.737-0.109-0.968-0.329c-0.231-0.219-0.347-0.523-0.347-0.914
			c0-0.378,0.115-0.679,0.347-0.904c0.23-0.225,0.554-0.337,0.968-0.337c0.407,0,0.729,0.112,0.963,0.337
			c0.233,0.225,0.351,0.526,0.351,0.904c0,0.39-0.117,0.695-0.351,0.914C220.483,356.928,220.161,357.038,219.754,357.038z
			 M218.692,367.37v-8.766h2.124v8.766H218.692z"
                />
                <path
                  fill="#FFFFFF"
                  d="M227.673,367.622c-0.84,0-1.562-0.115-2.164-0.347c-0.604-0.23-1.066-0.563-1.391-0.999
			s-0.489-0.958-0.495-1.571h1.962c0.018,0.432,0.189,0.747,0.513,0.945c0.324,0.198,0.766,0.297,1.323,0.297
			c0.306,0,0.577-0.045,0.814-0.135c0.237-0.09,0.422-0.213,0.554-0.369c0.132-0.156,0.198-0.33,0.198-0.522
			c0-0.246-0.089-0.439-0.266-0.58c-0.178-0.141-0.461-0.271-0.851-0.392l-1.908-0.684c-0.684-0.246-1.209-0.57-1.575-0.972
			c-0.366-0.402-0.549-0.882-0.549-1.44c0-0.738,0.335-1.333,1.004-1.787c0.669-0.453,1.564-0.679,2.687-0.679
			c1.115,0,1.979,0.245,2.587,0.733c0.609,0.489,0.923,1.108,0.94,1.858h-1.998c-0.029-0.288-0.168-0.516-0.414-0.684
			c-0.246-0.167-0.617-0.252-1.115-0.252c-0.45,0-0.803,0.092-1.058,0.275s-0.383,0.397-0.383,0.643
			c0,0.414,0.354,0.726,1.062,0.936l2.069,0.774c0.672,0.229,1.194,0.522,1.566,0.882s0.558,0.816,0.558,1.368
			c0,0.462-0.154,0.899-0.463,1.31c-0.31,0.411-0.74,0.746-1.292,1.004C229.038,367.493,228.399,367.622,227.673,367.622z"
                />
                <path
                  fill="#FFFFFF"
                  d="M237.222,367.622c-0.731,0-1.411-0.19-2.038-0.571c-0.627-0.381-1.134-0.918-1.521-1.611
			c-0.387-0.693-0.58-1.507-0.58-2.443c0-0.99,0.193-1.829,0.58-2.516c0.388-0.687,0.895-1.207,1.521-1.562
			c0.627-0.354,1.307-0.531,2.038-0.531c0.57,0,1.073,0.091,1.508,0.274s0.806,0.429,1.111,0.738
			c0.307,0.309,0.546,0.653,0.721,1.031c0.174,0.378,0.284,0.759,0.333,1.143l-2.143,0.054c-0.096-0.384-0.274-0.702-0.535-0.954
			c-0.261-0.252-0.599-0.378-1.013-0.378c-0.342,0-0.663,0.095-0.963,0.284s-0.545,0.481-0.733,0.877
			c-0.189,0.396-0.284,0.909-0.284,1.539c0,0.6,0.089,1.1,0.266,1.499s0.416,0.699,0.716,0.9c0.3,0.201,0.633,0.302,0.999,0.302
			c0.54,0,0.933-0.131,1.179-0.392c0.246-0.261,0.369-0.61,0.369-1.048h2.143c0,0.618-0.142,1.182-0.424,1.692
			c-0.281,0.51-0.695,0.917-1.241,1.219C238.683,367.47,238.014,367.622,237.222,367.622z"
                />
                <path
                  fill="#FFFFFF"
                  d="M243.441,367.37v-12.348h2.124v12.348H243.441z M249.435,367.37v-5.112c0-0.648-0.156-1.137-0.468-1.467
			c-0.312-0.33-0.72-0.495-1.224-0.495c-0.427,0-0.803,0.117-1.13,0.351c-0.327,0.234-0.584,0.587-0.77,1.058
			c-0.186,0.471-0.279,1.058-0.279,1.76h-0.701c0-1.134,0.141-2.078,0.423-2.831c0.281-0.753,0.681-1.315,1.196-1.688
			c0.517-0.372,1.122-0.558,1.818-0.558c0.72,0,1.321,0.161,1.805,0.481c0.482,0.321,0.846,0.772,1.089,1.354
			s0.364,1.26,0.364,2.034v5.112H249.435z"
                />
                <path
                  fill="#FFFFFF"
                  d="M254.655,367.37v-12.348h2.124v12.348H254.655z"
                />
                <path
                  fill="#FFFFFF"
                  d="M259.956,358.604h2.124v5.364c0,0.57,0.141,0.998,0.423,1.282c0.281,0.285,0.669,0.428,1.161,0.428
			c0.42,0,0.802-0.109,1.147-0.329c0.345-0.219,0.621-0.543,0.828-0.972c0.206-0.429,0.31-0.961,0.31-1.597h0.576
			c0,1.068-0.138,1.953-0.414,2.655c-0.275,0.702-0.656,1.227-1.143,1.575s-1.047,0.522-1.683,0.522
			c-0.625,0-1.188-0.111-1.692-0.333c-0.504-0.222-0.903-0.579-1.197-1.071c-0.294-0.492-0.44-1.14-0.44-1.944V358.604z
			 M265.949,358.604h2.124v8.766h-2.124V358.604z"
                />
                <path
                  fill="#FFFFFF"
                  d="M270.63,360.044v-1.404h5.04v1.404H270.63z M271.926,367.37v-9.792c0-0.63,0.123-1.143,0.369-1.539
			s0.571-0.688,0.977-0.877s0.845-0.284,1.318-0.284c0.294,0,0.597,0.021,0.909,0.063c0.312,0.042,0.615,0.093,0.909,0.153
			l-0.414,1.638c-0.15-0.054-0.306-0.097-0.468-0.13c-0.162-0.033-0.318-0.049-0.469-0.049c-0.168,0-0.327,0.031-0.477,0.094
			c-0.15,0.063-0.273,0.173-0.369,0.329c-0.096,0.156-0.144,0.369-0.144,0.639v9.756H271.926z"
                />
                <path
                  fill="#FFFFFF"
                  d="M276.939,360.044v-1.404h5.724v1.404H276.939z M280.989,367.622c-0.516,0-0.988-0.096-1.417-0.288
			c-0.43-0.192-0.771-0.486-1.026-0.882s-0.383-0.9-0.383-1.512v-8.622h2.124v8.55c0,0.72,0.342,1.08,1.026,1.08
			c0.21,0,0.42-0.024,0.63-0.072c0.21-0.048,0.45-0.12,0.72-0.216l0.307,1.494c-0.337,0.132-0.663,0.243-0.981,0.333
			S281.336,367.622,280.989,367.622z"
                />
                <path
                  fill="#FFFFFF"
                  d="M284.292,362.87v-1.854h5.058v1.854H284.292z"
                />
                <path
                  fill="#FFFFFF"
                  d="M295.929,367.622c-0.811,0-1.556-0.141-2.236-0.423c-0.682-0.282-1.233-0.711-1.656-1.287
			s-0.649-1.308-0.68-2.196h2.088c0,0.45,0.111,0.819,0.333,1.107s0.521,0.502,0.896,0.644c0.375,0.141,0.788,0.211,1.237,0.211
			c0.433,0,0.817-0.057,1.157-0.171c0.339-0.114,0.605-0.278,0.801-0.491c0.194-0.213,0.292-0.466,0.292-0.76
			c0-0.39-0.118-0.696-0.355-0.918c-0.236-0.222-0.616-0.426-1.138-0.612l-2.305-0.918c-0.899-0.354-1.59-0.765-2.069-1.233
			c-0.48-0.468-0.721-1.113-0.721-1.935c0-0.948,0.375-1.716,1.125-2.304c0.75-0.588,1.797-0.882,3.142-0.882
			c1.308,0,2.318,0.305,3.032,0.914c0.715,0.609,1.119,1.457,1.216,2.542h-2.089c-0.077-0.462-0.287-0.833-0.63-1.111
			c-0.342-0.279-0.852-0.419-1.529-0.419c-0.601,0-1.074,0.125-1.422,0.374c-0.349,0.249-0.522,0.562-0.522,0.941
			c0,0.324,0.109,0.58,0.328,0.769c0.22,0.189,0.56,0.371,1.021,0.545l2.557,0.99c0.863,0.336,1.526,0.759,1.988,1.269
			c0.463,0.51,0.693,1.149,0.693,1.917c0,0.702-0.177,1.31-0.531,1.823c-0.354,0.513-0.87,0.91-1.548,1.192
			S296.901,367.622,295.929,367.622z"
                />
                <path
                  fill="#FFFFFF"
                  d="M306.035,367.622c-0.75,0-1.376-0.131-1.877-0.392s-0.877-0.604-1.129-1.03
			c-0.252-0.426-0.378-0.888-0.378-1.386c0-0.552,0.146-1.015,0.437-1.39s0.703-0.657,1.237-0.846s1.164-0.284,1.89-0.284
			c0.475,0,0.923,0.036,1.346,0.108c0.423,0.072,0.845,0.168,1.265,0.288v1.242c-0.33-0.108-0.69-0.192-1.08-0.252
			s-0.756-0.09-1.098-0.09c-0.504,0-0.918,0.093-1.242,0.279s-0.486,0.501-0.486,0.945c0,0.324,0.132,0.603,0.396,0.837
			c0.264,0.234,0.684,0.351,1.26,0.351c0.438,0,0.81-0.096,1.116-0.288c0.306-0.192,0.538-0.432,0.697-0.72s0.238-0.576,0.238-0.864
			h0.504c0,0.63-0.117,1.211-0.351,1.742c-0.234,0.531-0.581,0.956-1.04,1.273S306.713,367.622,306.035,367.622z M308.898,367.37
			l-0.271-2.124v-3.51c0-0.612-0.124-1.065-0.373-1.359c-0.249-0.294-0.671-0.441-1.265-0.441c-0.342,0-0.639,0.051-0.892,0.153
			c-0.252,0.102-0.445,0.247-0.58,0.436c-0.135,0.189-0.202,0.419-0.202,0.689h-2.322c0-0.66,0.159-1.199,0.477-1.616
			c0.318-0.417,0.775-0.723,1.373-0.918c0.597-0.195,1.312-0.292,2.146-0.292c0.756,0,1.416,0.122,1.979,0.364
			c0.564,0.243,1.002,0.617,1.314,1.121c0.312,0.504,0.468,1.149,0.468,1.935v5.562H308.898z"
                />
                <path
                  fill="#FFFFFF"
                  d="M313.865,367.37v-12.348h2.124v12.348H313.865z"
                />
                <path
                  fill="#FFFFFF"
                  d="M322.784,367.622c-0.799,0-1.52-0.171-2.165-0.513c-0.645-0.342-1.156-0.853-1.534-1.534
			s-0.567-1.535-0.567-2.561c0-1.044,0.189-1.908,0.567-2.592c0.378-0.684,0.89-1.194,1.534-1.53
			c0.646-0.336,1.366-0.504,2.165-0.504c0.786,0,1.498,0.168,2.137,0.504c0.64,0.336,1.148,0.846,1.526,1.53
			c0.378,0.684,0.566,1.548,0.566,2.592c0,1.026-0.188,1.879-0.566,2.561s-0.887,1.192-1.526,1.534
			C324.282,367.451,323.571,367.622,322.784,367.622z M322.712,365.768c0.42,0,0.793-0.104,1.12-0.311s0.585-0.514,0.774-0.922
			c0.188-0.408,0.283-0.915,0.283-1.521c0-0.924-0.182-1.62-0.544-2.088c-0.363-0.468-0.859-0.702-1.49-0.702
			c-0.426,0-0.804,0.104-1.134,0.311s-0.589,0.518-0.778,0.932c-0.189,0.414-0.283,0.93-0.283,1.548
			c0,0.912,0.185,1.599,0.553,2.061C321.582,365.537,322.082,365.768,322.712,365.768z"
                />
                <path
                  fill="#FFFFFF"
                  d="M329.533,367.37v-8.73h1.854l0.27,1.728v7.002H329.533z M335.528,367.37v-5.112
			c0-0.648-0.156-1.137-0.468-1.467c-0.312-0.33-0.72-0.495-1.225-0.495c-0.426,0-0.802,0.117-1.129,0.351
			c-0.327,0.234-0.584,0.587-0.77,1.058c-0.187,0.471-0.279,1.058-0.279,1.76h-0.702c0-1.134,0.142-2.078,0.423-2.831
			c0.282-0.753,0.682-1.315,1.197-1.688c0.517-0.372,1.122-0.558,1.818-0.558c0.72,0,1.321,0.161,1.805,0.481
			c0.482,0.321,0.846,0.772,1.089,1.354s0.364,1.26,0.364,2.034v5.112H335.528z"
                />
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M561.407,139.533c-0.81,0-1.556-0.141-2.237-0.423c-0.681-0.282-1.232-0.711-1.655-1.287
			s-0.649-1.308-0.68-2.196h2.088c0,0.45,0.111,0.819,0.333,1.107c0.223,0.288,0.521,0.502,0.896,0.644
			c0.375,0.141,0.788,0.211,1.237,0.211c0.432,0,0.818-0.057,1.156-0.171c0.34-0.114,0.606-0.278,0.802-0.491
			c0.194-0.213,0.292-0.466,0.292-0.76c0-0.39-0.118-0.696-0.355-0.918c-0.236-0.222-0.616-0.426-1.139-0.612l-2.304-0.918
			c-0.899-0.354-1.59-0.765-2.069-1.233c-0.48-0.468-0.721-1.113-0.721-1.935c0-0.948,0.375-1.716,1.125-2.304
			c0.75-0.588,1.797-0.882,3.141-0.882c1.309,0,2.319,0.305,3.033,0.914c0.715,0.609,1.119,1.457,1.215,2.542h-2.088
			c-0.078-0.462-0.287-0.833-0.629-1.111c-0.343-0.279-0.853-0.419-1.531-0.419c-0.6,0-1.073,0.125-1.422,0.374
			c-0.348,0.249-0.521,0.562-0.521,0.941c0,0.324,0.109,0.58,0.328,0.769c0.219,0.189,0.56,0.371,1.021,0.545l2.557,0.99
			c0.863,0.336,1.527,0.759,1.988,1.269c0.463,0.51,0.693,1.149,0.693,1.917c0,0.702-0.177,1.31-0.531,1.823
			c-0.354,0.513-0.869,0.91-1.548,1.192C563.204,139.393,562.379,139.533,561.407,139.533z"
                />
                <path
                  fill="#FFFFFF"
                  d="M567.717,131.955v-1.404h5.723v1.404H567.717z M571.766,139.533c-0.516,0-0.988-0.096-1.417-0.288
			c-0.429-0.192-0.771-0.486-1.026-0.882c-0.255-0.396-0.383-0.9-0.383-1.512v-8.622h2.125v8.55c0,0.72,0.342,1.08,1.025,1.08
			c0.211,0,0.42-0.024,0.631-0.072c0.209-0.048,0.449-0.12,0.719-0.216l0.307,1.494c-0.336,0.132-0.663,0.243-0.98,0.333
			C572.448,139.488,572.115,139.533,571.766,139.533z"
                />
                <path
                  fill="#FFFFFF"
                  d="M579.353,139.533c-0.798,0-1.52-0.171-2.165-0.513c-0.645-0.342-1.156-0.853-1.533-1.534
			c-0.379-0.681-0.567-1.535-0.567-2.561c0-1.044,0.188-1.908,0.567-2.592c0.377-0.684,0.889-1.194,1.533-1.53
			c0.646-0.336,1.367-0.504,2.165-0.504c0.786,0,1.499,0.168,2.138,0.504s1.147,0.846,1.525,1.53
			c0.378,0.684,0.566,1.548,0.566,2.592c0,1.026-0.188,1.879-0.566,2.561s-0.887,1.192-1.525,1.534
			C580.852,139.362,580.139,139.533,579.353,139.533z M579.281,137.679c0.42,0,0.793-0.104,1.12-0.311s0.585-0.514,0.774-0.922
			c0.188-0.408,0.283-0.915,0.283-1.521c0-0.924-0.182-1.62-0.545-2.088c-0.363-0.468-0.859-0.702-1.489-0.702
			c-0.427,0-0.804,0.104-1.134,0.311s-0.59,0.518-0.779,0.932c-0.188,0.414-0.283,0.93-0.283,1.548c0,0.912,0.185,1.599,0.554,2.061
			S578.651,137.679,579.281,137.679z"
                />
                <path
                  fill="#FFFFFF"
                  d="M589.414,139.533c-0.731,0-1.411-0.19-2.038-0.571c-0.627-0.381-1.134-0.918-1.521-1.611
			c-0.387-0.693-0.58-1.507-0.58-2.443c0-0.99,0.193-1.829,0.58-2.516s0.894-1.207,1.521-1.562c0.627-0.354,1.307-0.531,2.038-0.531
			c0.57,0,1.073,0.091,1.508,0.274c0.436,0.183,0.806,0.429,1.111,0.738c0.307,0.309,0.547,0.653,0.721,1.031
			s0.285,0.759,0.333,1.143l-2.142,0.054c-0.097-0.384-0.275-0.702-0.536-0.954c-0.261-0.252-0.599-0.378-1.013-0.378
			c-0.342,0-0.662,0.095-0.963,0.284c-0.3,0.189-0.545,0.481-0.733,0.877s-0.284,0.909-0.284,1.539c0,0.6,0.09,1.1,0.266,1.499
			c0.178,0.399,0.416,0.699,0.717,0.9c0.299,0.201,0.633,0.302,0.998,0.302c0.54,0,0.934-0.131,1.18-0.392
			c0.246-0.261,0.369-0.61,0.369-1.048h2.142c0,0.618-0.142,1.182-0.423,1.692c-0.282,0.51-0.696,0.917-1.242,1.219
			C590.875,139.382,590.207,139.533,589.414,139.533z"
                />
                <path
                  fill="#FFFFFF"
                  d="M595.633,139.281v-12.348h2.125v12.348H595.633z M597.469,133.863l3.744-3.312h2.881l-6.625,5.508V133.863
			z M601.717,139.281l-3.905-5.562l2.017-0.486l4.48,6.048H601.717z"
                />
                <path
                  fill="#FFFFFF"
                  d="M604.823,130.551h2.213l1.891,6.624h-0.217l1.783-6.156h1.926l1.781,6.174h-0.215l1.889-6.642h2.125
			l-2.881,8.73h-2.088l-1.781-5.796h0.324l-1.746,5.796h-2.105L604.823,130.551z"
                />
                <path
                  fill="#FFFFFF"
                  d="M623.182,139.533c-0.744,0-1.426-0.192-2.043-0.576c-0.619-0.384-1.113-0.922-1.484-1.615
			c-0.373-0.693-0.559-1.504-0.559-2.435c0-0.96,0.184-1.785,0.553-2.475s0.863-1.218,1.48-1.584
			c0.619-0.366,1.303-0.549,2.053-0.549c0.695,0,1.297,0.14,1.805,0.418c0.506,0.279,0.914,0.657,1.225,1.134
			c0.309,0.477,0.516,1.016,0.625,1.616c0.107,0.6,0.113,1.218,0.018,1.854h-6.066v-1.152h4.562l-0.414,0.27
			c0.02-0.282,0.006-0.567-0.039-0.855c-0.045-0.288-0.131-0.552-0.258-0.792c-0.125-0.24-0.305-0.434-0.535-0.581
			s-0.525-0.221-0.887-0.221c-0.432,0-0.797,0.108-1.098,0.324c-0.299,0.216-0.527,0.51-0.684,0.882s-0.234,0.792-0.234,1.26v0.846
			c0,0.492,0.074,0.927,0.221,1.305s0.365,0.675,0.656,0.891c0.291,0.216,0.66,0.324,1.104,0.324c0.414,0,0.762-0.096,1.043-0.288
			c0.283-0.192,0.463-0.48,0.541-0.864h2.143c-0.09,0.564-0.303,1.061-0.635,1.489c-0.334,0.429-0.764,0.765-1.291,1.008
			C624.453,139.412,623.854,139.533,623.182,139.533z"
                />
                <path
                  fill="#FFFFFF"
                  d="M629.463,139.281v-8.766h2.125v8.766H629.463z M631.067,135.447c0-1.146,0.129-2.094,0.387-2.844
			c0.258-0.75,0.604-1.306,1.039-1.669c0.436-0.363,0.92-0.544,1.453-0.544c0.145,0,0.281,0.009,0.41,0.027
			c0.129,0.018,0.256,0.045,0.383,0.081l-0.037,2.124c-0.156-0.036-0.324-0.066-0.504-0.09s-0.342-0.036-0.486-0.036
			c-0.467,0-0.859,0.113-1.174,0.337c-0.314,0.225-0.553,0.557-0.711,0.995s-0.238,0.978-0.238,1.62H631.067z"
                />
                <path
                  fill="#FFFFFF"
                  d="M636.528,139.281v-12.348h2.125v12.348H636.528z M638.364,133.863l3.744-3.312h2.881l-6.625,5.508V133.863
			z M642.612,139.281l-3.906-5.562l2.018-0.486l4.48,6.048H642.612z"
                />
              </g>
              <g opacity="0.5">
                <path
                  fill="#FFFFFF"
                  d="M15.927,264.424h1.703l2.568,8.848h-0.416l2.592-7.952h1.032l2.608,7.864H25.63l2.841-8.76h1.712
			l-3.561,10.192h-1.6l-2.2-6.528h0.216l-2.248,6.528h-1.592L15.927,264.424z"
                />
                <path
                  fill="#FFFFFF"
                  d="M35.567,274.824c-0.901,0-1.679-0.22-2.332-0.66s-1.158-1.061-1.516-1.864s-0.536-1.745-0.536-2.828
			c0-1.141,0.189-2.1,0.568-2.876c0.378-0.776,0.899-1.363,1.563-1.76c0.664-0.397,1.415-0.596,2.252-0.596
			c1.125,0,2.021,0.279,2.688,0.836c0.666,0.558,1.109,1.281,1.328,2.172l-1.624,0.312c-0.192-0.592-0.496-1.065-0.912-1.42
			c-0.416-0.354-0.909-0.532-1.48-0.532c-0.501,0-0.96,0.144-1.376,0.432c-0.416,0.288-0.749,0.719-1,1.292
			c-0.251,0.574-0.376,1.287-0.376,2.14c0,0.79,0.104,1.481,0.312,2.076s0.515,1.057,0.92,1.388c0.405,0.331,0.912,0.496,1.52,0.496
			c0.741,0,1.325-0.199,1.752-0.596c0.427-0.397,0.641-0.916,0.641-1.556h1.64c0,0.747-0.167,1.383-0.5,1.908
			c-0.333,0.525-0.802,0.93-1.404,1.212C37.092,274.683,36.383,274.824,35.567,274.824z"
                />
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M6.466,375.274v-10.192h6.4v1.312h-4.88v3.104h4.592v1.312H7.987v3.136h4.88v1.328H6.466z"
                />
                <path
                  fill="#FFFFFF"
                  d="M16.523,365.938c-0.305,0-0.543-0.084-0.717-0.252c-0.173-0.168-0.26-0.396-0.26-0.684
			c0-0.277,0.087-0.497,0.26-0.66c0.174-0.163,0.412-0.244,0.717-0.244c0.298,0,0.534,0.081,0.708,0.244
			c0.173,0.163,0.26,0.383,0.26,0.66c0,0.288-0.087,0.516-0.26,0.684C17.057,365.854,16.821,365.938,16.523,365.938z
			 M15.762,375.274v-7.72h1.521v7.72H15.762z"
                />
                <path
                  fill="#FFFFFF"
                  d="M20.617,375.274v-7.696h1.32l0.2,1.664v6.032H20.617z M26.01,375.274v-4.512
			c0-0.667-0.159-1.172-0.477-1.516c-0.317-0.344-0.745-0.516-1.283-0.516c-0.427,0-0.799,0.116-1.116,0.348
			c-0.317,0.232-0.562,0.57-0.736,1.016c-0.173,0.445-0.26,0.988-0.26,1.628h-0.544c0-0.944,0.121-1.739,0.364-2.384
			c0.242-0.645,0.59-1.135,1.044-1.468c0.453-0.333,0.997-0.5,1.632-0.5c0.618,0,1.143,0.139,1.572,0.416
			c0.429,0.277,0.756,0.67,0.979,1.18s0.336,1.108,0.336,1.796v4.512H26.01z"
                />
                <path
                  fill="#FFFFFF"
                  d="M33.241,379.242c-0.832,0-1.548-0.114-2.147-0.344c-0.601-0.229-1.062-0.536-1.388-0.92
			c-0.326-0.384-0.488-0.803-0.488-1.256c0-0.576,0.211-1.018,0.632-1.324s0.957-0.46,1.608-0.46l0.983,0.464
			c-0.555,0-0.982,0.112-1.284,0.336c-0.301,0.225-0.451,0.528-0.451,0.912c0,0.4,0.218,0.729,0.655,0.988s1.064,0.388,1.88,0.388
			c0.593,0,1.112-0.087,1.561-0.26c0.448-0.174,0.795-0.4,1.04-0.681c0.245-0.279,0.368-0.579,0.368-0.899
			c0-0.219-0.079-0.404-0.236-0.556c-0.157-0.152-0.396-0.229-0.716-0.229h-2.816c-0.522,0-0.982-0.081-1.38-0.244
			c-0.397-0.163-0.708-0.377-0.932-0.644c-0.225-0.267-0.336-0.563-0.336-0.888c0-0.32,0.09-0.596,0.271-0.828
			s0.422-0.408,0.72-0.528c0.299-0.12,0.627-0.18,0.984-0.18l0.344,0.528c-0.154,0-0.309,0.033-0.464,0.1
			c-0.154,0.067-0.282,0.167-0.384,0.3s-0.152,0.299-0.152,0.496c0,0.229,0.098,0.42,0.292,0.572
			c0.195,0.152,0.54,0.228,1.036,0.228h2.816c0.768,0,1.37,0.152,1.808,0.456c0.438,0.304,0.656,0.746,0.656,1.328
			c0,0.527-0.182,1.032-0.544,1.512c-0.363,0.48-0.879,0.872-1.548,1.176C34.96,379.09,34.164,379.242,33.241,379.242z
			 M33.409,372.962c-0.597,0-1.14-0.116-1.628-0.348c-0.487-0.232-0.876-0.557-1.164-0.976c-0.287-0.418-0.432-0.908-0.432-1.468
			c0-0.565,0.145-1.057,0.432-1.476c0.288-0.418,0.677-0.744,1.164-0.976c0.488-0.232,1.031-0.348,1.628-0.348
			c0.582,0,1.113,0.116,1.597,0.348c0.482,0.232,0.869,0.558,1.16,0.976c0.29,0.419,0.436,0.911,0.436,1.476
			c0,0.56-0.146,1.049-0.436,1.468c-0.291,0.419-0.678,0.744-1.16,0.976C34.523,372.846,33.991,372.962,33.409,372.962z
			 M33.409,371.89c0.55,0,0.97-0.164,1.261-0.492c0.29-0.328,0.436-0.737,0.436-1.228c0-0.512-0.146-0.925-0.436-1.24
			c-0.291-0.314-0.711-0.472-1.261-0.472c-0.538,0-0.958,0.157-1.26,0.472c-0.301,0.315-0.452,0.728-0.452,1.24
			c0,0.491,0.151,0.9,0.452,1.228C32.451,371.726,32.871,371.89,33.409,371.89z M36.026,368.602l-0.712-0.344
			c0-0.458,0.096-0.858,0.288-1.2s0.459-0.608,0.8-0.8c0.342-0.192,0.733-0.288,1.176-0.288l-0.096,1.48
			c-0.475,0-0.836,0.094-1.084,0.28S36.026,368.208,36.026,368.602z"
                />
                <path
                  fill="#FFFFFF"
                  d="M41.81,375.482c-0.645,0-1.181-0.113-1.607-0.34s-0.745-0.524-0.956-0.892s-0.316-0.76-0.316-1.176
			c0-0.506,0.13-0.924,0.389-1.252c0.258-0.328,0.617-0.572,1.075-0.732c0.459-0.16,0.987-0.24,1.584-0.24
			c0.411,0,0.812,0.037,1.2,0.112c0.39,0.075,0.773,0.173,1.152,0.296v0.92c-0.31-0.112-0.652-0.201-1.028-0.268
			c-0.376-0.067-0.727-0.1-1.052-0.1c-0.485,0-0.891,0.096-1.216,0.288c-0.325,0.192-0.488,0.517-0.488,0.976
			c0,0.336,0.128,0.625,0.384,0.868s0.661,0.364,1.216,0.364c0.405,0,0.763-0.092,1.072-0.276s0.55-0.415,0.72-0.692
			c0.171-0.277,0.257-0.555,0.257-0.832h0.367c0,0.533-0.107,1.025-0.323,1.476c-0.217,0.451-0.529,0.813-0.94,1.088
			S42.392,375.482,41.81,375.482z M44.386,375.274l-0.191-1.768v-3.208c0-0.629-0.131-1.086-0.393-1.372
			c-0.261-0.285-0.675-0.428-1.24-0.428c-0.319,0-0.604,0.046-0.852,0.14c-0.248,0.094-0.442,0.233-0.584,0.42
			c-0.142,0.187-0.212,0.421-0.212,0.704h-1.68c0-0.565,0.139-1.024,0.416-1.376c0.276-0.352,0.665-0.609,1.163-0.772
			c0.499-0.163,1.082-0.244,1.748-0.244c0.624,0,1.174,0.103,1.648,0.308c0.475,0.206,0.844,0.527,1.108,0.964
			c0.264,0.437,0.396,1.002,0.396,1.696v4.936H44.386z"
                />
                <path
                  fill="#FFFFFF"
                  d="M48.602,375.274v-7.696h1.32l0.2,1.664v6.032H48.602z M53.994,375.274v-4.512
			c0-0.667-0.159-1.172-0.477-1.516c-0.317-0.344-0.745-0.516-1.283-0.516c-0.427,0-0.799,0.116-1.116,0.348
			c-0.317,0.232-0.562,0.57-0.736,1.016c-0.173,0.445-0.26,0.988-0.26,1.628h-0.544c0-0.944,0.121-1.739,0.364-2.384
			c0.242-0.645,0.59-1.135,1.044-1.468c0.453-0.333,0.997-0.5,1.632-0.5c0.618,0,1.143,0.139,1.572,0.416
			c0.429,0.277,0.756,0.67,0.979,1.18s0.336,1.108,0.336,1.796v4.512H53.994z"
                />
                <path
                  fill="#FFFFFF"
                  d="M61.226,379.242c-0.832,0-1.548-0.114-2.147-0.344c-0.601-0.229-1.062-0.536-1.388-0.92
			c-0.326-0.384-0.488-0.803-0.488-1.256c0-0.576,0.211-1.018,0.632-1.324s0.957-0.46,1.608-0.46l0.983,0.464
			c-0.555,0-0.982,0.112-1.284,0.336c-0.301,0.225-0.451,0.528-0.451,0.912c0,0.4,0.218,0.729,0.655,0.988s1.064,0.388,1.88,0.388
			c0.593,0,1.112-0.087,1.561-0.26c0.448-0.174,0.795-0.4,1.04-0.681c0.245-0.279,0.368-0.579,0.368-0.899
			c0-0.219-0.079-0.404-0.236-0.556c-0.157-0.152-0.396-0.229-0.716-0.229h-2.816c-0.522,0-0.982-0.081-1.38-0.244
			c-0.397-0.163-0.708-0.377-0.932-0.644c-0.225-0.267-0.336-0.563-0.336-0.888c0-0.32,0.09-0.596,0.271-0.828
			s0.422-0.408,0.72-0.528c0.299-0.12,0.627-0.18,0.984-0.18l0.344,0.528c-0.154,0-0.309,0.033-0.464,0.1
			c-0.154,0.067-0.282,0.167-0.384,0.3s-0.152,0.299-0.152,0.496c0,0.229,0.098,0.42,0.292,0.572
			c0.195,0.152,0.54,0.228,1.036,0.228h2.816c0.768,0,1.37,0.152,1.808,0.456c0.438,0.304,0.656,0.746,0.656,1.328
			c0,0.527-0.182,1.032-0.544,1.512c-0.363,0.48-0.879,0.872-1.548,1.176C62.945,379.09,62.149,379.242,61.226,379.242z
			 M61.394,372.962c-0.597,0-1.14-0.116-1.628-0.348c-0.487-0.232-0.876-0.557-1.164-0.976c-0.287-0.418-0.432-0.908-0.432-1.468
			c0-0.565,0.145-1.057,0.432-1.476c0.288-0.418,0.677-0.744,1.164-0.976c0.488-0.232,1.031-0.348,1.628-0.348
			c0.582,0,1.113,0.116,1.597,0.348c0.482,0.232,0.869,0.558,1.16,0.976c0.29,0.419,0.436,0.911,0.436,1.476
			c0,0.56-0.146,1.049-0.436,1.468c-0.291,0.419-0.678,0.744-1.16,0.976C62.507,372.846,61.976,372.962,61.394,372.962z
			 M61.394,371.89c0.55,0,0.97-0.164,1.261-0.492c0.29-0.328,0.436-0.737,0.436-1.228c0-0.512-0.146-0.925-0.436-1.24
			c-0.291-0.314-0.711-0.472-1.261-0.472c-0.538,0-0.958,0.157-1.26,0.472c-0.301,0.315-0.452,0.728-0.452,1.24
			c0,0.491,0.151,0.9,0.452,1.228C60.436,371.726,60.856,371.89,61.394,371.89z M64.01,368.602l-0.712-0.344
			c0-0.458,0.096-0.858,0.288-1.2s0.459-0.608,0.8-0.8c0.342-0.192,0.733-0.288,1.176-0.288l-0.096,1.48
			c-0.475,0-0.836,0.094-1.084,0.28S64.01,368.208,64.01,368.602z"
                />
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M21.88,309.672h1.185v10.232h-1.521v-8.432c-0.267,0.155-0.539,0.273-0.816,0.356
			c-0.277,0.083-0.555,0.129-0.832,0.14v-1.472c0.347-0.042,0.685-0.129,1.013-0.26S21.56,309.917,21.88,309.672z"
                />
                <path
                  fill="#FFFFFF"
                  d="M25.073,319.008c0-0.299,0.103-0.552,0.308-0.76c0.205-0.208,0.465-0.312,0.78-0.312
			c0.314,0,0.581,0.104,0.8,0.312c0.219,0.208,0.328,0.461,0.328,0.76c0,0.293-0.109,0.54-0.328,0.74
			c-0.219,0.2-0.485,0.299-0.8,0.299c-0.315,0-0.575-0.101-0.78-0.304C25.175,319.541,25.073,319.296,25.073,319.008z"
                />
                <path
                  fill="#FFFFFF"
                  d="M36.591,320.112c-0.693,0-1.331-0.124-1.912-0.372s-1.05-0.617-1.404-1.108s-0.54-1.104-0.556-1.84h1.488
			c0,0.442,0.107,0.805,0.323,1.088c0.217,0.283,0.503,0.492,0.86,0.628s0.752,0.204,1.184,0.204c0.438,0,0.824-0.067,1.16-0.2
			s0.597-0.312,0.78-0.536c0.184-0.224,0.276-0.48,0.276-0.768c0-0.39-0.12-0.703-0.36-0.94c-0.24-0.237-0.605-0.438-1.096-0.604
			l-1.896-0.704c-0.815-0.304-1.438-0.655-1.864-1.052c-0.427-0.397-0.64-0.951-0.64-1.66c0-0.806,0.314-1.46,0.944-1.964
			c0.629-0.504,1.515-0.756,2.656-0.756c1.082,0,1.924,0.25,2.523,0.752s0.942,1.184,1.028,2.048h-1.496
			c-0.075-0.437-0.279-0.784-0.612-1.04c-0.333-0.256-0.814-0.384-1.443-0.384c-0.608,0-1.083,0.132-1.425,0.396
			c-0.341,0.264-0.512,0.596-0.512,0.996c0,0.331,0.108,0.595,0.324,0.792s0.553,0.376,1.012,0.536l2.16,0.792
			c0.741,0.261,1.32,0.613,1.736,1.056c0.416,0.443,0.624,1.005,0.624,1.688c0,0.576-0.149,1.085-0.448,1.528
			c-0.299,0.442-0.736,0.791-1.312,1.044C38.119,319.985,37.417,320.112,36.591,320.112z"
                />
                <path
                  fill="#FFFFFF"
                  d="M42.064,313.24v-1.032h4.968v1.032H42.064z M45.568,320.112c-0.438,0-0.832-0.083-1.185-0.248
			c-0.352-0.165-0.631-0.415-0.836-0.748c-0.205-0.333-0.308-0.753-0.308-1.26v-7.744h1.512v7.696c0,0.726,0.339,1.088,1.017,1.088
			c0.208,0,0.41-0.023,0.607-0.068c0.197-0.045,0.422-0.113,0.672-0.204l0.216,1.08c-0.282,0.118-0.561,0.215-0.836,0.292
			C46.153,320.073,45.866,320.112,45.568,320.112z"
                />
                <path
                  fill="#FFFFFF"
                  d="M52.135,320.112c-0.699,0-1.324-0.152-1.876-0.456s-0.988-0.757-1.308-1.36
			c-0.32-0.603-0.48-1.347-0.48-2.232c0-0.901,0.16-1.652,0.48-2.252c0.319-0.6,0.756-1.052,1.308-1.356
			c0.552-0.304,1.177-0.456,1.876-0.456c0.688,0,1.307,0.152,1.855,0.456c0.55,0.304,0.983,0.756,1.301,1.356
			c0.317,0.6,0.476,1.351,0.476,2.252c0,0.885-0.158,1.629-0.476,2.232s-0.751,1.056-1.301,1.36
			C53.442,319.96,52.823,320.112,52.135,320.112z M52.087,318.784c0.442,0,0.825-0.106,1.147-0.32
			c0.323-0.213,0.571-0.524,0.744-0.932c0.174-0.408,0.26-0.897,0.26-1.468c0-0.875-0.177-1.552-0.531-2.032
			c-0.355-0.48-0.865-0.72-1.532-0.72c-0.448,0-0.833,0.108-1.156,0.324c-0.322,0.216-0.57,0.528-0.744,0.936
			c-0.173,0.408-0.26,0.905-0.26,1.492c0,0.858,0.179,1.526,0.536,2.004S51.42,318.784,52.087,318.784z"
                />
                <path
                  fill="#FFFFFF"
                  d="M60.919,320.112c-0.63,0-1.217-0.168-1.761-0.504s-0.981-0.808-1.312-1.416
			c-0.331-0.608-0.496-1.323-0.496-2.144c0-0.864,0.165-1.596,0.496-2.196c0.33-0.6,0.768-1.059,1.312-1.376
			c0.544-0.317,1.131-0.476,1.761-0.476c0.464,0,0.877,0.073,1.239,0.22c0.363,0.147,0.675,0.344,0.937,0.592
			c0.261,0.248,0.473,0.526,0.636,0.836c0.163,0.309,0.276,0.629,0.34,0.96l-1.544,0.08c-0.111-0.4-0.308-0.721-0.588-0.964
			c-0.28-0.243-0.625-0.364-1.036-0.364c-0.347,0-0.673,0.097-0.979,0.292s-0.555,0.491-0.744,0.888
			c-0.189,0.397-0.284,0.9-0.284,1.508c0,0.565,0.088,1.048,0.264,1.448c0.177,0.4,0.416,0.707,0.721,0.92
			c0.304,0.213,0.645,0.32,1.023,0.32c0.544,0,0.951-0.132,1.221-0.396c0.269-0.264,0.403-0.628,0.403-1.092h1.544
			c0,0.539-0.125,1.024-0.376,1.456c-0.251,0.432-0.611,0.775-1.084,1.028C62.139,319.985,61.574,320.112,60.919,320.112z"
                />
                <path
                  fill="#FFFFFF"
                  d="M66.462,319.904v-10.936h1.521v10.936H66.462z M67.767,315.336l3.655-3.128h2.089l-5.744,4.72V315.336z
			 M71.838,319.904l-3.567-4.88l1.488-0.312l3.936,5.192H71.838z"
                />
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M500.671,246.359v-10.192h6.4v1.312h-4.88v3.104h4.592v1.312h-4.592v3.136h4.88v1.328H500.671z"
                />
                <path
                  fill="#FFFFFF"
                  d="M509.847,246.359v-7.72h1.521v7.72H509.847z M510.951,242.919c0-0.987,0.103-1.807,0.308-2.46
			c0.206-0.653,0.493-1.14,0.864-1.46c0.371-0.32,0.807-0.48,1.308-0.48c0.149,0,0.29,0.011,0.421,0.032
			c0.13,0.021,0.26,0.051,0.388,0.088l-0.056,1.512c-0.145-0.037-0.301-0.071-0.469-0.1s-0.321-0.044-0.46-0.044
			c-0.438,0-0.795,0.116-1.071,0.348c-0.278,0.232-0.483,0.565-0.616,1c-0.134,0.435-0.2,0.956-0.2,1.564H510.951z"
                />
                <path
                  fill="#FFFFFF"
                  d="M518.631,246.567c-0.629,0-1.208-0.169-1.735-0.508c-0.528-0.339-0.948-0.813-1.26-1.424
			c-0.312-0.61-0.469-1.329-0.469-2.156c0-0.821,0.153-1.533,0.46-2.136c0.307-0.603,0.714-1.068,1.221-1.396
			c0.506-0.328,1.069-0.492,1.688-0.492c0.565,0,1.059,0.137,1.48,0.412c0.421,0.275,0.748,0.673,0.979,1.196
			c0.232,0.522,0.349,1.16,0.349,1.912h-0.528c0-0.464-0.094-0.857-0.28-1.18s-0.43-0.567-0.728-0.732
			c-0.299-0.166-0.619-0.248-0.96-0.248c-0.4,0-0.762,0.108-1.084,0.324c-0.323,0.216-0.579,0.523-0.769,0.92
			c-0.189,0.397-0.284,0.871-0.284,1.42c0,0.555,0.095,1.036,0.284,1.444s0.445,0.721,0.769,0.94
			c0.322,0.219,0.681,0.328,1.075,0.328c0.325,0,0.639-0.086,0.94-0.26c0.301-0.173,0.549-0.422,0.744-0.748
			c0.194-0.326,0.292-0.712,0.292-1.16h0.528c0,0.768-0.122,1.416-0.364,1.944c-0.243,0.528-0.569,0.927-0.98,1.196
			C519.589,246.432,519.133,246.567,518.631,246.567z M521.079,246.359l-0.264-1.848v-9.088h1.528v10.936H521.079z"
                />
                <path
                  fill="#FFFFFF"
                  d="M528.295,250.327c-0.832,0-1.548-0.114-2.147-0.344c-0.601-0.229-1.062-0.536-1.388-0.92
			c-0.326-0.384-0.488-0.803-0.488-1.256c0-0.576,0.211-1.018,0.632-1.324s0.957-0.46,1.608-0.46l0.983,0.464
			c-0.555,0-0.982,0.112-1.284,0.336c-0.301,0.225-0.451,0.528-0.451,0.912c0,0.4,0.218,0.729,0.655,0.988s1.064,0.388,1.88,0.388
			c0.593,0,1.112-0.087,1.561-0.26c0.448-0.174,0.795-0.4,1.04-0.681c0.245-0.279,0.368-0.579,0.368-0.899
			c0-0.219-0.079-0.404-0.236-0.556c-0.157-0.152-0.396-0.229-0.716-0.229h-2.816c-0.522,0-0.982-0.081-1.38-0.244
			c-0.397-0.163-0.708-0.377-0.932-0.644c-0.225-0.267-0.336-0.563-0.336-0.888c0-0.32,0.09-0.596,0.271-0.828
			s0.422-0.408,0.72-0.528c0.299-0.12,0.627-0.18,0.984-0.18l0.344,0.528c-0.154,0-0.309,0.033-0.464,0.1
			c-0.154,0.067-0.282,0.167-0.384,0.3s-0.152,0.299-0.152,0.496c0,0.229,0.098,0.42,0.292,0.572
			c0.195,0.152,0.54,0.228,1.036,0.228h2.816c0.768,0,1.37,0.152,1.808,0.456c0.438,0.304,0.656,0.746,0.656,1.328
			c0,0.527-0.182,1.032-0.544,1.512c-0.363,0.48-0.879,0.872-1.548,1.176C530.014,250.175,529.218,250.327,528.295,250.327z
			 M528.463,244.047c-0.597,0-1.14-0.116-1.628-0.348c-0.487-0.232-0.876-0.557-1.164-0.976c-0.287-0.418-0.432-0.908-0.432-1.468
			c0-0.565,0.145-1.057,0.432-1.476c0.288-0.418,0.677-0.744,1.164-0.976c0.488-0.232,1.031-0.348,1.628-0.348
			c0.582,0,1.113,0.116,1.597,0.348c0.482,0.232,0.869,0.558,1.16,0.976c0.29,0.419,0.436,0.911,0.436,1.476
			c0,0.56-0.146,1.049-0.436,1.468c-0.291,0.419-0.678,0.744-1.16,0.976C529.576,243.931,529.045,244.047,528.463,244.047z
			 M528.463,242.975c0.55,0,0.97-0.164,1.261-0.492c0.29-0.328,0.436-0.737,0.436-1.228c0-0.512-0.146-0.925-0.436-1.24
			c-0.291-0.314-0.711-0.472-1.261-0.472c-0.538,0-0.958,0.157-1.26,0.472c-0.301,0.315-0.452,0.728-0.452,1.24
			c0,0.491,0.151,0.9,0.452,1.228C527.505,242.811,527.925,242.975,528.463,242.975z M531.079,239.687l-0.712-0.344
			c0-0.458,0.096-0.858,0.288-1.2s0.459-0.608,0.8-0.8c0.342-0.192,0.733-0.288,1.176-0.288l-0.096,1.48
			c-0.475,0-0.836,0.094-1.084,0.28S531.079,239.293,531.079,239.687z"
                />
                <path
                  fill="#FFFFFF"
                  d="M537.375,246.567c-0.662,0-1.259-0.171-1.792-0.512c-0.534-0.341-0.956-0.816-1.269-1.424
			c-0.312-0.608-0.468-1.312-0.468-2.112c0-0.848,0.154-1.575,0.464-2.18s0.73-1.071,1.264-1.396
			c0.534-0.325,1.134-0.488,1.801-0.488c0.597,0,1.109,0.121,1.536,0.364c0.426,0.243,0.771,0.568,1.031,0.976
			c0.262,0.408,0.441,0.871,0.54,1.388c0.099,0.517,0.116,1.05,0.053,1.6h-5.473v-0.88h4.433l-0.368,0.24
			c0.016-0.288,0.001-0.576-0.044-0.864c-0.046-0.288-0.132-0.553-0.261-0.796c-0.128-0.243-0.308-0.438-0.54-0.584
			c-0.231-0.146-0.526-0.22-0.884-0.22c-0.447,0-0.822,0.112-1.124,0.336c-0.301,0.224-0.529,0.524-0.684,0.9
			c-0.155,0.376-0.232,0.788-0.232,1.236v0.616c0,0.496,0.075,0.936,0.225,1.32s0.372,0.687,0.668,0.908
			c0.296,0.221,0.67,0.332,1.124,0.332c0.442,0,0.809-0.104,1.1-0.312c0.291-0.208,0.471-0.52,0.54-0.936h1.552
			c-0.08,0.501-0.264,0.938-0.552,1.312c-0.288,0.373-0.657,0.663-1.108,0.868C538.456,246.464,537.946,246.567,537.375,246.567z"
                />
                <path
                  fill="#FFFFFF"
                  d="M545.759,246.567c-0.747,0-1.376-0.105-1.888-0.316c-0.513-0.211-0.903-0.504-1.172-0.88
			c-0.27-0.376-0.41-0.817-0.42-1.324h1.432c0.021,0.443,0.198,0.771,0.532,0.984c0.333,0.213,0.785,0.32,1.355,0.32
			c0.304,0,0.58-0.048,0.828-0.144c0.248-0.096,0.445-0.227,0.592-0.392s0.221-0.352,0.221-0.56c0-0.261-0.097-0.468-0.288-0.62
			c-0.192-0.152-0.502-0.287-0.929-0.404l-1.575-0.536c-0.63-0.213-1.112-0.497-1.448-0.852c-0.336-0.354-0.504-0.78-0.504-1.276
			c0-0.635,0.282-1.145,0.848-1.532c0.565-0.386,1.314-0.58,2.248-0.58c0.965,0,1.711,0.204,2.236,0.612s0.798,0.919,0.819,1.532
			h-1.464c-0.037-0.304-0.188-0.538-0.452-0.7c-0.264-0.163-0.644-0.244-1.14-0.244c-0.464,0-0.828,0.102-1.092,0.304
			c-0.265,0.203-0.396,0.432-0.396,0.688c0,0.458,0.395,0.8,1.185,1.024l1.655,0.584c0.63,0.203,1.115,0.464,1.456,0.784
			c0.342,0.32,0.513,0.725,0.513,1.216c0,0.395-0.131,0.768-0.393,1.12c-0.261,0.352-0.628,0.639-1.1,0.86
			C546.948,246.457,546.394,246.567,545.759,246.567z"
                />
                <path
                  fill="#FFFFFF"
                  d="M554.167,246.567c-0.63,0-1.217-0.168-1.761-0.504s-0.981-0.808-1.312-1.416
			c-0.331-0.608-0.496-1.323-0.496-2.144c0-0.864,0.165-1.596,0.496-2.196c0.33-0.6,0.768-1.059,1.312-1.376
			c0.544-0.317,1.131-0.476,1.761-0.476c0.464,0,0.877,0.073,1.239,0.22c0.363,0.147,0.675,0.344,0.937,0.592
			c0.261,0.248,0.473,0.526,0.636,0.836c0.163,0.309,0.276,0.629,0.34,0.96l-1.544,0.08c-0.111-0.4-0.308-0.721-0.588-0.964
			c-0.28-0.243-0.625-0.364-1.036-0.364c-0.347,0-0.673,0.097-0.979,0.292s-0.555,0.491-0.744,0.888
			c-0.189,0.397-0.284,0.9-0.284,1.508c0,0.565,0.088,1.048,0.264,1.448c0.177,0.4,0.416,0.707,0.721,0.92
			c0.304,0.213,0.645,0.32,1.023,0.32c0.544,0,0.951-0.132,1.221-0.396c0.269-0.264,0.403-0.628,0.403-1.092h1.544
			c0,0.539-0.125,1.024-0.376,1.456c-0.251,0.432-0.611,0.775-1.084,1.028C555.387,246.44,554.823,246.567,554.167,246.567z"
                />
                <path
                  fill="#FFFFFF"
                  d="M559.71,246.359v-10.936h1.521v10.936H559.71z M565.103,246.359v-4.512c0-0.667-0.159-1.172-0.477-1.516
			c-0.317-0.344-0.745-0.516-1.283-0.516c-0.427,0-0.799,0.116-1.116,0.348c-0.317,0.232-0.562,0.57-0.736,1.016
			c-0.173,0.445-0.26,0.988-0.26,1.628h-0.544c0-0.944,0.121-1.739,0.364-2.384c0.242-0.645,0.59-1.135,1.044-1.468
			c0.453-0.333,0.997-0.5,1.632-0.5c0.618,0,1.143,0.139,1.572,0.416c0.429,0.277,0.756,0.67,0.979,1.18s0.336,1.108,0.336,1.796
			v4.512H565.103z"
                />
                <path
                  fill="#FFFFFF"
                  d="M572.399,246.567c-0.699,0-1.324-0.152-1.876-0.456s-0.988-0.757-1.308-1.36
			c-0.32-0.603-0.48-1.347-0.48-2.232c0-0.901,0.16-1.652,0.48-2.252c0.319-0.6,0.756-1.052,1.308-1.356
			c0.552-0.304,1.177-0.456,1.876-0.456c0.688,0,1.307,0.152,1.855,0.456c0.55,0.304,0.983,0.756,1.301,1.356
			c0.317,0.6,0.476,1.351,0.476,2.252c0,0.885-0.158,1.629-0.476,2.232s-0.751,1.056-1.301,1.36
			C573.705,246.415,573.086,246.567,572.399,246.567z M572.351,245.239c0.442,0,0.825-0.106,1.147-0.32
			c0.323-0.213,0.571-0.524,0.744-0.932c0.174-0.408,0.26-0.897,0.26-1.468c0-0.875-0.177-1.552-0.531-2.032
			c-0.355-0.48-0.865-0.72-1.532-0.72c-0.448,0-0.833,0.108-1.156,0.324c-0.322,0.216-0.57,0.528-0.744,0.936
			c-0.173,0.408-0.26,0.905-0.26,1.492c0,0.858,0.179,1.526,0.536,2.004S571.684,245.239,572.351,245.239z"
                />
                <path
                  fill="#FFFFFF"
                  d="M581.198,246.567c-0.747,0-1.376-0.105-1.888-0.316c-0.513-0.211-0.903-0.504-1.172-0.88
			c-0.27-0.376-0.41-0.817-0.42-1.324h1.432c0.021,0.443,0.198,0.771,0.532,0.984c0.333,0.213,0.785,0.32,1.355,0.32
			c0.304,0,0.58-0.048,0.828-0.144c0.248-0.096,0.445-0.227,0.592-0.392s0.221-0.352,0.221-0.56c0-0.261-0.097-0.468-0.288-0.62
			c-0.192-0.152-0.502-0.287-0.929-0.404l-1.575-0.536c-0.63-0.213-1.112-0.497-1.448-0.852c-0.336-0.354-0.504-0.78-0.504-1.276
			c0-0.635,0.282-1.145,0.848-1.532c0.565-0.386,1.314-0.58,2.248-0.58c0.965,0,1.711,0.204,2.236,0.612s0.798,0.919,0.819,1.532
			h-1.464c-0.037-0.304-0.188-0.538-0.452-0.7c-0.264-0.163-0.644-0.244-1.14-0.244c-0.464,0-0.828,0.102-1.092,0.304
			c-0.265,0.203-0.396,0.432-0.396,0.688c0,0.458,0.395,0.8,1.185,1.024l1.655,0.584c0.63,0.203,1.115,0.464,1.456,0.784
			c0.342,0.32,0.513,0.725,0.513,1.216c0,0.395-0.131,0.768-0.393,1.12c-0.261,0.352-0.628,0.639-1.1,0.86
			C582.387,246.457,581.833,246.567,581.198,246.567z"
                />
                <path
                  fill="#FFFFFF"
                  d="M589.622,246.567c-0.747,0-1.376-0.105-1.888-0.316c-0.513-0.211-0.903-0.504-1.172-0.88
			c-0.27-0.376-0.41-0.817-0.42-1.324h1.432c0.021,0.443,0.198,0.771,0.532,0.984c0.333,0.213,0.785,0.32,1.355,0.32
			c0.304,0,0.58-0.048,0.828-0.144c0.248-0.096,0.445-0.227,0.592-0.392s0.221-0.352,0.221-0.56c0-0.261-0.097-0.468-0.288-0.62
			c-0.192-0.152-0.502-0.287-0.929-0.404l-1.575-0.536c-0.63-0.213-1.112-0.497-1.448-0.852c-0.336-0.354-0.504-0.78-0.504-1.276
			c0-0.635,0.282-1.145,0.848-1.532c0.565-0.386,1.314-0.58,2.248-0.58c0.965,0,1.711,0.204,2.236,0.612s0.798,0.919,0.819,1.532
			h-1.464c-0.037-0.304-0.188-0.538-0.452-0.7c-0.264-0.163-0.644-0.244-1.14-0.244c-0.464,0-0.828,0.102-1.092,0.304
			c-0.265,0.203-0.396,0.432-0.396,0.688c0,0.458,0.395,0.8,1.185,1.024l1.655,0.584c0.63,0.203,1.115,0.464,1.456,0.784
			c0.342,0.32,0.513,0.725,0.513,1.216c0,0.395-0.131,0.768-0.393,1.12c-0.261,0.352-0.628,0.639-1.1,0.86
			C590.811,246.457,590.257,246.567,589.622,246.567z"
                />
              </g>
              <g opacity="0.5">
                <path
                  fill="#FFFFFF"
                  d="M228.793,226.872h1.703l2.568,8.848h-0.416l2.592-7.952h1.032l2.608,7.864h-0.385l2.841-8.76h1.712
			l-3.561,10.192h-1.6l-2.2-6.528h0.216l-2.248,6.528h-1.592L228.793,226.872z"
                />
                <path
                  fill="#FFFFFF"
                  d="M248.433,237.272c-0.901,0-1.679-0.22-2.332-0.66s-1.158-1.061-1.516-1.864s-0.536-1.745-0.536-2.828
			c0-1.141,0.189-2.1,0.568-2.876c0.378-0.776,0.899-1.363,1.563-1.76c0.664-0.397,1.415-0.596,2.252-0.596
			c1.125,0,2.021,0.279,2.688,0.836c0.666,0.558,1.109,1.281,1.328,2.172l-1.624,0.312c-0.192-0.592-0.496-1.065-0.912-1.42
			c-0.416-0.354-0.909-0.532-1.48-0.532c-0.501,0-0.96,0.144-1.376,0.432c-0.416,0.288-0.749,0.719-1,1.292
			c-0.251,0.574-0.376,1.287-0.376,2.14c0,0.79,0.104,1.481,0.312,2.076s0.515,1.057,0.92,1.388c0.405,0.331,0.912,0.496,1.52,0.496
			c0.741,0,1.325-0.199,1.752-0.596c0.427-0.397,0.641-0.916,0.641-1.556h1.64c0,0.747-0.167,1.383-0.5,1.908
			c-0.333,0.525-0.802,0.93-1.404,1.212C249.958,237.13,249.249,237.272,248.433,237.272z"
                />
              </g>
              <g opacity="0.5">
                <path
                  fill="#FFFFFF"
                  d="M174.564,236.811v-10.192h3.416c1.136,0,2.011,0.283,2.624,0.848c0.613,0.565,0.92,1.347,0.92,2.344
			c0,0.987-0.307,1.761-0.92,2.324c-0.613,0.562-1.488,0.844-2.624,0.844h-1.88v3.832H174.564z M176.1,231.651h1.88
			c0.65,0,1.144-0.168,1.479-0.504s0.504-0.781,0.504-1.336c0-0.56-0.168-1.013-0.504-1.36s-0.829-0.52-1.479-0.52h-1.88V231.651z"
                />
                <path
                  fill="#FFFFFF"
                  d="M184.523,227.475c-0.304,0-0.542-0.084-0.716-0.252c-0.173-0.168-0.26-0.396-0.26-0.684
			c0-0.277,0.087-0.497,0.26-0.66c0.174-0.163,0.412-0.244,0.716-0.244c0.299,0,0.535,0.081,0.708,0.244
			c0.174,0.163,0.261,0.383,0.261,0.66c0,0.288-0.087,0.516-0.261,0.684C185.058,227.391,184.822,227.475,184.523,227.475z
			 M183.763,236.811v-7.72h1.521v7.72H183.763z"
                />
                <path
                  fill="#FFFFFF"
                  d="M187.931,236.811v-1.232l4.312-5.24h-4.312v-1.224h6.008v1.224l-4.216,5.24h4.216v1.232H187.931z"
                />
                <path
                  fill="#FFFFFF"
                  d="M195.658,236.811v-1.232l4.312-5.24h-4.312v-1.224h6.008v1.224l-4.216,5.24h4.216v1.232H195.658z"
                />
                <path
                  fill="#FFFFFF"
                  d="M206.322,237.019c-0.645,0-1.181-0.113-1.607-0.34s-0.745-0.524-0.956-0.892s-0.316-0.76-0.316-1.176
			c0-0.506,0.13-0.924,0.389-1.252c0.258-0.328,0.617-0.572,1.075-0.732c0.459-0.16,0.987-0.24,1.584-0.24
			c0.411,0,0.812,0.037,1.2,0.112c0.39,0.075,0.773,0.173,1.152,0.296v0.92c-0.31-0.112-0.652-0.201-1.028-0.268
			c-0.376-0.067-0.727-0.1-1.052-0.1c-0.485,0-0.891,0.096-1.216,0.288c-0.325,0.192-0.488,0.517-0.488,0.976
			c0,0.336,0.128,0.625,0.384,0.868s0.661,0.364,1.216,0.364c0.405,0,0.763-0.092,1.072-0.276s0.55-0.415,0.72-0.692
			c0.171-0.277,0.257-0.555,0.257-0.832h0.367c0,0.533-0.107,1.025-0.323,1.476c-0.217,0.451-0.529,0.813-0.94,1.088
			S206.904,237.019,206.322,237.019z M208.898,236.811l-0.191-1.768v-3.208c0-0.629-0.131-1.086-0.393-1.372
			c-0.261-0.285-0.675-0.428-1.24-0.428c-0.319,0-0.604,0.046-0.852,0.14c-0.248,0.094-0.442,0.233-0.584,0.42
			c-0.142,0.187-0.212,0.421-0.212,0.704h-1.68c0-0.565,0.139-1.024,0.416-1.376c0.276-0.352,0.665-0.609,1.163-0.772
			c0.499-0.163,1.082-0.244,1.748-0.244c0.624,0,1.174,0.103,1.648,0.308c0.475,0.206,0.844,0.527,1.108,0.964
			c0.264,0.437,0.396,1.002,0.396,1.696v4.936H208.898z"
                />
              </g>
              <g opacity="0.5">
                <path
                  fill="#FFFFFF"
                  d="M298.063,208.711v-10.192h3.943c0.764,0,1.396,0.123,1.9,0.368c0.504,0.246,0.881,0.571,1.133,0.976
			c0.25,0.406,0.375,0.851,0.375,1.336c0,0.582-0.161,1.083-0.483,1.504c-0.323,0.421-0.794,0.712-1.412,0.872
			c0.405,0.07,0.763,0.215,1.071,0.436c0.311,0.222,0.554,0.495,0.732,0.82s0.268,0.685,0.268,1.08c0,0.336-0.059,0.669-0.176,1
			c-0.117,0.331-0.312,0.632-0.584,0.904c-0.271,0.272-0.637,0.489-1.096,0.652c-0.459,0.163-1.031,0.244-1.72,0.244H298.063z
			 M299.582,199.831v3.136h2.369c0.592,0,1.057-0.141,1.396-0.424s0.508-0.675,0.508-1.176c0-0.475-0.164-0.849-0.492-1.124
			c-0.327-0.275-0.794-0.412-1.396-0.412H299.582z M299.582,207.382h2.4c0.693,0,1.195-0.144,1.508-0.432
			c0.312-0.288,0.469-0.662,0.469-1.12c0-0.464-0.166-0.837-0.496-1.12c-0.331-0.283-0.824-0.424-1.48-0.424h-2.4V207.382z"
                />
                <path
                  fill="#FFFFFF"
                  d="M310.438,208.919c-0.645,0-1.181-0.113-1.607-0.34c-0.426-0.227-0.745-0.524-0.955-0.892
			c-0.211-0.368-0.316-0.76-0.316-1.176c0-0.506,0.129-0.924,0.388-1.252c0.259-0.328,0.617-0.572,1.076-0.732
			c0.458-0.16,0.987-0.24,1.583-0.24c0.412,0,0.812,0.037,1.201,0.112s0.773,0.173,1.152,0.296v0.92
			c-0.311-0.112-0.652-0.201-1.029-0.268c-0.375-0.067-0.727-0.1-1.051-0.1c-0.486,0-0.891,0.096-1.217,0.288
			c-0.325,0.192-0.488,0.517-0.488,0.976c0,0.336,0.129,0.625,0.385,0.868s0.66,0.364,1.215,0.364c0.406,0,0.764-0.092,1.072-0.276
			c0.311-0.184,0.551-0.415,0.721-0.692s0.256-0.555,0.256-0.832h0.367c0,0.533-0.107,1.025-0.322,1.476
			c-0.217,0.451-0.529,0.813-0.941,1.088C311.516,208.781,311.02,208.919,310.438,208.919z M313.014,208.711l-0.191-1.768v-3.208
			c0-0.629-0.131-1.086-0.393-1.372c-0.26-0.285-0.674-0.428-1.24-0.428c-0.318,0-0.604,0.046-0.852,0.14
			c-0.248,0.094-0.441,0.233-0.584,0.42c-0.141,0.187-0.211,0.421-0.211,0.704h-1.681c0-0.565,0.139-1.024,0.416-1.376
			s0.665-0.609,1.164-0.772c0.499-0.163,1.081-0.244,1.747-0.244c0.625,0,1.174,0.103,1.648,0.308
			c0.475,0.206,0.844,0.527,1.109,0.964c0.264,0.437,0.395,1.002,0.395,1.696v4.936H313.014z"
                />
                <path
                  fill="#FFFFFF"
                  d="M317.23,208.711v-7.72h1.521v7.72H317.23z M318.334,205.271c0-0.987,0.103-1.807,0.308-2.46
			c0.206-0.653,0.493-1.14,0.864-1.46c0.371-0.32,0.807-0.48,1.308-0.48c0.149,0,0.29,0.011,0.421,0.032
			c0.13,0.021,0.26,0.051,0.388,0.088l-0.056,1.512c-0.145-0.037-0.301-0.071-0.469-0.1s-0.321-0.044-0.46-0.044
			c-0.438,0-0.795,0.116-1.071,0.348c-0.278,0.232-0.483,0.565-0.616,1c-0.134,0.435-0.2,0.956-0.2,1.564H318.334z"
                />
              </g>
              <g opacity="0.5">
                <path
                  fill="#FFFFFF"
                  d="M640.637,67.957V57.765h3.944c0.763,0,1.396,0.123,1.899,0.368c0.505,0.246,0.882,0.571,1.133,0.976
			c0.25,0.406,0.376,0.851,0.376,1.336c0,0.582-0.162,1.083-0.484,1.504s-0.793,0.712-1.412,0.872
			c0.405,0.07,0.763,0.215,1.072,0.436c0.31,0.222,0.553,0.495,0.731,0.82s0.269,0.685,0.269,1.08c0,0.336-0.059,0.669-0.176,1
			c-0.118,0.331-0.312,0.632-0.584,0.904c-0.272,0.272-0.638,0.489-1.097,0.652c-0.458,0.163-1.031,0.244-1.72,0.244H640.637z
			 M642.157,59.077v3.136h2.368c0.592,0,1.057-0.141,1.396-0.424s0.508-0.675,0.508-1.176c0-0.475-0.164-0.849-0.492-1.124
			c-0.327-0.275-0.793-0.412-1.396-0.412H642.157z M642.157,66.628h2.399c0.693,0,1.196-0.144,1.508-0.432
			c0.312-0.288,0.469-0.662,0.469-1.12c0-0.464-0.166-0.837-0.496-1.12c-0.331-0.283-0.824-0.424-1.48-0.424h-2.399V66.628z"
                />
                <path
                  fill="#FFFFFF"
                  d="M653.012,68.165c-0.645,0-1.181-0.113-1.606-0.34c-0.427-0.227-0.746-0.524-0.956-0.892
			c-0.211-0.368-0.316-0.76-0.316-1.176c0-0.506,0.129-0.924,0.388-1.252c0.259-0.328,0.617-0.572,1.076-0.732
			c0.458-0.16,0.987-0.24,1.583-0.24c0.412,0,0.812,0.037,1.201,0.112s0.773,0.173,1.152,0.296v0.92
			c-0.311-0.112-0.652-0.201-1.029-0.268c-0.375-0.067-0.727-0.1-1.051-0.1c-0.486,0-0.891,0.096-1.217,0.288
			c-0.325,0.192-0.488,0.517-0.488,0.976c0,0.336,0.129,0.625,0.385,0.868s0.66,0.364,1.215,0.364c0.406,0,0.764-0.092,1.072-0.276
			c0.311-0.184,0.551-0.415,0.721-0.692s0.256-0.555,0.256-0.832h0.367c0,0.533-0.107,1.025-0.322,1.476
			c-0.217,0.451-0.529,0.813-0.941,1.088C654.09,68.027,653.594,68.165,653.012,68.165z M655.588,67.957l-0.191-1.768v-3.208
			c0-0.629-0.131-1.086-0.393-1.372c-0.26-0.285-0.674-0.428-1.24-0.428c-0.318,0-0.604,0.046-0.852,0.14
			c-0.248,0.094-0.441,0.233-0.584,0.42c-0.141,0.187-0.211,0.421-0.211,0.704h-1.681c0-0.565,0.139-1.024,0.416-1.376
			s0.666-0.609,1.164-0.772c0.499-0.163,1.081-0.244,1.747-0.244c0.625,0,1.174,0.103,1.648,0.308
			c0.475,0.206,0.844,0.527,1.109,0.964c0.264,0.437,0.395,1.002,0.395,1.696v4.936H655.588z"
                />
                <path
                  fill="#FFFFFF"
                  d="M659.804,67.957v-7.72h1.521v7.72H659.804z M660.908,64.517c0-0.987,0.103-1.807,0.308-2.46
			c0.206-0.653,0.493-1.14,0.864-1.46c0.371-0.32,0.807-0.48,1.308-0.48c0.149,0,0.29,0.011,0.421,0.032
			c0.13,0.021,0.26,0.051,0.388,0.088l-0.056,1.512c-0.145-0.037-0.301-0.071-0.469-0.1s-0.321-0.044-0.46-0.044
			c-0.438,0-0.795,0.116-1.071,0.348c-0.278,0.232-0.483,0.565-0.616,1c-0.134,0.435-0.2,0.956-0.2,1.564H660.908z"
                />
              </g>
              <g opacity="0.5">
                <path
                  fill="#FFFFFF"
                  d="M532.923,266.808h1.704l2.568,8.848h-0.416l2.592-7.952h1.032l2.607,7.864h-0.384l2.84-8.76h1.712
			L543.619,277h-1.601l-2.199-6.528h0.216L537.787,277h-1.592L532.923,266.808z"
                />
                <path
                  fill="#FFFFFF"
                  d="M552.563,277.208c-0.901,0-1.679-0.22-2.332-0.66s-1.158-1.061-1.516-1.864s-0.536-1.745-0.536-2.828
			c0-1.141,0.189-2.1,0.568-2.876c0.378-0.776,0.899-1.363,1.563-1.76c0.664-0.397,1.415-0.596,2.252-0.596
			c1.125,0,2.021,0.279,2.688,0.836c0.666,0.558,1.109,1.281,1.328,2.172l-1.624,0.312c-0.192-0.592-0.496-1.065-0.912-1.42
			c-0.416-0.354-0.909-0.532-1.48-0.532c-0.501,0-0.96,0.144-1.376,0.432c-0.416,0.288-0.749,0.719-1,1.292
			c-0.251,0.574-0.376,1.287-0.376,2.14c0,0.79,0.104,1.481,0.312,2.076s0.515,1.057,0.92,1.388c0.405,0.331,0.912,0.496,1.52,0.496
			c0.741,0,1.325-0.199,1.752-0.596c0.427-0.397,0.641-0.916,0.641-1.556h1.64c0,0.747-0.167,1.383-0.5,1.908
			c-0.333,0.525-0.802,0.93-1.404,1.212C554.088,277.066,553.379,277.208,552.563,277.208z"
                />
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M367.258,150.534v-1.836h9.359v1.836h-4.094l0.477-0.486v10.332h-2.124v-10.332l0.478,0.486H367.258z"
                />
                <path
                  fill="#FFFFFF"
                  d="M376.862,160.38v-8.766h2.123v8.766H376.862z M378.463,156.546c0-1.146,0.129-2.094,0.387-2.844
			c0.258-0.75,0.605-1.306,1.04-1.669c0.435-0.363,0.919-0.544,1.453-0.544c0.144,0,0.28,0.009,0.409,0.027
			c0.129,0.018,0.257,0.045,0.383,0.081l-0.035,2.124c-0.156-0.036-0.324-0.066-0.504-0.09c-0.181-0.024-0.343-0.036-0.486-0.036
			c-0.469,0-0.859,0.113-1.175,0.337c-0.315,0.225-0.552,0.557-0.711,0.995s-0.239,0.978-0.239,1.62H378.463z"
                />
                <path
                  fill="#FFFFFF"
                  d="M385.168,150.048c-0.414,0-0.736-0.109-0.967-0.329c-0.232-0.219-0.348-0.523-0.348-0.914
			c0-0.378,0.115-0.679,0.348-0.904c0.23-0.225,0.553-0.337,0.967-0.337c0.408,0,0.729,0.112,0.963,0.337s0.352,0.526,0.352,0.904
			c0,0.39-0.117,0.695-0.352,0.914C385.897,149.939,385.576,150.048,385.168,150.048z M384.106,160.38v-8.766h2.125v8.766H384.106z"
                />
                <path
                  fill="#FFFFFF"
                  d="M388.733,153.054v-1.404h5.723v1.404H388.733z M392.781,160.632c-0.516,0-0.988-0.096-1.417-0.288
			c-0.429-0.192-0.771-0.486-1.026-0.882c-0.255-0.396-0.383-0.9-0.383-1.512v-8.622h2.125v8.55c0,0.72,0.342,1.08,1.025,1.08
			c0.211,0,0.42-0.024,0.631-0.072c0.209-0.048,0.449-0.12,0.719-0.216l0.307,1.494c-0.336,0.132-0.663,0.243-0.98,0.333
			C393.463,160.587,393.13,160.632,392.781,160.632z"
                />
                <path
                  fill="#FFFFFF"
                  d="M395.832,153.054v-1.404h5.725v1.404H395.832z M399.883,160.632c-0.517,0-0.989-0.096-1.418-0.288
			c-0.43-0.192-0.771-0.486-1.025-0.882c-0.256-0.396-0.383-0.9-0.383-1.512v-8.622h2.123v8.55c0,0.72,0.343,1.08,1.027,1.08
			c0.209,0,0.42-0.024,0.629-0.072c0.211-0.048,0.45-0.12,0.721-0.216l0.306,1.494c-0.336,0.132-0.663,0.243-0.981,0.333
			C400.564,160.587,400.231,160.632,399.883,160.632z"
                />
                <path
                  fill="#FFFFFF"
                  d="M404.031,160.38v-12.348h2.124v10.188l-0.738,2.16H404.031z M408.495,160.632
			c-0.547,0-1.045-0.153-1.498-0.459c-0.453-0.306-0.814-0.763-1.085-1.373c-0.27-0.609-0.405-1.373-0.405-2.291h0.648
			c0,0.438,0.099,0.822,0.297,1.152c0.197,0.33,0.453,0.586,0.766,0.769c0.311,0.183,0.635,0.275,0.971,0.275
			c0.414,0,0.783-0.114,1.107-0.342s0.58-0.546,0.77-0.954c0.189-0.408,0.283-0.888,0.283-1.44c0-0.534-0.094-1-0.279-1.399
			s-0.441-0.709-0.77-0.932c-0.326-0.222-0.703-0.333-1.129-0.333c-0.354,0-0.684,0.086-0.99,0.257s-0.553,0.417-0.742,0.738
			c-0.189,0.321-0.283,0.709-0.283,1.166h-0.648c0-0.882,0.128-1.626,0.383-2.232s0.617-1.063,1.088-1.373
			c0.473-0.309,1.031-0.463,1.68-0.463c0.707,0,1.352,0.188,1.93,0.562c0.58,0.375,1.041,0.906,1.387,1.593s0.518,1.493,0.518,2.417
			c0,0.936-0.18,1.754-0.535,2.453c-0.357,0.699-0.84,1.242-1.445,1.629C409.906,160.439,409.234,160.632,408.495,160.632z"
                />
                <path
                  fill="#FFFFFF"
                  d="M415.082,160.38v-8.766h2.124v8.766H415.082z M416.685,156.546c0-1.146,0.129-2.094,0.387-2.844
			c0.258-0.75,0.604-1.306,1.039-1.669c0.436-0.363,0.92-0.544,1.454-0.544c0.144,0,0.28,0.009,0.409,0.027
			c0.129,0.018,0.257,0.045,0.383,0.081l-0.036,2.124c-0.156-0.036-0.324-0.066-0.504-0.09c-0.181-0.024-0.342-0.036-0.486-0.036
			c-0.468,0-0.859,0.113-1.175,0.337c-0.314,0.225-0.552,0.557-0.711,0.995c-0.158,0.438-0.238,0.978-0.238,1.62H416.685z"
                />
                <path
                  fill="#FFFFFF"
                  d="M425.405,160.632c-0.744,0-1.425-0.192-2.043-0.576s-1.112-0.922-1.484-1.615s-0.559-1.504-0.559-2.435
			c0-0.96,0.185-1.785,0.554-2.475s0.862-1.218,1.48-1.584c0.618-0.366,1.302-0.549,2.052-0.549c0.696,0,1.298,0.14,1.805,0.418
			s0.915,0.657,1.225,1.134c0.309,0.477,0.517,1.016,0.625,1.616s0.114,1.218,0.018,1.854h-6.065v-1.152h4.562l-0.414,0.27
			c0.019-0.282,0.005-0.567-0.04-0.855c-0.045-0.288-0.131-0.552-0.257-0.792c-0.126-0.24-0.305-0.434-0.535-0.581
			c-0.231-0.147-0.526-0.221-0.887-0.221c-0.432,0-0.798,0.108-1.098,0.324c-0.3,0.216-0.528,0.51-0.685,0.882
			c-0.155,0.372-0.233,0.792-0.233,1.26v0.846c0,0.492,0.073,0.927,0.221,1.305c0.146,0.378,0.365,0.675,0.656,0.891
			c0.291,0.216,0.659,0.324,1.103,0.324c0.414,0,0.763-0.096,1.044-0.288c0.282-0.192,0.462-0.48,0.54-0.864h2.143
			c-0.09,0.564-0.302,1.061-0.635,1.489c-0.333,0.429-0.764,0.765-1.291,1.008C426.677,160.511,426.076,160.632,425.405,160.632z"
                />
                <path
                  fill="#FFFFFF"
                  d="M430.589,153.054v-1.404h5.724v1.404H430.589z M434.639,160.632c-0.516,0-0.988-0.096-1.417-0.288
			c-0.43-0.192-0.771-0.486-1.026-0.882s-0.383-0.9-0.383-1.512v-8.622h2.124v8.55c0,0.72,0.342,1.08,1.026,1.08
			c0.21,0,0.42-0.024,0.63-0.072c0.21-0.048,0.45-0.12,0.72-0.216l0.307,1.494c-0.337,0.132-0.663,0.243-0.981,0.333
			S434.987,160.632,434.639,160.632z"
                />
                <path
                  fill="#FFFFFF"
                  d="M437.69,153.054v-1.404h5.724v1.404H437.69z M441.74,160.632c-0.516,0-0.988-0.096-1.417-0.288
			c-0.43-0.192-0.771-0.486-1.026-0.882s-0.383-0.9-0.383-1.512v-8.622h2.124v8.55c0,0.72,0.342,1.08,1.026,1.08
			c0.21,0,0.42-0.024,0.63-0.072c0.21-0.048,0.45-0.12,0.72-0.216l0.307,1.494c-0.337,0.132-0.663,0.243-0.981,0.333
			S442.087,160.632,441.74,160.632z"
                />
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M382.969,464.038v-11.682h7.362v1.836h-5.238v3.078h4.788v1.818h-4.788v4.95H382.969z"
                />
                <path
                  fill="#FFFFFF"
                  d="M395.489,464.29c-0.744,0-1.426-0.192-2.043-0.576c-0.618-0.384-1.113-0.922-1.485-1.615
			s-0.558-1.504-0.558-2.435c0-0.96,0.184-1.785,0.553-2.475s0.862-1.218,1.48-1.584c0.618-0.366,1.303-0.549,2.053-0.549
			c0.695,0,1.297,0.14,1.804,0.418c0.508,0.279,0.915,0.657,1.225,1.134c0.309,0.477,0.518,1.016,0.625,1.616
			c0.108,0.6,0.114,1.218,0.019,1.854h-6.066v-1.152h4.563l-0.414,0.27c0.018-0.282,0.004-0.567-0.041-0.855
			c-0.045-0.288-0.13-0.552-0.256-0.792c-0.126-0.24-0.305-0.434-0.536-0.581c-0.23-0.147-0.526-0.221-0.887-0.221
			c-0.432,0-0.798,0.108-1.098,0.324c-0.3,0.216-0.528,0.51-0.684,0.882c-0.156,0.372-0.234,0.792-0.234,1.26v0.846
			c0,0.492,0.073,0.927,0.221,1.305c0.146,0.378,0.366,0.675,0.657,0.891c0.291,0.216,0.658,0.324,1.103,0.324
			c0.414,0,0.762-0.096,1.044-0.288c0.281-0.192,0.462-0.48,0.54-0.864h2.142c-0.09,0.564-0.302,1.061-0.635,1.489
			c-0.333,0.429-0.764,0.765-1.291,1.008C396.76,464.169,396.16,464.29,395.489,464.29z"
                />
                <path
                  fill="#FFFFFF"
                  d="M401.59,455.272h2.124v5.364c0,0.57,0.141,0.998,0.423,1.282c0.282,0.285,0.669,0.428,1.161,0.428
			c0.42,0,0.803-0.109,1.147-0.329c0.345-0.219,0.621-0.543,0.828-0.972c0.207-0.429,0.311-0.961,0.311-1.597h0.576
			c0,1.068-0.139,1.953-0.414,2.655c-0.276,0.702-0.657,1.227-1.144,1.575c-0.485,0.348-1.047,0.522-1.683,0.522
			c-0.624,0-1.188-0.111-1.692-0.333c-0.504-0.222-0.902-0.579-1.196-1.071c-0.294-0.492-0.441-1.14-0.441-1.944V455.272z
			 M407.584,455.272h2.124v8.766h-2.124V455.272z"
                />
                <path
                  fill="#FFFFFF"
                  d="M416.314,464.29c-0.744,0-1.425-0.192-2.043-0.576s-1.112-0.922-1.484-1.615s-0.559-1.504-0.559-2.435
			c0-0.96,0.185-1.785,0.554-2.475s0.862-1.218,1.48-1.584c0.618-0.366,1.302-0.549,2.052-0.549c0.696,0,1.298,0.14,1.805,0.418
			s0.915,0.657,1.225,1.134c0.309,0.477,0.517,1.016,0.625,1.616s0.114,1.218,0.018,1.854h-6.065v-1.152h4.562l-0.414,0.27
			c0.019-0.282,0.005-0.567-0.04-0.855c-0.045-0.288-0.131-0.552-0.257-0.792c-0.126-0.24-0.305-0.434-0.535-0.581
			c-0.231-0.147-0.526-0.221-0.887-0.221c-0.432,0-0.798,0.108-1.098,0.324c-0.3,0.216-0.528,0.51-0.685,0.882
			c-0.155,0.372-0.233,0.792-0.233,1.26v0.846c0,0.492,0.073,0.927,0.221,1.305c0.146,0.378,0.365,0.675,0.656,0.891
			c0.291,0.216,0.659,0.324,1.103,0.324c0.414,0,0.763-0.096,1.044-0.288c0.282-0.192,0.462-0.48,0.54-0.864h2.143
			c-0.09,0.564-0.302,1.061-0.635,1.489c-0.333,0.429-0.764,0.765-1.291,1.008C417.586,464.169,416.986,464.29,416.314,464.29z"
                />
                <path
                  fill="#FFFFFF"
                  d="M422.596,464.038v-8.766h2.124v8.766H422.596z M424.198,460.204c0-1.146,0.129-2.094,0.387-2.844
			c0.258-0.75,0.604-1.306,1.039-1.669c0.436-0.363,0.92-0.544,1.454-0.544c0.144,0,0.28,0.009,0.409,0.027
			c0.129,0.018,0.257,0.045,0.383,0.081l-0.036,2.124c-0.156-0.036-0.324-0.066-0.504-0.09c-0.181-0.024-0.342-0.036-0.486-0.036
			c-0.468,0-0.859,0.113-1.175,0.337c-0.314,0.225-0.552,0.557-0.711,0.995c-0.158,0.438-0.238,0.978-0.238,1.62H424.198z"
                />
                <path
                  fill="#FFFFFF"
                  d="M428.815,459.538v-1.854h5.058v1.854H428.815z"
                />
                <path
                  fill="#FFFFFF"
                  d="M388.644,488.555c-0.468,0-0.901-0.051-1.301-0.152c-0.398-0.103-0.806-0.237-1.22-0.405l0.036-1.836
			c0.307,0.149,0.629,0.277,0.968,0.383c0.339,0.104,0.671,0.157,0.994,0.157c0.438,0,0.772-0.126,1.004-0.378
			c0.23-0.252,0.347-0.679,0.347-1.278v-9.773h2.124v9.81c0,1.248-0.26,2.139-0.778,2.673
			C390.298,488.289,389.573,488.555,388.644,488.555z M390.516,473.706c-0.402,0-0.722-0.109-0.959-0.328
			c-0.236-0.22-0.355-0.523-0.355-0.914c0-0.378,0.119-0.68,0.355-0.904c0.237-0.225,0.557-0.338,0.959-0.338
			c0.408,0,0.729,0.113,0.963,0.338s0.351,0.526,0.351,0.904c0,0.391-0.116,0.694-0.351,0.914
			C391.244,473.596,390.924,473.706,390.516,473.706z"
                />
                <path
                  fill="#FFFFFF"
                  d="M395.016,475.272h2.124v5.363c0,0.57,0.141,0.998,0.423,1.283c0.281,0.285,0.669,0.427,1.161,0.427
			c0.42,0,0.802-0.109,1.147-0.328c0.345-0.219,0.621-0.543,0.828-0.972c0.206-0.43,0.31-0.962,0.31-1.598h0.576
			c0,1.068-0.138,1.953-0.414,2.655c-0.275,0.701-0.656,1.227-1.143,1.574c-0.486,0.349-1.047,0.522-1.683,0.522
			c-0.625,0-1.188-0.111-1.692-0.333s-0.903-0.579-1.197-1.071s-0.44-1.14-0.44-1.943V475.272z M401.009,475.272h2.124v8.766h-2.124
			V475.272z"
                />
                <path
                  fill="#FFFFFF"
                  d="M406.482,484.038v-8.766h2.124v8.766H406.482z M408.083,480.204c0-1.146,0.129-2.094,0.388-2.844
			c0.258-0.75,0.604-1.307,1.039-1.67s0.92-0.544,1.453-0.544c0.145,0,0.281,0.009,0.41,0.026c0.129,0.019,0.256,0.045,0.383,0.081
			l-0.037,2.124c-0.156-0.036-0.324-0.065-0.504-0.09c-0.18-0.023-0.342-0.036-0.486-0.036c-0.467,0-0.859,0.112-1.174,0.338
			c-0.314,0.225-0.553,0.557-0.711,0.994c-0.159,0.438-0.238,0.979-0.238,1.62H408.083z"
                />
                <path
                  fill="#FFFFFF"
                  d="M412.448,476.711v-1.404h5.724v1.404H412.448z M416.498,484.29c-0.516,0-0.988-0.096-1.417-0.288
			c-0.43-0.191-0.771-0.486-1.026-0.882c-0.255-0.396-0.383-0.9-0.383-1.512v-8.622h2.124v8.55c0,0.72,0.342,1.08,1.026,1.08
			c0.21,0,0.42-0.024,0.63-0.072s0.45-0.12,0.72-0.216l0.307,1.494c-0.337,0.132-0.663,0.243-0.981,0.333
			S416.846,484.29,416.498,484.29z"
                />
                <path
                  fill="#FFFFFF"
                  d="M423.905,484.29c-0.744,0-1.425-0.192-2.043-0.576s-1.112-0.922-1.484-1.615s-0.559-1.505-0.559-2.435
			c0-0.96,0.185-1.785,0.554-2.475c0.369-0.69,0.862-1.219,1.48-1.584c0.618-0.366,1.302-0.55,2.052-0.55
			c0.696,0,1.298,0.14,1.805,0.419s0.915,0.657,1.225,1.134c0.309,0.478,0.517,1.016,0.625,1.615
			c0.108,0.601,0.114,1.219,0.018,1.854h-6.065v-1.152h4.562l-0.414,0.271c0.019-0.282,0.005-0.567-0.04-0.855
			s-0.131-0.552-0.257-0.792c-0.126-0.239-0.305-0.434-0.535-0.58c-0.231-0.147-0.526-0.221-0.887-0.221
			c-0.432,0-0.798,0.108-1.098,0.324s-0.528,0.51-0.685,0.882c-0.155,0.372-0.233,0.792-0.233,1.26v0.847
			c0,0.491,0.073,0.927,0.221,1.305c0.146,0.378,0.365,0.675,0.656,0.891s0.659,0.324,1.103,0.324c0.414,0,0.763-0.096,1.044-0.288
			c0.282-0.191,0.462-0.479,0.54-0.864h2.143c-0.09,0.564-0.302,1.061-0.635,1.49c-0.333,0.429-0.764,0.765-1.291,1.008
			C425.177,484.169,424.576,484.29,423.905,484.29z"
                />
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M76.033,236.811l3.978-11.682h2.664l3.815,11.682h-2.231l-0.81-2.52h-4.338l-0.828,2.52H76.033z
			 M79.651,232.689h3.295l-1.639-5.022L79.651,232.689z"
                />
                <path
                  fill="#FFFFFF"
                  d="M88.57,236.811v-8.766h2.124v8.766H88.57z M90.172,232.977c0-1.146,0.129-2.094,0.387-2.844
			c0.258-0.75,0.604-1.306,1.039-1.669c0.436-0.363,0.92-0.544,1.453-0.544c0.145,0,0.281,0.009,0.41,0.027
			c0.129,0.018,0.256,0.045,0.383,0.081l-0.036,2.124c-0.156-0.036-0.324-0.066-0.504-0.09c-0.181-0.024-0.343-0.036-0.486-0.036
			c-0.468,0-0.859,0.113-1.175,0.337c-0.314,0.225-0.552,0.557-0.711,0.995c-0.158,0.438-0.238,0.978-0.238,1.62H90.172z"
                />
                <path
                  fill="#FFFFFF"
                  d="M94.537,229.485v-1.404h5.723v1.404H94.537z M98.586,237.063c-0.516,0-0.988-0.096-1.417-0.288
			c-0.429-0.192-0.771-0.486-1.026-0.882c-0.255-0.396-0.383-0.9-0.383-1.512v-8.622h2.125v8.55c0,0.72,0.342,1.08,1.025,1.08
			c0.211,0,0.42-0.024,0.631-0.072c0.209-0.048,0.449-0.12,0.719-0.216l0.307,1.494c-0.336,0.132-0.663,0.243-0.98,0.333
			C99.268,237.018,98.935,237.063,98.586,237.063z"
                />
                <path
                  fill="#FFFFFF"
                  d="M105.993,237.063c-0.841,0-1.562-0.115-2.165-0.347c-0.603-0.23-1.066-0.563-1.391-0.999
			c-0.323-0.435-0.488-0.958-0.494-1.571h1.962c0.018,0.432,0.188,0.747,0.513,0.945c0.324,0.198,0.766,0.297,1.323,0.297
			c0.306,0,0.577-0.045,0.814-0.135c0.237-0.09,0.421-0.213,0.554-0.369c0.131-0.156,0.197-0.33,0.197-0.522
			c0-0.246-0.088-0.439-0.266-0.58c-0.177-0.141-0.46-0.271-0.85-0.392l-1.908-0.684c-0.684-0.246-1.209-0.57-1.575-0.972
			c-0.366-0.402-0.549-0.882-0.549-1.44c0-0.738,0.335-1.333,1.003-1.787c0.67-0.453,1.564-0.679,2.688-0.679
			c1.115,0,1.979,0.245,2.587,0.733c0.608,0.489,0.923,1.108,0.94,1.858h-1.998c-0.029-0.288-0.168-0.516-0.414-0.684
			c-0.246-0.167-0.617-0.252-1.115-0.252c-0.451,0-0.803,0.092-1.059,0.275c-0.254,0.183-0.382,0.397-0.382,0.643
			c0,0.414,0.354,0.726,1.062,0.936l2.07,0.774c0.672,0.229,1.193,0.522,1.566,0.882c0.371,0.36,0.558,0.816,0.558,1.368
			c0,0.462-0.155,0.899-0.464,1.31c-0.309,0.411-0.739,0.746-1.291,1.004C107.358,236.934,106.719,237.063,105.993,237.063z"
                />
                <path
                  fill="#FFFFFF"
                  d="M112.231,241.077v-12.996h1.746l0.377,2.16v10.835H112.231z M116.604,237.063
			c-0.641,0-1.188-0.138-1.637-0.414c-0.451-0.276-0.795-0.694-1.031-1.255s-0.355-1.265-0.355-2.11h0.773
			c0,0.39,0.09,0.723,0.271,0.999c0.18,0.276,0.418,0.487,0.715,0.634s0.617,0.221,0.959,0.221c0.354,0,0.703-0.098,1.047-0.292
			c0.346-0.195,0.633-0.492,0.861-0.891c0.227-0.399,0.342-0.905,0.342-1.517c0-0.558-0.098-1.04-0.289-1.444
			c-0.191-0.405-0.455-0.716-0.787-0.932s-0.713-0.324-1.139-0.324c-0.359,0-0.689,0.081-0.99,0.243
			c-0.299,0.162-0.539,0.397-0.719,0.707c-0.182,0.309-0.271,0.683-0.271,1.121h-0.773c0-0.918,0.141-1.669,0.424-2.254
			c0.281-0.585,0.66-1.019,1.133-1.301c0.475-0.282,0.994-0.423,1.557-0.423c0.691,0,1.34,0.183,1.949,0.549
			c0.609,0.366,1.104,0.893,1.48,1.58c0.379,0.687,0.566,1.514,0.566,2.479c0,0.972-0.191,1.803-0.576,2.493
			c-0.383,0.69-0.885,1.218-1.506,1.584S117.319,237.063,116.604,237.063z"
                />
                <path
                  fill="#FFFFFF"
                  d="M125.91,237.063c-0.75,0-1.376-0.131-1.877-0.392s-0.877-0.604-1.129-1.03
			c-0.252-0.426-0.378-0.888-0.378-1.386c0-0.552,0.146-1.015,0.437-1.39s0.703-0.657,1.237-0.846s1.164-0.284,1.89-0.284
			c0.475,0,0.923,0.036,1.346,0.108c0.423,0.072,0.845,0.168,1.265,0.288v1.242c-0.33-0.108-0.69-0.192-1.08-0.252
			s-0.756-0.09-1.098-0.09c-0.504,0-0.918,0.093-1.242,0.279s-0.486,0.501-0.486,0.945c0,0.324,0.132,0.603,0.396,0.837
			c0.264,0.234,0.684,0.351,1.26,0.351c0.438,0,0.81-0.096,1.116-0.288c0.306-0.192,0.538-0.432,0.697-0.72s0.238-0.576,0.238-0.864
			h0.504c0,0.63-0.117,1.211-0.351,1.742c-0.234,0.531-0.581,0.956-1.04,1.273S126.588,237.063,125.91,237.063z M128.773,236.811
			l-0.271-2.124v-3.51c0-0.612-0.124-1.065-0.373-1.359c-0.249-0.294-0.671-0.441-1.265-0.441c-0.342,0-0.639,0.051-0.892,0.153
			c-0.252,0.102-0.445,0.247-0.58,0.436c-0.135,0.189-0.202,0.419-0.202,0.689h-2.322c0-0.66,0.159-1.199,0.477-1.616
			c0.318-0.417,0.775-0.723,1.373-0.918c0.597-0.195,1.312-0.292,2.146-0.292c0.756,0,1.416,0.122,1.979,0.364
			c0.564,0.243,1.002,0.617,1.314,1.121c0.312,0.504,0.468,1.149,0.468,1.935v5.562H128.773z"
                />
                <path
                  fill="#FFFFFF"
                  d="M137.053,237.063c-0.732,0-1.412-0.19-2.039-0.571c-0.627-0.381-1.134-0.918-1.521-1.611
			c-0.388-0.693-0.581-1.507-0.581-2.443c0-0.99,0.193-1.829,0.581-2.516c0.387-0.687,0.894-1.207,1.521-1.562
			c0.627-0.354,1.307-0.531,2.039-0.531c0.569,0,1.072,0.091,1.507,0.274c0.436,0.183,0.806,0.429,1.112,0.738
			c0.306,0.309,0.546,0.653,0.72,1.031s0.285,0.759,0.333,1.143l-2.143,0.054c-0.096-0.384-0.274-0.702-0.535-0.954
			c-0.261-0.252-0.599-0.378-1.013-0.378c-0.342,0-0.663,0.095-0.963,0.284s-0.544,0.481-0.733,0.877s-0.283,0.909-0.283,1.539
			c0,0.6,0.088,1.1,0.266,1.499c0.177,0.399,0.415,0.699,0.715,0.9c0.3,0.201,0.633,0.302,0.999,0.302
			c0.54,0,0.934-0.131,1.18-0.392c0.245-0.261,0.368-0.61,0.368-1.048h2.143c0,0.618-0.142,1.182-0.423,1.692
			c-0.282,0.51-0.696,0.917-1.242,1.219C138.514,236.912,137.845,237.063,137.053,237.063z"
                />
                <path
                  fill="#FFFFFF"
                  d="M146.53,237.063c-0.744,0-1.425-0.192-2.043-0.576s-1.112-0.922-1.484-1.615s-0.559-1.504-0.559-2.435
			c0-0.96,0.185-1.785,0.554-2.475s0.862-1.218,1.48-1.584c0.618-0.366,1.302-0.549,2.052-0.549c0.696,0,1.298,0.14,1.805,0.418
			s0.915,0.657,1.225,1.134c0.309,0.477,0.517,1.016,0.625,1.616s0.114,1.218,0.018,1.854h-6.065v-1.152h4.562l-0.414,0.27
			c0.019-0.282,0.005-0.567-0.04-0.855c-0.045-0.288-0.131-0.552-0.257-0.792c-0.126-0.24-0.305-0.434-0.535-0.581
			c-0.231-0.147-0.526-0.221-0.887-0.221c-0.432,0-0.798,0.108-1.098,0.324c-0.3,0.216-0.528,0.51-0.685,0.882
			c-0.155,0.372-0.233,0.792-0.233,1.26v0.846c0,0.492,0.073,0.927,0.221,1.305c0.146,0.378,0.365,0.675,0.656,0.891
			c0.291,0.216,0.659,0.324,1.103,0.324c0.414,0,0.763-0.096,1.044-0.288c0.282-0.192,0.462-0.48,0.54-0.864h2.143
			c-0.09,0.564-0.302,1.061-0.635,1.489c-0.333,0.429-0.764,0.765-1.291,1.008C147.802,236.942,147.201,237.063,146.53,237.063z"
                />
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M725.96,167.333v-1.836l6.786-8.658v0.774l-6.786-0.018v-1.944h8.838v1.818l-6.372,8.172l-0.054-0.27
			l6.426,0.018v1.944H725.96z"
                />
                <path
                  fill="#FFFFFF"
                  d="M740.891,167.585c-0.744,0-1.425-0.192-2.043-0.576c-0.617-0.384-1.113-0.922-1.484-1.615
			c-0.373-0.693-0.559-1.504-0.559-2.435c0-0.96,0.185-1.785,0.554-2.475s0.862-1.218,1.48-1.584
			c0.618-0.366,1.302-0.549,2.052-0.549c0.696,0,1.298,0.14,1.805,0.418c0.508,0.279,0.915,0.657,1.225,1.134
			c0.309,0.477,0.518,1.016,0.625,1.616s0.113,1.218,0.018,1.854h-6.065v-1.152h4.563l-0.414,0.27
			c0.018-0.282,0.004-0.567-0.041-0.855c-0.045-0.288-0.131-0.552-0.256-0.792c-0.127-0.24-0.305-0.434-0.536-0.581
			s-0.526-0.221-0.887-0.221c-0.432,0-0.798,0.108-1.098,0.324c-0.3,0.216-0.528,0.51-0.685,0.882s-0.233,0.792-0.233,1.26v0.846
			c0,0.492,0.073,0.927,0.22,1.305c0.147,0.378,0.367,0.675,0.658,0.891c0.291,0.216,0.658,0.324,1.102,0.324
			c0.414,0,0.762-0.096,1.045-0.288c0.281-0.192,0.461-0.48,0.539-0.864h2.143c-0.09,0.564-0.302,1.061-0.635,1.489
			c-0.333,0.429-0.764,0.765-1.291,1.008C742.163,167.463,741.563,167.585,740.891,167.585z"
                />
                <path
                  fill="#FFFFFF"
                  d="M748.415,157.001c-0.414,0-0.737-0.109-0.968-0.329c-0.23-0.219-0.346-0.523-0.346-0.914
			c0-0.378,0.115-0.679,0.346-0.904s0.554-0.337,0.968-0.337c0.407,0,0.729,0.112,0.963,0.337s0.351,0.526,0.351,0.904
			c0,0.39-0.117,0.695-0.351,0.914C749.145,156.891,748.823,157.001,748.415,157.001z M747.354,167.333v-8.766h2.123v8.766H747.354z
			"
                />
                <path
                  fill="#FFFFFF"
                  d="M751.979,160.006v-1.404h5.725v1.404H751.979z M756.03,167.585c-0.516,0-0.988-0.096-1.418-0.288
			c-0.43-0.192-0.771-0.486-1.025-0.882c-0.256-0.396-0.383-0.9-0.383-1.512v-8.622h2.123v8.55c0,0.72,0.342,1.08,1.027,1.08
			c0.209,0,0.42-0.024,0.629-0.072c0.211-0.048,0.451-0.12,0.721-0.216l0.307,1.494c-0.338,0.132-0.664,0.243-0.982,0.333
			S756.377,167.585,756.03,167.585z"
                />
                <path
                  fill="#FFFFFF"
                  d="M760.178,167.333v-12.348h2.123v12.348H760.178z"
                />
                <path
                  fill="#FFFFFF"
                  d="M769.096,167.585c-0.799,0-1.52-0.171-2.164-0.513c-0.645-0.342-1.156-0.853-1.535-1.534
			c-0.377-0.681-0.566-1.535-0.566-2.561c0-1.044,0.189-1.908,0.566-2.592c0.379-0.684,0.891-1.194,1.535-1.53
			s1.365-0.504,2.164-0.504c0.787,0,1.498,0.168,2.137,0.504c0.641,0.336,1.148,0.846,1.527,1.53
			c0.377,0.684,0.566,1.548,0.566,2.592c0,1.026-0.189,1.879-0.566,2.561c-0.379,0.681-0.887,1.192-1.527,1.534
			C770.594,167.414,769.883,167.585,769.096,167.585z M769.024,165.731c0.42,0,0.793-0.104,1.121-0.311
			c0.326-0.207,0.584-0.514,0.773-0.922s0.283-0.915,0.283-1.521c0-0.924-0.182-1.62-0.543-2.088
			c-0.363-0.468-0.859-0.702-1.49-0.702c-0.426,0-0.805,0.104-1.135,0.311s-0.588,0.518-0.777,0.932
			c-0.189,0.414-0.283,0.93-0.283,1.548c0,0.912,0.184,1.599,0.553,2.061S768.395,165.731,769.024,165.731z"
                />
                <path
                  fill="#FFFFFF"
                  d="M779.104,167.585c-0.84,0-1.562-0.115-2.164-0.347c-0.604-0.23-1.066-0.563-1.391-0.999
			s-0.49-0.958-0.496-1.571h1.963c0.018,0.432,0.189,0.747,0.514,0.945c0.322,0.198,0.764,0.297,1.322,0.297
			c0.307,0,0.578-0.045,0.814-0.135c0.236-0.09,0.422-0.213,0.553-0.369c0.133-0.156,0.199-0.33,0.199-0.522
			c0-0.246-0.09-0.439-0.266-0.58c-0.178-0.141-0.461-0.271-0.852-0.392l-1.908-0.684c-0.684-0.246-1.209-0.57-1.574-0.972
			c-0.365-0.402-0.549-0.882-0.549-1.44c0-0.738,0.334-1.333,1.004-1.787c0.668-0.453,1.564-0.679,2.686-0.679
			c1.117,0,1.979,0.245,2.588,0.733c0.609,0.489,0.922,1.108,0.941,1.858h-1.998c-0.031-0.288-0.168-0.516-0.414-0.684
			c-0.246-0.167-0.619-0.252-1.117-0.252c-0.449,0-0.803,0.092-1.057,0.275c-0.256,0.183-0.383,0.397-0.383,0.643
			c0,0.414,0.354,0.726,1.062,0.936l2.07,0.774c0.672,0.229,1.193,0.522,1.564,0.882c0.373,0.36,0.559,0.816,0.559,1.368
			c0,0.462-0.154,0.899-0.463,1.31c-0.311,0.411-0.74,0.746-1.293,1.004C780.469,167.456,779.83,167.585,779.104,167.585z"
                />
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M1.656,14.652V2.97h7.506v1.836H3.781v3.078h5.058V9.72H3.781v3.078h5.382v1.854H1.656z"
                />
                <path
                  fill="#FFFFFF"
                  d="M12.231,14.652V5.886h2.124v8.766H12.231z M13.833,10.818c0-1.146,0.129-2.094,0.387-2.844
			c0.258-0.75,0.604-1.306,1.039-1.669c0.436-0.363,0.92-0.544,1.454-0.544c0.144,0,0.28,0.009,0.409,0.027
			c0.129,0.018,0.257,0.045,0.383,0.081l-0.036,2.124c-0.156-0.036-0.324-0.066-0.504-0.09c-0.181-0.024-0.342-0.036-0.486-0.036
			c-0.468,0-0.859,0.113-1.175,0.337c-0.314,0.225-0.552,0.557-0.711,0.995c-0.158,0.438-0.238,0.978-0.238,1.62H13.833z"
                />
                <path
                  fill="#FFFFFF"
                  d="M22.518,14.904c-0.732,0-1.4-0.193-2.003-0.581c-0.604-0.387-1.083-0.93-1.44-1.629
			c-0.356-0.699-0.535-1.517-0.535-2.453c0-0.924,0.173-1.729,0.518-2.417s0.807-1.218,1.386-1.593s1.223-0.562,1.931-0.562
			c0.648,0,1.209,0.154,1.683,0.463c0.475,0.309,0.838,0.767,1.09,1.373s0.378,1.35,0.378,2.232h-0.666
			c0-0.456-0.094-0.844-0.279-1.166c-0.187-0.321-0.431-0.567-0.733-0.738s-0.632-0.257-0.985-0.257
			c-0.427,0-0.803,0.111-1.13,0.333c-0.327,0.222-0.583,0.533-0.77,0.932c-0.186,0.399-0.279,0.866-0.279,1.399
			c0,0.552,0.094,1.032,0.279,1.44c0.187,0.408,0.442,0.726,0.77,0.954s0.697,0.342,1.111,0.342c0.336,0,0.658-0.092,0.968-0.275
			c0.309-0.183,0.561-0.439,0.756-0.769c0.195-0.33,0.293-0.714,0.293-1.152h0.666c0,0.918-0.137,1.682-0.41,2.291
			c-0.272,0.609-0.638,1.066-1.093,1.373C23.565,14.751,23.064,14.904,22.518,14.904z M25.253,14.652l-0.396-2.16V2.304h2.142
			v12.348H25.253z"
                />
                <path
                  fill="#FFFFFF"
                  d="M33.713,19.169c-1.002,0-1.858-0.138-2.569-0.414c-0.711-0.275-1.256-0.637-1.634-1.084
			s-0.567-0.932-0.567-1.453c0-0.618,0.226-1.097,0.676-1.436c0.449-0.339,1.017-0.509,1.701-0.509l1.548,0.576
			c-0.589,0-1.041,0.11-1.359,0.333c-0.318,0.222-0.477,0.53-0.477,0.927c0,0.414,0.238,0.745,0.715,0.994
			c0.478,0.249,1.133,0.374,1.967,0.374c0.605,0,1.131-0.09,1.575-0.271c0.443-0.18,0.787-0.414,1.03-0.702
			c0.243-0.287,0.364-0.594,0.364-0.918c0-0.216-0.071-0.393-0.216-0.53c-0.144-0.139-0.366-0.207-0.666-0.207h-2.934
			c-0.589,0-1.124-0.095-1.606-0.284c-0.483-0.189-0.867-0.444-1.152-0.765c-0.285-0.321-0.428-0.679-0.428-1.075
			c0-0.366,0.103-0.683,0.307-0.95c0.203-0.267,0.481-0.473,0.832-0.617s0.745-0.216,1.184-0.216l0.54,0.702
			c-0.174,0-0.341,0.035-0.5,0.104c-0.158,0.069-0.289,0.169-0.392,0.301c-0.102,0.132-0.152,0.297-0.152,0.495
			c0,0.24,0.102,0.435,0.306,0.585s0.559,0.225,1.062,0.225h2.934c0.93,0,1.66,0.171,2.191,0.513
			c0.531,0.342,0.797,0.867,0.797,1.575c0,0.63-0.207,1.229-0.621,1.796c-0.414,0.566-1.001,1.03-1.76,1.39
			C35.65,18.99,34.751,19.169,33.713,19.169z M33.822,11.952c-0.69,0-1.322-0.13-1.895-0.391c-0.573-0.261-1.031-0.625-1.373-1.094
			c-0.342-0.468-0.513-1.017-0.513-1.647c0-0.642,0.171-1.2,0.513-1.674s0.8-0.838,1.373-1.094c0.572-0.255,1.204-0.382,1.895-0.382
			c0.672,0,1.292,0.127,1.862,0.382c0.57,0.255,1.029,0.62,1.377,1.094c0.349,0.474,0.522,1.032,0.522,1.674
			c0,0.63-0.174,1.179-0.522,1.647c-0.348,0.468-0.807,0.833-1.377,1.094C35.114,11.821,34.493,11.952,33.822,11.952z
			 M33.839,10.476c0.552,0,0.966-0.159,1.242-0.477c0.275-0.318,0.414-0.711,0.414-1.179c0-0.504-0.14-0.909-0.419-1.215
			c-0.279-0.306-0.697-0.459-1.255-0.459c-0.54,0-0.954,0.153-1.242,0.459c-0.288,0.306-0.433,0.711-0.433,1.215
			c0,0.468,0.146,0.861,0.437,1.179C32.874,10.317,33.293,10.476,33.839,10.476z M36.917,7.074l-0.972-0.558
			c0-0.522,0.121-0.979,0.364-1.372s0.566-0.698,0.972-0.914c0.405-0.216,0.854-0.324,1.346-0.324L38.519,5.94
			c-0.521,0-0.919,0.091-1.192,0.274C37.054,6.398,36.917,6.684,36.917,7.074z"
                />
                <path
                  fill="#FFFFFF"
                  d="M44.08,14.904c-0.744,0-1.425-0.192-2.043-0.576s-1.112-0.922-1.484-1.615s-0.559-1.504-0.559-2.435
			c0-0.96,0.185-1.785,0.554-2.475s0.862-1.218,1.48-1.584C42.647,5.853,43.33,5.67,44.08,5.67c0.696,0,1.298,0.14,1.805,0.418
			S46.8,6.745,47.11,7.222c0.309,0.477,0.517,1.016,0.625,1.616s0.114,1.218,0.018,1.854h-6.065V9.54h4.562l-0.414,0.27
			c0.019-0.282,0.005-0.567-0.04-0.855c-0.045-0.288-0.131-0.552-0.257-0.792c-0.126-0.24-0.305-0.434-0.535-0.581
			c-0.231-0.147-0.526-0.221-0.887-0.221c-0.432,0-0.798,0.108-1.098,0.324c-0.3,0.216-0.528,0.51-0.685,0.882
			c-0.155,0.372-0.233,0.792-0.233,1.26v0.846c0,0.492,0.073,0.927,0.221,1.305c0.146,0.378,0.365,0.675,0.656,0.891
			c0.291,0.216,0.659,0.324,1.103,0.324c0.414,0,0.763-0.096,1.044-0.288c0.282-0.192,0.462-0.48,0.54-0.864h2.143
			c-0.09,0.564-0.302,1.061-0.635,1.489c-0.333,0.429-0.764,0.765-1.291,1.008C45.353,14.783,44.752,14.904,44.08,14.904z"
                />
                <path
                  fill="#FFFFFF"
                  d="M53.62,14.904c-0.84,0-1.562-0.115-2.164-0.347c-0.604-0.23-1.066-0.563-1.391-0.999
			s-0.489-0.958-0.495-1.571h1.962c0.019,0.432,0.189,0.747,0.514,0.945c0.323,0.198,0.765,0.297,1.322,0.297
			c0.307,0,0.578-0.045,0.814-0.135c0.237-0.09,0.422-0.213,0.554-0.369c0.132-0.156,0.198-0.33,0.198-0.522
			c0-0.246-0.089-0.439-0.266-0.58c-0.177-0.141-0.461-0.271-0.851-0.392l-1.908-0.684c-0.684-0.246-1.209-0.57-1.574-0.972
			c-0.366-0.402-0.55-0.882-0.55-1.44c0-0.738,0.335-1.333,1.004-1.787c0.669-0.453,1.564-0.679,2.687-0.679
			c1.116,0,1.979,0.245,2.588,0.733c0.608,0.489,0.922,1.108,0.94,1.858h-1.998c-0.03-0.288-0.168-0.516-0.414-0.684
			c-0.246-0.167-0.618-0.252-1.116-0.252c-0.45,0-0.803,0.092-1.058,0.275s-0.383,0.397-0.383,0.643
			c0,0.414,0.354,0.726,1.062,0.936l2.07,0.774c0.672,0.229,1.193,0.522,1.565,0.882s0.558,0.816,0.558,1.368
			c0,0.462-0.154,0.899-0.463,1.31c-0.31,0.411-0.739,0.746-1.292,1.004C54.986,14.775,54.347,14.904,53.62,14.904z"
                />
                <path
                  fill="#FFFFFF"
                  d="M63.17,14.904c-0.732,0-1.412-0.19-2.039-0.571c-0.627-0.381-1.134-0.918-1.521-1.611
			c-0.388-0.693-0.581-1.507-0.581-2.443c0-0.99,0.193-1.829,0.581-2.516c0.387-0.687,0.894-1.207,1.521-1.562
			c0.627-0.354,1.307-0.531,2.039-0.531c0.569,0,1.072,0.091,1.507,0.274c0.436,0.183,0.806,0.429,1.112,0.738
			c0.306,0.309,0.546,0.653,0.72,1.031s0.285,0.759,0.333,1.143L64.699,8.91c-0.096-0.384-0.274-0.702-0.535-0.954
			c-0.261-0.252-0.599-0.378-1.013-0.378c-0.342,0-0.663,0.095-0.963,0.284s-0.544,0.481-0.733,0.877s-0.283,0.909-0.283,1.539
			c0,0.6,0.088,1.1,0.266,1.499c0.177,0.399,0.415,0.699,0.715,0.9c0.3,0.201,0.633,0.302,0.999,0.302
			c0.54,0,0.934-0.131,1.18-0.392c0.245-0.261,0.368-0.61,0.368-1.048h2.143c0,0.618-0.142,1.182-0.423,1.692
			c-0.282,0.51-0.696,0.917-1.242,1.219C64.631,14.752,63.962,14.904,63.17,14.904z"
                />
                <path
                  fill="#FFFFFF"
                  d="M69.389,14.652V2.304h2.124v12.348H69.389z M75.383,14.652V9.54c0-0.648-0.156-1.137-0.468-1.467
			c-0.312-0.33-0.72-0.495-1.225-0.495c-0.426,0-0.802,0.117-1.129,0.351c-0.327,0.234-0.584,0.587-0.77,1.058
			c-0.187,0.471-0.279,1.058-0.279,1.76h-0.702c0-1.134,0.142-2.078,0.423-2.831c0.282-0.753,0.682-1.315,1.197-1.688
			c0.517-0.372,1.122-0.558,1.818-0.558c0.72,0,1.321,0.161,1.805,0.481c0.482,0.321,0.846,0.772,1.089,1.354
			s0.364,1.26,0.364,2.034v5.112H75.383z"
                />
                <path
                  fill="#FFFFFF"
                  d="M84.041,14.904c-0.799,0-1.52-0.171-2.165-0.513c-0.645-0.342-1.156-0.853-1.534-1.534
			s-0.567-1.535-0.567-2.561c0-1.044,0.189-1.908,0.567-2.592c0.378-0.684,0.89-1.194,1.534-1.53
			c0.646-0.336,1.366-0.504,2.165-0.504c0.786,0,1.498,0.168,2.137,0.504c0.64,0.336,1.148,0.846,1.526,1.53
			c0.378,0.684,0.566,1.548,0.566,2.592c0,1.026-0.188,1.879-0.566,2.561s-0.887,1.192-1.526,1.534
			C85.539,14.733,84.827,14.904,84.041,14.904z M83.969,13.05c0.42,0,0.793-0.104,1.12-0.311s0.585-0.514,0.774-0.922
			c0.188-0.408,0.283-0.915,0.283-1.521c0-0.924-0.182-1.62-0.544-2.088c-0.363-0.468-0.859-0.702-1.49-0.702
			c-0.426,0-0.804,0.104-1.134,0.311S82.39,8.334,82.2,8.748c-0.189,0.414-0.283,0.93-0.283,1.548c0,0.912,0.185,1.599,0.553,2.061
			C82.839,12.819,83.339,13.05,83.969,13.05z"
                />
                <path
                  fill="#FFFFFF"
                  d="M94.048,14.904c-0.84,0-1.562-0.115-2.164-0.347c-0.604-0.23-1.066-0.563-1.391-0.999
			s-0.489-0.958-0.495-1.571h1.962c0.019,0.432,0.189,0.747,0.514,0.945c0.323,0.198,0.765,0.297,1.322,0.297
			c0.307,0,0.578-0.045,0.814-0.135c0.237-0.09,0.422-0.213,0.554-0.369c0.132-0.156,0.198-0.33,0.198-0.522
			c0-0.246-0.089-0.439-0.266-0.58c-0.177-0.141-0.461-0.271-0.851-0.392l-1.908-0.684c-0.684-0.246-1.209-0.57-1.574-0.972
			c-0.366-0.402-0.55-0.882-0.55-1.44c0-0.738,0.335-1.333,1.004-1.787c0.669-0.453,1.564-0.679,2.687-0.679
			c1.116,0,1.979,0.245,2.588,0.733c0.608,0.489,0.922,1.108,0.94,1.858h-1.998c-0.03-0.288-0.168-0.516-0.414-0.684
			c-0.246-0.167-0.618-0.252-1.116-0.252c-0.45,0-0.803,0.092-1.058,0.275s-0.383,0.397-0.383,0.643
			c0,0.414,0.354,0.726,1.062,0.936l2.07,0.774c0.672,0.229,1.193,0.522,1.565,0.882s0.558,0.816,0.558,1.368
			c0,0.462-0.154,0.899-0.463,1.31c-0.31,0.411-0.739,0.746-1.292,1.004C95.413,14.775,94.775,14.904,94.048,14.904z"
                />
                <path
                  fill="#FFFFFF"
                  d="M103.543,14.904c-0.84,0-1.562-0.115-2.164-0.347c-0.604-0.23-1.066-0.563-1.391-0.999
			s-0.489-0.958-0.495-1.571h1.962c0.019,0.432,0.189,0.747,0.514,0.945c0.323,0.198,0.765,0.297,1.322,0.297
			c0.307,0,0.578-0.045,0.814-0.135c0.237-0.09,0.422-0.213,0.554-0.369c0.132-0.156,0.198-0.33,0.198-0.522
			c0-0.246-0.089-0.439-0.266-0.58c-0.177-0.141-0.461-0.271-0.851-0.392l-1.908-0.684c-0.684-0.246-1.209-0.57-1.574-0.972
			c-0.366-0.402-0.55-0.882-0.55-1.44c0-0.738,0.335-1.333,1.004-1.787c0.669-0.453,1.564-0.679,2.687-0.679
			c1.116,0,1.979,0.245,2.588,0.733c0.608,0.489,0.922,1.108,0.94,1.858h-1.998c-0.03-0.288-0.168-0.516-0.414-0.684
			c-0.246-0.167-0.618-0.252-1.116-0.252c-0.45,0-0.803,0.092-1.058,0.275s-0.383,0.397-0.383,0.643
			c0,0.414,0.354,0.726,1.062,0.936l2.07,0.774c0.672,0.229,1.193,0.522,1.565,0.882s0.558,0.816,0.558,1.368
			c0,0.462-0.154,0.899-0.463,1.31c-0.31,0.411-0.739,0.746-1.292,1.004C104.908,14.775,104.27,14.904,103.543,14.904z"
                />
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M492.676,2.898h1.603v11.754h-2.124V5.436c-0.318,0.144-0.63,0.255-0.937,0.333
			c-0.306,0.078-0.612,0.117-0.918,0.117V3.852c0.408-0.042,0.804-0.137,1.188-0.284C491.872,3.421,492.268,3.198,492.676,2.898z"
                />
                <path
                  fill="#FFFFFF"
                  d="M496.393,13.41c0-0.408,0.139-0.75,0.414-1.026c0.276-0.276,0.63-0.414,1.062-0.414
			c0.432,0,0.792,0.138,1.08,0.414s0.432,0.618,0.432,1.026c0,0.402-0.144,0.737-0.432,1.004c-0.288,0.267-0.648,0.4-1.08,0.4
			c-0.433,0-0.786-0.135-1.062-0.405C496.531,14.139,496.393,13.806,496.393,13.41z"
                />
                <path
                  fill="#FFFFFF"
                  d="M510.127,14.904c-0.811,0-1.556-0.141-2.236-0.423c-0.682-0.282-1.233-0.711-1.656-1.287
			s-0.649-1.308-0.68-2.196h2.088c0,0.45,0.111,0.819,0.333,1.107s0.521,0.502,0.896,0.644c0.375,0.141,0.788,0.211,1.237,0.211
			c0.433,0,0.817-0.057,1.157-0.171c0.339-0.114,0.605-0.278,0.801-0.491c0.194-0.213,0.292-0.466,0.292-0.76
			c0-0.39-0.118-0.696-0.355-0.918c-0.236-0.222-0.616-0.426-1.138-0.612l-2.305-0.918c-0.899-0.354-1.59-0.765-2.069-1.233
			c-0.48-0.468-0.721-1.113-0.721-1.935c0-0.948,0.375-1.716,1.125-2.304c0.75-0.588,1.797-0.882,3.142-0.882
			c1.308,0,2.318,0.305,3.032,0.914c0.715,0.609,1.119,1.457,1.216,2.542h-2.089c-0.077-0.462-0.287-0.833-0.63-1.111
			c-0.342-0.279-0.852-0.419-1.529-0.419c-0.601,0-1.074,0.125-1.422,0.374c-0.349,0.249-0.522,0.562-0.522,0.941
			c0,0.324,0.109,0.58,0.328,0.769c0.22,0.189,0.56,0.371,1.021,0.545l2.557,0.99c0.863,0.336,1.526,0.759,1.988,1.269
			c0.463,0.51,0.693,1.149,0.693,1.917c0,0.702-0.177,1.31-0.531,1.823c-0.354,0.513-0.87,0.91-1.548,1.192
			S511.099,14.904,510.127,14.904z"
                />
                <path
                  fill="#FFFFFF"
                  d="M516.436,7.326V5.922h5.724v1.404H516.436z M520.486,14.904c-0.516,0-0.988-0.096-1.417-0.288
			c-0.43-0.192-0.771-0.486-1.026-0.882s-0.383-0.9-0.383-1.512V3.6h2.124v8.55c0,0.72,0.342,1.08,1.026,1.08
			c0.21,0,0.42-0.024,0.63-0.072c0.21-0.048,0.45-0.12,0.72-0.216l0.307,1.494c-0.337,0.132-0.663,0.243-0.981,0.333
			S520.833,14.904,520.486,14.904z"
                />
                <path
                  fill="#FFFFFF"
                  d="M528.073,14.904c-0.799,0-1.52-0.171-2.165-0.513c-0.645-0.342-1.156-0.853-1.534-1.534
			s-0.567-1.535-0.567-2.561c0-1.044,0.189-1.908,0.567-2.592c0.378-0.684,0.89-1.194,1.534-1.53
			c0.646-0.336,1.366-0.504,2.165-0.504c0.786,0,1.498,0.168,2.137,0.504c0.64,0.336,1.148,0.846,1.526,1.53
			c0.378,0.684,0.566,1.548,0.566,2.592c0,1.026-0.188,1.879-0.566,2.561s-0.887,1.192-1.526,1.534
			C529.571,14.733,528.859,14.904,528.073,14.904z M528,13.05c0.42,0,0.793-0.104,1.12-0.311s0.585-0.514,0.774-0.922
			c0.188-0.408,0.283-0.915,0.283-1.521c0-0.924-0.182-1.62-0.544-2.088c-0.363-0.468-0.859-0.702-1.49-0.702
			c-0.426,0-0.804,0.104-1.134,0.311s-0.589,0.518-0.778,0.932c-0.189,0.414-0.283,0.93-0.283,1.548
			c0,0.912,0.185,1.599,0.553,2.061C526.87,12.819,527.37,13.05,528,13.05z"
                />
                <path
                  fill="#FFFFFF"
                  d="M538.134,14.904c-0.732,0-1.412-0.19-2.039-0.571c-0.627-0.381-1.134-0.918-1.521-1.611
			c-0.388-0.693-0.581-1.507-0.581-2.443c0-0.99,0.193-1.829,0.581-2.516c0.387-0.687,0.894-1.207,1.521-1.562
			c0.627-0.354,1.307-0.531,2.039-0.531c0.569,0,1.072,0.091,1.507,0.274c0.436,0.183,0.806,0.429,1.112,0.738
			c0.306,0.309,0.546,0.653,0.72,1.031s0.285,0.759,0.333,1.143l-2.143,0.054c-0.096-0.384-0.274-0.702-0.535-0.954
			c-0.261-0.252-0.599-0.378-1.013-0.378c-0.342,0-0.663,0.095-0.963,0.284s-0.544,0.481-0.733,0.877s-0.283,0.909-0.283,1.539
			c0,0.6,0.088,1.1,0.266,1.499c0.177,0.399,0.415,0.699,0.715,0.9c0.3,0.201,0.633,0.302,0.999,0.302
			c0.54,0,0.934-0.131,1.18-0.392c0.245-0.261,0.368-0.61,0.368-1.048h2.143c0,0.618-0.142,1.182-0.423,1.692
			c-0.282,0.51-0.696,0.917-1.242,1.219C539.595,14.752,538.926,14.904,538.134,14.904z"
                />
                <path
                  fill="#FFFFFF"
                  d="M544.353,14.652V2.304h2.124v12.348H544.353z M546.189,9.234l3.744-3.312h2.88l-6.624,5.508V9.234z
			 M550.437,14.652l-3.906-5.562l2.017-0.486l4.481,6.048H550.437z"
                />
              </g>
              <path
                id="arrow-left_00000038398986874123537260000015032077260692805014_"
                fill="#FFFFFF"
                d="M481.42,235.671l-1.46,9.559l0,0
		l3.217,3.217l9.556-1.462l-3.217-3.217l-4.213,0.646l7.918-7.918l-1.296-1.296l-7.918,7.918l0.583-4.275L481.42,235.671z"
              />
              <path
                id="arrow-left_00000134214868261446508300000016152841483465469059_"
                fill="#FFFFFF"
                d="M12.777,309.861l-9.559-1.46l0,0
		l-3.217,3.217l1.462,9.556l3.217-3.217l-0.646-4.213l7.918,7.918l1.296-1.296l-7.918-7.918l4.275,0.583L12.777,309.861z"
              />
              <path
                id="arrow-left_00000036228529237227259930000010075554094515905966_"
                fill="#FFFFFF"
                d="M79.731,378.738l7.791-5.727l0,0
		v-4.55l-7.791-5.723v4.55l3.435,2.522H71.969v1.833h11.197l-3.435,2.61V378.738z"
              />
            </g>
            <g id="guides" display="none"></g>
            <g id="cmyk_x5F_Lines" display="none"></g>
            <g id="cmyk_x5F_Grafik" display="none"></g>
            <g id="cmyk_x5F_Text" display="none"></g>
          </svg>
        </div>
      </div>
      <ScrolltoTopbutton />
    </div>
  );
}
