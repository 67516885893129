import React, { useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer id="spacetofooter" className="footer-wrapper">
      <div className="newsletter-wrapper">
        <p className="placeholdertext">
          Unser fleißiger Programmier-Beauftragter arbeitet auf Hochtouren, um
          unsere gesamte Website im neuen Erscheinungsbild erstrahlen zu lassen.
          Bis dahin findest du auf dieser vorläufigen Version nur das Nötigste,
          aber keine Angst: Wir halten dich wie gewohnt über unseren
          Instagram-Account auf dem Laufenden! :)
        </p>
        <div className="socials">
          <a
            className="insta-link"
            href="https://www.instagram.com/luftundlaune.art/"
          >
            <i className="icon-service_insta"></i>
          </a>
          <a className="mail-link" href="mailto:mail@luftundlaune.art">
            <i className="icon-service_mail"></i>
          </a>
        </div>
      </div>
      <div className="flex-wrapper">
        <p className="footnote">&copy; 2022 Luft&Laune</p>
        <div className="otherlinks-wrapper">
          <Link className="otherlinks" to="/Kontakt">
            <p className="footnote">Kontakt</p>
          </Link>
          <Link className="otherlinks" to="/Impressum">
            <p className="footnote">Impressum</p>
          </Link>
          <a
            className="otherlinks"
            href="https://uutech.de/web/datenschutzerklaerung/"
          >
            <p className="footnote">Datenschutz</p>
          </a>
        </div>
      </div>
    </footer>
  );
};
