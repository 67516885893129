import { render } from "react-dom";
import React from "react";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Next from "./seiten/Next";
import Home from "./seiten/Home";
import Impressum from "./seiten/Impressum";
import Kontakt from "./seiten/Kontakt";
import ScrollToTop from "./components/ScrolltoTop";
import Koncept from "./seiten/Koncept";
import Awareness from "./seiten/Awareness";
import FAQ from "./seiten/FAQ";

render(
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Impressum" element={<Impressum />} />
      <Route path="/Kontakt" element={<Kontakt />} />
      <Route path="/Next_Event" element={<Next />} />
      <Route path="/Konzept" element={<Koncept />} />
      <Route path="/Awareness" element={<Awareness />} />
      <Route path="/Tickets" element={<Redirect />} />
      {/* <Route path="/FAQ" element={<FAQ />} /> */}
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

function Redirect() {
  window.location.replace("https://www.yesticket.org/events/de/luftlaune/");
}
